import React from "react";
import VMBC from "../../VMBC";

function MachinesByState(props) {
  const { plan } = props;

  const MachinesByStateChartOptions = (Series) => ({
    chart: {
      type: "donut",
      width: 380,
    },
    colors: ["#5c1ac3", "#e2a03f", "#e7515a", "#e2a03f"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      markers: {
        width: 10,
        height: 10,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 8,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          background: "transparent",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Quicksand, sans-serif",
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: "26px",
              fontFamily: "Quicksand, sans-serif",
              color: "#bfc9d4",
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "Total",
              color: "#888ea8",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce(function (a, b) {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    stroke: {
      show: true,
      width: 25,
      colors: "#0e1726",
    },
    series: Series,
    labels: ["Online", "Offline", "Suspended"],
    responsive: [
      {
        breakpoint: 1599,
        options: {
          chart: {
            width: "350px",
            height: "400px",
          },
          legend: {
            position: "bottom",
          },
        },

        breakpoint: 1439,
        options: {
          chart: {
            width: "250px",
            height: "380px",
          },
          legend: {
            position: "bottom",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "65%",
              },
            },
          },
        },
      },
    ],
  });

  React.useEffect(() => {
    if (document.getElementById("machinesByStateChart")) {
      VMBC.Dashboard.GetMachinesCountByState((res, err) => {
        if (err) {
          VMBC.Alert.Show(err, "error");
        } else {
          document.getElementById("machinesByStateChart").innerHTML = "";
          var chart1 = new window.ApexCharts(
            document.querySelector("#machinesByStateChart"),
            MachinesByStateChartOptions([
              res.Online,
              res.Offline,
              res.Suspended,
            ])
          );
          chart1.render();
        }
      });
    }
  }, []);

  return (
    <div class="widget widget-chart-two">
      <div class="widget-heading">
        <h5>Machines By State</h5>
      </div>
      <div class="widget-content">
        <div className={`row justify-content-center`}>
          <div id="machinesByStateChart" class=""></div>
        </div>
      </div>
    </div>
  );
}

export default MachinesByState;
