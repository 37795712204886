function Navigate(history, target) {
  if (!!history) {
    history.push({ pathname: target });
  } else if (!!History.current) {
    History.current.push({ pathname: target });
  }
}

var History = { current: false };

export default {
  Navigate,
  History,
};
