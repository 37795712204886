import React from "react";
import VMBC from "../../VMBC";
import Demo from "../../Demo";

function VCenter(props) {
  const { VCenter } = props;

  const [editable, setEditable] = React.useState(false);
  const [host, setHost] = React.useState(VCenter.VcenterAddr);
  const [username, setUsername] = React.useState(VCenter.VcenterUser);
  const [password, setPassword] = React.useState("");
  const [sslCrt, setSslCrt] = React.useState("");

  const crtFileRef = React.useRef();

  const submitData = () => {
    if (host == "" || username == "" || password == "") {
      VMBC.Alert.Show("Fields can not be empty");
      return;
    }
    if (
      !crtFileRef.current ||
      !crtFileRef.current.files ||
      !crtFileRef.current.files[0]
    ) {
      crtFileRef.current = {
        files: [
          {
            name: "Not Selected",
          },
        ],
      };
    }
    if (crtFileRef.current.files[0].size > 1024 * 1024) {
      VMBC.Alert.Show("Certificate file can not be larger than 1MB", "error");
      return;
    }

    VMBC.Dialogs.Show({
      Title: "Are you sure you want to reconfigure vcenter credentials?",
      Ignorable: true,
      Actions: [
        {
          className: "btn-sm btn-outline-primary",
          onClick: () => {
            VMBC.Dialogs.Close();
          },
          text: "No",
        },
        {
          className: "btn-sm btn-primary",
          onClick: () => {
            Demo.DelayRandom(() =>
              Demo.DelayRandom(() => {
                VMBC.Alert.Show("Done", "success");
                VMBC.Dialogs.Close();
              })
            );
          },
          text: "Yes",
        },
      ],
      Fields: (
        <>
          <p>
            <span style={{ color: "#fff", fontSize: "15px" }}>
              Host Address:
            </span>{" "}
            {host}
          </p>
          <p>
            <span style={{ color: "#fff", fontSize: "15px" }}>Username:</span>{" "}
            {username}
          </p>
          <p>
            <span style={{ color: "#fff", fontSize: "15px" }}>
              Certificate file name:
            </span>{" "}
            {!!crtFileRef &&
            !!crtFileRef.current &&
            !!crtFileRef.current.files &&
            !!crtFileRef.current.files[0]
              ? crtFileRef.current.files[0].name
              : "---"}
          </p>
        </>
      ),
    });
    setEditable(false);
  };

  return (
    <div className="col-lg-6 col-md-12">
      <div className={`widget`}>
        <div class="widget-heading">
          <h5>Your vCenter Credentials</h5>
        </div>
        <div class="widget-content px-4">
          <div class="input-group mt-2">
            <div class="input-group-prepend">
              <div class="input-group-text">Host Address:</div>
            </div>
            <input
              class="form-control"
              value={
                editable ? host : VCenter ? VCenter.VcenterAddr : "Loading..."
              }
              onChange={(e) => editable && setHost(e.target.value)}
            />
          </div>

          <div class="input-group mt-2">
            <div class="input-group-prepend">
              <div class="input-group-text">Username:</div>
            </div>
            <input
              class="form-control"
              value={
                editable
                  ? username
                  : VCenter
                  ? VCenter.VcenterUser
                  : "Loading..."
              }
              onChange={(e) => editable && setUsername(e.target.value)}
            />
          </div>

          <div class="input-group mt-2">
            <div class="input-group-prepend">
              <div class="input-group-text">Password:</div>
            </div>
            <input
              class="form-control"
              value={
                editable
                  ? password
                  : VCenter
                  ? editable
                    ? ""
                    : "• • • • • • • •"
                  : "Loading..."
              }
              type={"password"}
              onChange={(e) => editable && setPassword(e.target.value)}
            />
          </div>

          {editable ? (
            <div class="input-group mt-2">
              <div class="input-group-prepend">
                <div class="input-group-text">SSL Certificate:</div>
              </div>
              <input
                class="form-control"
                value={
                  !!crtFileRef &&
                  !!crtFileRef.current &&
                  !!crtFileRef.current.files &&
                  !!crtFileRef.current.files[0]
                    ? crtFileRef.current.files[0].name
                    : ""
                }
                style={{ cursor: "default", userSelect: "none" }}
              />
              <div class="input-group-append">
                <input
                  ref={crtFileRef}
                  type="file"
                  onChange={(e) => {
                    if (!e.target.files[0]) {
                      return;
                    }
                    var fr = new FileReader();
                    fr.onloadend = () => {
                      setSslCrt(fr.result);
                    };
                    fr.readAsText(e.target.files[0]);
                  }}
                  style={{ display: "none" }}
                />
                <button
                  className={`btn btn-outline-primary`}
                  onClick={() => crtFileRef.current.click()}
                >
                  Browse
                </button>
              </div>
            </div>
          ) : undefined}

          <div className={`row justify-content-end mt-2`}>
            {editable ? (
              <>
                <button
                  key={1}
                  className={`btn btn-outline-primary ml-1`}
                  onClick={() => {
                    setHost(VCenter.VcenterAddr);
                    setUsername(VCenter.VcenterUser);
                    setPassword("");
                    setEditable(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  key={2}
                  className={`btn btn-primary ml-1`}
                  onClick={submitData}
                >
                  Submit
                </button>
                <button
                  key={3}
                  className={`btn btn-primary ml-1`}
                  onClick={() => {
                    if (host == "" || username == "" || password == "") {
                      VMBC.Alert.Show("Fields can not be empty");
                      return;
                    }
                    if (
                      !crtFileRef.current ||
                      !crtFileRef.current.files ||
                      !crtFileRef.current.files[0]
                    ) {
                      crtFileRef.current = {
                        files: [
                          {
                            name: "Not Selected",
                          },
                        ],
                      };
                    }
                    if (crtFileRef.current.files[0].size > 1024 * 1024) {
                      VMBC.Alert.Show(
                        "Certificate file can not be larger than 1MB",
                        "error"
                      );
                      return;
                    }
                    VMBC.Dialogs.Show({
                      Title: "Checking Connection",
                      Text: "Connecting to vCenter...",
                    });
                    Demo.DelayRandom(() =>
                      Demo.DelayRandom(() =>
                        Demo.DelayRandom(() =>
                          Demo.DelayRandom(() => {
                            VMBC.Dialogs.Show({
                              Title: "Checking Connection",
                              Text: (
                                <p style={{ color: "#fff" }}>
                                  Connection established successfully
                                </p>
                              ),
                              Actions: [
                                {
                                  text: "Ok",
                                  className: "btn-primary btn-sm",
                                  onClick: () => VMBC.Dialogs.Close(),
                                },
                              ],
                            });
                          })
                        )
                      )
                    );
                  }}
                >
                  Check Connection
                </button>
              </>
            ) : (
              <button
                key={4}
                className={`btn btn-primary`}
                onClick={() => {
                  setHost(VCenter.VcenterAddr);
                  setUsername(VCenter.VcenterUser);
                  setPassword("");
                  setEditable(true);
                }}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VCenter;
