import React from "react";
import { withRouter } from "react-router";

import styles from "./index.module.css";

import VMBC from "../VMBC";

function Login(props) {
  const [loading, setLoading] = React.useState(false);

  let login = () => {
    var username = document.getElementById("username").value;
    var password = document.getElementById("password").value;
    if (!username || username === "") {
      VMBC.Alert.Show("Please enter a valid username", "error", 100000);
      return;
    }
    if (!password || password === "") {
      VMBC.Alert.Show("Please enter a valid password", "error");
      return;
    }
    if (props.isModal) {
      setLoading(true);
      VMBC.Auth.login(username, password, (err, gotoInstall) => {
        if (!err) {
          window.$("#loginModal").modal("hide");
          if (gotoInstall) {
            VMBC.Navigate(VMBC.Navigator.History, "/install");
          } else {
            var cb = localStorage.getItem("callback");
            if (!cb) {
              VMBC.Navigate(props.history, "/panel");
            } else {
              localStorage.removeItem("callback");
              window.location.href = cb;
            }
          }
        } else {
          VMBC.Alert.Show(err, "error", 3000);
        }
        setLoading(false);
      });
    } else {
      setLoading(true);
      VMBC.Auth.login(username, password, (err, gotoInstall) => {
        if (err) {
          VMBC.Alert.Show(err, "error", 3000);
        } else {
          window.$("#loginModal").modal("hide");
          if (gotoInstall) {
            VMBC.Navigate(VMBC.Navigator.History, "/install");
          } else {
            VMBC.Navigate(props.history, "/panel");
          }
        }
        setLoading(false);
      });
    }
  };

  React.useEffect(() => {
    if (!props.isModal) {
      window.$("#loginModal").modal({ backdrop: "static", keyboard: false });
    }
  }, []);

  return (
    <>
      {!props.isModal ? <div className={`${styles.bg}`}></div> : undefined}
      <div
        class="modal fade login-modal"
        id="loginModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="loginModalLabel"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header" id="loginModalLabel">
              <h4 class="modal-title">Login</h4>
              {props.isModal ? (
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              ) : undefined}
            </div>
            <div class="modal-body">
              <div class="form-group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-user"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <input
                  type="username"
                  name="username"
                  class="form-control mb-2"
                  defaultValue="admin"
                  onKeyDown={(e) => e.keyCode === 13 && login()}
                  id="username"
                  placeholder="Username"
                />
              </div>
              <div class="form-group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-lock"
                >
                  <rect
                    x="3"
                    y="11"
                    width="18"
                    height="11"
                    rx="2"
                    ry="2"
                  ></rect>
                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                </svg>
                <input
                  type="password"
                  name="password"
                  class="form-control mb-4"
                  onKeyDown={(e) => e.keyCode === 13 && login()}
                  id="password"
                  defaultValue="admin"
                  placeholder="Password"
                />
              </div>
              <button
                type="submit"
                class="btn btn-primary mt-2 mb-2 btn-block"
                onClick={login}
              >
                {loading ? (
                  <span
                    class="spinner-border text-white mr-2 align-self-center loader-sm "
                    style={{ width: "17px", height: "17px" }}
                  ></span>
                ) : undefined}
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Login);
