import React from "react";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import WHMCS from "./WHMCS";
window.AddToLoadingVmboxProccess(1);

function WHMCSRoot(props) {
  return (
    <Switch>
      <Route path={`${props.match.path}/`} exact children={WHMCS} />
    </Switch>
  );
}

export default withRouter(WHMCSRoot);
