import { ManagedObject } from "../../DataTypes";

import axios from "axios";
import Utils from "../../Utils"
import ComputeResource from "./../ComputeResource";

class Datacenter extends ManagedObject {

  constructor() {
    if (arguments.length === 1 && typeof arguments[0] === "string") { // Datacenter ( string DatacenterId )
      super(arguments[0]);
    } else if (arguments.length === 1 && arguments[0]._VMBC_Type && arguments[0]._VMBC_Type === "ManagedObject") { // Datacenter ( ManagedObject Datacenter )
      super(arguments[0].MO_Reference);
    }
  }

  GetDetails(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/datacenter/getDetails",
          { SId: window.VMBC.SId, DatacenterId: this.MO_Reference },
          { timeout: 30000 }
        )
        .then(res => {
          if (!res.data) {
            cb(false)
            return;
          }
          if (res.data.res === "success") {
            this.Name = res.data.data.Name;
            this._Path = res.data.data.Path;
            cb({ Name: this.Name, Path: this._Path });
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(() => {
          cb(false);
        });
    });
  }

  GetComputeResources(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/datacenter/computeResources",
          { SId: window.VMBC.SId, DatacenterId: this.MO_Reference },
          { timeout: 30000 }
        )
        .then(res => {
          if (!res.data) {
            cb(false)
            return;
          }
          if (res.data.res === "success") {
            var ComputeResourceList = res.data.data;
            for (var i = 0; i < ComputeResourceList.length; i++) {
              var CR = ComputeResourceList[i];
              this.ComputeResources.push(new ComputeResource({
                MO_Reference: CR.ComputeResourceId,
                Name: CR.Name,
                Path: CR.Path,
                ResourcePoolId: CR.ResourcePoolId,
                Hosts: CR.Hosts,
                Datastores: CR.Datastores,
                Networks: CR.Networks,
                AutoConstruct: true,
                _SRC: "constructComputeResource"
              }));
            }
            cb(this.ComputeResources);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(res => {
          cb(false);
        });
    });
  }

  GetTemplates(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/datacenter/templates",
          { SId: window.VMBC.SId, DatacenterId: this.MO_Reference },
          { timeout: 30000 }
        )
        .then(res => {
          if (!res.data) {
            cb(false)
            return;
          }
          if (res.data.res === "success") {
            this.Templates = res.data.data;
            cb(this.Templates);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(res => {
          cb(false);
        });
    });
  }

  AddNewTemplate(VmachineId, OsType, VmachineName, Alias, Options, cb) {
    if (typeof VmachineId !== "string" || VmachineId === "" ||
      typeof OsType !== "string" || OsType === "" ||
      typeof VmachineName !== "string" || VmachineName === "" ||
      typeof Alias !== "string" || Alias === "" ||
      typeof Options !== "object") {
      return;
    }
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/datacenter/addTemplate",
          {
            SId: window.VMBC.SId, DatacenterId: this.MO_Reference,
            VmachineId, OsType, VmachineName, Alias,
            Options: Options
          },
          { timeout: 30000 }
        )
        .then(res => {
          if (!res.data) {
            cb(false)
            return;
          }
          if (res.data.res === "success") {
            cb(true);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(res => {
          cb(false);
        });
    });
  }

  DeleteTemplate(templateId, cb) {
    if (typeof templateId !== "string" || templateId === "") {
      return;
    }
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/datacenter/deleteTemplate",
          {
            SId: window.VMBC.SId,
            TemplateId: templateId
          },
          { timeout: 30000 }
        )
        .then(res => {
          if (!res.data) {
            cb(false)
            return;
          }
          if (res.data.res === "success") {
            cb(true);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(res => {
          cb(false);
        });
    });
  }

  EditTemplate(templateId, OsType, Alias, Options, cb) {
    if (typeof OsType !== "string" || OsType === "" ||
      typeof Alias !== "string" || Alias === "" ||
      typeof templateId !== "string" || templateId === "" ||
      typeof Options !== "object") {
      return;
    }
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/datacenter/editTemplate",
          {
            SId: window.VMBC.SId,
            TemplateId: templateId, OsType, Alias, Options
          },
          { timeout: 30000 }
        )
        .then(res => {
          if (!res.data) {
            cb(false)
            return;
          }
          if (res.data.res === "success") {
            cb(true);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(res => {
          cb(false);
        });
    });
  }

  GetTemplateVMs(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/datacenter/templateVMs",
          { SId: window.VMBC.SId, DatacenterId: this.MO_Reference },
          { timeout: 30000 }
        )
        .then(res => {
          if (!res.data) {
            cb(false)
            return;
          }
          if (res.data.res === "success") {
            this.TemplateVMs = res.data.data;
            cb(this.TemplateVMs);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(res => {
          cb(false);
        });
    });
  }

  GetPlans(cb){
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/datacenter/plans",
          { SId: window.VMBC.SId, DatacenterId: this.MO_Reference },
          { timeout: 30000 }
        )
        .then(res => {
          if (!res.data) {
            cb(false)
            return;
          }
          if (res.data.res === "success") {
            this.Plans = res.data.data;
            cb(this.Plans);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(res => {
          cb(false);
        });
    });
  }

  Name = "";
  _Path = "";
  ComputeResources = [];
  Templates = [];
  TemplateVMs = [];
  Plans = [];
  _VMBC_Type = "Datacenter";
}



export default Datacenter;
