import React from "react";
import VMBC from "../../../../../VMBC";

function Customer(props) {
  const { vm, Reload } = props;
  const {
    Id,
    FirstName,
    LastName,
    Email,
    ClientId,
    WhmcsClientLink,
  } = vm.Customer;

  return (
    <div className={``}>
      <div className={`container`}>
        <div className={`row`}>
          <div className={`col col-12 py-2`}>
            <div className={`container`}>
              <div className={`row`}>
                <div
                  className={`col-12 my-2 text-center align-self-center`}
                  style={{ color: "#fff" }}
                >
                  <span style={{ fontSize: ".9rem" }}>Name:</span>
                  <br />
                  {FirstName} {LastName}
                </div>
                <div
                  className={`col-12 my-2 text-center align-self-center`}
                  style={{ color: "#fff" }}
                >
                  <span style={{ fontSize: ".9rem" }}>Email:</span>
                  <br />
                  {Email.length <= 15 ? (
                    <VMBC.A href={``}>{Email}</VMBC.A>
                  ) : (
                    <VMBC.ToolTip title={"Click to Copy"}>
                      <VMBC.A href={``}>
                        <span
                          onClick={() => {
                            navigator.clipboard.writeText(Email);
                            VMBC.Alert.Show(
                              "Email copied to clipboard",
                              "info"
                            );
                          }}
                        >
                          {Email.substr(0, 15)}...
                        </span>
                      </VMBC.A>
                    </VMBC.ToolTip>
                  )}
                </div>

                <div
                  className={`col-12 my-2 text-center align-self-center`}
                  style={{ color: "#fff" }}
                >
                  <span style={{ fontSize: ".9rem" }}>WHMCS Client ID:</span>
                  <br />
                  {ClientId}
                </div>

                <div
                  className={`col-12 my-2 text-center align-self-center`}
                  style={{ color: "#fff" }}
                >
                  <VMBC.A href={`/panel/customers/machines?client=${Id}`}>
                    <button className={`btn btn-primary`}>
                      Show Machines
                    </button>
                  </VMBC.A>
                </div>

                <div
                  className={`col-12 my-2 text-center align-self-center`}
                  style={{ color: "#fff" }}
                >
                  <VMBC.A href={WhmcsClientLink}>
                    <button className={`btn btn-primary`}>
                      Client Details at WHMCS
                    </button>
                  </VMBC.A>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customer;
