import React from "react";
import { withRouter } from "react-router";
import "./index.css";
import VMBC from "../../VMBC";
import RegistrationStatistics from "./RegistrationStatistics";
import MachinesByState from "./MachinesByState";
import FinancialSummary from "./FinancialSummary";
import PlansConditions from "./PlansCondition";
import RecentOrders from "./RecentOrders";
import RecentActions from "./RecentActions";
window.AddToLoadingVmboxProccess(1);

function Dashboard(props) {
  return (
    <>
      <div className="page-header">
        <div className="page-title">
          <h3>Dashboard</h3>
        </div>
      </div>
      <div className="row layout-top-spacing">
        <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <RegistrationStatistics />
        </div>
        <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <MachinesByState />
        </div>
        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <FinancialSummary />
        </div>
        <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <PlansConditions />
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <RecentOrders />
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <RecentActions />
        </div>
      </div>
    </>
  );
}

export default withRouter(Dashboard);
