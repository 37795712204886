import React from "react";
import "./index.css";
import VMBC from "../../../VMBC";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

function RecentActions(props) {
  const [recentActions, setRecentActions] = React.useState();
  const [recentActionsCount, setRecentActionsCount] = React.useState();
  const [refreshT, refresh] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    setRecentActions();
    VMBC.Dashboard.GetRecentActions({ Limit: 10 }, (recentActions, err) => {
      if (err) {
        VMBC.Alert.Show(err, "error");
      } else {
        var acts = recentActions.RecentActions
          ? recentActions.RecentActions
          : [];
        let now = parseInt(Date.now() / 1000);
        acts = acts.map((act) => {
          let when = now - act.StartDate;
          let WhenStr = "";
          if (when < 60) {
            WhenStr = `${when}s ago`;
          } else if (when < 3600) {
            WhenStr = `${parseInt(when / 60)}m ${when % 60}s ago`;
          } else if (when < 86400) {
            WhenStr = `${parseInt(when / 3600)}h ago`;
          } else if (when < 7 * 86400) {
            WhenStr = `${parseInt(when / 86400)}days ago`;
          } else if (when < 4 * 7 * 86400) {
            WhenStr = `${parseInt(when / (7 * 86400))}weeks ago`;
          } else {
            WhenStr = VMBC.Time.UnixToDateString(act.StartDate, {
              OnlyDate: true,
            });
          }

          let IsPending = false;
          let Took = false;
          if (act.EndDate < act.StartDate) {
            IsPending = true;
          } else if (act.EndDate === act.StartDate) {
            Took = "Less than 1s";
          } else {
            Took = VMBC.Time.SecondsToElapsed(act.EndDate - act.StartDate);
          }

          const ActionDetails = Actions[act.ActionName];

          let Icon =
            ActionDetails !== undefined
              ? {
                  ClassName: ActionDetails.IconClassName,
                  Content: ActionDetails.IconContent,
                }
              : {
                  ClassName: "t-dark",
                  Content: <VMBC.SVGs.Server />,
                };

          return {
            ...act,
            when,
            Icon,
            Actor:
              act.ActorType === "C" ? (
                act.ActorName
              ) : act.ActorType === "W" ? (
                "WHMCS"
              ) : act.ActorType === "U" ? (
                act.ActorName
              ) : act.ActorType === "S" ? (
                <VMBC.ToolTip
                  tooltip={{ delayShow: 500 }}
                  title={`These Actions take place when machine's state changes unexpectedly.\nlike powering off the machine from vCenter or ESXi Host.`}
                >
                  <span style={{ cursor: "default" }}>Synchronizer</span>
                </VMBC.ToolTip>
              ) : (
                `Unknown (${act.ActorType})`
              ),
            ActionName:
              ActionDetails && ActionDetails.Name
                ? ActionDetails.Name
                : act.ActionName,
            IsPending,
            Took,
            WhenFullString: VMBC.Time.UnixToDateString(act.StartDate),
            WhenStr,
          };
        });
        setRecentActions(acts);
        setRecentActionsCount(recentActions.Count);
      }
    });
  }, [refreshT]);

  const ShowFailure = (act) => {
    VMBC.Dialogs.Show({
      Title: (
        <div>
          <span style={{ color: "#fff", fontSize: "1.2rem" }}>
            {act.ActionName}
          </span>{" "}
          By {act.Actor}
        </div>
      ),
      Text: (
        <>
          <h5>Time:</h5>
          <p>{VMBC.Time.UnixToDateString(act.Failure.CreatedDate)}</p>
          <h5 className={`mt-2`}>Message:</h5>
          <p style={{ color: "#ccc" }}>{act.Failure.Message}</p>
        </>
      ),
      Ignorable: true,
      Actions: [
        {
          onClick: () => VMBC.Dialogs.Close(),
          className: "btn-sm btn-outline-primary",
          text: "Close",
        },
      ],
    });
  };

  return (
    <>
      <div class="widget widget-table-two">
        <div class="widget-heading">
          <h5 class="">Recent Actions</h5>
        </div>
          <ScrollBar
            className={`widget-content recentActionsContainer mb-3 ${
              !expanded ? "collapsed" : ""
            }`}
          >
            {recentActions ? (
              <>
                <div className="timeline-line">
                  <div className={`mt-2`}></div>

                  {recentActions.map((act) => (
                    <div className="item-timeline">
                      <div className={`t-timespan`}>{act.WhenStr}</div>
                      <div className="t-dot">
                        <div className={act.Icon.ClassName}>
                          {act.Icon.Content}
                        </div>
                      </div>
                      <div className="t-content mr-2">
                        <div className="t-uppercontent">
                          <h5>{act.ActionName}</h5>
                          <span className="">{act.WhenFullString}</span>
                        </div>
                        <p className={`d-flex justify-content-between`}>
                          <span>
                            <span>By</span> {act.Actor}
                          </span>
                          <span>
                            {act.VmName ? (
                              act.VmName.length < 25 ? (
                                act.VmName
                              ) : (
                                <VMBC.A
                                  href={`/panel/customers/machines/${act.VmRecordId}`}
                                >
                                  <VMBC.ToolTip
                                    tooltip={{ delayShow: "500" }}
                                    title={act.VmName}
                                  >
                                    {act.VmName.substr(0, 25)}...
                                  </VMBC.ToolTip>
                                </VMBC.A>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </p>
                        <div className="tags" style={{ cursor: "default" }}>
                          {act.Took ? (
                            <div className="badge badge-primary mr-1">
                              {act.Took}
                            </div>
                          ) : undefined}
                          {act.ActorType === "U" ? (
                            <div className="badge badge-secondary mr-1">
                              VMBox User
                            </div>
                          ) : undefined}
                          {act.IsPending ? (
                            <div className="badge badge-warning mr-1">
                              Pending
                            </div>
                          ) : undefined}
                          {act.Failed ? (
                            <div
                              className="badge badge-danger mr-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => ShowFailure(act)}
                            >
                              Failed
                            </div>
                          ) : undefined}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className={`d-flex pt-4 justify-content-center`}>
                <VMBC.Loader />
              </div>
            )}
          </ScrollBar>
        <div class="tm-action-btn">
          <button class="btn" onClick={() => setExpanded(!expanded)}>
            {expanded ? "Collapse" : "Expand"}
            <VMBC.SVGs.ChevronDown
              className={`expansionDir`}
              style={expanded ? { transform: "rotate(180deg)" } : {}}
            />
          </button>
        </div>
      </div>
    </>
  );
}

const Actions = {
  "Acquire WMKS Ticket": {
    IconClassName: "t-primary",
    IconContent: <VMBC.SVGs.AirPlay />,
  },
  PowerOn: {
    IconClassName: "t-success",
    IconContent: <VMBC.SVGs.Power />,
    Name: "Power On",
  },
  PowerOff: {
    IconClassName: "t-dark",
    IconContent: <VMBC.SVGs.Power />,
    Name: "Power Off",
  },
  NotFinding: {
    IconClassName: "t-danger",
    IconContent: <VMBC.SVGs.Frown />,
    Name: "VM Not Found",
  },
  Deploy: {
    IconClassName: "t-primary",
    IconContent: <VMBC.SVGs.Template />,
  },
  Delete: {
    IconClassName: "t-danger",
    IconContent: <VMBC.SVGs.RecycleBin />,
  },
  SetupOS: {
    IconClassName: "t-success",
    IconContent: <VMBC.SVGs.Zap />,
    Name: "Configuring Guest OS",
  },
};

export default RecentActions;
