import React from "react";
import VMBC from "../../../../../../VMBC";

function LimitNetworkUsage(props) {
  const { data } = props;

  const [enabled, setEnabled] = React.useState(
    data && data.enabled !== undefined ? data.enabled : false
  );

  const [limitTx, setLimitTx] = React.useState(
    data && data.limitTx !== undefined ? data.limitTx : false
  );
  const [limitTxBy, setLimitTxBy] = React.useState(
    data && data.limitTxBy !== undefined ? data.limitTxBy : ""
  );
  const [limitTxByUnit, setLimitTxByUnit] = React.useState(
    data && data.limitTxByUnit !== undefined ? data.limitTxByUnit : 0
  );

  const [limitRx, setLimitRx] = React.useState(
    data && data.limitRx !== undefined ? data.limitRx : false
  );
  const [limitRxBy, setLimitRxBy] = React.useState(
    data && data.limitRxBy !== undefined ? data.limitRxBy : ""
  );
  const [limitRxByUnit, setLimitRxByUnit] = React.useState(
    data && data.limitRxByUnit !== undefined ? data.limitRxByUnit : 0
  );

  const [limitUsage, setLimitUsage] = React.useState(
    data && data.limitUsage !== undefined ? data.limitUsage : false
  );
  const [limitUsageBy, setLimitUsageBy] = React.useState(
    data && data.limitUsageBy !== undefined ? data.limitUsageBy : ""
  );
  const [limitUsageByUnit, setLimitUsageByUnit] = React.useState(
    data && data.limitUsageByUnit !== undefined ? data.limitUsageByUnit : 0
  );

  const [resetQuota, setResetQuota] = React.useState(
    data && data.resetQuota !== undefined ? data.resetQuota : false
  );
  const [resetAfter, setResetAfter] = React.useState(
    data && data.resetAfter !== undefined ? data.resetAfter : 30
  );
  const [resetAfterUnit, setResetAfterUnit] = React.useState(
    data && data.resetAfterUnit !== undefined ? data.resetAfterUnit : 1
  );

  const [limitByRatio, setLimitByRatio] = React.useState(
    data && data.limitByRatio !== undefined ? data.limitByRatio : false
  );
  const [limitRatioType, setLimitRatioType] = React.useState(
    data && data.limitRatioType !== undefined && data.limitRatioType !== 0
      ? data.limitRatioType
      : undefined
  );
  const [maxAllowedRatio, setMaxAllowedRatio] = React.useState(
    data && data.maxAllowedRatio !== undefined ? data.maxAllowedRatio : ""
  );
  const [limitByRatioCheckpoint, setLimitByRatioCheckpoint] = React.useState(
    data && data.limitByRatioCheckpoint !== undefined
      ? data.limitByRatioCheckpoint
      : undefined
  );
  const [
    limitByRatioCheckpointType,
    setLimitByRatioCheckpointType,
  ] = React.useState(
    data && data.limitByRatioCheckpointType !== undefined
      ? data.limitByRatioCheckpointType
      : 0
  );

  React.useEffect(() => {
    window.$(".limitNetSelectpicker").selectpicker("refresh");
  });

  var dataIsChanged = false;
  if (!!data) {
    if (
      enabled !== data.enabled ||
      limitTx !== data.limitTx ||
      limitTxBy !== data.limitTxBy ||
      limitTxByUnit !== data.limitTxByUnit ||
      limitRx !== data.limitRx ||
      limitRxBy !== data.limitRxBy ||
      limitRxByUnit !== data.limitRxByUnit ||
      limitUsage !== data.limitUsage ||
      limitUsageBy !== data.limitUsageBy ||
      limitUsageByUnit !== data.limitUsageByUnit ||
      resetQuota !== data.resetQuota ||
      resetAfter !== data.resetAfter ||
      resetAfterUnit !== data.resetAfterUnit ||
      limitByRatio !== data.limitByRatio ||
      limitRatioType !== data.limitRatioType ||
      maxAllowedRatio !== data.maxAllowedRatio ||
      limitByRatioCheckpoint !== data.limitByRatioCheckpoint ||
      limitByRatioCheckpointType !== data.limitByRatioCheckpointType
    ) {
      dataIsChanged = true;
    }
  }

  const submitChanges = () => {
    if (limitByRatio) {
      if (!limitRatioType || (limitRatioType != 1 && limitRatioType != 2)) {
        VMBC.Alert.Show("Please select a Limit Ratio Type", "error");
        return;
      }
      if (!maxAllowedRatio || maxAllowedRatio === 0) {
        VMBC.Alert.Show(
          "Maximum allowed ratio can not be zero or empty",
          "error"
        );
        return;
      }
      if (!limitByRatioCheckpoint) {
        VMBC.Alert.Show("Please enter a Limit Ratio Checkpoint", "error");
        return;
      }
      if (
        !limitByRatioCheckpointType ||
        limitByRatioCheckpointType < 0 ||
        limitByRatioCheckpointType > 3
      ) {
        VMBC.Alert.Show("Please select a Limit Ratio Checkpoint Type", "error");
        return;
      }
    }
    VMBC.Dialogs.Show({
      Title: "Are you sure?",
      Text: "Are you sure you want to edit network quota specifications?",
      Actions: [
        {
          text: "Yes",
          className: "btn-primary btn-sm",
          onClick: () => {
            VMBC.Plans.EditNetworkQuota(
              data.networkQuotaId,
              {
                PlanId: data.PlanId,
                Disable: !enabled,
                Tx:
                  enabled && limitTx
                    ? limitTxBy * (limitTxByUnit == 1 ? 1024 : 1)
                    : 0,
                Rx:
                  enabled && limitRx
                    ? limitRxBy * (limitRxByUnit == 1 ? 1024 : 1)
                    : 0,
                Usage:
                  enabled && limitUsage
                    ? limitUsageBy * (limitUsageByUnit == 1 ? 1024 : 1)
                    : 0,
                Reset:
                  enabled && resetQuota
                    ? resetAfter * (resetAfterUnit == 1 ? 24 : 1)
                    : 0,
                RatioLimitEnabled: limitByRatio,
                RatioLimitType: Number(limitRatioType),
                RatioAllowed: Number(maxAllowedRatio),
                RatioCheckpoint: Number(limitByRatioCheckpoint),
                RatioCheckpointType: Number(limitByRatioCheckpointType),
              },
              (res, err) => {
                if (err) {
                  VMBC.Alert.Show(err);
                } else {
                  VMBC.Alert.Show(res, "success");
                  VMBC.Dialogs.Close();
                  if (props.Reload) props.Reload();
                }
              }
            );
          },
        },
        {
          text: "No",
          className: "btn-outline-primary btn-sm",
          onClick: () => VMBC.Dialogs.Close(),
        },
      ],
    });
  };

  return (
    <>
      <div className={`card no-outer-spacing`}>
        <div className="card-header" id={"limitNetworkUsage"}>
          <section className="mb-0 mt-0">
            <div
              role="menu"
              className={`collapsed`}
              data-toggle="collapse"
              data-target={`#limitNetworkUsageAcc`}
              aria-expanded="false"
              aria-controls={`limitNetworkUsageAcc`}
            >
              <span style={{ color: "#fff" }}>Measure Network Usage</span>
              <div className="icons">
                <VMBC.SVGs.ChevronDown />
              </div>
            </div>
          </section>
        </div>

        <div
          id={`limitNetworkUsageAcc`}
          className="collapse"
          aria-labelledby={"limitNetworkUsage"}
          data-parent="#toggleAccordion"
        >
          <div className="card-body">
            <div className={`container`}>
              <div className={`row mb-3`}>
                <label class="switch s-primary mb-0 mr-2">
                  <input
                    type="checkbox"
                    checked={enabled}
                    onChange={(e) => setEnabled(e.target.checked)}
                  />
                  <span class="slider round"></span>
                </label>
                Enabled
              </div>

              <div className={`row mb-3`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <label class="switch s-primary mb-0 mr-2">
                        <input
                          disabled={!enabled}
                          type="checkbox"
                          checked={limitTx}
                          onChange={(e) =>
                            enabled && setLimitTx(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                      Limit Transmiting Data
                    </div>
                  </div>
                  <input
                    disabled={!enabled}
                    class="form-control"
                    aria-label="switch"
                    value={limitTxBy}
                    onChange={(e) =>
                      enabled &&
                      limitTx &&
                      setLimitTxBy(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!enabled || !limitTx}
                      className="limitNetSelectpicker"
                      data-width="100%"
                      value={limitTxByUnit}
                      onChange={(e) =>
                        enabled &&
                        limitTx &&
                        setLimitTxByUnit(Number(e.target.value))
                      }
                    >
                      <option value={0}>MB</option>
                      <option value={1}>GB</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`row mb-3`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <label class="switch s-primary mb-0 mr-2">
                        <input
                          disabled={!enabled}
                          type="checkbox"
                          checked={limitRx}
                          onChange={(e) =>
                            enabled && setLimitRx(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                      Limit Recieving Data
                    </div>
                  </div>
                  <input
                    disabled={!enabled}
                    class="form-control"
                    aria-label="switch"
                    value={limitRxBy}
                    onChange={(e) =>
                      enabled &&
                      limitRx &&
                      setLimitRxBy(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!enabled || !limitRx}
                      className="limitNetSelectpicker"
                      data-width="100%"
                      value={limitRxByUnit}
                      onChange={(e) =>
                        enabled &&
                        limitRx &&
                        setLimitRxByUnit(Number(e.target.value))
                      }
                    >
                      <option value={0}>MB</option>
                      <option value={1}>GB</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`row mb-3`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <label class="switch s-primary mb-0 mr-2">
                        <input
                          disabled={!enabled}
                          type="checkbox"
                          checked={limitUsage}
                          onChange={(e) =>
                            enabled && setLimitUsage(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                      Limit Both Recieving and Transmiting Data
                    </div>
                  </div>
                  <input
                    disabled={!enabled}
                    class="form-control"
                    aria-label="switch"
                    value={limitUsageBy}
                    onChange={(e) =>
                      enabled &&
                      limitUsage &&
                      setLimitUsageBy(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!enabled || !limitUsage}
                      className="limitNetSelectpicker"
                      data-width="100%"
                      value={limitUsageByUnit}
                      onChange={(e) =>
                        enabled &&
                        limitUsage &&
                        setLimitUsageByUnit(Number(e.target.value))
                      }
                    >
                      <option value={0}>MB</option>
                      <option value={1}>GB</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`row mb-3`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <label class="switch s-primary mb-0 mr-2">
                        <input
                          disabled={!enabled}
                          type="checkbox"
                          checked={resetQuota}
                          onChange={(e) =>
                            enabled && setResetQuota(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                      Reset Quota after
                    </div>
                  </div>
                  <input
                    disabled={!enabled}
                    class="form-control"
                    aria-label="switch"
                    value={resetAfter}
                    onChange={(e) =>
                      enabled &&
                      resetQuota &&
                      setResetAfter(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!enabled || !resetQuota}
                      className="limitNetSelectpicker"
                      data-width="100%"
                      value={resetAfterUnit}
                      onChange={(e) =>
                        enabled &&
                        resetQuota &&
                        setResetAfterUnit(Number(e.target.value))
                      }
                    >
                      <option value={0}>Hours</option>
                      <option value={1}>Days</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`row mb-1`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <label class="switch s-primary mb-0 mr-2">
                        <input
                          disabled={!enabled}
                          type="checkbox"
                          checked={limitByRatio}
                          onChange={(e) =>
                            enabled && setLimitByRatio(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                      if
                    </div>

                    <select
                      disabled={!enabled || !limitByRatio}
                      className="limitNetSelectpicker"
                      title={
                        limitRatioType === undefined
                          ? "Select a Ratio Type"
                          : undefined
                      }
                      data-width="100%"
                      value={limitRatioType}
                      onChange={(e) =>
                        enabled &&
                        limitByRatio &&
                        setLimitRatioType(Number(e.target.value))
                      }
                    >
                      <option value={1}>the upload to download</option>
                      <option value={2}>the download to upload</option>
                    </select>

                    <div class="input-group-text">ratio is more than</div>
                  </div>
                  <input
                    disabled={!enabled}
                    class="form-control text-center"
                    placeholder={`Maximum Allowed`}
                    aria-label="switch"
                    value={maxAllowedRatio}
                    onChange={(e) =>
                      enabled &&
                      limitByRatio &&
                      setMaxAllowedRatio(
                        e.target.value.replace(/[^0-9\.]/g, "")
                      )
                    }
                  />
                </div>
              </div>

              <div className={`row mb-1`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      then disconnect the network if
                    </div>
                  </div>

                  <input
                    disabled={!enabled}
                    class="form-control text-center"
                    placeholder={`Ratio Checkpoint`}
                    aria-label="switch"
                    value={limitByRatioCheckpoint}
                    onChange={(e) =>
                      enabled &&
                      limitByRatio &&
                      setLimitByRatioCheckpoint(
                        e.target.value.replace(/[^0-9]/g, "")
                      )
                    }
                  />

                  <div className={`input-group-append`}>
                    <select
                      disabled={!enabled || !limitByRatio}
                      className="limitNetSelectpicker"
                      title={
                        limitByRatioCheckpointType === undefined
                          ? "Select a Checkpoint Type"
                          : undefined
                      }
                      data-width="100%"
                      value={limitByRatioCheckpointType}
                      onChange={(e) =>
                        enabled &&
                        limitByRatio &&
                        setLimitByRatioCheckpointType(Number(e.target.value))
                      }
                    >
                      <option value={0}>Hours have elapsed</option>
                      <option value={1}>Days have elapsed</option>
                      <option value={2}>MB have been used</option>
                      <option value={3}>GB have been used</option>
                    </select>
                    <div class="input-group-text">
                      since the begining of the network quota{" "}
                    </div>
                  </div>
                  <div
                    className={`d-flex align-items-center px-1`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      VMBC.Dialogs.Show({
                        Title: "Network Limit By Ratio",
                        Text: limitByRatio
                          ? !limitRatioType || limitRatioType === 0
                            ? "Please select a Ratio Type first"
                            : !maxAllowedRatio || maxAllowedRatio === ""
                            ? "Maximum Allowed Ratio is required"
                            : !limitByRatioCheckpoint ||
                              limitByRatioCheckpoint === ""
                            ? "Ratio Checkpoint is required"
                            : typeof limitByRatioCheckpointType !== "number" ||
                              limitByRatioCheckpointType < 0 ||
                              limitByRatioCheckpointType > 3
                            ? "Please select a Checkpoint Type first"
                            : `VM's Network will be disconnected if ${
                                limitRatioType === 1
                                  ? "uploading traffic from vm"
                                  : "downloading traffic to vm"
                              } is more than ( ${maxAllowedRatio} x ${
                                limitRatioType === 1
                                  ? "downloading traffic to vm"
                                  : "uploading traffic from vm"
                              } ), after ${
                                limitByRatioCheckpointType < 2
                                  ? `${limitByRatioCheckpoint} ${
                                      limitByRatioCheckpointType === 0
                                        ? "Hour(s)"
                                        : "Day(s)"
                                    }`
                                  : `${limitByRatioCheckpoint} ${
                                      limitByRatioCheckpointType === 2
                                        ? "MB"
                                        : "GB"
                                    } of traffic have been used by the vm`
                              } since the begining of the network quota.`
                          : "Limiting by Network Usage Ratio is disabled",
                        Ignorable: true,
                        Actions: [
                          {
                            className: "btn-outline-primary btn-sm",
                            text: "Close",
                            onClick: () => VMBC.Dialogs.Close(),
                          },
                        ],
                      });
                    }}
                  >
                    <VMBC.SVGs.Info />
                  </div>
                </div>
              </div>

              {dataIsChanged ? (
                <div
                  className={`row mb-1 mt-1`}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <button className={`btn btn-primary`} onClick={submitChanges}>
                    Submit Changes
                  </button>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LimitNetworkUsage;
