import React from "react";
import { withRouter } from "react-router";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Start from "./Start";
import Eula from "./Eula";
import License from "./License";
import VMBC from "../VMBC";
import VCenter from "./vCenter";

function Install(props) {
  VMBC.Navigator.History = props.history;

  const [pageNum, setPageNum] = React.useState(-1);

  React.useEffect(() => {
    if (!localStorage.getItem("GotoInstall")) {
      VMBC.Navigator.Navigate(VMBC.Navigator.History, "/panel");
    }
  }, []);

  const InstallData = React.useRef({
    Eula: {
      Accepted: false,
      Lang: null,
    },
    License: {
      Code: "",
      ConfirmedCode: "",
    },
    vCenter: {
      Address: "",
      Username: "",
      Password: "",
      UseSSL: true,
      SslCrt: "",
      SslCrtName: "Not Selected",
      Confirmed: false,
    },
  });

  const Goto = (num) => setPageNum(num);

  const onFinish = () => {
    BlockWidget();
    VMBC.Install.finish(InstallData.current, (err) => {
      if (err) {
        console.log("error on finishing install, Err: ", err);
      }
      var CheckStageTwo = () => {
        VMBC.Install.CheckStageTwo((res) => {
          if (res && res.isStageTwo) {
            // vmbox is restarted and is on stageTwo
            UnblockWidget();
            window
              .swal({
                title: "Done!",
                text: "VMBOX Installed Successfully!",
                type: "success",
                padding: "2em",

                showCloseButton: true,
                // showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Go To Panel", // "Set Primary Settings",
                // cancelButtonText: "Skip & Go To Panel",
              })
              .then((res) => {
                localStorage.removeItem("GotoInstall");
                VMBC.Navigate(VMBC.Navigator.History, "/panel"); // Goto Panel

                // Set primary settings is disabled on thisrelease
                // if (res.dismiss && res.dismiss === "cancel") {
                //   localStorage.removeItem("GotoInstall");
                //   VMBC.Navigate(VMBC.Navigator.History, "/panel"); // Goto Panel
                // }
                // else Set Primary settings
              });
          } else {
            // repeat again until get stageTwo from vmbox
            setTimeout(CheckStageTwo, 1000);
          }
        });
      };
      CheckStageTwo();
    });
  };

  return (
    <ScrollBar
      className={`w-100 `}
      style={{ height: "100vh", overflowY: "auto" }}
    >
      <div
        className={`d-flex justify-content-center align-items-center py-5`}
        style={{ minHeight: "100vh" }}
      >
        <div
          id={"installWidget"}
          className={`widget`}
          style={{ minWidth: "320px" }}
        >
          {pageNum === -1 ? (
            <Start
              InstallData={InstallData}
              onNext={() => {
                setPageNum(0);
              }}
            />
          ) : undefined}

          {pageNum === 0 ? (
            <Eula
              InstallData={InstallData}
              Goto={Goto}
              onNext={() => {
                setPageNum(1);
              }}
            />
          ) : undefined}

          {pageNum === 1 ? (
            <License
              Block={BlockWidget}
              Unblock={UnblockWidget}
              InstallData={InstallData}
              Goto={Goto}
              onNext={() => {
                setPageNum(2);
              }}
            />
          ) : undefined}

          {pageNum === 2 ? (
            <VCenter
              Block={BlockWidget}
              Unblock={UnblockWidget}
              InstallData={InstallData}
              Goto={Goto}
              onFinish={onFinish}
            />
          ) : undefined}
        </div>
      </div>
      <CopyrightFooter />
      <Logout />
    </ScrollBar>
  );
}

function CopyrightFooter(props) {
  return (
    <div className="footer-wrapper" style={{ position: "fixed", bottom: "0" }}>
      <div className="footer-section f-section-1">
        <p className="">
          Copyright ©{" "}
          <a target="_blank" href="https://rasatelecom.com/">
            Rasa Telecom
          </a>{" "}
          {new Date().getFullYear()}, All rights reserved.
        </p>
      </div>
      <div className="footer-section f-section-2">
        <p className="">
          Coded with{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-heart"
          >
            <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
          </svg>
        </p>
      </div>
    </div>
  );
}

function Logout(props) {
  const logout = () => {
    VMBC.Auth.logout(() => {
      VMBC.Navigate(VMBC.Navigator.History, "/");
    });
  };
  return (
    <>
      <button
        className={`btn btn-outline-dark m-2 px-2 mr-3`}
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          cursor: "pointer",
          fontSize: "13px",
        }}
        onClick={logout}
      >
        <VMBC.SVGs.Logout /> Sign Out
      </button>
    </>
  );
}

var BlockingWidget = false;
function BlockWidget() {
  BlockingWidget = true;
  setTimeout(() => {
    if (BlockingWidget) {
      window.$(window.$("body")).block({
        message:
          '<span class="text-semibold"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader spin position-left"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg></i>&nbsp; Please wait</span>',
        timeout: 200000,
        overlayCSS: {
          backgroundColor: "#191e3a",
          opacity: 0.7,
          cursor: "wait",
        },
        css: {
          border: 0,
          padding: "10px 15px",
          color: "#0e1726",
          width: "auto",
          "-webkit-border-radius": 2,
          "-moz-border-radius": 2,
          backgroundColor: "#009688",
        },
      });
    }
  }, 100);
}

function UnblockWidget() {
  BlockingWidget = false;
  window.$(window.$("body")).unblock();
}

export default withRouter(Install);
