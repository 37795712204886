import React from "react";
import VMBC from "../VMBC";

function Locked(props) {
  const passwordField = React.useRef();
  return (
    <>
      <div class="form-container outer">
        <div class="form-form">
          <div class="form-form-wrap">
            <div class="form-container">
              <div class="form-content">
                <div class="d-flex user-meta">
                  <img
                    src={VMBC.SVGs.VMBOX_PNG}
                    class="usr-profile"
                    alt="avatar"
                  />
                  <div class="">
                    <p class="">
                      VMBOX is Locked
                      {/* {localStorage.getItem("userfullname")
                        ? localStorage.getItem("userfullname")
                        : "VMBOX is Locked"} */}
                    </p>
                  </div>
                </div>

                <div class="mainForm text-left">
                  <div class="form">
                    <div id="password-field" class="field-wrapper input mb-2">
                      <div class="d-flex justify-content-between">
                        <label for="password">PASSWORD</label>
                        {/* <a
                          href="auth_pass_recovery_boxed.html"
                          class="forgot-pass-link"
                        >
                          Forgot Password?
                        </a> */}
                      </div>
                      <VMBC.SVGs.Lock />
                      <input
                        id="password"
                        ref={passwordField}
                        name="password"
                        type="password"
                        class="form-control"
                        placeholder="Password"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        id="toggle-password"
                        class="feather feather-eye"
                        onMouseDown={() => {
                          if (passwordField.current) {
                            passwordField.current.type = "";
                          }
                        }}
                        onMouseUp={() => {
                          if (passwordField.current) {
                            passwordField.current.type = "password";
                          }
                        }}
                        onMouseLeave={() => {
                          if (passwordField.current) {
                            passwordField.current.type = "password";
                          }
                        }}
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </div>
                    <div class="d-sm-flex justify-content-between">
                      <div class="field-wrapper">
                        <button
                          class="btn btn-sm btn-primary"
                          onClick={() => {
                            if (passwordField.current) {
                              if (
                                !passwordField.current.value ||
                                !passwordField.current.value.length ||
                                passwordField.current.value.length < 2
                              ) {
                                VMBC.Alert.Show(
                                  "Password is required",
                                  "error"
                                );
                                return;
                              }
                              VMBC.Auth.Unlock(passwordField.current.value);
                            }
                          }}
                        >
                          Unlock me
                        </button>
                        <VMBC.A href="/logout">
                          <button class="btn btn-sm btn-outline-primary mt-2">
                            Logout
                          </button>
                        </VMBC.A>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Locked;
