import React from "react";
import VMBC from "../../VMBC";

function OnDelete(props) {
  const { index, act, rowIndex, row } = props;

  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <li key={index}>
      <div className="dropdown d-inline">
        <a
          className="dropdown-toggle"
          role="button"
          id={`onDelete-${rowIndex}-${index}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <VMBC.ToolTip title={act.title}>{act.icon}</VMBC.ToolTip>
        </a>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby={`onDelete-${rowIndex}-${index}`}
        >
          <p style={{ fontSize: "14px", textAlign: "center" }}>
            Are you sure you want to delete this item?
          </p>
          <a
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              if (isLoading) {
                return;
              }
              act.eventHandler(row, () => {
                setIsLoading(false);
              });
              setIsLoading(true);
            }}
          >
            {isLoading ? (
              <div
                className="spinner-border text-white align-self-center loader-sm"
                style={{ width: "1rem", height: "1rem" }}
              ></div>
            ) : (
              `Yes`
            )}
          </a>
          <a className="dropdown-item" style={{ cursor: "pointer" }}>
            No
          </a>
        </div>
      </div>
    </li>
  );
}

export default OnDelete;
