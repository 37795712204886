import VMBC from "../..";
import axios from "axios";

function EditVMBoxConfiguration(Config, cb) {
  if (typeof Config !== "object") return;
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/offline/inventory/vmbox/editVMBoxConfig",
        {
          SId: window.VMBC.SId,
          Config: Config,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

function RenewSSL(type, cb) {
  if (type !== "config" && type !== "client" && type !== "customer") return;
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/offline/inventory/vmbox/renewSSL",
        {
          SId: window.VMBC.SId,
          Type: type,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

function SetSslState(type, enabled, cb) {
  if (
    (type !== "config" &&
      type !== "client" &&
      type !== "customer" &&
      type !== "customerACMEBuiltIn" &&
      type !== "customerACMECertBot") ||
    typeof enabled !== "boolean"
  )
    return;
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/offline/inventory/vmbox/setSslState",
        {
          SId: window.VMBC.SId,
          Type: type,
          Enabled: enabled,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

function Restart(cb) {
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/offline/inventory/vmbox/restart",
        {
          SId: window.VMBC.SId,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

export default {
  EditVMBoxConfiguration,
  RenewSSL,
  SetSslState,
  Restart,
};
