import React from "react";
import VMBC from "../../../../../../VMBC";
import InsertEjectIso from "./InsertEjectIso";

export default function(props) {
  const { vm, Refresh } = props;
  const { Id } = vm;

  return (
    <>
      <button
        className={`btn m-1 btn-outline-primary`}
        // onClick={() =>
        //   VMBC.Dialogs.Show({
        //     Title: "Are you sure?",
        //     Text: "Are you sure you want to power on this virtual machine?",
        //     Ignorable: true,
        //     Actions: [
        //       {
        //         onClick: () => {
        //           VMBC.Customers.VirtualMachines.RunAction(
        //             { Name: "PowerOn", RecordId: Id },
        //             (res, err) => {
        //               if (err) {
        //                 VMBC.Alert.Show(err);
        //               } else {
        //                 Refresh();
        //               }
        //             }
        //           );
        //           VMBC.Dialogs.Close();
        //         },
        //         className: "btn-sm btn-primary",
        //         text: "Yes"
        //       },
        //       {
        //         onClick: () => {
        //           VMBC.Dialogs.Close();
        //         },
        //         className: "btn-sm btn-primary",
        //         text: "No"
        //       }
        //     ]
        //   })
        // }
      >
        Power On
      </button>
      <button
        className={`btn m-1 btn-outline-primary`}
        // onClick={() =>
        //   VMBC.Dialogs.Show({
        //     Title: "Are you sure?",
        //     Text: "Are you sure you want to delete this virtual machine?",
        //     Ignorable: true,
        //     Actions: [
        //       {
        //         onClick: () => {
        //           VMBC.Customers.VirtualMachines.RunAction(
        //             { Name: "Delete", RecordId: Id },
        //             (res, err) => {
        //               if (err) {
        //                 VMBC.Alert.Show(err);
        //               } else {
        //                 Refresh();
        //               }
        //             }
        //           );
        //           VMBC.Dialogs.Close();
        //         },
        //         className: "btn-sm btn-primary",
        //         text: "Yes"
        //       },
        //       {
        //         onClick: () => {
        //           VMBC.Dialogs.Close();
        //         },
        //         className: "btn-sm btn-primary",
        //         text: "No"
        //       }
        //     ]
        //   })
        // }
      >
        Delete
      </button>
      <InsertEjectIso {...props} />
    </>
  );
}
