class ManagedObject {
  constructor() {
    if (arguments.length === 1 && typeof arguments[0] === "string") { // ManagedObject ( string MO_Reference )
      this.MO_Reference = arguments[0];
    }
  }
  MO_Reference = "";
  _VMBC_Type = "ManagedObject";
}

export default ManagedObject;