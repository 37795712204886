import React from "react";
import VMBC from "../../../../../VMBC";
import Table from "../../../../../Components/Table";

function AssignVm(props) {
  const { onOpen, onSelect, Reload } = props;
  const [openState, setOpenState] = React.useState(false);
  const [vms, setVms] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    onOpen.current = () => {
      window.$("#assignVmModal").modal("show");
      setOpenState(true);
    };
  }, []);

  React.useEffect(() => {
    if (!openState) return;
    setVms();
    VMBC.Customers.VirtualMachines.GetFreeMachines((machines) => {
      setVms(machines);
    });
  }, [refreshT, openState]);

  const Close = () => {
    Reload.current = true;
    window.$("#assignVmModal").modal("hide");
    setOpenState(false);
  };

  return (
    <div
      className="modal fade"
      id="assignVmModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="Assign Existing VM"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl mx-2"
        style={{ maxWidth: "none", marginTop: "8px" }}
        role="document"
      >
        <div className="modal-content widget">
          {!vms ? (
            <div className={`d-flex justify-content-center`}>
              <VMBC.Loader />
            </div>
          ) : (
            <Table
              columns={[
                { title: "Datacenter", fieldName: "DatacenterName" },
                {
                  title: "Compute Resource",
                  fieldName: "ComputeResourceName",
                },
                { title: "Name", fieldName: "VmName" },
              ]}
              data={vms}
              actions={{
                FreeActions: [
                  {
                    Icon: (
                      <button
                        onClick={() => refresh(!refreshT)}
                        className={`btn m-1 btn-sm btn-primary`}
                      >
                        Refresh
                      </button>
                    ),
                  },
                  {
                    Icon: (
                      <button
                        onClick={Close}
                        className={`btn m-1 btn-sm btn-primary`}
                      >
                        Close
                      </button>
                    ),
                  },
                ],
                Others: [
                  {
                    Icon: (rowData) => (
                      <button
                        className={`btn btn-sm btn-primary`}
                        onClick={() => {
                          if (onSelect) {
                            onSelect(rowData);
                            window.$("#assignVmModal").modal("hide");
                          }
                        }}
                      >
                        Select
                      </button>
                    ),
                  },
                ],
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AssignVm;
