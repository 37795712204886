import axios from "axios";

function GetVmIpAddresses(Id, cb) {
  if (!Id || typeof Id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/machines/getVmIpAddresses",
        { SId: window.VMBC.SId, Id },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false, reason);
      });
  });
}

function SetVmIpAssignmentDefaultState(
  { VmId, IpAssignmentId, NewDefaultState },
  cb
) {
  if (
    !VmId ||
    typeof VmId !== "string" ||
    !IpAssignmentId ||
    typeof IpAssignmentId !== "string"
  )
    return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin +
          "/api/customers/machines/setIpAssignmentDefault",
        {
          SId: window.VMBC.SId,
          VmId,
          IpAssignmentId,
          UnMark: !NewDefaultState,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false, reason);
      });
  });
}

function ReleaseIpAssignment(Id, cb) {
  if (!Id || typeof Id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin +
          "/api/customers/machines/releaseIpAssignment",
        {
          SId: window.VMBC.SId,
          IpAssignmentId: Id,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false, reason);
      });
  });
}

function GetSelectableIpPoolForIpAssign(VmId, cb) {
  if (!VmId || typeof VmId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin +
          "/api/customers/machines/getSelectableIpPoolForIpAssign",
        { SId: window.VMBC.SId, VmId },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false, reason);
      });
  });
}


function AssignNewIpToVM(
  { VmId, GroupId, IpPoolId, IpAddressId },
  cb
) {
  if (
    !VmId ||
    typeof VmId !== "string" ||
    !GroupId ||
    typeof GroupId !== "string" ||
    !IpPoolId ||
    typeof IpPoolId !== "string" ||
    !IpAddressId ||
    typeof IpAddressId !== "string"
  )
    return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin +
          "/api/customers/machines/assignNewIpToVM",
        {
          SId: window.VMBC.SId,
          VmId,
          GroupId,
          IpPoolId,
          IpAddressId,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false, reason);
      });
  });
}

export default {
  GetVmIpAddresses,
  SetVmIpAssignmentDefaultState,
  ReleaseIpAssignment,
  GetSelectableIpPoolForIpAssign,
  AssignNewIpToVM,
};
