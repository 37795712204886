import React from "react";
import VMBC from "../../../../VMBC";
import { withRouter } from "react-router";
import RegistrationStatistics from "./RegistrationStatistics";
import MachinesByState from "./MachinesByState";
import ResourceUsage from "./ResourceUsage";
import PlanCondition from "./PlanCondition";
import PlanInfo from "./PlanInfo";
import RecentOrders from "./RecentOrders";
import RecentActions from "./RecentActions";
import Templates from "./Templates";
import Customizations from "./Customizations";
import ComputeResources from "./ComputeResources";
import Datastores from "./Datastores";
import IpPools from "./IpPools";
import CdDrives from "./CdDrives";
import VirtualMachines from "./VirtualMachines";
import ExportMachinesList from "./PlanActions/ExportMachinesList";
import OtherActions from "./PlanActions/OtherActions";
import FinancialSummary from "./FinancialSummary";

function PlanRoot(props) {
  const [plan, setPlan] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setPlan();
    var id = props.match.params.id;
    VMBC.Plans.GetPlan(id, (pln, err) => {
      if (err) {
        VMBC.Alert.Show(err);
      } else {
        var plnDatacenter = new VMBC.Inventory.Datacenter(pln.DatacenterId);
        pln.Datacenter = plnDatacenter;
        plnDatacenter.GetComputeResources(() => {
          plnDatacenter.GetTemplates(() => {
            setPlan(pln);
          });
        });
      }
    });
  }, [refreshT]);

  const Refresh = () => refresh(!refreshT);

  if (!plan) {
    return (
      <div className="widget mt-4 text-center py-4">
        <div
          className="spinner-border align-self-center loader-lg"
          style={{ width: "3rem", height: "3rem" }}
        ></div>
      </div>
    );
  }

  return (
    <>
      <div class="page-header">
        <div class="page-title">
          <h3>
            {plan.Name}
            {plan.Deleted ? (
              <span className="badge outline-badge-danger badge-lg ml-2">
                Deleted
              </span>
            ) : plan.Disabled ? (
              <span className="badge outline-badge-danger badge-lg ml-2">
                Disabled
              </span>
            ) : undefined}
          </h3>
        </div>
        <div class="dropdown filter custom-dropdown-icon">
          <a
            class="dropdown-toggle btn"
            href="#"
            role="button"
            id="filterDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="text">
              <span>Actions</span>
            </span>{" "}
            <VMBC.SVGs.ChevronDown />
          </a>

          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="filterDropdown"
          >
            {/* <a
              class="dropdown-item"
              data-value="<span>Show</span> : Monthly Sales"
              href="javascript:void(0);"
            >
              Download Statistics
            </a> */}
            <ExportMachinesList plan={plan} />
            <OtherActions plan={plan} />
          </div>
        </div>
      </div>

      {/* TODO IMPORTANT */}
      {/* remove widgets' css file from index.html */}
      {/* and use each one's styles within their components */}
      <div class="row layout-top-spacing">
        <RegistrationStatistics plan={plan} />
        <MachinesByState plan={plan} />

        {/* <ResourceUsage plan={plan} /> */}
        <FinancialSummary plan={plan} />
        
        <PlanCondition plan={plan} />
        <PlanInfo plan={plan} />
        <RecentOrders plan={plan} />
        <RecentActions plan={plan} />
        <Templates plan={plan} />
        <Customizations plan={plan} />
        <ComputeResources plan={plan} Refresh={Refresh} />
        <Datastores plan={plan} />
        <IpPools plan={plan} />
        <CdDrives plan={plan} />
        <VirtualMachines plan={plan} />
      </div>

      <div className="widget mt-4 text-center py-4">
        <h6>{plan.Name}</h6>
      </div>
    </>
  );
}

export default withRouter(PlanRoot);
