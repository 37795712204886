import React from "react";

function FinancialSummary(props) {
  return (
    <>
      <div class="widget-three d-flex" style={{ flexDirection: "column" }}>
        <div
          class="widget-heading"
          style={{
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px dashed #3b3f5c",
          }}
        >
          <h5 class="">Financial Summary</h5>
        </div>
        <div
          class="widget-content d-flex justify-content-center align-items-center"
          style={{
            flexGrow: "1",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <span
            style={{
              position: "absolute",
              // top: "10px",
              margin: "auto",
              zIndex: "2",
            }}
            className="badge badge-warning badge-lg"
          >
            Coming Soon ...
          </span>

          <div class="order-summary" style={{ opacity: "0.3", width: "100%" }}>
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-shopping-bag"
                >
                  <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Income</h6>
                  <p class="summary-count">$92,600</p>
                </div>

                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      class="progress-bar bg-gradient-secondary"
                      role="progressbar"
                      style={{ width: "64%" }}
                      aria-valuenow="64"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-tag"
                >
                  <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                  <line x1="7" y1="7" x2="7" y2="7"></line>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Profit</h6>
                  <p class="summary-count">$37,515</p>
                </div>

                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      class="progress-bar bg-gradient-success"
                      role="progressbar"
                      style={{ width: "49%" }}
                      aria-valuenow="49"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-credit-card"
                >
                  <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                  <line x1="1" y1="10" x2="23" y2="10"></line>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Expenses</h6>
                  <p class="summary-count">$55,085</p>
                </div>

                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      class="progress-bar bg-gradient-warning"
                      role="progressbar"
                      style={{ width: "54%" }}
                      aria-valuenow="54"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinancialSummary;
