import React from "react";

import FolderExplorer, {
  LinkParents,
} from "../../../Components/FolderExplorer";
import VMBC from "../../../VMBC";

function AddIsoFile(props) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentFolder, setCurrentFolder] = React.useState({});
  const [selectedObject, setSelectedObject] = React.useState();
  const [refreshT, refresh] = React.useState(false);
  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");

  React.useEffect(() => {
    onRefresh();
    if (props.onOpen != undefined && typeof props.onOpen === "object") {
      props.onOpen.current = () => {
        window
          .$(`#selectIsoFilesModal${props.ComputeResource.MO_Reference}`)
          .modal("show");
        onRefresh();
      };
    }
  }, []);

  const selectHandler = () => {
    if (!props.onSelect) return;
    if (!selectedObject) {
      VMBC.Alert.Show("Please select a *.iso File first", "error", 3000);
      return;
    }
    if (typeof name != "string" || name == "") {
      VMBC.Alert.Show("Please a valid name for iso file", "error", 3000);
      return;
    }
    let IsoFileObj = {
      isoFileAddress: selectedObject.isoFileAddress,
      Name: name,
      Desc: desc,
    };
    if (props.onSelect(IsoFileObj)) {
      window
        .$(`#selectIsoFilesModal${props.ComputeResource.MO_Reference}`)
        .modal("hide");
      setSelectedObject(null);
      setName("");
      setDesc("");
    }
  };

  const onRefresh = () => {
    setCurrentFolder();
    var RootFolder = {
      isFolder: true,
      Name: "root",
      items: [],
    };
    props.ComputeResource.Datastores.map((ds) => {
      var newDSfld = {
        isFolder: true,
        Name: ds.Name,
        icon: <VMBC.SVGs.Template size={80} />,
        items: false,
      };
      ds.GetIsoFiles((isoFiles, err) => {
        if (!!err) {
          return;
        }
        newDSfld.items = isoFiles.map((isofile) => ({
          isFolder: false,
          Name: isofile.Name,
          isoFileAddress: isofile.Address,
          icon: <VMBC.SVGs.Template size={80} />,
          items: false,
        }));
        refresh(!refreshT);
      });
      RootFolder.items.push(newDSfld);
    });
    LinkParents(RootFolder);
    setCurrentFolder(RootFolder);
  };

  return (
    <>
      <div
        className="modal fade"
        id={`selectIsoFilesModal${props.ComputeResource.MO_Reference}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Select Iso File"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Iso File</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <VMBC.SVGs.CloseXIcon />
              </button>
            </div>
            <div className="modal-body">
              <div className={`container-fluid`}>
                <div className={`row`}>
                  <div className={`col-12`}>
                    <FolderExplorer
                      Folder={currentFolder}
                      SelectedObject={selectedObject}
                      // onRefresh={onRefresh}
                      onAddressClicked={setCurrentFolder}
                      onObjectSelect={setSelectedObject}
                      onObjectDoubleClick={(Obj) => {
                        if (Obj.isFolder) {
                          setCurrentFolder(Obj);
                        }
                      }}
                    />
                  </div>

                  <div className={`col-5 detailsContainer`}>
                    <div className={`input-group`}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">Iso File name:</span>
                      </div>
                      <input
                        id="IsoFileName"
                        type="text"
                        name="name"
                        placeholder="(shown to customer)"
                        className={`form-control`}
                        style={{ height: "48.5px" }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className={`col-7 detailsContainer`}>
                    <div className={`input-group`}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">Description:</span>
                      </div>
                      <input
                        id="IsoFileName"
                        type="text"
                        name="description"
                        className={`form-control`}
                        style={{ height: "48.5px" }}
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* <div className={`col-6 detailsContainer`}>
                    <h6>
                      {selectedObject
                        ? `VM Path Name: ${selectedObject.TemplateObj.VmPathName}`
                        : ""}
                    </h6>
                  </div>

                  <div className={`col-6 detailsContainer text-right`}>
                    <h6>
                      {selectedObject
                        ? `Guest Full Name: ${selectedObject.TemplateObj.GuestFullName}`
                        : ""}
                    </h6>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className={`btn btn-outline-primary`}
                onClick={() =>
                  window
                    .$(
                      `#selectIsoFilesModal${props.ComputeResource.MO_Reference}`
                    )
                    .modal("hide")
                }
              >
                Cancel
              </button>
              <button className={`btn btn-primary`} onClick={selectHandler}>
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddIsoFile;
