import React from "react";
import VMBC from "../../../../VMBC";
import CPU from "./VirtualHardwarePanels/CPU";
import Memory from "./VirtualHardwarePanels/Memory";
import HardDisk from "./VirtualHardwarePanels/HardDisk";
import CdDvdDevice from "./VirtualHardwarePanels/CdDvdDevice";

function VirtualHardware(props) {
  return (
    <>
      <div id="toggleAccordion" className={`no-outer-spacing`}>
        <TabCard id={"cpuProfile"} title={"CPU"}>
          <CPU Data={props.Data} />
        </TabCard>
        <TabCard id={"memoryProfile"} title={"Memory"}>
          <Memory Data={props.Data} />
        </TabCard>
        <TabCard id={"hardDiskProfile"} title={"Hard Disk"}>
          <HardDisk Data={props.Data} />
        </TabCard>
        <TabCard id={"cdDevice"} title={"CD/DVD Device"}>
          <CdDvdDevice Data={props.Data} />
        </TabCard>
      </div>
    </>
  );
}

function TabCard(props) {
  return (
    <div className={`card no-outer-spacing`}>
      <div className="card-header" id={props.id}>
        <section className="mb-0 mt-0">
          <div
            role="menu"
            className={`collapsed ${props.shown ? "show" : ""}`}
            data-toggle="collapse"
            data-target={`#${props.id}Acc`}
            aria-expanded="false"
            aria-controls={`${props.id}Acc`}
          >
            {props.title}
            <div className="icons">
              <VMBC.SVGs.ChevronDown />
            </div>
          </div>
        </section>
      </div>

      <div
        id={`${props.id}Acc`}
        className="collapse"
        aria-labelledby={props.id}
        data-parent="#toggleAccordion"
      >
        <div className="card-body">{props.children}</div>
      </div>
    </div>
  );
}

export default VirtualHardware;
