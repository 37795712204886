import React from "react";
import VMBC from "../../../../VMBC";
import { withRouter } from "react-router";
import "./index.css";
import Console from "./Console";
import Details from "./Details";
import Customer from "./Customer";
import RecentActions from "./RecentActions";
import ResourceUsage from "./ResourceUsage";
import ComputeResourceAndDS from "./ComputeResourceAndDS";
import IpAddress from "./IpAddress";
import Demo from "../../../../Demo";

window.AddToLoadingVmboxProccess(1);

function VMRoot(props) {
  const [vm, setVm] = React.useState();
  const [refreshT, refresh] = React.useState(false);
  const Reload = React.useRef(false);

  React.useEffect(() => {
    // setVm();
    var id = props.match.params.id;
    var vms = Demo(Demo.VMachines).filter((val) => val.id === Number(id));
    if (vms[0]) {
      var vm = vms[0];
      var customers = Demo(Demo.Customers).filter(
        (val) => val.id === vm.LINK_ownerId
      );
      if (!customers[0]) {
        customers[0] = {};
      }
      var customer = customers[0];
      var plan = Demo.VMPlans.Data[vm.LINK_planId];

      var VM = {
        NotFound: false,
        Id: vm.id,
        PackageId: "1",
        WhmcsServiceLink:
          "https://clients.yourhosting.com/admin/clientsservices.php?productselect=1",
        Customer: {
          Id: customer.id,
          FirstName: customer.firstname,
          LastName: customer.lastname,
          Email: customer.email,
          ClientId: "1",
          WhmcsClientLink:
            "https://clients.yourhosting.com/admin/clientssummary.php?userid=1",
        },
        VmachineId: "vm-2072",
        IsSuspended: Demo.RandInt(1, 100) < 10,
        IsReserved: true,
        Location: `/${plan.DC}/vm/${vm.vmName}`,
        VmName: vm.vmName,
        IpAddress: vm.Ip,
        State: vm.State,
        OsPassword: Demo.RandInt(10000000, 100000000),
        PlanName: plan.PlanName,
        PlanId: plan.PlanId,
        IsoFiles: null,
        ComputeResourceMoId: "domain-s89",
        ResourcePoolMoId: "",
        DatastoreMoId: "datastore-94",
        Misc: {
          IpAddress: vm.Ip,
          MoreIpAddresses: [vm.Ip],
          DatastoreId: "",
          MoreDatastores: null,
        },
      };
      setVm(VM);
    } else {
      setVm(false);
    }
    // var Q = !!props.package ? { PackageId: id } : { Id: id };
    // VMBC.Customers.VirtualMachines.GetMachine(Q, (vm, err) => {
    //   if (err) {
    //     VMBC.Alert.Show(err, "error");
    //   } else {
    //     setVm(vm);
    //   }
    // });
  }, [refreshT]);

  setTimeout(() => {
    if (Reload.current) refresh(!refreshT);
  }, 3000);

  if (vm === false) {
    return <div className="widget mt-4 text-center py-4">VM not found</div>;
  }

  if (!vm) {
    return (
      <div className="widget mt-4 text-center py-4">
        <VMBC.Loader />
      </div>
    );
  }
  return <VM vm={vm} Refresh={() => refresh(!refreshT)} Reload={Reload} />;
}

function VM(props) {
  const { vm, Refresh, Reload } = props;

  React.useEffect(() => {
    window
      .dragula([document.getElementById("vmDetailPanelsContainer")], {
        accepts: function (el, target, source, sibling) {
          return true;
        },
        moves: function (el, container, handle) {
          return handle.classList.contains("vmDetailPanelHandle");
        },
      })
      .on("drag", function (el) {
        el.className += " el-drag-ex-1";
      })
      .on("drop", (el) => {
        el.className = el.className.replace("el-drag-ex-1", "");
        var children = document.getElementById("vmDetailPanelsContainer")
          .children;
        var lenn = children.length;
        var order = [];
        for (var i = 0; i < lenn; i++) {
          order.push(children[i].id);
        }
        localStorage.setItem("vmDetailsPanelsOrder", order.join(","));
      })
      .on("cancel", function (el) {
        el.className = el.className.replace("el-drag-ex-1", "");
      });
  }, []);

  let Panels = [
    <Panel id={0} col={"col-12 col-md-4 col-sm-5 col-xs-12"} title={"Console"}>
      <Console vm={vm} />
    </Panel>,

    <Panel id={1} col={"col-12 col-md-5 col-sm-7 col-xs-12"} title={"Details"}>
      <Details vm={vm} Reload={Reload} Refresh={Refresh} />
    </Panel>,

    <Panel id={2} col={"col-12 col-md-3 col-sm-5 col-xs-12"} title={"Customer"}>
      <Customer vm={vm} Reload={Reload} />
    </Panel>,

    <Panel
      id={3}
      col={"col-12 col-lg-3 col-sm-6 col-xs-12"}
      title={"IP Address"}
    >
      <IpAddress vm={vm} />
    </Panel>,

    <Panel
      id={4}
      col={"col-12 col-lg-4 col-sm-6 col-xs-12"}
      title={"Recent Actions"}
    >
      <RecentActions vm={vm} />
    </Panel>,

    <Panel
      id={5}
      col={"col-12 col-md-2 col-sm-5 col-xs-12"}
      title={"Resources"}
    >
      <ResourceUsage vm={vm} />
    </Panel>,

    <Panel
      id={6}
      col={"col-12 col-lg-3 col-sm-6 col-xs-12"}
      title={"Compute Resource & Datastore"}
    >
      <ComputeResourceAndDS vm={vm} Refresh={Refresh} />
    </Panel>,
  ];
  let Order = [0, 1, 2, 3, 4, 5, 6];
  if (localStorage.getItem("vmDetailsPanelsOrder")) {
    Order = localStorage
      .getItem("vmDetailsPanelsOrder")
      .split(",")
      .map((index) => Number(index));
  }

  return (
    <div className={`container-fluid`}>
      <div
        id={`vmDetailPanelsContainer`}
        className={`row justify-content-center`}
      >
        {Order.map((order) => Panels[order])}
      </div>
    </div>
  );
}

function Panel(props) {
  let { rootStyle, rootClassName, style, className, title, col } = props;
  if (!col) {
    col = "";
  } else if (!col.startsWith("col")) {
    col = `col-${col}`;
  }
  return (
    <span
      id={props.id}
      className={`${col} my-2 px-2 ${rootClassName}`}
      style={rootStyle}
    >
      <div
        className={`widget ${className}`}
        style={{ padding: "0px", ...style }}
      >
        <div
          className={`vmDetailPanelHandle py-2 px-3`}
          style={{ borderBottom: "1px dashed #3b3f5c" }}
        >
          <span style={{ fontSize: "1rem", color: "#fff", userSelect: "none" }}>
            {title}
          </span>
        </div>
        {props.children}
      </div>
    </span>
  );
}

export default withRouter(VMRoot);
