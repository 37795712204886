import Demo from "..";

const TableName = "Demo_WHMCS";

var DefaultData = [];

function Setup() {
  var Table = [];
  Table.push(...DefaultData);
  localStorage.setItem(TableName, JSON.stringify(Table));
  Demo.WHMCS = { Name: "WHMCS", Data: Table };
}

export default { Setup };
