import { Dialog } from "../../Components/Dialogs";
var Show = function ({ Title, Ignorable, Text, Fields, Actions }) {
  /*
  Action: {
    onClick: () => { }
    color: "#fffff"
    text: "Text"
  }
  */
  alert("Dialogs not ready");
};

function Close() {
  alert("Dialogs not ready");
}

/*
VMBC.Dialogs.Show({
  Title: "Titlee",
  Text: "Textt Lorem ipsum losadfnhwehauifbekbwjef wehf ajwef haw efhwe jafhe wlfh ewjh",
  Ignorable: true,
  Fields: <TextField value={"TextFieldd"} />,
  Actions: [
    { onClick: () => VMBC.Dialogs.Close(), text: "Close" },
    { onClick: () => alert("Ok"), text: "Ok" }
  ]
});*/

export default {
  Show,
  Close,
  Dialog,
};
