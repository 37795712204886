import React from "react";
import VMBC from "../../../../../VMBC";
import ComputeResources from "../ComputeResources";

function IpPoolsList(props) {
  const { PlanSolution } = props;
  const { ComputeResourceId, SolutionId } = PlanSolution;

  const [ipPools, setIpPools] = React.useState();
  const [allIpPools, setAllIpPools] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setIpPools();
    VMBC.Plans.GetSolutionIpPools(SolutionId, (ippls, err) => {
      if (!err) {
        setIpPools(ippls);
      } else {
        VMBC.Alert.Show(err);
      }
    });
    setAllIpPools();
    var ComputeResource = new VMBC.Inventory.ComputeResource(ComputeResourceId);
    ComputeResource.GetIpPools((ippls) => {
      if (!ippls) {
        VMBC.Alert.Show("Error on loading IP Pools List", "error");
      } else {
        setAllIpPools(ippls);
      }
    });
  }, [refreshT, PlanSolution]);

  const Refresh = () => refresh(!refreshT);

  const onRemoveClicked = (ippl) => {
    VMBC.Dialogs.Show({
      Title: "Are you sure?",
      Text: `Are you sure you want to remove "${ippl.IpPoolName}" from this Plan? No VMachines will use IP Addresses from this ipPool in this plan anymore`,
      Ignorable: true,
      Actions: [
        {
          onClick: () => {
            VMBC.Plans.RemoveSolutionIpPool(
              ippl.SolutionIpPoolId,
              (res, err) => {
                if (err) {
                  VMBC.Alert.Show(err);
                } else {
                  VMBC.Alert.Show(res, "success");
                  Refresh();
                }
              }
            );
            VMBC.Dialogs.Close();
          },
          className: "btn btn-outline-primary btn-sm",
          text: "Yes",
        },
        {
          onClick: () => VMBC.Dialogs.Close(),
          className: "btn btn-primary btn-sm",
          text: "No",
        },
      ],
    });
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
                <div className="th-content text-center">Order</div>
              </th>
              <th>
                <div className="th-content text-center">Name</div>
              </th>
              <th>
                <div className="th-content text-center">Free IPs</div>
              </th>
              <th>
                <div className="th-content text-center">Actions</div>
              </th>
            </tr>
          </thead>
          {ipPools ? (
            <tbody>
              {ipPools.map((ippl) => (
                <tr style={{ border: "1px dashed grey" }}>
                  <td>
                    <div className="td-content text-center customer-name">
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            // display: "flex",
                            display: "none", // TODO: remove this and add new feature to change ipPools order
                            flexDirection: "column",
                            marginRight: "10px",
                          }}
                        >
                          <span onClick={() => VMBC.Alert.Show("+", "info")}>
                            +
                          </span>
                          <span onClick={() => VMBC.Alert.Show("-", "error")}>
                            -
                          </span>
                        </span>
                        {ippl.Order}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="td-content text-center product-brand">
                      {ippl.IpPoolName}
                    </div>
                  </td>
                  <td>
                    <div className="td-content text-center">
                      {ippl.FreeIpCount}
                    </div>
                  </td>
                  <td>
                    <div className="td-content text-center">
                      <VMBC.A href={`/panel/plans/ipPools/${ippl.IpPoolId}`}>
                        <span className="badge outline-badge-info">
                          Details
                        </span>
                      </VMBC.A>
                      <span
                        className="ml-1 badge outline-badge-danger"
                        onClick={() => onRemoveClicked(ippl)}
                      >
                        Remove
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : undefined}
        </table>
      </div>
      {ipPools ? (
        ipPools.length != undefined && ipPools.length === 0 ? (
          <div className={`my-4 text-center`}>No IP Pools</div>
        ) : undefined
      ) : (
        <div
          className={`my-2`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="spinner-border align-self-center loader-lg"
            style={{ width: "3rem", height: "3rem" }}
          ></div>
        </div>
      )}

      {allIpPools ? (
        <>
          <div
            className={`mt-3`}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span></span>

            <span>
              <button
                className={`btn btn-primary btn-sm`}
                onClick={() => {
                  window.$("#addIpPoolDialog").modal("show");
                  window.$(".planIpPoolSelectpicker").selectpicker("refresh");
                }}
              >
                Add
              </button>
            </span>

            <span></span>
          </div>

          <AddIpPool
            allIpPools={allIpPools}
            Refresh={Refresh}
            ipPools={ipPools}
            SolutionId={SolutionId}
          />
        </>
      ) : undefined}
    </>
  );
}

function AddIpPool(props) {
  const { allIpPools, Refresh, ipPools, SolutionId } = props;

  const selectedIpPlToAddObj = React.useRef();
  const [selectedIpPlToAdd, setSelectedIpPlToAdd] = React.useState();

  const handleSelectedIpPlChange = (e) => {
    if (allIpPools != undefined) {
      if (allIpPools.length > e) {
        //check array index bound
        selectedIpPlToAddObj.current = allIpPools[e];
        setSelectedIpPlToAdd(allIpPools[e]);
      }
    }
  };

  const handleAddIpPool = () => {
    if (
      ipPools
        .map((ippl) => ippl.IpPoolName)
        .includes(selectedIpPlToAddObj.current.Name)
    ) {
      VMBC.Alert.Show(
        `IpPool '${selectedIpPlToAddObj.current.Name}' is Already in Plan for this Compute Resource`,
        "error"
      );
      return;
    }
    VMBC.Plans.AddSolutionIpPool(
      SolutionId,
      selectedIpPlToAddObj.current,
      (res, err) => {
        if (err) {
          VMBC.Alert.Show(err);
        } else {
          VMBC.Alert.Show(res, "success");
        }
        handleCloseAddIpPool();
        Refresh();
      }
    );
  };

  const handleCloseAddIpPool = () => {
    window.$("#addIpPoolDialog").modal("hide");
    setSelectedIpPlToAdd();
    selectedIpPlToAddObj.current = null;
  };

  return (
    <div>
      <div
        className="modal fade"
        id="addIpPoolDialog"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Add IpPool Dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6>Choose an IP Pool</h6>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <p className="modal-text" style={{ color: "#fff" }}>
                Choose the IP Pool you want to add to this Plan for this Compute
                Resource
              </p>
              <select
                className="planIpPoolSelectpicker"
                title={`Choose an IP Pool`}
                data-width="100%"
                value={selectedIpPlToAdd}
                onChange={(e) => handleSelectedIpPlChange(e.target.value)}
              >
                {allIpPools.map((IpPool, i) => {
                  return (
                    <option value={i}>
                      {IpPool.Name !== "" ? IpPool.Name : "No Name"}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="modal-footer">
              <button
                className={`btn btn-primary btn-sm`}
                onClick={handleAddIpPool}
              >
                Add
              </button>
              <button
                className={`btn btn-dark btn-sm`}
                onClick={handleCloseAddIpPool}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IpPoolsList;
