import React from "react";
import "./index.css";
import Stepper from "../../Components/Stepper";
import VMBC from "../../VMBC";
import { Languages, SelectLanguage } from "./Languages";

function Eula(props) {
  const [accepted, setAccepted] = React.useState(
    props.InstallData && props.InstallData.current.Eula
      ? !!props.InstallData.current.Eula.Accepted
      : false
  );

  var lang = null;

  if (props.InstallData) {
    props.InstallData.current.Eula.Accepted = accepted;
    lang = props.InstallData.current.Eula.Lang;
  }

  return (
    <>
      <Stepper
        steps={[
          {
            title: "EULA",
            onClick: (e) => {
              e.preventDefault();
            },
          },
          {
            title: "License",
            onClick: (e) => {
              e.preventDefault();
              if (props.onNext && accepted) {
                props.onNext();
              }
            },
          },
          {
            title: "vCenter",
            onClick: (e) => {
              e.preventDefault();
            },
          },
          // {
          //   title: "Finish",
          //   onClick: (e) => {
          //     e.preventDefault();
          //   },
          // },
        ]}
        activeStep={0}
      />
      <EulaContent
        defaultLang={lang}
        OnChange={(lang) => {
          if (props.InstallData) {
            props.InstallData.current.Eula.Lang = lang;
          }
          setAccepted(false);
        }}
      />
      <div className={`d-flex justify-content-between align-items-center mt-4`}>
        <span className={`d-flex`}>
          <div class="n-chk">
            <label class="new-control new-radio radio-classic-info">
              <input
                type="radio"
                class="new-control-input"
                name="custom-radio-1"
                checked={accepted}
                onClick={(e) => setAccepted(true)}
              />
              <span class="new-control-indicator"></span>I Accept
            </label>

            <label class="new-control new-radio radio-classic-info">
              <input
                type="radio"
                class="new-control-input"
                name="custom-radio-1"
                checked={!accepted}
                onClick={(e) => setAccepted(false)}
              />
              <span class="new-control-indicator"></span>I do not Accept
            </label>
          </div>
        </span>
        <button
          className={`btn btn-primary`}
          onClick={() => {
            if (accepted) {
              props.onNext && props.onNext();
              return;
            }
            VMBC.Alert.Show("You must accept EULA to continue.", "error", 5000);
          }}
        >
          Next
        </button>
      </div>
    </>
  );
}

function EulaContent(props) {
  const { defaultLang } = props;
  const [language, setLanguage] = React.useState(
    defaultLang ? defaultLang : Languages.En
  );

  const langChanged = (lang) => {
    if (props.OnChange) {
      props.OnChange(lang);
    }
    return true;
  };
  return (
    <>
      <div id="eulaWrapper" class="">
        <language.Content
          SelectLanguage={
            <SelectLanguage
              language={language}
              SetLanguage={(lang) => langChanged(lang) && setLanguage(lang)}
            />
          }
        />
      </div>
    </>
  );
}

export default Eula;
