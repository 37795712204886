import axios from "axios";
import Demo from "../../Demo";

function GetRegTermStatistics(cb) {
  cb = cb ? cb : () => {};
  Demo.DelayRandom(() => {
    window.VMBC.CheckSId(() => {
      Demo.DelayRandom(() => {
        Demo.DelayRandom(() => {
          var mRndReg = () => Demo.RandInt(1, 22);
          var mRndTer = () => Demo.RandInt(0, 20);
          var Res = {
            data: {
              yearly: {
                categories: [
                  "2019 Dec",
                  "2020 Jan",
                  "2020 Feb",
                  "2020 Mar",
                  "2020 Apr",
                  "2020 May",
                  "2020 Jun",
                  "2020 Jul",
                  "2020 Aug",
                  "2020 Sep",
                  "2020 Oct",
                  "2020 Nov",
                ],
                RegistrationSeries: {
                  201912: 0,
                  202001: 0,
                  202002: 0,
                  202003: 0,
                  202004: 0,
                  202005: 0,
                  202006: 0,
                  202007: 0,
                  202008: 0,
                  202009: 0,
                  202010: 2,
                  202011: 8,
                },
                TerminationSeries: {
                  201912: 0,
                  202001: 0,
                  202002: 0,
                  202003: 0,
                  202004: 0,
                  202005: 0,
                  202006: 0,
                  202007: 0,
                  202008: 0,
                  202009: 0,
                  202010: 10,
                  202011: 9,
                },
              },
              monthly: {
                categories: [
                  "24 Oct",
                  "25 Oct",
                  "26 Oct",
                  "27 Oct",
                  "28 Oct",
                  "29 Oct",
                  "30 Oct",
                  "31 Oct",
                  "1 Nov",
                  "2 Nov",
                  "3 Nov",
                  "4 Nov",
                  "5 Nov",
                  "6 Nov",
                  "7 Nov",
                  "8 Nov",
                  "9 Nov",
                  "10 Nov",
                  "11 Nov",
                  "12 Nov",
                  "13 Nov",
                  "14 Nov",
                  "15 Nov",
                  "16 Nov",
                  "17 Nov",
                  "18 Nov",
                  "19 Nov",
                  "20 Nov",
                  "21 Nov",
                  "22 Nov",
                ],
                RegistrationSeries: {
                  20201024: mRndReg(),
                  20201025: mRndReg(),
                  20201026: mRndReg(),
                  20201027: mRndReg(),
                  20201028: mRndReg(),
                  20201029: mRndReg(),
                  20201030: mRndReg(),
                  20201031: mRndReg(),
                  20201101: mRndReg(),
                  20201102: mRndReg(),
                  20201103: mRndReg(),
                  20201104: mRndReg(),
                  20201105: mRndReg(),
                  20201106: mRndReg(),
                  20201107: mRndReg(),
                  20201108: mRndReg(),
                  20201109: mRndReg(),
                  20201110: mRndReg(),
                  20201111: mRndReg(),
                  20201112: mRndReg(),
                  20201113: mRndReg(),
                  20201114: mRndReg(),
                  20201115: mRndReg(),
                  20201116: mRndReg(),
                  20201117: mRndReg(),
                  20201118: mRndReg(),
                  20201119: mRndReg(),
                  20201120: mRndReg(),
                  20201121: mRndReg(),
                  20201122: mRndReg(),
                },
                TerminationSeries: {
                  20201024: mRndTer(),
                  20201025: mRndTer(),
                  20201026: mRndTer(),
                  20201027: mRndTer(),
                  20201028: mRndTer(),
                  20201029: mRndTer(),
                  20201030: mRndTer(),
                  20201031: mRndTer(),
                  20201101: mRndTer(),
                  20201102: mRndTer(),
                  20201103: mRndTer(),
                  20201104: mRndTer(),
                  20201105: mRndTer(),
                  20201106: mRndTer(),
                  20201107: mRndTer(),
                  20201108: mRndTer(),
                  20201109: mRndTer(),
                  20201110: mRndTer(),
                  20201111: mRndTer(),
                  20201112: mRndTer(),
                  20201113: mRndTer(),
                  20201114: mRndTer(),
                  20201115: mRndTer(),
                  20201116: mRndTer(),
                  20201117: mRndTer(),
                  20201118: mRndTer(),
                  20201119: mRndTer(),
                  20201120: mRndTer(),
                  20201121: mRndTer(),
                  20201122: mRndTer(),
                },
              },
            },
          };
          var lastMonthReg = 0;
          Object.keys(Res.data.monthly.RegistrationSeries).forEach(
            (key) => (lastMonthReg += Res.data.monthly.RegistrationSeries[key])
          );
          var lastMonthTer = 0;
          Object.keys(Res.data.monthly.TerminationSeries).forEach(
            (key) => (lastMonthTer += Res.data.monthly.TerminationSeries[key])
          );
          var mRndReg = () =>
            Demo.RandInt(0.9 * lastMonthReg, 1.1 * lastMonthReg);
          var mRndTer = () =>
            Demo.RandInt(0.9 * lastMonthTer, 1.1 * lastMonthTer);
          var i = 0;
          var regKeys = Object.keys(Res.data.yearly.RegistrationSeries);
          for (i = 0; i < regKeys.length - 1; i++) {
            Res.data.yearly.RegistrationSeries[regKeys[i]] = mRndReg();
          }
          Res.data.yearly.RegistrationSeries[regKeys[i]] = lastMonthReg;
          var terKeys = Object.keys(Res.data.yearly.TerminationSeries);
          for (i = 0; i < terKeys.length - 1; i++) {
            Res.data.yearly.TerminationSeries[terKeys[i]] = mRndTer();
          }
          Res.data.yearly.TerminationSeries[terKeys[i]] = lastMonthTer;

          cb(Res.data);
        });
      });
    });
  });
}

function GetMachinesCountByState(cb) {
  cb = cb ? cb : () => {};
  Demo.DelayRandom(() => {
    window.VMBC.CheckSId(() => {
      Demo.DelayRandom(() => {
        var Res = {
          Total: 0,
          Online: parseInt((Demo.RandInt(60, 85) / 100) * Demo.SampleSize),
          Offline: parseInt((Demo.RandInt(5, 20) / 100) * Demo.SampleSize),
          Suspended: parseInt((Demo.RandInt(5, 30) / 100) * Demo.SampleSize),
        };
        Res.Total = Res.Online + Res.Offline + Res.Suspended;
        cb(Res);
      });
    });
  });
}

function GetPlansConditions(cb) {
  cb = cb ? cb : () => {};
  Demo.DelayRandom(() => {
    window.VMBC.CheckSId(() => {
      Demo.DelayRandom(() => {
        cb(Demo.VMPlans.Data);
      });
    });
  });
}

var Actions = [
  "PowerOn",
  "PowerOff",
  "NotFinding",
  "Deploy",
  "Delete",
  "SetupOS",
  "Acquire WMKS Ticket",
];

function GetRecentActions(Options, cb) {
  cb = cb ? cb : () => {};
  Demo.DelayRandom(() => {
    window.VMBC.CheckSId(() => {
      Demo.DelayRandom(() => {
        var Res = [];
        var actsLen = Actions.length;
        var getRandomAction = () => Actions[Demo.RandInt(0, actsLen)];
        var vmCount = Demo.VMachines.Data.length;
        var now = parseInt(Date.now() / 1000);
        var getRandomStartDate = () => {
          now = now - Demo.RandInt(3, 140);
          return now;
        };
        for (var i = 0; i < 10; i++) {
          var vm = Demo.VMachines.Data[Demo.RandInt(0, vmCount)];
          var ActorType = [
            "U",
            "C",
            "C",
            "C",
            "C",
            "C",
            "C",
            "C",
            "C",
            "W",
            "W",
            "S",
            "S",
            "S",
          ][Demo.RandInt(0, 4)];
          var startDate = getRandomStartDate();
          var customer = Demo.Customers.Data[vm.LINK_ownerId];
          Res.push({
            Id: i + 1,
            VmName: vm.vmName,
            VmRecordId: vm.id,
            ActionName: getRandomAction(),
            ActorType: ActorType,
            ActorName:
              ActorType === "U"
                ? "Administrator"
                : `${customer.firstname} ${customer.lastname}`,
            StartDate: startDate,
            EndDate: startDate + Demo.RandInt(1, 5),
            Failed: false,
            Failure: null,
          });
        }
        cb({
          RecentActions: Res,
          Count: 10,
          Limit: 10,
        });
      });
    });
  });
}

function GetRecentOrders(cb) {
  cb = cb ? cb : () => {};
  Demo.DelayRandom(() => {
    window.VMBC.CheckSId(() => {
      Demo.DelayRandom(() => {
        var Res = [];
        var vmCount = Demo.VMachines.Data.length;
        var now = parseInt(Date.now() / 1000);
        var lastServiceId = Demo.RandInt(1000, 10000);
        var getRandomRegDate = () => {
          now = now - Demo.RandInt(180, 3600);
          return now;
        };
        for (var i = 0; i < 7; i++) {
          var vm = Demo.VMachines.Data[Demo.RandInt(0, vmCount)];
          var regDate = getRandomRegDate();
          var customer = Demo.Customers.Data[vm.LINK_ownerId];
          var plan = Demo.VMPlans.Data[vm.LINK_planId];
          var serviceId = "" + lastServiceId;
          lastServiceId--;
          Res.push({
            VmName: vm.vmName,
            VmRecordId: vm.id,
            ServiceId: serviceId,
            WhmcsServiceLink: `/productDetails/clientsservices.php?productselect=${serviceId}`,
            State: "Deployed",
            RegistrationDate: regDate,
            ClientId: "" + customer.id,
            WhmcsClientLink: `/productDetails/clientssummary.php?userid=${customer.id}`,
            ClientName: `${customer.firstname} ${customer.lastname}`,
            PlanName: plan.PlanName,
            PlanId: plan.PlanId,
          });
        }
        cb(Res);
      });
    });
  });
}

export default {
  GetRegTermStatistics,
  GetMachinesCountByState,
  GetPlansConditions,
  GetRecentOrders,
  GetRecentActions,
};
