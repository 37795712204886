import React from "react";
import CdDrivesList from "./CdDrivesList";
import VMBC from "../../../../../VMBC";

function CdDrives(props) {
  const { plan } = props;

  const [selectedCRToShow, setSelectedCRToShow] = React.useState(
    plan.Solutions.length != undefined && plan.Solutions.length > 0
      ? plan.Solutions[0]
      : undefined
  );
  const selectedCRToShowObj = React.useRef();

  const handleSelectedCRChange = (e) => {
    if (plan && plan.Solutions) {
      if (plan.Solutions.length > e) {
        //check array index bound
        selectedCRToShowObj.current = plan.Solutions[e];
        setSelectedCRToShow(plan.Solutions[e]);
      }
    }
  };

  React.useEffect(() => {
    window.$(".planCdDriveCrSelectpicker").selectpicker("refresh");
  }, [selectedCRToShowObj]);

  return (
    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div className="widget widget-table-two">
        <div
          className="widget-heading"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>CD/DVD Drives</h5>
          <span>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <div class="input-group-text" style={{ color: "#fff" }}>
                  Compute Resource:
                </div>
              </div>
              <select
                className="planCdDriveCrSelectpicker"
                title={
                  selectedCRToShow != undefined
                    ? selectedCRToShow.Name
                    : `Select a Compute Resource`
                }
                defaultValue={selectedCRToShow}
                onChange={(e) => handleSelectedCRChange(e.target.value)}
              >
                {plan.Solutions.map((solu, i) => (
                  <option value={i}>{solu.ComputeResourceName}</option>
                ))}
              </select>
            </div>
          </span>
        </div>

        <div className="widget-content">
          {selectedCRToShow ? (
            <CdDrivesList PlanSolution={selectedCRToShow} />
          ) : (
            <div
              className={`my-2`}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className="spinner-border align-self-center loader-lg"
                style={{ width: "3rem", height: "3rem" }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CdDrives;
