import React from "react";
import "./SelectPortGroup.css";

function SelectPortGroup(props) {
  const [networks, setNetworks] = React.useState([]);
  const Nets = React.useRef([]);

  React.useEffect(() => {
    if (props.ComputeResource) {
      Nets.current = props.ComputeResource.Networks;
      setNetworks(
        Nets.current.map((net, i) => (
          <option value={i}>{net.Name ? net.Name : net.NetworkId}</option>
        ))
      );
      window.$(".portGroupSelectpicker").selectpicker("refresh");
    }
  }, []);

  React.useEffect(() => {
    // rerender select picker on component did mount
    window.$(".portGroupSelectpicker").selectpicker("refresh");
  });

  return (
    <span class="input-group" style={{ width: "auto" }} key={networks.length}>
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon5">
          Port Group
        </span>
      </div>
      <select
        className="portGroupSelectpicker"
        title={`Select a Port Group`}
        onChange={(e) => {
          props.onChange &&
            Nets.current[e.target.value] &&
            props.onChange({ pg: Nets.current[e.target.value] });
        }}
      >
        {networks}
      </select>
    </span>
  );
}

export default SelectPortGroup;
