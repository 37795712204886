import React from "react";
import VMBC from "../../VMBC";

function Inventory(props) {
  
  return (
    <>
      <div class="container-fluid text-center maintanence-content">
        <div class="" style={{ padding: "60px 0" }}>
          {/* <div class="maintanence-hero-img">
                <img alt="logo" src="/assets/img/90x90.jpg" />
            </div> */}
          <h1 class="error-title">Under Maintenance</h1>
          <p class="error-text">Thank you for choosing VMBox.</p>
          <p class="text">
            We are currently working on this sections <br /> to give
            you better use.
          </p>
          <p class="text">Please check for next updates.</p>
          <VMBC.A href="/panel" class="btn btn-info mt-4">
            Dashboard
          </VMBC.A>
        </div>
      </div>
    </>
  );
}

export default Inventory;
