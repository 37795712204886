import React from "react";
import { withRouter } from "react-router";
import VMBC from "../../VMBC";

function FolderExplorer(props) {
  let currentFolder = props.Folder;

  const [selectedObject, setSelectedObject] = React.useState(
    props.SelectedObject
  );

  const onAddressClicked = props.onAddressClicked
    ? props.onAddressClicked
    : () => {};
  const onObjectSelect = props.onObjectSelect ? props.onObjectSelect : () => {};
  const onObjectDoubleClick = props.onObjectDoubleClick
    ? props.onObjectDoubleClick
    : () => {};

  return (
    <div
      className={props.className ? props.className : ""}
      style={{ width: "100%" }}
    >
      <div className={`container`} style={{ padding: "0 5px" }}>
        <div className={`row justify-content-between`}>
          {props.Title ? (
            <h6 style={{ marginLeft: "15px" }}>{props.Title}</h6>
          ) : undefined}

          {!props.HideAddress ? (
            <span>
              {currentFolder &&
              currentFolder.Address &&
              currentFolder.Address.length > 1 ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={(e) =>
                    onAddressClicked(
                      currentFolder.Address[currentFolder.Address.length - 2]
                    )
                  }
                >
                  <VMBC.SVGs.ArrowLeftCircle
                    size="18"
                    style={{ marginTop: "-2px" }}
                  />
                </span>
              ) : undefined}
              {currentFolder && currentFolder.Address
                ? currentFolder.Address.map((addr, i) => (
                    <React.Fragment key={i}>
                      <span>{i === 0 ? " / " : " / "}</span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => onAddressClicked(addr)}
                      >
                        {addr.Name}
                      </span>
                    </React.Fragment>
                  ))
                : ""}
            </span>
          ) : undefined}
        </div>
      </div>
      <div
        id={props.id}
        className={`container col-xs-12`}
        style={{
          height: props.height ? props.height : "320px",
          position: "relative",
          overflow: "auto",
          padding: "2px",
          borderRadius: "7px",
          border: "1px solid grey",
          padding: "0 15px",
        }}
      >
        <div className={`row`}>
          {props.onRefresh ? (
            <span
              style={{
                right: "0",
                position: "absolute",
                padding: "0 5px",
                cursor: "pointer",
              }}
              onClick={props.onRefresh}
            >
              <VMBC.SVGs.RefreshCCW size="20" />
            </span>
          ) : undefined}
          {currentFolder && currentFolder.items ? (
            currentFolder.items.map((Obj, i) => (
              <ListObject
                key={i}
                Object={Obj}
                selected={selectedObject && Obj.Name === selectedObject.Name}
                onSelect={(Obj) => {
                  setSelectedObject(Obj);
                  onObjectSelect(Obj);
                }}
                onDoubleClick={onObjectDoubleClick}
              />
            ))
          ) : (
            <h6 className={`text-center`}>Loading...</h6>
          )}
        </div>
      </div>
    </div>
  );
}

function ListObject(props) {
  const [hover, setHover] = React.useState(false);
  return (
    <div
      className={`col-xs-12 col-sm-4 col-md-3 col-lg-2`}
      style={{ padding: "2px" }}
    >
      <div
        className={`container-fluid justify-content-center`}
        onClick={() => {
          props.onSelect && props.onSelect(props.Object);
        }}
        onDoubleClick={() => {
          props.onDoubleClick && props.onDoubleClick(props.Object);
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          borderRadius: "5px",
          overflow: "hidden",
          cursor: "pointer",
          padding: "15px 0",
          backgroundColor: `rgba(0,0,0,.${
            props.selected ? "5" : hover ? "4" : "0"
          })`,
        }}
      >
        <div className={`row justify-content-center`}>
          {props.Object && props.Object.icon}
        </div>
        <div className={`row justify-content-center`}>
          <span style={{ textAlign: "center" }}>
            {props.Object && props.Object.Name}
          </span>
        </div>
      </div>
    </div>
  );
}

export function LinkParents(Obj) {
  return linkParents(Obj, []);
}

function linkParents(Obj, Address) {
  Obj.Address = [...Address, Obj];
  if (Obj.isFolder && Obj.items) {
    Obj.items.sort((a, b) => !a.isFolder & b.isFolder);
    Obj.items.forEach((item) => {
      linkParents(item, Obj.Address);
    });
  }
}

export default withRouter(FolderExplorer);
