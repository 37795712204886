import React from "react";
import VMBC from "../../../../../VMBC";

function ComputeResources(props) {
  const { plan, Refresh } = props;

  return (
    <div class="col-xl-5 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-table-one">
        <div
          class="widget-heading"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h5 class="">Compute Resources</h5>
          <span>
            <span
              style={{ cursor: "pointer", marginRight: "10px" }}
              onClick={Refresh}
            >
              <VMBC.SVGs.RefreshCCW />
            </span>

            <AddSolution plan={plan} Refresh={Refresh} />
          </span>
        </div>

        {!plan || !plan.Solutions ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              className="spinner-border align-self-center loader-lg"
              style={{ width: "3rem", height: "3rem" }}
            ></div>
          </div>
        ) : undefined}

        <div
          class="widget-content"
          style={{
            height: "315px",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "5px 0",
          }}
        >
          {plan && plan.Solutions && plan.Solutions.length != undefined
            ? plan.Solutions.map((solution) => (
                <div
                  class="transactions-list"
                  style={{ width: "95%", margin: "0 auto 15px auto" }}
                >
                  <div class="t-item">
                    <div class="t-company-name">
                      <div class="t-icon">
                        <div class="icon">
                          <VMBC.SVGs.Server />
                        </div>
                      </div>
                      <div class="t-name">
                        <h4>{solution.ComputeResourceName}</h4>
                        <p class="meta-date">
                          Host System: {solution.ComputeResourceId}
                        </p>
                      </div>
                    </div>
                    <div class="t-rate">
                      <p>
                        <VMBC.SVGs.RecycleBin
                          style={{
                            width: "22px",
                            height: "22px",
                            color: "#e7515a",
                            marginRight: "3px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            VMBC.Dialogs.Show({
                              Title: "Are you sure?",
                              Text:
                                "Are you sure you want to remove this compute resource from this plan?",
                              Ignorable: true,
                              Actions: [
                                {
                                  onClick: () => {
                                    VMBC.Plans.RemoveSolution(
                                      solution.SolutionId,
                                      (res, err) => {
                                        if (err) {
                                          VMBC.Alert.Show(err);
                                        } else {
                                          VMBC.Alert.Show(res, "success");
                                          Refresh();
                                        }
                                      }
                                    );
                                    VMBC.Dialogs.Close();
                                  },
                                  className: "btn btn-outline-primary btn-sm",
                                  text: "Yes",
                                },
                                {
                                  onClick: () => VMBC.Dialogs.Close(),
                                  className: "btn btn-primary btn-sm",
                                  text: "No",
                                },
                              ],
                            });
                          }}
                        />
                        {/* <VMBC.A
                          href={`/panel/plans/solutions/${solution.SolutionId}`}
                        >
                          <VMBC.SVGs.Settings
                            style={{ width: "20px", height: "20px" }}
                          />
                        </VMBC.A> */}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            : undefined}
        </div>
      </div>
    </div>
  );
}

function AddSolution(props) {
  const { plan, Refresh } = props;

  const [selectedCRToAdd, setSelectedCRToAdd] = React.useState();
  const selectedCRToAddObj = React.useRef();

  const handleSelectedCRChange = (e) => {
    if (plan && plan.Datacenter && plan.Datacenter.ComputeResources) {
      if (plan.Datacenter.ComputeResources.length > e) {
        //check array index bound
        selectedCRToAddObj.current = plan.Datacenter.ComputeResources[e];
        setSelectedCRToAdd(plan.Datacenter.ComputeResources[e]);
      }
    }
  };

  const handleAddComputeResource = () => {
    if (
      plan.Solutions.map((Solu) => Solu.ComputeResourceId).includes(
        selectedCRToAddObj.current.MO_Reference
      )
    ) {
      VMBC.Alert.Show("Compute Resource is Already in Plan", "error", 3000);
      return;
    }
    VMBC.Plans.AddSolution(
      plan.PlanId,
      selectedCRToAddObj.current,
      (res, err) => {
        if (err) {
          VMBC.Alert.Show(err);
        } else {
          VMBC.Alert.Show(res, "success");
        }
        handleCloseAddComputeResource();
        Refresh();
      }
    );
  };

  const handleCloseAddComputeResource = () => {
    window.$("#addSolutionDialog").modal("hide");
    setSelectedCRToAdd();
    selectedCRToAddObj.current = null;
  };

  return (
    <>
      <button
        className={`btn btn-primary btn-sm`}
        onClick={() => {
          window.$(".AddComputeResourceSelectpicker").selectpicker("refresh");
          window.$("#addSolutionDialog").modal("show");
        }}
      >
        Add
      </button>

      <div
        className="modal fade"
        id="addSolutionDialog"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Add Solution Dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6>Select a Compute Resource</h6>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <p className="modal-text" style={{ color: "#fff" }}>
                Select the Compute Resource you want to add to plan
              </p>
              <select
                className="AddComputeResourceSelectpicker"
                title={`Select a Compute Resource`}
                data-width="100%"
                value={selectedCRToAdd}
                onChange={(e) => handleSelectedCRChange(e.target.value)}
              >
                {plan.Datacenter.ComputeResources.map((CR, i) => (
                  <option value={i}>{CR.Name}</option>
                ))}
              </select>
            </div>
            <div className="modal-footer">
              <button
                className={`btn btn-primary btn-sm`}
                onClick={handleAddComputeResource}
              >
                Add
              </button>
              <button
                className={`btn btn-dark btn-sm`}
                onClick={handleCloseAddComputeResource}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComputeResources;
