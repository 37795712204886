import React from "react";
import { withRouter, Switch, Route } from "react-router";
import TargetFolder from "./TargetFolder";
import ComputeResource from "./ComputeResource";
import Datastore from "./Datastore";
import Stepper from "../../../Components/Stepper";
import Template from "./Template";
import Customization from "./Customization";
import IpPool from "./IpPool";
import Finishing from "./Finishing";
window.AddToLoadingVmboxProccess(1);

function Add(props) {
  // next and previous button click events
  const Next = React.useRef();
  const Previous = React.useRef();
  const [stepNum, setStep] = React.useState(0);

  const Data = React.useRef({
    PlanAndTarget: {
      PlanName: "",
    },
    ComputeResources: {
      PriorityByDatastore: true,
      // SelectedComputeResources: [
      //   {
      //     Name, ...
      //     SelectedDatastores: [
      //       {
      //         Name,
      //         DatastoreMO
      //       }, ...
      //     ],
      //     SelectedIpPools: [
      //      {
      //         Id, Name, Description
      //      }
      //     ],
      //     SelectedIsoFiles: [
      //      {
      //         Id
      //      }
      //     ]
      //   }, ...
      // ]
    },
    Datastores: {
      PriorityByDatastore: true,
    },
    Templates: {},
    Customization: {
      NamingOptions: {
        name: "{ipAddress}-{planName}",
        isUnique: true,
      },
      NetworkQuota: {
        enabled: false,
      },
      CPU: [],
      Memory: [],
      HardDisk: [],
      CdDrive: { collection: { current: [] } },
    },
    IpPools: {},
  });
  const nextValidators = React.useRef([]);
  const SubmitNextValidator = (pageNum) => (validator) =>
    (nextValidators.current[pageNum] = validator);

  const isValidToGo = (step) => {
    for (var i = 0; i < step; i++) {
      if (!nextValidators.current[i]) return false;
      if (!nextValidators.current[i]()) return false;
    }
    return true;
  };

  const nextButtonClicked = () => {
    if (isValidToGo(stepNum + 1)) {
      setStep(stepNum + 1);
    }
  };

  return (
    <>
      <div style={{ marginTop: "15px" }}>
        <div class="widget-content widget-content-area">
          <Stepper
            steps={[
              {
                title: "Name and Target Folder",
                onClick: (e) => {
                  e.preventDefault();
                  if (isValidToGo(0)) {
                    setStep(0);
                  }
                },
              },
              {
                title: "Compute Resource",
                onClick: (e) => {
                  e.preventDefault();
                  if (isValidToGo(1)) {
                    setStep(1);
                  }
                },
              },
              {
                title: "Datastore",
                onClick: (e) => {
                  e.preventDefault();
                  if (isValidToGo(2)) {
                    setStep(2);
                  }
                },
              },
              {
                title: "Templates",
                onClick: (e) => {
                  e.preventDefault();
                  if (isValidToGo(3)) {
                    setStep(3);
                  }
                },
              },
              {
                title: "Customization",
                onClick: (e) => {
                  e.preventDefault();
                  if (isValidToGo(4)) {
                    setStep(4);
                  }
                },
              },
              {
                title: "IP Pools",
                onClick: (e) => {
                  e.preventDefault();
                  if (isValidToGo(5)) {
                    setStep(5);
                  }
                },
              },
              {
                title: "Finishing",
                onClick: (e) => {
                  e.preventDefault();
                  if (isValidToGo(6)) {
                    setStep(6);
                  }
                },
              },
            ]}
            activeStep={stepNum}
          />

          <div className={`container`}>
            <div className={`row`}>
              {stepNum === 0 ? (
                <TargetFolder
                  Data={Data}
                  SubmitNextValidator={SubmitNextValidator(0)}
                />
              ) : stepNum === 1 ? (
                <ComputeResource
                  Data={Data}
                  SubmitNextValidator={SubmitNextValidator(1)}
                />
              ) : stepNum === 2 ? (
                <Datastore
                  Data={Data}
                  SubmitNextValidator={SubmitNextValidator(2)}
                />
              ) : stepNum === 3 ? (
                <Template
                  Data={Data}
                  SubmitNextValidator={SubmitNextValidator(3)}
                />
              ) : stepNum === 4 ? (
                <Customization
                  Data={Data}
                  SubmitNextValidator={SubmitNextValidator(4)}
                />
              ) : stepNum === 5 ? (
                <IpPool
                  Data={Data}
                  SubmitNextValidator={SubmitNextValidator(5)}
                />
              ) : stepNum === 6 ? (
                <Finishing
                  Data={Data}
                  onGotoBegining={() => {
                    setStep(0);
                  }}
                />
              ) : undefined}
            </div>

            <div className={`row justify-content-end`}>
              {stepNum !== 0 ? (
                <>
                  <button
                    className={`btn btn-outline-primary`}
                    style={{ margin: "1rem 5px -5px" }}
                    onClick={() => setStep(stepNum - 1)}
                  >
                    Previous
                  </button>
                </>
              ) : undefined}
              {stepNum !== 6 ? (
                <>
                  <button
                    className={`btn btn-primary`}
                    style={{ margin: "1rem 5px -5px" }}
                    onClick={nextButtonClicked}
                  >
                    Next
                  </button>
                </>
              ) : undefined}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Time(props) {
  const [time, setTime] = React.useState("");
  React.useEffect(() => {
    setInterval(() => {
      setTime(new Date().toTimeString());
    }, 1000);
  }, []);

  return <>{time.split("GMT")[0]}</>;
}
export default withRouter(Add);
