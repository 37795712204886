import React, { memo } from "react";
import VMBC from "../../../../../../VMBC";

import CPU from "./CPU";
import Memory from "./Memory";
import HardDisk from "./HardDisk";

function VirtualHardware(props) {
  const { PlanId } = props;

  const [cpu, setCpu] = React.useState();
  const [memory, setMemory] = React.useState();
  const [hardDisk, setHardDisk] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    // setCpu();
    // setMemory();
    // setHardDisk();
    VMBC.Plans.GetPlanVirtualHardware(PlanId, (data, err) => {
      setCpu(data.Cpu ? data.Cpu : []);
      setMemory(data.Memory ? data.Memory : []);
      setHardDisk(data.HardDisk ? data.HardDisk : []);
    });
  }, [refreshT]);

  const Refresh = () => refresh(!refreshT);

  return (
    <>
      <div id="virtualHardwareAccordion" className={`no-outer-spacing`}>
        <TabCard id={"cpuProfile"} title={"CPU"}>
          <CPU Cpu={cpu} PlanId={PlanId} Refresh={Refresh} />
        </TabCard>
        <TabCard id={"memoryProfile"} title={"Memory"}>
          <Memory Memory={memory} PlanId={PlanId} Refresh={Refresh} />
        </TabCard>
        <TabCard id={"hardDiskProfile"} title={"Hard Disk"}>
          <HardDisk HardDisk={hardDisk} PlanId={PlanId} Refresh={Refresh} />
        </TabCard>
      </div>
    </>
  );
}

function TabCard(props) {
  return (
    <div className={`card no-outer-spacing`}>
      <div className="card-header" id={props.id}>
        <section className="mb-0 mt-0">
          <div
            role="menu"
            className={`collapsed ${props.shown ? "show" : ""}`}
            data-toggle="collapse"
            data-target={`#${props.id}Acc`}
            aria-expanded="false"
            aria-controls={`${props.id}Acc`}
          >
            <span style={{ color: "#fff" }}>{props.title}</span>
            <div className="icons">
              <VMBC.SVGs.ChevronDown />
            </div>
          </div>
        </section>
      </div>

      <div
        id={`${props.id}Acc`}
        className="collapse"
        aria-labelledby={props.id}
        data-parent="#virtualHardwareAccordion"
      >
        <div className="card-body">{props.children}</div>
      </div>
    </div>
  );
}

export default VirtualHardware;
