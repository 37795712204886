import React from "react";
import "./MemoryProfile.css";
import VMBC from "../../../../../../../VMBC";

function MemoryProfile(props) {
  const { Profile } = props;

  const [editable, setEditable] = React.useState(props.isNew);

  const [alias, setAlias] = React.useState(Profile.Alias);
  const [capacity, setCapacity] = React.useState(
    Profile.Capacity
      ? Profile.Capacity % 1024 === 0
        ? Number(Profile.Capacity) / 1024
        : Number(Profile.Capacity)
      : 0
  );
  const [capacityUnit, setCapacityUnit] = React.useState(
    Profile.Capacity % 1024 === 0 ? 1 : 0
  );

  const [reserve, setReserve] = React.useState(
    !props.isNew && Profile.Reservation !== -1
  );
  const [reservation, setReservation] = React.useState(
    Profile.Reservation
      ? Profile.Reservation % 1024 === 0
        ? Number(Profile.Reservation) / 1024
        : Number(Profile.Reservation) !== -1
        ? Number(Profile.Reservation)
        : 0
      : 0
  );
  const [reservationUnit, setReservationUnit] = React.useState(
    Profile.Reservation % 1024 === 0 ? 1 : 0
  );

  const [limit, setLimit] = React.useState(
    !props.isNew && Profile.LimitBy !== -1
  );
  const [limitBy, setLimitBy] = React.useState(
    Profile.LimitBy
      ? Profile.LimitBy % 1024 === 0
        ? Number(Profile.LimitBy) / 1024
        : Number(Profile.LimitBy) !== -1
        ? Number(Profile.LimitBy)
        : 0
      : 0
  );
  const [limitByUnit, setLimitByUnit] = React.useState(
    Profile.LimitBy % 1024 === 0 ? 1 : 0
  );

  const saveProfile = () => {
    if (alias === "" || alias == undefined) {
      VMBC.Alert.Show(`Alias cannot be empty`, "error", 3000);
      return;
    }
    if (alias.length > 100) {
      VMBC.Alert.Show(
        `Alias cannot be more than 100 characters`,
        "error",
        3000
      );
      return;
    }
    if (alias.includes(",")) {
      VMBC.Alert.Show(`Alias cannot contain unknown characters`, "error", 3000);
      return;
    }
    let found = false;
    props.AllProfiles.forEach(
      (prof, i) =>
        (found = prof.Alias == alias && i != props.Index ? true : found)
    );
    if (found) {
      VMBC.Alert.Show(
        `Additional Memory named '${alias}' already exists.`,
        "error",
        3000
      );
      return;
    }
    if (
      isNaN(capacity) ||
      isNaN(capacityUnit) ||
      isNaN(reservation) ||
      isNaN(limitBy)
    ) {
      VMBC.Alert.Show(`Please Check your inputs`, "error", 3000);
      return;
    }
    if (props.isNew) {
      VMBC.Plans.AddMemoryProfile(
        props.PlanId,
        {
          Alias: alias,
          Capacity: Number(capacity),
          CapacityUnit: Number(capacityUnit),
          Reserve: reserve,
          Reservation: Number(reservation),
          ReservationUnit: Number(reservationUnit),
          Limit: limit,
          LimitBy: Number(limitBy),
          LimitByUnit: Number(limitByUnit),
        },
        (res, err) => {
          if (err) {
            VMBC.Alert.Show(err);
          } else {
            VMBC.Alert.Show(res, "success");
            if (props.Refresh) props.Refresh();
          }
        }
      );
    } else {
      VMBC.Plans.EditMemoryProfile(
        props.PlanId,
        {
          ProfileId: Profile.ProfileId,
          Alias: alias,
          Capacity: Number(capacity),
          CapacityUnit: Number(capacityUnit),
          Reserve: reserve && reservation > 0,
          Reservation: Number(reservation),
          ReservationUnit: Number(reservationUnit),
          Limit: limit && limitBy > 0,
          LimitBy: Number(limitBy),
          LimitByUnit: Number(limitByUnit),
        },
        (res, err) => {
          if (err) {
            VMBC.Alert.Show(err);
          } else {
            VMBC.Alert.Show(res, "success");
            if (props.Refresh) props.Refresh();
          }
        }
      );
    }
    if (props.isNew) {
      if (props.onClose) props.onClose();
    } else {
      setEditable(false);
    }
  };

  React.useEffect(() => {
    // rerender select picker on component did mount
    window.$(".planMemorySelectpicker").selectpicker("refresh");
  }, [editable]);

  return (
    <div className={`col-12`}>
      <div
        className={`memoryAdditionalCard`}
        style={
          Profile.isDefault
            ? editable
              ? { boxShadow: "0px 0px 15px 0px #8DEE9B", scale: "1.01" }
              : { boxShadow: "0px 0px 15px 0px #8DEE9B" }
            : editable
            ? props.isNew
              ? {
                  boxShadow: "none",
                  border: "none",
                }
              : {
                  boxShadow: "0px 0px 15px 0px rgba(255,255,255,0.35)",
                  scale: "1.01",
                }
            : {}
        }
      >
        <div className={`container`}>
          <div className={`row justify-content-around align-items-center`}>
            <div className={`container`}>
              <div className={`row memoryAdditionalCardPropertyContainer`}>
                <div className={`input-group mb-1`}>
                  <div className={`input-group-prepend`}>
                    <div class="input-group-text">Alias</div>
                  </div>
                  <input
                    class="form-control"
                    value={alias}
                    onChange={(e) => editable && setAlias(e.target.value)}
                  />
                </div>
              </div>

              <div className={`row memoryAdditionalCardPropertyContainer`}>
                <div className={`input-group mb-1`}>
                  <div className={`input-group-prepend`}>
                    <div class="input-group-text">Capacity</div>
                  </div>
                  <input
                    class="form-control"
                    aria-label="switch"
                    value={capacity}
                    onChange={(e) =>
                      editable &&
                      setCapacity(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!editable}
                      className="planMemorySelectpicker"
                      data-width="100%"
                      value={capacityUnit}
                      onChange={(e) =>
                        editable && setCapacityUnit(e.target.value)
                      }
                    >
                      <option value={0}>MB</option>
                      <option value={1}>GB</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`row memoryAdditionalCardPropertyContainer`}>
                <div class="input-group mb-1">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      Reservation
                      <label class="switch s-primary mb-0 ml-2">
                        <input
                          type="checkbox"
                          checked={reserve}
                          onChange={(e) =>
                            editable && setReserve(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <input
                    class="form-control"
                    aria-label="switch"
                    value={reservation}
                    onChange={(e) =>
                      editable &&
                      setReservation(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!editable}
                      className="planMemorySelectpicker"
                      data-width="100%"
                      value={reservationUnit}
                      onChange={(e) =>
                        editable && setReservationUnit(e.target.value)
                      }
                    >
                      <option value={0}>MB</option>
                      <option value={1}>GB</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`row memoryAdditionalCardPropertyContainer`}>
                <div class="input-group mb-1">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      Limit
                      <label class="switch s-primary mb-0 ml-2">
                        <input
                          type="checkbox"
                          checked={limit}
                          onChange={(e) =>
                            editable && setLimit(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <input
                    class="form-control"
                    aria-label="switch"
                    value={limitBy}
                    onChange={(e) =>
                      editable &&
                      setLimitBy(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!editable}
                      className="planMemorySelectpicker"
                      data-width="100%"
                      value={limitByUnit}
                      onChange={(e) =>
                        editable && setLimitByUnit(e.target.value)
                      }
                    >
                      <option value={0}>MB</option>
                      <option value={1}>GB</option>
                    </select>
                  </div>
                </div>
              </div>

              <div
                className={`row memoryAdditionalCardPropertyContainer justify-content-end`}
              >
                {!editable ? (
                  Profile.isDefault ? (
                    <VMBC.ToolTip title={"This Profile is Default"}>
                      <button
                        className="btn btn-primary mb-2 mr-2 rounded-circle"
                        onClick={() => {
                          props.onSetDefault(
                            Profile.ProfileId,
                            Profile.isDefault
                          );
                        }}
                      >
                        <VMBC.SVGs.Star filled />
                      </button>
                    </VMBC.ToolTip>
                  ) : (
                    <VMBC.ToolTip title={"Mark as Default"}>
                      <button
                        className="btn btn-primary mb-2 mr-2 rounded-circle"
                        onClick={() =>
                          props.onSetDefault(
                            Profile.ProfileId,
                            Profile.isDefault
                          )
                        }
                      >
                        <VMBC.SVGs.Star />
                      </button>
                    </VMBC.ToolTip>
                  )
                ) : undefined}
                {editable ? (
                  <>
                    <button
                      className="btn btn-danger mb-2 mr-2 rounded-circle"
                      onClick={() => {
                        if (props.isNew && props.onClose) {
                          props.onClose();
                        } else {
                          setEditable(false);
                          let Old = props.AllProfiles[props.Index]
                            ? props.AllProfiles[props.Index]
                            : {};
                          setAlias(Old.Alias);
                          setCapacity(Old.Sockets);
                          setCapacityUnit(Old.Cores);
                          setReserve(Old.Reserve);
                          setReservation(Old.Reservation);
                          setReservationUnit(0);
                          setLimit(Old.Limit);
                          setLimitBy(Old.LimitBy);
                          setLimitByUnit(0);
                        }
                      }}
                    >
                      <VMBC.SVGs.CloseXIcon />
                    </button>
                    <button
                      className="btn btn-primary mb-2 mr-2 rounded-circle"
                      onClick={saveProfile}
                    >
                      <VMBC.SVGs.CheckIcon />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-danger mb-2 mr-2 rounded-circle"
                      onClick={() => props.onDelete(Profile.ProfileId)}
                    >
                      <VMBC.SVGs.RecycleBin />
                    </button>
                    <button
                      className="btn btn-primary mb-2 mr-2 rounded-circle"
                      onClick={() => setEditable(true)}
                    >
                      <VMBC.SVGs.EditByPen />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemoryProfile;
