import React from "react";
import VMBC from "../../VMBC";

function OnAdd(props) {
  const { EventHandler, onClick, columns, dataSortFunction } = props;

  const [message, setMessage] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const newData = React.useRef();

  if (onClick !== undefined && typeof onClick === "function") {
    return (
      <button className={`btn btn-primary`} onClick={onClick}>
        Add
      </button>
    );
  }

  return (
    <span>
      <div className="dropdown">
        <a
          className="dropdown-toggle"
          href="#"
          role="button"
          id={`onAdd`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        ></a>
        <div
          className="dropdown-menu dropdown-menu-right"
          style={{
            backgroundColor: "#0e1726",
            boxShadow: "0 0 4px 4px #0006",
          }}
          aria-labelledby={`onAdd`}
        >
          {columns
            .filter((col) =>
              col.addable !== undefined ? col.addable : !col.hidden
            )
            .sort((...args) =>
              dataSortFunction ? dataSortFunction(...args) : false
            )
            .map((col, colIndex) => {
              return (
                <div
                  key={colIndex}
                  className="input-group input-group-sm mb-1"
                  style={{ minWidth: "200px" }}
                >
                  <input
                    type="text"
                    className="form-control mt-0 ml-0"
                    onChange={(e) => {
                      if (newData.current === undefined) {
                        newData.current = {};
                      }
                      newData.current[col.fieldName] = e.target.value;
                      if (col.addValidator !== undefined) {
                        var notValid = col.addValidator(e.target.value);
                        if (notValid) {
                          setMessage(notValid);
                        } else {
                          setMessage("");
                        }
                      }
                    }}
                    placeholder={`${col.title}`}
                  />
                </div>
              );
            })}
          <span
            style={{
              display: "inline-block",
              color: "red",
              fontSize: "12px",
              width: "120px",
            }}
          >
            {message}
          </span>
          <button
            className={`btn btn-primary btn-sm mt-1 float-right`}
            onClick={(e) => {
              if (isLoading) {
                return;
              }
              var notValid = false;
              if (newData.current === undefined) {
                newData.current = {};
              }
              columns
                .filter((col) =>
                  col.addable !== undefined ? col.addable : !col.hidden
                )
                .sort((...args) =>
                  dataSortFunction ? dataSortFunction(...args) : false
                )
                .forEach((col) => {
                  if (col.addValidator !== undefined) {
                    var thisOneNotValid = col.addValidator(
                      newData.current[col.fieldName]
                    );
                    if (thisOneNotValid) {
                      notValid = thisOneNotValid;
                    }
                  }
                });

              if (notValid) {
                // some of inputs are not valid
                setMessage(notValid);
                VMBC.Alert.Show(notValid, "error");
                return;
              }
              if (!EventHandler) {
                VMBC.Alert.Show("No EventHandler Found", "error");
              }
              EventHandler(newData.current, () => {
                setIsLoading(false);
              });
              setIsLoading(true);
            }}
          >
            {isLoading ? (
              <div
                className="spinner-border text-white align-self-center loader-sm"
                style={{ width: "1rem", height: "1rem" }}
              ></div>
            ) : (
              `Ok`
            )}
          </button>
        </div>
      </div>
      <button
        className={`btn btn-primary`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Add
      </button>
    </span>
  );
}

export default OnAdd;
