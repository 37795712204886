import React from "react";
import VMBC from "../../VMBC";

function RegistrationStatistics(props) {
  const [timeSpan, setTimeSpan] = React.useState(
    localStorage.getItem("dashboardRegStatisticsTimePer")
      ? localStorage.getItem("dashboardRegStatisticsTimePer").startsWith("y")
        ? "yearly"
        : "monthly"
      : "monthly"
  );

  const Data = React.useRef();

  const RegistrationsChartOptions = (timeSpan, cb) => {
    if (!!Data.current) {
      cb(GenerateChartOptions(timeSpan, Data.current), false);
    } else {
      VMBC.Dashboard.GetRegTermStatistics((res, err) => {
        if (err) {
          cb(null, err);
        } else {
          Data.current = res;
          cb(GenerateChartOptions(timeSpan, Data.current), false);
        }
      });
    }
  };

  React.useEffect(() => {
    if (document.getElementById("RegistrationsChart")) {
      RegistrationsChartOptions(timeSpan, (options, err) => {
        if (err) {
          VMBC.Alert.Show(err, "error");
          return;
        }
        document.getElementById("RegistrationsChart").innerHTML = "";
        var chart1 = new window.ApexCharts(
          document.querySelector("#RegistrationsChart"),
          options
        );
        chart1.render();
      });
    }
  }, [timeSpan]);

  React.useEffect(() => {
    document.getElementById("RegistrationsChart").innerHTML = "";
    var chart1 = new window.ApexCharts(
      document.querySelector("#RegistrationsChart"),
      GenerateChartOptions(timeSpan, {
        yearly: {
          TerminationSeries: [],
          RegistrationSeries: [],
          categories: [],
        },
        monthly: {
          TerminationSeries: [],
          RegistrationSeries: [],
          categories: [],
        },
      })
    );
    chart1.render();
  }, []);
  return (
    <>
      <div className="widget widget-chart-one">
        <div className="widget-heading">
          <h5 className="">Registrations</h5>
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className={`btn btn-dark ${
                timeSpan === "monthly" ? "disabled" : ""
              }`}
              style={{
                cursor: timeSpan === "yearly" ? "pointer" : "default",
              }}
              onClick={() => {
                if (timeSpan === "yearly") {
                  localStorage.setItem(
                    "dashboardRegStatisticsTimePer",
                    "monthly"
                  );
                  setTimeSpan("monthly");
                }
              }}
            >
              Monthly
            </button>
            <button
              type="button"
              className={`btn btn-dark ${
                timeSpan === "yearly" ? "disabled" : ""
              }`}
              style={{
                cursor: timeSpan === "monthly" ? "pointer" : "default",
              }}
              onClick={() => {
                if (timeSpan === "monthly") {
                  localStorage.setItem(
                    "dashboardRegStatisticsTimePer",
                    "yearly"
                  );
                  setTimeSpan("yearly");
                }
              }}
            >
              Yearly
            </button>
          </div>
        </div>

        <div className="widget-content">
          <div className="tabs tab-content">
            <div className="tabcontent">
              <div id="RegistrationsChart">Loading...</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function GenerateChartOptions(timeSpan, Statistics) {
  if (!Statistics[timeSpan]) {
    return null;
  }
  var ChartType = "Graph"; // Bar or Graph
  return ChartType === "Bar"
    ? {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: "#515365",
            opacity: 0.3,
          },
        },
        colors: ["#1b55e2", "#e7515a"], //["#5c1ac3", "#ffbb44"],
        subtitle: {
          text: "Total Registration - Total Termination",
          align: "left",
          margin: 0,
          offsetX: -10,
          offsetY: 35,
          floating: false,
          style: {
            fontSize: "14px",
            color: "#888ea8",
          },
        },
        title: {
          text:
            "" +
            (Object.values(Statistics[timeSpan].RegistrationSeries).length > 1
              ? Object.values(Statistics[timeSpan].RegistrationSeries).reduce(
                  (Acc, val) => Acc + val
                )
              : "0") +
            " - " +
            (Object.values(Statistics[timeSpan].TerminationSeries).length > 1
              ? Object.values(Statistics[timeSpan].TerminationSeries).reduce(
                  (Acc, val) => Acc + val
                )
              : "0"),
          align: "left",
          margin: 0,
          offsetX: -10,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "25px",
            color: "#bfc9d4",
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "14px",
          markers: {
            width: 10,
            height: 10,
          },
          itemMargin: {
            horizontal: 0,
            vertical: 8,
          },
        },
        grid: {
          borderColor: "#191e3a",
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        series: [
          {
            name: "Register",
            data: Object.values(Statistics[timeSpan].RegistrationSeries),
          },
          {
            name: "Terminate",
            data: Object.values(Statistics[timeSpan].TerminationSeries),
          },
        ],
        xaxis: {
          categories: Statistics[timeSpan].categories,
        },
        yaxis: {
          labels: {
            formatter: function (value, index) {
              return typeof value === "number"
                ? value == value.toFixed(0)
                  ? value
                  : ""
                : value;
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.3,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.8,
            stops: [0, 100],
          },
        },
        tooltip: {
          theme: "dark",
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      }
    : ChartType === "Graph"
    ? {
        chart: {
          fontFamily: "Quicksand, sans-serif",
          height: 365,
          type: "area",
          zoom: {
            enabled: false,
          },
          dropShadow: {
            enabled: true,
            opacity: 0.3,
            blur: 5,
            left: -7,
            top: 22,
          },
          toolbar: {
            show: false,
          },
          events: {
            mounted: function (ctx, config) {
              const highest1 = ctx.getHighestValueInSeries(0);
              const highest2 = ctx.getHighestValueInSeries(1);

              ctx.addPointAnnotation({
                x: new Date(
                  ctx.w.globals.seriesX[0][
                    ctx.w.globals.series[0].indexOf(highest1)
                  ]
                ).getTime(),
                y: highest1,
                label: {
                  style: {
                    cssClass: "d-none",
                  },
                },
                customSVG: {
                  SVG:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="#e7515a" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>',
                  cssClass: undefined,
                  offsetX: -8,
                  offsetY: 5,
                },
              });

              ctx.addPointAnnotation({
                x: new Date(
                  ctx.w.globals.seriesX[1][
                    ctx.w.globals.series[1].indexOf(highest2)
                  ]
                ).getTime(),
                y: highest2,
                label: {
                  style: {
                    cssClass: "d-none",
                  },
                },
                customSVG: {
                  SVG:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="#1b55e2" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>',
                  cssClass: undefined,
                  offsetX: -8,
                  offsetY: 5,
                },
              });
            },
          },
        },
        colors: ["#e7515a", "#1b55e2"],
        dataLabels: {
          enabled: false,
        },
        markers: {
          discrete: [
            {
              seriesIndex: 0,
              dataPointIndex: 7,
              fillColor: "#000",
              strokeColor: "#000",
              size: 5,
            },
            {
              seriesIndex: 2,
              dataPointIndex: 11,
              fillColor: "#000",
              strokeColor: "#000",
              size: 4,
            },
          ],
        },
        subtitle: {
          text:
            "Total Terminations: " +
            (Object.values(Statistics[timeSpan].TerminationSeries).length > 1
              ? Object.values(Statistics[timeSpan].TerminationSeries).reduce(
                  (Acc, val) => Acc + val
                )
              : "0"),
          align: "left",
          margin: 0,
          offsetX: -10,
          offsetY: 35,
          floating: false,
          style: {
            fontSize: "14px",
            color: "#888ea8",
          },
        },
        title: {
          text:
            "Total Registrations: " +
            (Object.values(Statistics[timeSpan].RegistrationSeries).length > 0
              ? Object.values(Statistics[timeSpan].RegistrationSeries).reduce(
                  (Acc, val) => Acc + val
                )
              : "0"),
          align: "left",
          margin: 0,
          offsetX: -10,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "25px",
            color: "#bfc9d4",
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          width: 2,
          lineCap: "square",
        },
        series: [
          {
            name: "Terminate",
            data: Object.values(Statistics[timeSpan].TerminationSeries),
          },
          {
            name: "Register",
            data: Object.values(Statistics[timeSpan].RegistrationSeries),
          },
        ],
        labels: Statistics[timeSpan].categories,
        xaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: true,
          },
          labels: {
            offsetX: 0,
            offsetY: 5,
            style: {
              fontSize: "12px",
              fontFamily: "Quicksand, sans-serif",
              cssClass: "apexcharts-xaxis-title",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value, index) {
              return typeof value === "number"
                ? value == value.toFixed(0)
                  ? value
                  : ""
                : value;
            },
            offsetX: -22,
            offsetY: 0,
            style: {
              fontSize: "12px",
              fontFamily: "Quicksand, sans-serif",
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        grid: {
          borderColor: "#191e3a",
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: -10,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          offsetY: -50,
          fontSize: "16px",
          fontFamily: "Quicksand, sans-serif",
          markers: {
            width: 10,
            height: 10,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 0,
            vertical: 20,
          },
        },
        tooltip: {
          theme: "dark",
          marker: {
            show: true,
          },
          x: {
            show: false,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            inverseColors: !1,
            opacityFrom: 0.28,
            opacityTo: 0.05,
            stops: [45, 100],
          },
        },
        responsive: [
          {
            breakpoint: 575,
            options: {
              legend: {
                offsetY: -30,
              },
            },
          },
        ],
      }
    : {};
}

export default RegistrationStatistics;
