import React from "react";
import "./index.css";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { withRouter, Switch, Route } from "react-router";
import VMBC from "../VMBC";
import Locked from "../Login/Locked";

import Dashboard from "./Dashboard";
import Inventory from "./Inventory";
import Plans from "./Plans";
import Customers from "./Customers";
import Administration from "./Administration";
import Settings from "./Settings";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import dust from "../assets/dust.png"
window.AddToLoadingVmboxProccess(1);

function Panel(props) {
  VMBC.Navigator.History = props.history;
  let contentStyle;
  if (
    props.location.search.includes("hideAppBar") ||
    props.location.search.includes("hideNavBar")
  ) {
    contentStyle = {
      paddingTop: "0",
    };
  }

  var locked = localStorage.getItem("LockId");
  if (!!locked) {
    return <Locked />;
  }

  var GotoInstall = localStorage.getItem("GotoInstall");
  if (GotoInstall) {
    VMBC.Navigate(VMBC.Navigator.History, "/install")
    return null;
  }

  return (
    <ScrollBar style={{ height: "100vh", overflowY: "unset", background:`linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${dust})` }}>
      <NavBar />

      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        
        <TopBar />

        <div id="content" className="main-content" style={contentStyle}>
          <div className="layout-px-spacing">
            <Switch>
              <Route exact path={`${props.match.path}`} children={Dashboard} />
              <Route
                path={`${props.match.path}/inventory`}
                children={Inventory}
              />
              <Route path={`${props.match.path}/plans`} children={Plans} />
              <Route
                path={`${props.match.path}/customers`}
                children={Customers}
              />
              <Route
                path={`${props.match.path}/administration`}
                children={Administration}
              />
              <Route
                path={`${props.match.path}/settings`}
                children={Settings}
              />
            </Switch>

            <CopyrightFooter />
          </div>
        </div>
      </div>
    </ScrollBar>
  );
}

function CopyrightFooter(props) {
  return (
    <div className="footer-wrapper">
      <div className="footer-section f-section-1">
        <p className="">
          Copyright ©{" "}
          <a target="_blank" href="https://rasatelecom.com/">
            Rasa Telecom
          </a>{" "}
          {new Date().getFullYear()}, All rights reserved.
        </p>
      </div>
      <div className="footer-section f-section-2">
        <p className="">
          Coded with{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-heart"
          >
            <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
          </svg>
        </p>
      </div>
    </div>
  );
}

function GetPageLocations(url) {
  return (name) => {
    var URL = "/panel/";
    if (URL.endsWith("/")) {
      URL = URL.substr(0, URL.length - 1);
    }
    return "active";
  };
}

export default withRouter(Panel);
