import React from "react";
import VMBC from "../../../../../VMBC";
import AddPlanTemplate from "./AddPlanTemplate";

function Templates(props) {
  const { plan } = props;

  const [templates, setTemplates] = React.useState();
  const [refreshT, refresh] = React.useState(true);
  const openAddPlanTemplate = React.useRef(() => {});

  React.useEffect(() => {
    setTemplates();
    VMBC.Plans.GetPlanTemplates(plan.PlanId, (tmplts, err) => {
      if (!err) {
        setTimeout(() => {
          setTemplates(tmplts);
        }, 100);
      } else {
        VMBC.Alert.Show(err, "error", 30000);
      }
    });
  }, [refreshT]);

  const Refresh = () => refresh(!refreshT);

  const handleAddTemplate = (temp) => {
    VMBC.Plans.AddTemplate(plan.PlanId, temp, (res, err) => {
      if (err) {
        VMBC.Alert.Show(err);
      } else {
        Refresh();
        VMBC.Alert.Show(res, "success");
      }
    });
  };

  return (
    <div class="col-xl-5 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-table-one">
        <div
          class="widget-heading"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h5 class="">Templates</h5>
          <span>
            <span
              style={{ cursor: "pointer", marginRight: "10px" }}
              onClick={Refresh}
            >
              <VMBC.SVGs.RefreshCCW />
            </span>

            <button
              className={`btn btn-primary btn-sm`}
              onClick={() => openAddPlanTemplate.current()}
            >
              Add
            </button>
            <AddPlanTemplate
              onOpen={openAddPlanTemplate}
              plan={plan}
              onSelect={handleAddTemplate}
            />
          </span>
        </div>

        {!templates ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              className="spinner-border align-self-center loader-lg"
              style={{ width: "3rem", height: "3rem" }}
            ></div>
          </div>
        ) : undefined}

        <div
          class="widget-content"
          style={{
            height: "315px",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "5px 0",
          }}
        >
          {templates && templates.length != undefined
            ? templates.map((template) => (
                <div
                  class="transactions-list"
                  style={{ width: "95%", margin: "0 auto 15px auto" }}
                >
                  <div class="t-item">
                    <div class="t-company-name">
                      <div class="t-icon">
                        <div
                          class="icon"
                          style={{ backgroundColor: "#1b55e2" }}
                        >
                          <VMBC.SVGs.Template />
                        </div>
                      </div>
                      <div class="t-name">
                        <h4>{template.Alias}</h4>
                        <p class="meta-date">
                          VM Name: {template.VmachineName}
                        </p>
                      </div>
                    </div>
                    <div class="t-rate">
                      <p>
                        <VMBC.SVGs.RecycleBin
                          style={{
                            width: "22px",
                            height: "22px",
                            color: "#e7515a",
                            marginRight: "3px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            VMBC.Dialogs.Show({
                              Title: "Are you sure?",
                              Text:
                                "Are you sure you want to remove this Template from this Plan. No VMachines will be deployed by this template from this plan anymore",
                              Ignorable: true,
                              Actions: [
                                {
                                  onClick: () => VMBC.Dialogs.Close(),
                                  className: "btn btn-primary btn-sm",
                                  text: "No",
                                },
                                {
                                  onClick: () => {
                                    VMBC.Plans.RemovePlanTemplate(
                                      template.PlanTemplateId,
                                      (res, err) => {
                                        if (err) {
                                          VMBC.Alert.Show(err);
                                        } else {
                                          VMBC.Alert.Show(res, "success");
                                          Refresh();
                                        }
                                      }
                                    );
                                    VMBC.Dialogs.Close();
                                  },
                                  className: "btn btn-outline-primary btn-sm",
                                  text: "Yes",
                                },
                              ],
                            })
                          }
                        />
                        <VMBC.A
                          href={`/panel/plans/templates/${template.TemplateId}`}
                        >
                          <VMBC.SVGs.Settings
                            style={{ width: "20px", height: "20px" }}
                          />
                        </VMBC.A>
                      </p>
                    </div>
                  </div>
                </div>
              ))
            : undefined}

          {/* <div class="transactions-list">
            <div class="t-item">
              <div class="t-company-name">
                <div class="t-icon">
                  <div class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-home"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                  </div>
                </div>
                <div class="t-name">
                  <h4>Electricity Bill</h4>
                  <p class="meta-date">4 Aug 1:00PM</p>
                </div>
              </div>
              <div class="t-rate rate-dec">
                <p>
                  <span>-$16.44</span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-arrow-down"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <polyline points="19 12 12 19 5 12"></polyline>
                  </svg>
                </p>
              </div>
            </div>
          </div>

          <div class="transactions-list">
            <div class="t-item">
              <div class="t-company-name">
                <div class="t-icon">
                  <div class="avatar avatar-xl">
                    <span class="avatar-title rounded-circle">SP</span>
                  </div>
                </div>
                <div class="t-name">
                  <h4>Shaun Park</h4>
                  <p class="meta-date">4 Aug 1:00PM</p>
                </div>
              </div>
              <div class="t-rate rate-inc">
                <p>
                  <span>+$66.44</span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-arrow-up"
                  >
                    <line x1="12" y1="19" x2="12" y2="5"></line>
                    <polyline points="5 12 12 5 19 12"></polyline>
                  </svg>
                </p>
              </div>
            </div>
          </div>

          <div class="transactions-list">
            <div class="t-item">
              <div class="t-company-name">
                <div class="t-icon">
                  <div class="avatar avatar-xl">
                    <span class="avatar-title rounded-circle">AD</span>
                  </div>
                </div>
                <div class="t-name">
                  <h4>Amy Diaz</h4>
                  <p class="meta-date">4 Aug 1:00PM</p>
                </div>
              </div>
              <div class="t-rate rate-inc">
                <p>
                  <span>+$66.44</span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-arrow-up"
                  >
                    <line x1="12" y1="19" x2="12" y2="5"></line>
                    <polyline points="5 12 12 5 19 12"></polyline>
                  </svg>
                </p>
              </div>
            </div>
          </div>

          <div class="transactions-list">
            <div class="t-item">
              <div class="t-company-name">
                <div class="t-icon">
                  <div class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-home"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                  </div>
                </div>
                <div class="t-name">
                  <h4>Netflix</h4>
                  <p class="meta-date">4 Aug 1:00PM</p>
                </div>
              </div>
              <div class="t-rate rate-dec">
                <p>
                  <span>-$32.00</span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-arrow-down"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <polyline points="19 12 12 19 5 12"></polyline>
                  </svg>
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Templates;
