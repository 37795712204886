import React from "react";
import IpPoolTable from "./IpPoolTable";
import Statistics from "./Statistics";
import { withRouter } from "react-router";
import VMBC from "../../../../VMBC";

function IpPoolRoot(props) {
  const { id } = props.match.params;

  const [ipPool, setIpPool] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setIpPool();
    VMBC.Inventory.GetIpPool(id, (ipp) => {
      if (!ipp.IsGeneral) {
        ipp.ComputeResource = new VMBC.Inventory.ComputeResource(
          ipp.ComputeResourceId
        );
        ipp.ComputeResource.GetNetworks();
      } else {
        ipp.ComputeResource = false;
      }
      setIpPool(ipp);
    });
  }, [refreshT]);

  return (
    <>
      <Statistics ipPoolId={id} />
      <IpPoolTable IpPool={ipPool} refresh={() => refresh(!refreshT)} />
    </>
  );
}

export default withRouter(IpPoolRoot);
export { IpPoolTable };
