import React from "react";
import VmOptions from "./VmOptions";
import VirtualHardware from "./VirtualHardware";

function Customizations(props) {
  const { plan } = props;

  return (
    <div
      className={`col-xl-7 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing`}
    >
      <div className={`widget`}>
        <div className={`widget-heading`}>
          <h5>Customizations</h5>
        </div>
        <div className={`widget-content`}>
          <div className="tab-justify-centered">
            <ul
              className="nav nav-tabs mb-3 mt-3 justify-content-center"
              id="justifyCenterTab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className={`nav-link active`}
                  id={`vm-options-tab`}
                  data-toggle="tab"
                  href={`#vm-options`}
                  role="tab"
                  aria-controls={`vm-options`}
                  aria-selected={"true"}
                >
                  VM Options
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link`}
                  id={`virtual-hardware-tab`}
                  data-toggle="tab"
                  href={`#virtual-hardware`}
                  role="tab"
                  aria-controls={`virtual-hardware`}
                  aria-selected={"false"}
                >
                  Virtual Hardware
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className={`tab-pane fade show active`}
                id={`vm-options`}
                role="tabpanel"
                aria-labelledby={`vm-options-tab`}
              >
                <VmOptions plan={plan} />
              </div>

              <div
                className={`tab-pane fade`}
                id={`virtual-hardware`}
                role="tabpanel"
                aria-labelledby={`virtual-hardware-tab`}
              >
                <VirtualHardware plan={plan} PlanId={plan.PlanId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customizations;
