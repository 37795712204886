import React from "react";
import Stepper from "../../Components/Stepper";
import VMBC from "../../VMBC";

function VCenter(props) {
  const { InstallData, Block, Unblock } = props;
  const [address, setAddress] = React.useState(
    InstallData.current ? InstallData.current.vCenter.Address : ""
  );
  const [username, setUsername] = React.useState(
    InstallData.current ? InstallData.current.vCenter.Username : ""
  );
  const [password, setPassword] = React.useState(
    InstallData.current ? InstallData.current.vCenter.Password : ""
  );
  const [useSsl, setUseSsl] = React.useState(
    InstallData.current ? InstallData.current.vCenter.UseSSL : true
  );
  const [sslCrt, setSslCrt] = React.useState(
    InstallData.current ? InstallData.current.vCenter.SslCrt : ""
  );
  const [sslCrtName, setSslCrtName] = React.useState(
    InstallData.current ? InstallData.current.vCenter.SslCrtName : ""
  );
  const [refreshT, refresh] = React.useState(false);

  const [errorDetails, setErrorDetails] = React.useState();

  const crtFileRef = React.useRef();

  InstallData.current.vCenter.Address = address;
  InstallData.current.vCenter.Username = username;
  InstallData.current.vCenter.Password = password;
  InstallData.current.vCenter.UseSSL = useSsl;
  InstallData.current.vCenter.SslCrt = sslCrt;
  InstallData.current.vCenter.SslCrtName = sslCrtName;

  const testConnection = () => {
    if (useSsl && sslCrt == "") {
      window.VMBC.Alert.Show("Invalid CRT file", "error", 3000);
      return;
    }
    Block();
    VMBC.Install.checkVCAuth(
      address,
      username,
      password,
      useSsl ? sslCrt : false,
      (err) => {
        if (err) {
          VMBC.Alert.Show(err.errorType, "error");
          setErrorDetails(err.rawErrorMessage);
        } else {
          VMBC.Alert.Show("vCenter Authentication Confirmed", "success");
          InstallData.current.vCenter.Confirmed = true;
          setErrorDetails();
        }
        Unblock();
        refresh(!refreshT);
      }
    );
  };

  return (
    <>
      <Stepper
        steps={[
          {
            title: "EULA",
            onClick: (e) => {
              e.preventDefault();
              if (props.Goto) {
                props.Goto(0);
              }
            },
          },
          {
            title: "License",
            onClick: (e) => {
              e.preventDefault();
              if (props.Goto) {
                props.Goto(1);
              }
            },
          },
          {
            title: "vCenter",
            onClick: (e) => {
              e.preventDefault();
            },
          },
          // {
          //   title: "Finish",
          //   onClick: (e) => {
          //     e.preventDefault();
          //   },
          // },
        ]}
        activeStep={2}
      />
      <div class="input-group mt-4 px-5">
        <div class="input-group-prepend">
          <span class="input-group-text">vCenter Host Address</span>
        </div>
        <input
          type="text"
          autoComplete={"new-password"}
          placeholder={"vcenter.fireserver.org"}
          spellCheck={false}
          value={address}
          onChange={(e) => {
            InstallData.current.vCenter.Confirmed = false;
            setAddress(e.target.value);
          }}
          className="text-center form-control"
          style={{ minWidth: "260px" }}
        />
      </div>

      <div class="input-group mt-4 px-5">
        <div class="input-group-prepend">
          <span class="input-group-text">Username</span>
        </div>
        <input
          type="text"
          autoComplete={"new-password"}
          placeholder={"administrator@vsphere.local"}
          spellCheck={false}
          value={username}
          onChange={(e) => {
            InstallData.current.vCenter.Confirmed = false;
            setUsername(e.target.value);
          }}
          className="text-center form-control"
          style={{ minWidth: "260px" }}
        />
      </div>

      <div class="input-group mt-4 px-5">
        <div class="input-group-prepend">
          <span class="input-group-text">Password</span>
        </div>
        <input
          type="password"
          autoComplete={"new-password"}
          placeholder={"•••••••••••••••••••••"}
          spellCheck={false}
          value={password}
          onChange={(e) => {
            InstallData.current.vCenter.Confirmed = false;
            setPassword(e.target.value);
          }}
          className="text-center form-control"
          style={{ minWidth: "260px" }}
        />
      </div>

      <div class="input-group mt-4 px-5">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <label class="switch s-info mb-0 mr-2">
              <input
                type="checkbox"
                checked={useSsl}
                onChange={(e) => {
                  InstallData.current.vCenter.Confirmed = false;
                  setUseSsl(e.target.checked);
                }}
              />
              <span class="slider round"></span>
            </label>
            Use SSL
          </div>
        </div>
        <input
          class="form-control text-center"
          aria-label="switch"
          spellCheck={false}
          tabIndex={-1}
          value={sslCrtName}
          style={{ width: "150px" }}
        />
        <div class="input-group-append">
          <button
            className={`btn btn-primary`}
            onClick={() => crtFileRef.current.click()}
          >
            Browse CRT File
          </button>
        </div>
      </div>

      <input
        ref={crtFileRef}
        type="file"
        onChange={(e) => {
          if (!e.target.files[0]) {
            return;
          }
          var fr = new FileReader();
          fr.onloadend = () => {
            InstallData.current.vCenter.Confirmed = false;
            setSslCrt(fr.result);
          };
          fr.readAsText(e.target.files[0]);
          setSslCrtName(e.target.files[0].name);
        }}
        style={{ display: "none" }}
      />

      <div className={`d-flex justify-content-center mt-3`}>
        <button className={`btn btn-primary`} onClick={testConnection}>
          Test Connection
        </button>
      </div>
      {errorDetails ? (
        <div className={`d-flex justify-content-center mt-3`}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              VMBC.Dialogs.Show({
                Title: "Checking vCenter Connection",
                Text: (
                  <>
                    <h6>Error Details:</h6>
                    <p>{errorDetails}</p>
                  </>
                ),
                Actions: [
                  {
                    text: "Ok",
                    className: "btn-primary btn-sm",
                    onClick: () => VMBC.Dialogs.Close(),
                  },
                ],
              });
            }}
          >
            Click to see Details
          </span>
        </div>
      ) : undefined}
      <div className={`d-flex justify-content-end mt-4`}>
        <button
          disabled={!InstallData.current.vCenter.Confirmed}
          className={`btn btn-primary`}
          onClick={props.onFinish}
        >
          Finish
        </button>
      </div>
    </>
  );
}

export default VCenter;
