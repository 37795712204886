import React from "react";
import VirtualHardware from "./VirtualHardware";
import VmOptions from "./VmOptions";
import VMBC from "../../../../VMBC";

function Customization(props) {
  var validator = () => {
    if (!props.Data || !props.Data.current.Customization) {
      return false;
    }
    const { NamingOptions, NetworkQuota } = props.Data.current.Customization;
    if (!NamingOptions.isUnique) {
      VMBC.Alert.Show("VM Name must contain at least one unique parameter");
      return false;
    }
    if (!!NetworkQuota && NetworkQuota.enabled) {
      if (NetworkQuota.limitTx && NetworkQuota.limitTxBy < 1) {
        VMBC.Alert.Show("LimitTx can not be less than 1");
        return false;
      }
      if (NetworkQuota.limitRx && NetworkQuota.limitRxBy < 1) {
        VMBC.Alert.Show("LimitRx can not be less than 1");
        return false;
      }
      if (NetworkQuota.limitUsage && NetworkQuota.limitUsageBy < 1) {
        VMBC.Alert.Show("Limit Both Tx and Rx can not be less than 1");
        return false;
      }
      if (NetworkQuota.resetQuota && NetworkQuota.resetAfter < 1) {
        VMBC.Alert.Show("Reset Quota time can not be less than 1");
        return false;
      }
      if (NetworkQuota.limitByRatio) {
        NetworkQuota.limitByRatio = true;
        NetworkQuota.limitRatioType = Number(NetworkQuota.limitRatioType);
        if (
          !NetworkQuota.limitRatioType ||
          (NetworkQuota.limitRatioType != 1 && NetworkQuota.limitRatioType != 2)
        ) {
          VMBC.Alert.Show(
            "Please select a Limit Ratio Type at Network Quota",
            "error"
          );
          return;
        }
        NetworkQuota.maxAllowedRatio = Number(NetworkQuota.maxAllowedRatio);
        if (
          !NetworkQuota.maxAllowedRatio ||
          NetworkQuota.maxAllowedRatio === 0
        ) {
          VMBC.Alert.Show(
            "Maximum allowed ratio can not be zero or empty at Network Quota",
            "error"
          );
          return;
        }
        NetworkQuota.limitByRatioCheckpoint = Number(
          NetworkQuota.limitByRatioCheckpoint
        );
        if (!NetworkQuota.limitByRatioCheckpoint) {
          VMBC.Alert.Show(
            "Please enter a Limit Ratio Checkpoint at Network Quota",
            "error"
          );
          return;
        }
        NetworkQuota.limitByRatioCheckpointType = Number(
          NetworkQuota.limitByRatioCheckpointType
        );
        if (
          !NetworkQuota.limitByRatioCheckpointType ||
          NetworkQuota.limitByRatioCheckpointType < 0 ||
          NetworkQuota.limitByRatioCheckpointType > 3
        ) {
          VMBC.Alert.Show(
            "Please select a Limit Ratio Checkpoint Type at Network Quota",
            "error"
          );
          return;
        }
      }
    }
    return true;
  };

  React.useEffect(() => {
    props.SubmitNextValidator && props.SubmitNextValidator(validator);
  }, []);

  return (
    <>
      <div className={`col-xs-12 col-sm-2 col-lg-3 mt-5`}>
        <div
          class="nav flex-column nav-pills mb-sm-0 mb-3"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            class="nav-link mb-2 active"
            id="v-pills-virtual-hardware-tab"
            data-toggle="pill"
            href="#v-pills-virtual-hardware"
            role="tab"
            aria-controls="v-pills-virtual-hardware"
            aria-selected="true"
          >
            Virtual Hardware
          </a>
          <a
            class="nav-link mb-2"
            id="v-pills-vm-options-tab"
            data-toggle="pill"
            href="#v-pills-vm-options"
            role="tab"
            aria-controls="v-pills-vm-options"
            aria-selected="false"
          >
            VM Options
          </a>
        </div>
      </div>
      <div className={`col-xs-12 col-sm-10 col-lg-9 mt-5`}>
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-virtual-hardware"
            role="tabpanel"
            aria-labelledby="v-pills-virtual-hardware-tab"
          >
            <VirtualHardware Data={props.Data} />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-vm-options"
            role="tabpanel"
            aria-labelledby="v-pills-vm-options-tab"
          >
            <VmOptions Data={props.Data} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Customization;
