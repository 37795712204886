import React from "react";
import VMBC from "../../../../../VMBC";
import Demo from "../../../../../Demo";

function ResourceUsage(props) {
  const { vm, Reload } = props;
  const { Id } = vm;

  const [data, setData] = React.useState();

  React.useEffect(() => {
    Demo.DelayRandom(() =>
      Demo.DelayRandom(() => {
        setData({
          AllocatedCpuNum: 3,
          CpuUsage: 252,
          CpuDemand: 324,
          AllocatedMemorySize: 4096,
          GuestMemoryUsage: 245,
          HostMemoryUsage: 2946,
          AllocatedDiskUsage: 75161927680,
          DiskUsage: 77680651,
          Uptime: 65248,
        });
      })
    );
  }, []);

  return (
    <div className={``}>
      <div className={`container`}>
        <div className={`row`}>
          <div className={`col col-12 py-2`}>
            <div className={`container`} style={{ padding: "0" }}>
              <div className={`row justify-content-center align-items-center`}>
                {data ? (
                  <>
                    <div
                      className={`col-12 my-1 align-self-center`}
                      style={{ color: "#fff" }}
                    >
                      <span style={{ fontSize: "1.2rem" }}>CPU</span>
                      <br />
                      <div className={`d-flex justify-content-between`}>
                        <span style={{ fontSize: "12px" }}>
                          {data.CpuUsage < 1024
                            ? `${data.CpuUsage} MHz`
                            : `${parseInt(data.CpuUsage / 1024)} GHz`}{" "}
                          Usage
                        </span>
                        <span style={{ fontSize: "10px", textAlign: "right" }}>
                          {data.AllocatedCpuNum} CPU(s) Allocated
                        </span>
                      </div>
                      <hr className={`mt-3 mb-1`} />
                    </div>
                    <div
                      className={`col-12 my-1 align-self-center`}
                      style={{ color: "#fff" }}
                    >
                      <span style={{ fontSize: "1.2rem" }}>Memory</span>
                      <br />
                      <div className={`d-flex justify-content-between`}>
                        <span style={{ fontSize: "12px" }}>
                          {data.GuestMemoryUsage < 1024
                            ? `${data.GuestMemoryUsage} MB`
                            : `${parseInt(
                                data.GuestMemoryUsage / 1024
                              )} GB`}{" "}
                          Usage
                        </span>
                        <span style={{ fontSize: "10px", textAlign: "right" }}>
                          {data.AllocatedMemorySize < 1024
                            ? `${data.AllocatedMemorySize} MB`
                            : `${parseInt(
                                data.AllocatedMemorySize / 1024
                              )} GB`}{" "}
                          Allocated
                        </span>
                      </div>
                      <hr className={`mt-3 mb-1`} />
                    </div>
                    <div
                      className={`col-12 my-1 align-self-center mb-3`}
                      style={{ color: "#fff" }}
                    >
                      <span style={{ fontSize: "1.2rem" }}>Storage</span>
                      <br />
                      <div className={`d-flex justify-content-between`}>
                        <span style={{ fontSize: "12px" }}>
                          {data.DiskUsage < 1024 * 1024
                            ? `${data.DiskUsage} MB`
                            : `${parseInt(
                                data.DiskUsage / (1024 * 1024)
                              )} GB`}{" "}
                          Usage
                        </span>
                        <span style={{ fontSize: "10px", textAlign: "right" }}>
                          {data.AllocatedDiskUsage < 1024 * 1024 * 1024
                            ? `${data.AllocatedDiskUsage} MB`
                            : `${parseInt(
                                data.AllocatedDiskUsage / (1024 * 1024 * 1024)
                              )} GB`}{" "}
                          Allocated
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <VMBC.Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourceUsage;
