import React from "react";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import VmList from "./VmList";
import VM from "./VM";
window.AddToLoadingVmboxProccess(1);

function VirtualMachinesRoot(props) {
  return (
    <Switch>
      <Route path={`${props.match.path}/`} exact children={<VmList />} />
      <Route
        path={`${props.match.path}/package/:id`}
        exact
        children={<VM package />}
      />
      <Route path={`${props.match.path}/:id`} exact children={<VM />} />
    </Switch>
  );
}

export default withRouter(VirtualMachinesRoot);
