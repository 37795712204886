import React from "react";
import VMBC from "../VMBC";
import { withRouter } from "react-router";
window.AddToLoadingVmboxProccess(1);

function NavBar(props) {
  if (
    props.location.search.includes("hideAppBar") ||
    props.location.search.includes("hideNavBar")
  ) {
    return null;
  }
  return (
    <>
      <div className="header-container">
        <header className="header navbar navbar-expand-sm">
          <a href="" className="sidebarCollapse" data-placement="bottom">
            <VMBC.SVGs.Menu />
          </a>

          <div className="nav-logo align-self-center">
            <a className="navbar-brand" href="">
              <img alt="logo" src="/assets/img/90x90.jpg" />{" "}
              <span className="navbar-brand-name">VMBox</span>
              {localStorage.getItem("version") ? (
                <span
                  className={`mx-2`}
                  style={{
                    fontWeight: "600",
                    color: "#25d5e4",
                    fontSize: "13px",
                  }}
                >
                  ver {localStorage.getItem("version")}
                </span>
              ) : undefined}
            </a>
          </div>

          <ul
            className="navbar-item flex-row mr-auto"
            style={{ margin: "auto" }}
          >
            <a href="https://fireserver.org" target="_blank">
              {/* FireServer */}
            </a>
          </ul>

          <ul className="navbar-item flex-row nav-dropdowns">
            {/* Notifications */}
            {/* <li className="nav-item dropdown notification-dropdown">
              <a
                href=""
                className="nav-link dropdown-toggle"
                id="notificationDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <VMBC.SVGs.Bell />
                <span className="badge badge-success"></span>
              </a>
              <div
                className="dropdown-menu position-absolute animated fadeInUp"
                aria-labelledby="notificationDropdown"
              >
                <div className="notification-scroll">
                  <div className="dropdown-item">
                    <div className="media server-log">
                      <VMBC.SVGs.Server />
                      <div className="media-body">
                        <div className="data-info">
                          <h6 className="">Server Rebooted</h6>
                          <p className="">45 min ago</p>
                        </div>

                        <div className="icon-status">
                          <VMBC.SVGs.CloseXIcon />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dropdown-item">
                    <div className="media ">
                      <VMBC.SVGs.Heart />
                      <div className="media-body">
                        <div className="data-info">
                          <h6 className="">Licence Expiring Soon</h6>
                          <p className="">8 hrs ago</p>
                        </div>

                        <div className="icon-status">
                          <VMBC.SVGs.CloseXIcon />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dropdown-item">
                    <div className="media file-upload">
                      <VMBC.SVGs.FileText />
                      <div className="media-body">
                        <div className="data-info">
                          <h6 className="">Kelly Portfolio.pdf</h6>
                          <p className="">670 kb</p>
                        </div>

                        <div className="icon-status">
                          <VMBC.SVGs.CheckIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li> */}

            <li className="nav-item dropdown user-profile-dropdown order-lg-0 order-1">
              <a
                href=""
                className="nav-link dropdown-toggle user"
                id="user-profile-dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="media">
                  <img
                    src="/assets/img/user.png"
                    className="img-fluid"
                    alt="admin-profile"
                  />
                  <div className="media-body align-self-center">
                    <h6>
                      <span>Hi,</span>{" "}
                      {localStorage.getItem("userfullname")
                        ? localStorage.getItem("userfullname")
                        : "User"}
                    </h6>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </a>
              <div
                className="dropdown-menu position-absolute animated fadeInUp"
                aria-labelledby="user-profile-dropdown"
              >
                <div className="">
                  <div className="dropdown-item">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => VMBC.Auth.Lock()}
                    >
                      <VMBC.SVGs.Lock />
                      Lock Screen
                    </a>
                  </div>
                  <div className="dropdown-item">
                    <a className="" href="/logout">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-log-out"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>
                      Sign Out
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </header>
      </div>
    </>
  );
}

function Time(props) {
  return "time";

  // const [time, setTime] = React.useState("");
  // React.useEffect(() => {
  //   setInterval(() => {
  //     setTime(new Date().toTimeString());
  //   }, 1000);
  // }, []);

  // return <>{time.split("GMT")[0]}</>;
}

export default withRouter(NavBar);
