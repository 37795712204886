import React from "react";

import FolderExplorer, {
  LinkParents,
} from "../../../Components/FolderExplorer";
import VMBC from "../../../VMBC";

function AddTemplate(props) {
  const [currentFolder, setCurrentFolder] = React.useState({});
  const [selectedObject, setSelectedObject] = React.useState();
  const [alias, setAlias] = React.useState("");
  const [osType, setOsType] = React.useState();

  React.useEffect(() => {
    let loadTemps = () => {
      var temps = props.Datacenter.TemplateVMs
        ? props.Datacenter.TemplateVMs
        : [];
      var RootFolder = {
        isFolder: true,
        Name: "root",
        items: temps.map((temp) => ({
          isFolder: false,
          Name: temp.TemplateName,
          TemplateObj: temp,
          icon: <VMBC.SVGs.Template size={80} />,
        })),
      };
      LinkParents(RootFolder);
      setCurrentFolder(RootFolder);
    };
    props.Datacenter.templateVMsLoaded = () => loadTemps();
    loadTemps();
    window.$(".planAddTemplateSelectpicker").selectpicker("refresh");
  }, []);

  const selectHandler = () => {
    if (!props.onSelect) return;
    if (!selectedObject) {
      VMBC.Alert.Show("Please select a Template first", "error", 3000);
      return;
    }
    if (typeof osType != "number") {
      VMBC.Alert.Show("Please select an OS Type for template", "error", 3000);
      return;
    }
    let TemplateObj = {
      templateId: selectedObject.TemplateObj.TemplateId,
      templateName: selectedObject.TemplateObj.TemplateName,
      alias: alias,
      osType: osType,
    };
    if (props.onSelect(TemplateObj)) {
      window
        .$(`#selectTemplateModal${props.Datacenter.MO_Reference}`)
        .modal("hide");
      setSelectedObject(null);
      setAlias("");
      setOsType(null);
    }
  };

  const onRefresh = () => {
    setCurrentFolder();
    props.Datacenter.GetTemplateVMs((temps) => {
      var RootFolder = {
        isFolder: true,
        Name: "root",
        items: temps
          ? temps.map((temp) => ({
              isFolder: false,
              Name: temp.TemplateName,
              TemplateObj: temp,
              icon: <VMBC.SVGs.Template size={80} />,
            }))
          : [],
      };
      LinkParents(RootFolder);
      setCurrentFolder(RootFolder);
    });
  };

  return (
    <>
      <button
        className={`btn btn-primary`}
        onClick={() =>
          window
            .$(`#selectTemplateModal${props.Datacenter.MO_Reference}`)
            .modal("show")
        }
      >
        Add New Template
      </button>

      <div
        className="modal fade"
        id={`selectTemplateModal${props.Datacenter.MO_Reference}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Select Template"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Template</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <VMBC.SVGs.CloseXIcon />
              </button>
            </div>
            <div className="modal-body">
              <div className={`container-fluid`}>
                <div className={`row`}>
                  <div className={`col-12`}>
                    <FolderExplorer
                      height={250}
                      className={`addTemplateFolderExplorer`}
                      Folder={currentFolder}
                      HideAddress={true}
                      SelectedObject={selectedObject}
                      // onRefresh={onRefresh}
                      onAddressClicked={setCurrentFolder}
                      onObjectSelect={setSelectedObject}
                      onObjectDoubleClick={(Obj) => {
                        if (Obj.isFolder) {
                          setCurrentFolder(Obj);
                        }
                      }}
                    />
                  </div>

                  <div className={`col-7 detailsContainer`}>
                    <div className={`input-group`}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">Template name:</span>
                      </div>
                      <input
                        id="templateAlias"
                        type="text"
                        name="alias"
                        placeholder="(shown to customer)"
                        className={`form-control`}
                        style={{ height: "48.5px" }}
                        value={alias}
                        onChange={(e) => setAlias(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className={`col-5 detailsContainer`}>
                    <div className={`row justify-content-center`}>
                      <span
                        className={`input-group mt-1`}
                        style={{ width: "auto" }}
                      >
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Template name:
                          </span>
                        </div>
                        <select
                          className="planAddTemplateSelectpicker"
                          title={`Select a Template`}
                          value={
                            selectedObject
                              ? VMBC.Enums.OsTypes.indexOf(
                                  selectedObject.TemplateObj.OsType
                                )
                              : -1
                          }
                          onChange={(e) => {
                            setOsType(Number(e.target.value));
                          }}
                        >
                          {VMBC.Enums.OsTypes.map((os, i) => (
                            <option value={i}>{os}</option>
                          ))}
                        </select>
                      </span>
                    </div>
                  </div>

                  <div className={`col-6 detailsContainer`}>
                    <h6>
                      {selectedObject
                        ? `VM Path Name: ${selectedObject.TemplateObj.VmPathName}`
                        : ""}
                    </h6>
                  </div>

                  <div className={`col-6 detailsContainer text-right`}>
                    <h6>
                      {selectedObject
                        ? `Guest Full Name: ${selectedObject.TemplateObj.GuestFullName}`
                        : ""}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className={`btn btn-outline-primary`}
                onClick={() =>
                  window
                    .$(`#selectTemplateModal${props.Datacenter.MO_Reference}`)
                    .modal("hide")
                }
              >
                Cancel
              </button>
              <button className={`btn btn-primary`} onClick={selectHandler}>
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTemplate;
