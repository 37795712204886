import React from "react";
import AssignVm from "../AssignVm";
import VMBC from "../../../../../../VMBC";

export default function (props) {
  const { vm, Reload } = props;
  const { Id } = vm;
  const onOpenClicked = React.useRef(() => {});

  const onVmAssign = (vm) => {
    VMBC.Dialogs.Show({
      Title: "Are you sure?",
      Text: (
        <>
          Are you sure you want to assign{" "}
          <span style={{ color: "#fff" }}>"{vm.VmName}"</span> to this service?
        </>
      ),
      Ignorable: true,
      Actions: [
        {
          onClick: () => {
            VMBC.Customers.VirtualMachines.AssignVmToRecord(
              Id,
              vm.VmId,
              (res, err) => {
                if (err) {
                  VMBC.Alert.Show(err);
                } else {
                  VMBC.Alert.Show(res, "success");
                  setTimeout(() => window.location.reload(), 3000);
                }
              }
            );
            VMBC.Dialogs.Close();
          },
          className: "btn-sm btn-primary",
          text: "Yes",
        },
        {
          onClick: () => {
            VMBC.Dialogs.Close();
          },
          className: "btn-sm btn-primary",
          text: "No",
        },
      ],
    });
  };

  return (
    <>
      <AssignVm onOpen={onOpenClicked} onSelect={onVmAssign} Reload={Reload} />
      <button
        className={`btn m-1 btn-outline-primary`}
        // onClick={() => {
        //   VMBC.Dialogs.Close();
        //   Reload.current = false;
        //   onOpenClicked.current();
        // }}
      >
        Assign Existing VM
      </button>
      <button
        className={`btn m-1 btn-outline-primary`}
        // onClick={() => {
        //   VMBC.Dialogs.Show({
        //     Title: "Are you sure?",
        //     Text:
        //       "Are you sure you want to create new virtual machine for this service?",
        //     Ignorable: true,
        //     Actions: [
        //       {
        //         onClick: () => {
        //           VMBC.Customers.VirtualMachines.CreateNewVmForRecord(
        //             Id,
        //             (res, err) => {
        //               if (err) {
        //                 VMBC.Alert.Show(err);
        //               } else {
        //                 VMBC.Alert.Show(res, "success");
        //                 setTimeout(() => window.location.reload(), 3000);
        //               }
        //             }
        //           );
        //           VMBC.Dialogs.Close();
        //         },
        //         className: "btn-sm btn-primary",
        //         text: "Yes",
        //       },
        //       {
        //         onClick: () => {
        //           VMBC.Dialogs.Close();
        //         },
        //         className: "btn-sm btn-primary",
        //         text: "No",
        //       },
        //     ],
        //   });
        // }}
      >
        Create new VM
      </button>
    </>
  );
}
