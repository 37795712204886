import React from "react";
import { Switch, Route, withRouter } from "react-router";

import List from "./List";
import Add from "./Add";
import IpPools from "./IpPools";
import Templates from "./Templates";
import IsoFiles from "./IsoFiles";
window.AddToLoadingVmboxProccess(1);

function Plans(props) {
  return (
    <>
      <Switch>
        <Route path={`${props.match.path}/list`} children={List} />
        <Route path={`${props.match.path}/add`} children={Add} />
        <Route path={`${props.match.path}/ipPools`} children={IpPools} />
        <Route
          path={`${props.match.path}/templates`}
          children={<Templates />}
        />
        <Route
          path={`${props.match.path}/isoFiles`}
          exact
          children={<IsoFiles />}
        />
      </Switch>
    </>
  );
}

export default withRouter(Plans);
