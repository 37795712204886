import React from "react";
import VMBC from "../VMBC";

function Start(props) {
  return (
    <>
      <div className={`container`}>
        <div className={`row justify-content-center px-5`}>
          <img src={VMBC.SVGs.VMBOX_PNG} style={{ width: "200px" }} />
        </div>
        <div
          className={`row justify-content-center align-items-center pt-3 px-5`}
          style={{ flexDirection: "column" }}
        >
          <h4>VMBox Management System</h4>
          {localStorage.getItem("version") ? (
            <p>Version {localStorage.getItem("version")}</p>
          ) : undefined}
        </div>
        <div className={`row justify-content-center pt-3 pb-2`}>
          <button className={`btn btn-primary`} onClick={props.onNext}>
            Install
          </button>
        </div>
      </div>
    </>
  );
}

export default Start;
