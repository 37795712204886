import { ManagedObject } from "../../DataTypes";

import axios from "axios";
import Utils from "../../Utils";
import VMBC from "../..";

class Plan {
  constructor() {}

  SetDetails(
    Name,
    targetFolder,
    datacenter,
    bestComputeResource,
    bestDatastore,
    customization
  ) {
    this.Name = Name;
    this.TargetFolder = targetFolder;
    this.Datacenter = datacenter;
    this.BestComputeResource = bestComputeResource;
    this.BestDatastore = bestDatastore;
    this.VmNamingPolicy = customization.vmOptions.name;
    this.NetworkQuota = customization.vmOptions.networkQuota;
    customization.CPU.forEach((p) => {
      this.CPU.push({
        Alias: p.Alias,
        IsDefault: p.isDefault,
        Sockets: p.Sockets,
        CoresPerSocket: p.CoresPerSocket,
        Reservation: p.Reserve ? p.Reservation : -1,
        ReservationUnit: p.ReservationUnit,
        LimitBy: p.Limit ? p.LimitBy : -1,
        LimitByUnit: p.LimitByUnit,
      });
    });
    customization.Memory.forEach((p) => {
      this.Memory.push({
        Alias: p.Alias,
        IsDefault: p.isDefault,
        Capacity: p.Capacity,
        CapacityUnit: p.CapacityUnit,
        Reservation: p.Reserve ? p.Reservation : -1,
        ReservationUnit: p.ReservationUnit,
        LimitBy: p.Limit ? p.LimitBy : -1,
        LimitByUnit: p.LimitByUnit,
      });
    });
    customization.HardDisk.forEach((p) => {
      this.HardDisk.push({
        Alias: p.Alias,
        IsDefault: p.isDefault,
        Capacity: p.Capacity,
        CapacityUnit: p.CapacityUnit,
      });
    });
  }

  Register(cb) {
    cb = cb ? cb : () => {};
    Utils.CheckSId(() => {
      axios
        .post(window.VMBC.Config.Origin + "/api/plans/add", {
          SId: window.VMBC.SId,
          Name: this.Name,
          TargetFolder: this.TargetFolder,
          Datacenter: this.Datacenter,
          VmNamingPolicy: this.VmNamingPolicy,
          NetworkQuota: this.NetworkQuota
            ? { Enabled: true, ...this.NetworkQuota }
            : { Enabled: false },
          CPU: this.CPU,
          Memory: this.Memory,
          HardDisk: this.HardDisk,
          BestComputeResource: !!this.BestComputeResource,
          BestDatastore: !!this.BestDatastore,
          Solutions: this.Solutions,
          Templates: this.Templates,
        })
        .then((res) => {
          if (!res.data) {
            cb(null, "data undefined");
            return;
          }
          if (res.data.res === "success") {
            cb(res.data.data, null);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else if (res.data.err) {
            cb(null, res.data.err);
          } else if (res.data.res === "error") {
            cb(null, res.data.data);
          } else {
            cb(null, "Unknown Error");
          }
        })
        .catch((reason) => {
          cb(false);
        });
    });
  }

  Log() {
    console.log("Plan", this);
  }

  AddSolution(computeResource) {
    var planSolution = new Solution(computeResource);
    this.Solutions.push(planSolution);
    return planSolution;
  }

  AddTemplate(Alias, TemplateId) {
    var planTemplate = new PlanTemplate(Alias, TemplateId);
    this.Templates.push(planTemplate);
    return planTemplate;
  }

  Name = "";
  TargetFolder = null; // FolderId
  Datacenter = null; // DatacenterId
  BestComputeResource = true;
  BestDatastore = true;
  VmNamingPolicy = "";
  NetworkQuota = false;
  CPU = [];
  Memory = [];
  HardDisk = [];

  Solutions = []; // array of VMBC/Plans/Plan.Solution
  Templates = []; // array of VMBC/Plans/Plan.PlanTemplate
}

class Solution {
  constructor(computeResource) {
    this.SetComputeResource(computeResource);
  }

  SetComputeResource(computeResource) {
    // as VMBC/Inventory/ComputeResource
    this.ComputeResourceId = computeResource.MO_Reference;
    this.ResourcePoolId = computeResource.ResourcePoolId;
  }

  AddDatastore(datastore) {
    // as VMBC/Inventory/Datastore
    this.SelectedDatastores.push(datastore.MO_Reference);
  }

  AddIpPoolId(ipPoolId) {
    this.SelectedIpPoolsId.push(ipPoolId);
  }

  AddCdIsoFileId(cdIsoFileId) {
    this.SelectedCdIsoFilesId.push(cdIsoFileId);
  }

  ComputeResourceId = null;
  ResourcePoolId = null;
  SelectedDatastores = []; // array of strings(datastoreId)
  SelectedIpPoolsId = []; // array of strings(ipPoolId)
  SelectedCdIsoFilesId = []; // array of strings(cdIsoFileId)
}

class PlanTemplate {
  constructor(alias, templateId) {
    this.Alias = alias;
    this.TemplateId = templateId;
  }
  Alias = "";
  TemplateId = ""; // virtual machine MoR of the template
}

export default Plan;
