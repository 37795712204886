import axios from "axios";
import VMBC from "..";

function checkLicenseCode(Code, cb) {
  if (!Code || (Code && typeof Code !== "string")) {
    cb("Please enter a valid Code");
    return;
  }
  Code = Code.trim();
  while (Code.indexOf("-") !== -1) {
    Code = Code.replace("-", "");
  }
  if (Code.length !== 16) {
    cb("License Code must be 16 charachters long");
    return;
  }

  axios
    .post(window.VMBC.Config.Origin + "/api/checkLicense", {
      SId: window.VMBC.SId,
      code: Code,
    })
    .then((res) => {
      if (res.data.res && res.data.res === "success") {
        cb(false);
        return;
      }
      cb(res.data.err ? res.data.err : "Unknown Error");
    });
}

function checkVCAuth(Host, Username, Password, sslCrt, cb) {
  if (!Host || (Host && typeof Host !== "string") || Host == "") {
    cb("Please enter a valid Host Address");
  }
  if (
    !Username ||
    (Username && typeof Username !== "string") ||
    Username == ""
  ) {
    cb("Please enter a valid Username");
  }
  if (
    !Password ||
    (Password && typeof Password !== "string") ||
    Password == ""
  ) {
    cb("Please enter a valid Password");
  }

  axios
    .post(window.VMBC.Config.Origin + "/api/checkVCAuth", {
      SId: window.VMBC.SId,
      host: Host,
      user: Username,
      pass: Password,
      sslCrt: typeof sslCrt == "string" ? sslCrt : "",
    })
    .then((res) => {
      if (res.data.res && res.data.res === "success") {
        cb(false);
        return;
      }
      if (res.data.res && res.data.res === "error") {
        cb(res.data);
        return;
      }
      cb(res.data.err ? res.data.err : "Unknown Error");
    });
}

function finish(installationSpec, cb) {
  cb = cb ? cb : () => {};
  if (
    !installationSpec ||
    !installationSpec.License ||
    !installationSpec.License.ConfirmedCode ||
    !installationSpec.vCenter ||
    !installationSpec.vCenter.Address ||
    !installationSpec.vCenter.Username ||
    !installationSpec.vCenter.Password
  ) {
    cb("Please enter a valid License Code");
    return;
  }
  axios
    .post(window.VMBC.Config.Origin + "/api/submit", {
      SId: window.VMBC.SId,
      code: installationSpec.License.ConfirmedCode,
      host: installationSpec.vCenter.Address,
      user: installationSpec.vCenter.Username,
      pass: installationSpec.vCenter.Password,
      sslCrt: installationSpec.vCenter.SslCrt,
    })
    .then((res) => {
      if (res.data.res && res.data.res === "success") {
        cb(false);
      } else {
        cb(res.data.err ? res.data.err : "Unknown Error");
      }
    });
}

function restart(cb) {
  cb = cb ? cb : () => {};
  axios
    .post(window.VMBC.Config.Origin + "/api/restart", {
      SId: window.VMBC.SId,
    })
    .then((res) => {
      if (res.data.res && res.data.res === "success") {
        cb(false);
      } else {
        cb(res.data.err ? res.data.err : "Unknown Error");
      }
    });
}

function CheckStageTwo(cb) {
  cb = cb ? cb : () => {};
  axios
    .post(
      VMBC.Config.Origin + "/api/chkSId",
      { SId: VMBC.SId },
      { timeout: 4000 }
    )
    .then((res) => {
      if (res.data) {
        cb({ isStageTwo: !!res.data.StageTwo });
      } else {
        cb();
      }
    })
    .catch(() => cb());
}

export default {
  checkLicenseCode,
  checkVCAuth,
  finish,
  restart,
  CheckStageTwo,
};
