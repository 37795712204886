import React from "react";
import Alert from "./Alert";
import Dialogs from "./Dialogs";
import ToolTip from "./Tooltip";
import Navigator from "./Navigator";
import A from "./A";
import Time from "./Time";
import Export from "./Export";

import axios from "axios";
import VMBC from "..";
import Demo from "../../Demo";
window.AddToLoadingVmboxProccess(1);

function CheckSId(cb, overrideVcenterCheckup) {
  Demo.DelayRandom(() => {
    var session = Demo.Session.Data.filter((val) => val.sessionId === VMBC.SId);
    if (session[0]) {
      cb();
    }
  });
}

function Loader(props) {
  return (
    <div
      className={`spinner-border align-self-center loader-lg ${props.className}`}
      style={{ width: "3rem", height: "3rem", ...props.style }}
      {...props}
    ></div>
  );
}

export default {
  Alert,
  Dialogs,
  ToolTip,
  Loader,
  A,
  Time,
  Export,
  Navigate: Navigator.Navigate,
  Navigator,
  CheckSId,
};
