import axios from "axios";

function AddCpuProfile(
  planId,
  {
    Alias,
    Sockets,
    CoresPerSocket,
    Reserve,
    Reservation,
    ReservationUnit,
    Limit,
    LimitBy,
    LimitByUnit
  },
  cb
) {
  if (!planId || typeof planId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/addCpuProfile",
        {
          SId: window.VMBC.SId,
          PlanId: planId,
          Alias,
          Sockets,
          CoresPerSocket,
          Reserve,
          Reservation,
          ReservationUnit,
          Limit,
          LimitBy,
          LimitByUnit
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function EditCpuProfile(
  planId,
  {
    ProfileId,
    Alias,
    Sockets,
    CoresPerSocket,
    Reserve,
    Reservation,
    ReservationUnit,
    Limit,
    LimitBy,
    LimitByUnit
  },
  cb
) {
  if (!planId || typeof planId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/editCpuProfile",
        {
          SId: window.VMBC.SId,
          PlanId: planId,
          ProfileId,
          Alias,
          Sockets,
          CoresPerSocket,
          Reserve,
          Reservation,
          ReservationUnit,
          Limit,
          LimitBy,
          LimitByUnit
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function RemoveCpuProfile(ProfileId, cb) {
  if (!ProfileId || typeof ProfileId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/removeCpuProfile",
        {
          SId: window.VMBC.SId,
          ProfileId: ProfileId
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function AddMemoryProfile(
  planId,
  {
    Alias,
    Capacity,
    CapacityUnit,
    Reserve,
    Reservation,
    ReservationUnit,
    Limit,
    LimitBy,
    LimitByUnit
  },
  cb
) {
  if (!planId || typeof planId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/addMemoryProfile",
        {
          SId: window.VMBC.SId,
          PlanId: planId,
          Alias,
          Capacity,
          CapacityUnit,
          Reserve,
          Reservation,
          ReservationUnit,
          Limit,
          LimitBy,
          LimitByUnit
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function EditMemoryProfile(
  planId,
  {
    ProfileId,
    Alias,
    Capacity,
    CapacityUnit,
    Reserve,
    Reservation,
    ReservationUnit,
    Limit,
    LimitBy,
    LimitByUnit
  },
  cb
) {
  if (!planId || typeof planId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/editMemoryProfile",
        {
          SId: window.VMBC.SId,
          PlanId: planId,
          ProfileId,
          Alias,
          Capacity,
          CapacityUnit,
          Reserve,
          Reservation,
          ReservationUnit,
          Limit,
          LimitBy,
          LimitByUnit
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function RemoveMemoryProfile(ProfileId, cb) {
  if (!ProfileId || typeof ProfileId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/removeMemoryProfile",
        {
          SId: window.VMBC.SId,
          ProfileId: ProfileId
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function AddHardDiskProfile(planId, { Alias, Capacity, CapacityUnit }, cb) {
  if (!planId || typeof planId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/addHardDiskProfile",
        {
          SId: window.VMBC.SId,
          PlanId: planId,
          Alias,
          Capacity,
          CapacityUnit
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function EditHardDiskProfile(
  planId,
  { ProfileId, Alias, Capacity, CapacityUnit },
  cb
) {
  if (!planId || typeof planId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/editHardDiskProfile",
        {
          SId: window.VMBC.SId,
          PlanId: planId,
          ProfileId,
          Alias,
          Capacity,
          CapacityUnit
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function RemoveHardDiskProfile(ProfileId, cb) {
  if (!ProfileId || typeof ProfileId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/removeHardDiskProfile",
        {
          SId: window.VMBC.SId,
          ProfileId: ProfileId
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function MarkProfileAsDefault(
  { CpuProfileId, MemoryProfileId, HardDiskProfileId, UnMark },
  cb
) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/markProfileAsDefault",
        {
          SId: window.VMBC.SId,
          CpuProfileId: CpuProfileId ? CpuProfileId : "",
          MemoryProfileId: MemoryProfileId ? MemoryProfileId : "",
          HardDiskProfileId: HardDiskProfileId ? HardDiskProfileId : "",
          UnMark: UnMark
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

export default {
  AddCpuProfile,
  EditCpuProfile,
  RemoveCpuProfile,

  AddMemoryProfile,
  EditMemoryProfile,
  RemoveMemoryProfile,

  AddHardDiskProfile,
  EditHardDiskProfile,
  RemoveHardDiskProfile,

  MarkProfileAsDefault
};
