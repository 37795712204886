import React from "react";
import NamingOptions from "./VmOptionsPanels/NamingOptions";
import LimitNetworkUsage from "./VmOptionsPanels/LimitNetworkUsage";
import VMBC from "../../../../VMBC";

function VmOptions(props) {
  return (
    <>
      <div id="toggleAccordion" className={`no-outer-spacing`}>
        <NamingOptions Data={props.Data} />
        <LimitNetworkUsage Data={props.Data} />
      </div>
    </>
  );
}

export default VmOptions;
