import axios from "axios";
import VMBC from "../..";

function GetAll(cb) {
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/whmcs/getAll",
        {
          SId: window.VMBC.SId,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

function AddNew(Name, Desc, AdminLink, cb) {
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/whmcs/add",
        { SId: window.VMBC.SId, Name, Desc, AdminAddress: AdminLink },
        { timeout: 4000 }
      )
      .then((res) => {
        if (!res.data) {
          cb("No response from vmbox server");
        }
        if (res.data.res === "success") {
          cb(false);
        } else if (res.data.res === "error") {
          cb({ message: res.data.data });
        } else {
          cb("Unknown error");
        }
      })
      .catch((reason) => {
        cb(reason);
      });
  });
}

function Edit(Id, Name, Desc, AdminLink, cb) {
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/whmcs/edit",
        { SId: window.VMBC.SId, Id, Name, Desc, AdminAddress: AdminLink },
        { timeout: 4000 }
      )
      .then((res) => {
        if (!res.data) {
          cb("No response from vmbox server");
        }
        if (res.data.res === "success") {
          cb(false);
        } else if (res.data.res === "error") {
          cb({ message: res.data.data });
        } else {
          cb("Unknown error");
        }
      })
      .catch((reason) => {
        cb(reason);
      });
  });
}

function Delete(Id, cb) {
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/whmcs/delete",
        { SId: window.VMBC.SId, WhmcsId: Id },
        { timeout: 4000 }
      )
      .then((res) => {
        if (!res.data) {
          cb("No response from vmbox server");
        }
        if (res.data.res === "success") {
          cb(false);
        } else if (res.data.res === "error") {
          cb({ message: res.data.data });
        } else {
          cb("Unknown error");
        }
      })
      .catch((reason) => {
        cb(reason);
      });
  });
}

function GetSetting(whmcsId, cb) {
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/whmcs/getSetting",
        {
          SId: window.VMBC.SId,
          WhmcsId: whmcsId,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

function GenerateModule(setting, cb) {
  if (typeof setting !== "object") return;
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/whmcs/generateModule",
        {
          SId: window.VMBC.SId,
          ...setting,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

export default {
  GetAll,
  AddNew,
  Edit,
  Delete,
  GetSetting,
  GenerateModule,
};
