import axios from "axios";

function GetAllGeneralIpPools(cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin +
          "/api/inventory/ipPool/getAllGeneralIpPools",
        {
          SId: window.VMBC.SId,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function CreateGeneralIpPool(Name, Description, cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/inventory/ipPool/createGeneralIpPool",
        {
          SId: window.VMBC.SId,
          Name: Name,
          Desc: Description,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function EditGeneralIpPool(id, Name, Description, cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/inventory/ipPool/editGeneralIpPool",
        {
          SId: window.VMBC.SId,
          ipPoolId: id,
          Name: Name,
          Desc: Description,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function DeleteGeneralIpPool(id, cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/inventory/ipPool/deleteGeneralIpPool",
        {
          SId: window.VMBC.SId,
          IpPoolId: id,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetIpPoolPlans(id, cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/inventory/ipPool/getIpPoolPlans",
        {
          SId: window.VMBC.SId,
          IpPoolId: id,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetIpPoolRecentAssignments(id, cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin +
          "/api/inventory/ipPool/getIpPoolRecentAssignments",
        {
          SId: window.VMBC.SId,
          IpPoolId: id,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}


function GetIpPoolIPsCountByState(id, cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin +
          "/api/inventory/ipPool/getIpPoolIpAddressesCountByState",
        {
          SId: window.VMBC.SId,
          IpPoolId: id,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

export default {
  GetAllGeneralIpPools,
  CreateGeneralIpPool,
  EditGeneralIpPool,
  DeleteGeneralIpPool,
  GetIpPoolPlans,
  GetIpPoolRecentAssignments,
  GetIpPoolIPsCountByState,
};
