import VMBC from "../..";
import axios from "axios";
import config from "./config";

function GetVmboxDetails(cb) {
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/offline/inventory/vmbox/getDetails",
        {
          SId: window.VMBC.SId,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

function EditSystemNetwork(spec, cb) {
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/offline/inventory/vmbox/editSysNet",
        {
          SId: window.VMBC.SId,
          ...spec,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

function EditVcenterCredentials(spec, cb) {
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/offline/inventory/vmbox/editVcCred",
        {
          SId: window.VMBC.SId,
          ...spec,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

function CheckVcenterCredentials(spec, cb) {
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/offline/inventory/vmbox/checkVcCred",
        {
          SId: window.VMBC.SId,
          ...spec,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

function GetLicenseDetails(cb) {
  cb = cb ? cb : () => {};
  VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/offline/inventory/vmbox/license",
        {
          SId: window.VMBC.SId,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  }, true);
}

export default {
  GetVmboxDetails,
  EditSystemNetwork,
  EditVcenterCredentials,
  CheckVcenterCredentials,
  GetLicenseDetails,
  ...config,
};
