import axios from "axios";
import VMBC from "..";
import Demo from "../../Demo";

function login(u, p, cb) {
  Demo.DelayRandom(() => {
    var user = Demo.Users.Data.filter((val) => val.username == u);
    if (!user[0] || user[0].password !== p) {
      cb("username or password is wrong");
      return;
    }

    var SId = "" + Math.random();

    Demo(Demo.Session).push({
      sessionId: SId,
      LINK_usersId: user[0].id,
    });

    VMBC.SId = SId;
    localStorage.setItem("SId", SId);
    localStorage.setItem("username", user[0].username);
    localStorage.setItem("userfullname", user[0].fullName);
    localStorage.setItem("version", "Demo");

    cb(false);
  });
}

function logout(cb) {
  if (!!VMBC.SId) {
    Demo.DelayRandom(() => {
      cb = cb ? cb : () => {};
      var session = Demo(Demo.Session);
      session.pop((val) => val.sessionId === VMBC.SId);
      VMBC.SId = false;
      localStorage.removeItem("SId");
      localStorage.removeItem("LockId");
      cb(true);
    });
  } else {
    cb();
  }
}

function Lock() {
  localStorage.setItem("LockId", VMBC.SId);
  localStorage.removeItem("SId");
  VMBC.Navigate(VMBC.Navigator.History, "/panel");
}

function Unlock(password) {
  if (localStorage.getItem("LockId")) {
    var lockId = localStorage.getItem("LockId");
    var sessions = Demo.Session.Data.filter((val) => val.sessionId === lockId);
    if (!sessions[0]) {
      VMBC.Alert.Show("Session or Password is Expired.", "error");
      return;
    }
    var Users = Demo.Users.Data.filter(
      (val) => val.id === sessions[0].LINK_usersId
    );
    if (!Users[0]) {
      VMBC.Alert.Show("Session or Password is Expired.", "error");
      return;
    }
    if (Users[0].password !== password) {
      VMBC.Alert.Show("Session or Password is Expired.", "error");
      return;
    }
    localStorage.setItem("SId", lockId);
    localStorage.removeItem("LockId");
    VMBC.SId = lockId;
    VMBC.Navigate(VMBC.Navigator.History, "/panel");
  }
}

export default { login, logout, Lock, Unlock };
