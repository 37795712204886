import React from "react";
import { Switch, Route, BrowserRouter, withRouter } from "react-router-dom";

import Entry from "./Entry";
import Logout from "./Login/Logout";
import Login from "./Login";
import Panel from "./Panel";

import VMBC from "./VMBC";
import AlertSnackBar from "./Components/AlertSnackBar";
import Dialogs from "./Components/Dialogs";
import Install from "./Install";
import Demo from "./Demo";

function App(props) {
  window.VMBC = VMBC;
  if (VMBC.SId === "false") {
    let localSId = localStorage.getItem("SId");
    VMBC.SId = localSId ? localSId : false;
  }

  React.useEffect(() => {
    Demo.Setup();
  }, []);

  return (
    <>
      <AlertSnackBar Agent={VMBC.Alert} />
      <Dialogs Agent={VMBC.Dialogs} />
      <BrowserRouter>
        <Switch>
          <Route exact path={"/"} children={Entry} />
          <Route exact path={"/logout"} children={Logout} />
          <Route exact path={"/login"} children={Login} />
          <Route path={"/panel"} children={Panel} />
          <Route path={"/install"} children={Install} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
