import React from "react";
import VMBC from "../../../../../VMBC";

function DatastoresList(props) {
  const { PlanSolution } = props;
  const { ComputeResourceId, SolutionId } = PlanSolution;

  const [datastores, setDatastores] = React.useState();
  const [allDatastores, setAllDatastores] = React.useState();
  const [refreshT, refresh] = React.useState(false);
  const [addDatastoreShown, setAddDatastoreShown] = React.useState(false);
  const getDsNameWaiGroupCount = React.useRef(-1);

  React.useEffect(() => {
    setAddDatastoreShown(false);
    setDatastores();
    VMBC.Plans.GetSolutionDatastores(SolutionId, (dss, err) => {
      if (!err) {
        setDatastores(dss);
      } else {
        VMBC.Alert.Show(err);
      }
    });
    setAllDatastores();
    var ComputeResource = new VMBC.Inventory.ComputeResource(ComputeResourceId);
    ComputeResource.GetDatastores((dss, err) => {
      if (!err) {
        getDsNameWaiGroupCount.current = dss.length;
        dss.forEach((ds) =>
          ds.GetSummary(() => {
            getDsNameWaiGroupCount.current = getDsNameWaiGroupCount.current - 1;
            if (getDsNameWaiGroupCount.current === 0) {
              setAddDatastoreShown(true);
              getDsNameWaiGroupCount.current = -1;
            }
          })
        );
        setAllDatastores(dss);
      } else {
        VMBC.Alert.Show(err);
      }
    });
  }, [refreshT, PlanSolution]);

  const Refresh = () => refresh(!refreshT);

  const onRemoveClicked = (ds) => {
    VMBC.Dialogs.Show({
      Title: "Are you sure?",
      Text: `Are you sure you want to remove "${ds.DatastoreName}" from this Plan? No VMachines will be deployed on this datastore from this plan anymore`,
      Ignorable: true,
      Actions: [
        {
          onClick: () => {
            VMBC.Plans.RemoveSolutionDatastore(
              ds.SolutionDsRecordId,
              (res, err) => {
                if (err) {
                  VMBC.Alert.Show(err);
                } else {
                  VMBC.Alert.Show(res, "success");
                  Refresh();
                }
              }
            );
            VMBC.Dialogs.Close();
          },
          className: "btn btn-outline-primary btn-sm",
          text: "Yes",
        },
        {
          onClick: () => VMBC.Dialogs.Close(),
          className: "btn btn-primary btn-sm",
          text: "No",
        },
      ],
    });
  };

  const onBestDatastoreChange = (best) => {
    VMBC.Dialogs.Show({
      Title: "Are you sure you want to change datastore usage order?",
      Text: best
        ? `Datastores with less machines, will be in prority.`
        : `Datastores will be used in order.`,
      Ignorable: true,
      Actions: [
        {
          onClick: () => {
            // TODO: Add this feature to edit best datastore policy
            // VMBC.Plans.ChangeBestDatastorePolicy(
            //   SolutionId,
            //   (res, err) => {
            //     if (err) {
            //       VMBC.Alert.Show(err);
            //     } else {
            //       VMBC.Alert.Show(res, "success");
            //       Refresh();
            //     }
            //   }
            // );
            VMBC.Dialogs.Close();
          },
          className: "btn btn-outline-primary btn-sm disabled",
          text: "Yes",
        },
        {
          onClick: () => VMBC.Dialogs.Close(),
          className: "btn btn-primary btn-sm",
          text: "No",
        },
      ],
    });
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
                <div className="th-content text-center">Order</div>
              </th>
              <th>
                <div className="th-content text-center">Name</div>
              </th>
              <th>
                <div className="th-content text-center">VMs Count</div>
              </th>
              <th>
                <div className="th-content text-center">Available Space</div>
              </th>
              <th>
                <div className="th-content text-center">Reserved Space</div>
              </th>
              <th>
                <div className="th-content text-center">Actions</div>
              </th>
            </tr>
          </thead>
          {datastores ? (
            <tbody>
              {datastores.map((ds) => (
                <tr style={{ border: "1px dashed grey" }}>
                  <td>
                    <div className="td-content text-center customer-name">
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            // display: "flex",
                            display: "none", // TODO: remove this and add new feature to change datastores order
                            flexDirection: "column",
                            marginRight: "10px",
                          }}
                        >
                          <span onClick={() => VMBC.Alert.Show("+", "info")}>
                            +
                          </span>
                          <span onClick={() => VMBC.Alert.Show("-", "error")}>
                            -
                          </span>
                        </span>
                        {ds.Order}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="td-content text-center product-brand">
                      {ds.DatastoreName}
                    </div>
                  </td>
                  <td>
                    <div className="td-content text-center">
                      <VMBC.ToolTip
                        title={`Deployed: ${ds.VmCount} + Reserved: ${ds.UncountedVms}`}
                      >
                        {`${ds.VmCount} + ${ds.UncountedVms}`}
                      </VMBC.ToolTip>
                    </div>
                  </td>
                  <td>
                    <div className="td-content text-center">
                      <VMBC.ToolTip
                        title={`${ds.AvailableDiskSpace} MB / ${ds.MaxDiskSpace} MB`}
                      >
                        {ds.AvailableDiskSpace > 10240
                          ? `${parseFloat(ds.AvailableDiskSpace / 1024).toFixed(
                              2
                            )} GB`
                          : `${ds.AvailableDiskSpace} MB`}
                        {" / "}
                        {ds.MaxDiskSpace > 10240
                          ? `${parseFloat(ds.MaxDiskSpace / 1024).toFixed(
                              2
                            )} GB`
                          : `${ds.MaxDiskSpace} MB`}
                      </VMBC.ToolTip>
                    </div>
                  </td>

                  <td>
                    <div className="td-content text-center">
                      <VMBC.ToolTip title={`${ds.UncountedDiskSpace} MB`}>
                        {ds.UncountedDiskSpace > 10240
                          ? `${parseFloat(ds.UncountedDiskSpace / 1024).toFixed(
                              2
                            )} GB`
                          : `${ds.UncountedDiskSpace} MB`}
                      </VMBC.ToolTip>
                    </div>
                  </td>

                  <td>
                    <div className="td-content text-center">
                      <span
                        class="badge outline-badge-danger"
                        onClick={() => onRemoveClicked(ds)}
                      >
                        Remove
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : undefined}
        </table>
      </div>
      {datastores ? (
        datastores.length != undefined && datastores.length === 0 ? (
          <div className={`my-4 text-center`}>No Datastores</div>
        ) : undefined
      ) : (
        <div
          className={`my-2`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="spinner-border align-self-center loader-lg"
            style={{ width: "3rem", height: "3rem" }}
          ></div>
        </div>
      )}

      {allDatastores && addDatastoreShown ? ( //Wait Group is done, all datastores names are loaded
        <>
          <div
            className={`mt-3`}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* TODO Remove display: none and add feature to change best datastore policy */}
              <div style={{ display: "none" }}>
                <label
                  class="switch s-primary mr-2"
                  style={{ marginBottom: "0" }}
                >
                  <input
                    type="checkbox"
                    defaultValue={true}
                    onChange={(e) => {
                      e.preventDefault();
                      onBestDatastoreChange(e.target.checked);
                    }}
                  />
                  <span class="slider round"></span>
                </label>
                Use Best Datastore
              </div>
            </div>

            <span>
              <button
                className={`btn btn-primary btn-sm`}
                onClick={() => {
                  window.$("#addDatastoreDialog").modal("show");
                  window
                    .$(".planDatastoreSelectpicker")
                    .selectpicker("refresh");
                }}
              >
                Add
              </button>
            </span>

            <span></span>
          </div>

          <AddDatastore
            allDatastores={allDatastores}
            Refresh={Refresh}
            datastores={datastores}
            SolutionId={SolutionId}
          />
        </>
      ) : undefined}
    </>
  );
}

function AddDatastore(props) {
  const { allDatastores, Refresh, datastores, SolutionId } = props;

  const selectedDSToAddObj = React.useRef();
  const [selectedDSToAdd, setSelectedDSToAdd] = React.useState();

  const handleSelectedDSChange = (e) => {
    if (allDatastores != undefined) {
      if (allDatastores.length > e) {
        //check array index bound
        selectedDSToAddObj.current = allDatastores[e];
        setSelectedDSToAdd(allDatastores[e]);
      }
    }
  };

  const handleAddDatastore = () => {
    if (
      datastores
        .map((ds) => ds.DatastoreName)
        .includes(selectedDSToAddObj.current.Name)
    ) {
      VMBC.Alert.Show(
        `Datastore '${selectedDSToAddObj.current.Name}' is Already in Plan for this Compute Resource`,
        "error"
      );
      return;
    }
    VMBC.Plans.AddSolutionDatastore(
      SolutionId,
      selectedDSToAddObj.current,
      (res, err) => {
        if (err) {
          VMBC.Alert.Show(err);
        } else {
          VMBC.Alert.Show(res, "success");
        }
        handleCloseAddDatastore();
        Refresh();
      }
    );
  };

  const handleCloseAddDatastore = () => {
    window.$("#addDatastoreDialog").modal("hide");
    setSelectedDSToAdd();
    selectedDSToAddObj.current = null;
  };

  return (
    <div>
      <div
        className="modal fade"
        id="addDatastoreDialog"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Add Datastore Dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6>Choose a Datastore</h6>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <p className="modal-text" style={{ color: "#fff" }}>
                Choose the Datastore you want to add to this Plan for this
                Compute Resource
              </p>
              <select
                className="planDatastoreSelectpicker"
                title={`Choose a Datastore`}
                data-width="100%"
                value={selectedDSToAdd}
                onChange={(e) => handleSelectedDSChange(e.target.value)}
              >
                {allDatastores.map((Ds, i) => {
                  return (
                    <option value={i}>
                      {Ds.Name !== "" ? Ds.Name : "No Name"}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="modal-footer">
              <button
                className={`btn btn-primary btn-sm`}
                onClick={handleAddDatastore}
              >
                Add
              </button>
              <button
                className={`btn btn-dark btn-sm`}
                onClick={handleCloseAddDatastore}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatastoresList;
