import React from "react";
import Table from "../../../../../Components/Table";
import VMBC from "../../../../../VMBC";

function VirtualMachinesTable(props) {
  let { VMs, Refresh } = props;

  VMs = VMs.map((vm) => ({ ...vm, Id: Number(vm.Id) }));

  return (
    <>
      <Table
        columns={[
          {
            title: "ID",
            fieldName: "Id",
          },
          {
            title: "Name",
            fieldName: "VmName",
          },
          {
            title: "IP Address",
            fieldName: "IpAddress",
          },
          {
            title: "State",
            fieldName: "State",
          },
        ]}
        data={VMs}
        actions={{
          FreeActions: [
            {
              Title: "Refresh",
              onClick: () => {
                if (Refresh != undefined && typeof Refresh === "function") {
                  Refresh();
                }
              },
              Icon: <VMBC.SVGs.RefreshCCW style={{ position: "initial" }} />,
            },
          ],
          Others: [
            {
              Icon: (rowData) => (
                <VMBC.A href={`/panel/customers/machines/${rowData.Id}`}>
                  <span className="badge outline-badge-info">Details</span>
                </VMBC.A>
              ),
              Title: "Details",
            },
          ],
        }}
      />
    </>
  );
}

export default VirtualMachinesTable;
