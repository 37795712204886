import React from "react";
import Actions from "./Actions";
import VMBC from "../../../../../VMBC";
import Demo from "../../../../../Demo";

function Details(props) {
  const { vm, Reload, Refresh } = props;
  const {
    IsReserved,
    Location,
    VmName,
    IpAddress,
    State,
    OsPassword,
    PlanName,
    PlanId,
  } = vm;
  const [passShown, setPassShown] = React.useState(false);

  var IsSuspended = Demo.RandInt(1, 100) < 10;

  return (
    <div className={``}>
      <div className={`container`}>
        <div className={`row`}>
          <div
            className={`col col-12 py-2 d-flex justify-content-center`}
            style={{ position: "relative" }}
          >
            {IsSuspended ? (
              <span
                className="badge badge-danger"
                style={{ position: "absolute", fontSize: "1rem", zIndex: "2" }}
              >
                Suspended
              </span>
            ) : undefined}
            {!IsReserved ? (
              <span
                className="badge outline-badge-warning"
                style={{
                  position: "absolute",
                  fontSize: "1rem",
                  zIndex: "2",
                  cursor: "pointer",
                }}
                onClick={() => {
                  VMBC.Dialogs.Show({
                    Title: "Not Reserved VM",
                    Text:
                      "You can select Compute Resource and Datastore for VM in order to reserve vm",
                    Actions: [
                      {
                        className: "btn-primary btn-sm",
                        text: "Ok",
                        onClick: () => VMBC.Dialogs.Close(),
                      },
                    ],
                  });
                }}
              >
                Not Reserved
              </span>
            ) : undefined}
          </div>
          <div className={`col col-lg-6 col-12 py-lg-5 pt-md-4 py-2 mt-3`}>
            <div className={`container`}>
              <div className={`row`}>
                <div
                  className={`col-6 mt-1 text-right align-self-center`}
                  style={{ color: "#fff" }}
                >
                  State
                </div>
                <div className={`col-6 mt-1 text-left align-self-center`}>
                  <VMBC.A href={`#`}>{State}</VMBC.A>
                </div>
                <div
                  className={`col-6 mt-1 text-right align-self-center`}
                  style={{ color: "#fff" }}
                >
                  Location
                </div>
                <div className={`col-6 mt-1 text-left align-self-center`}>
                  {Location.length <= 15 ? (
                    <VMBC.A href={`#`}>{Location}</VMBC.A>
                  ) : (
                    <VMBC.ToolTip title={"Click to Copy"}>
                      <VMBC.A href={`#`}>
                        <span
                          onClick={() => {
                            navigator.clipboard.writeText(Location);
                            VMBC.Alert.Show(
                              "Location copied to clipboard",
                              "info"
                            );
                          }}
                        >
                          {Location.substr(0, 15)}...
                        </span>
                      </VMBC.A>
                    </VMBC.ToolTip>
                  )}
                </div>
                <div
                  className={`col-6 mt-1 text-right align-self-center`}
                  style={{ color: "#fff" }}
                >
                  Name
                </div>
                <div className={`col-6 mt-1 text-left align-self-center`}>
                  {VmName.length <= 15 ? (
                    <VMBC.A href={`#`}>{VmName}</VMBC.A>
                  ) : (
                    <VMBC.ToolTip title={"Click to Copy"}>
                      <VMBC.A href={`#`}>
                        <span
                          onClick={() => {
                            navigator.clipboard.writeText(VmName);
                            VMBC.Alert.Show(
                              "VM Name copied to clipboard",
                              "info"
                            );
                          }}
                        >
                          {VmName.substr(0, 15)}...
                        </span>
                      </VMBC.A>
                    </VMBC.ToolTip>
                  )}
                </div>
                <div
                  className={`col-6 mt-1 text-right align-self-center`}
                  style={{ color: "#fff" }}
                >
                  Plan Name
                </div>
                <div className={`col-6 mt-1 text-left align-self-center`}>
                  <VMBC.A href={`/panel/plans/list/${PlanId}`}>
                    {PlanName}
                  </VMBC.A>
                </div>
                <div
                  className={`col-6 mt-1 text-right align-self-center`}
                  style={{ color: "#fff" }}
                >
                  IP Address
                </div>
                <div className={`col-6 mt-1 text-left align-self-center`}>
                  <VMBC.A href={`#`}>{IpAddress}</VMBC.A>
                </div>
                <div
                  className={`col-6 mt-1 text-right align-self-center`}
                  style={{ color: "#fff" }}
                >
                  Password
                </div>
                <div className={`col-6 mt-1 text-left align-self-center`}>
                  <VMBC.A href={`#`}>
                    <VMBC.ToolTip
                      title={`Click to See.
Double Click to Copy`}
                    >
                      <span
                        style={{ userSelect: "none" }}
                        onMouseDown={() => setPassShown(true)}
                        onMouseUp={() => setPassShown(false)}
                        onMouseLeave={() => setPassShown(false)}
                        onDoubleClick={() => {
                          navigator.clipboard.writeText(OsPassword);
                          VMBC.Alert.Show(
                            "Password copied to clipboard",
                            "info"
                          );
                        }}
                      >
                        {passShown ? OsPassword : "• • • • • • • •"}
                      </span>
                    </VMBC.ToolTip>
                  </VMBC.A>
                </div>
                <div className={`col-12 mt-3 text-center align-self-center`}>
                  <VMBC.A href={"#"}>
                    <button className={`btn btn-primary`}>
                      Details at WHMCS
                    </button>
                  </VMBC.A>
                </div>
              </div>
            </div>
          </div>

          <div className={`col col-lg-6 col-12 py-2`}>
            <div
              style={{
                maxHeight: "270px",
              }}
              className={`container-fluid h-100 overflow-auto d-flex align-items-center justify-content-center`}
            >
              <div
                className={`row justify-content-center`}
                style={{ maxHeight: "100%" }}
              >
                <Actions vm={vm} Reload={Reload} Refresh={Refresh} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
