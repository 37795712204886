import axios from "axios";
import Plan from "./Plan";
import IpPools from "./IpPools";
import VirtualHardwareMethods from "./VirtualHardwareMethods";
import SolutionDatastoreMethods from "./SolutionDatastoreMethods";
import SolutionIpPoolMethods from "./SolutionIpPoolMethods";
import SolutionCdDrivesMethods from "./SolutionCdDrivesMethods";
import PlanTemplate from "./PlanTemplate";
import PlanStatistics from "./PlanStatistics";
window.AddToLoadingVmboxProccess(1);

function RegisterPlan(Plan) {
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/add",
        {
          SId: window.VMBC.SId,
          Name: Plan.Name,
          TemplateId: Plan.TemplateId,
          Datacenter: Plan.Datacenter,
          Solutions: Plan.Solutions.map((solution) => ({
            ComputeResourceId: solution.ComputeResourceId,
            Datastores: solution.Datastores,
            IpPools: solution.IpPools,
          })),
        },
        { timeout: 300000 }
      )
      .then((res) => {
        console.log("Res", res);
      })
      .catch((reason) => {
        console.log("err", reason);
      });
  });
}

function GetAllVmTargetFolders(cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/getTargetFoldersList",
        {
          SId: window.VMBC.SId,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        cb(res.data.data);
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetPlan(id, cb) {
  if (!id || typeof id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/getPlan",
        { SId: window.VMBC.SId, PlanId: id },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function EditNamingPolicy(id, { NamingPolicy, RenameAll }, cb) {
  if (
    !id ||
    typeof id !== "string" ||
    !NamingPolicy ||
    typeof NamingPolicy !== "string"
  )
    return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/editNamingPolicy",
        {
          SId: window.VMBC.SId,
          PlanId: id,
          VmNamingPolicy: NamingPolicy,
          RenameAll: !!RenameAll,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function EditNetworkQuota(
  id,
  {
    Disable,
    PlanId,
    Tx,
    Rx,
    Usage,
    Reset,
    RatioLimitEnabled,
    RatioLimitType,
    RatioAllowed,
    RatioCheckpoint,
    RatioCheckpointType,
  },
  cb
) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/editNetworkQuota",
        {
          SId: window.VMBC.SId,
          PlanNetworkQuotaId: id ? id : "",
          Disable,
          PlanId,
          Tx,
          Rx,
          Usage,
          Reset,

          RatioLimitEnabled,
          RatioLimitType,
          RatioAllowed,
          RatioCheckpoint,
          RatioCheckpointType,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetPlanVirtualHardware(id, cb) {
  if (!id || typeof id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/getPlanVirtualHardware",
        { SId: window.VMBC.SId, PlanId: id },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function AddSolution(planId, { MO_Reference, ResourcePoolId }, cb) {
  if (
    !planId ||
    typeof planId !== "string" ||
    !MO_Reference ||
    typeof MO_Reference !== "string" ||
    !ResourcePoolId ||
    typeof ResourcePoolId !== "string"
  )
    return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/addSolution",
        {
          SId: window.VMBC.SId,
          PlanId: planId,
          ComputeResourceId: MO_Reference,
          ResourcePoolId: ResourcePoolId,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function RemoveSolution(solutionId, cb) {
  if (!solutionId || typeof solutionId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/removeSolution",
        {
          SId: window.VMBC.SId,
          SolutionId: solutionId,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetPlanVirtualMachines(id, cb) {
  if (!id || typeof id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/getPlanVirtualMachines",
        { SId: window.VMBC.SId, PlanId: id },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function SetPlanDisabledState(id, disabled, cb) {
  if (!id || typeof id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/setDisabledState",
        { SId: window.VMBC.SId, PlanId: id, DisabledState: !!disabled },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function SetPlanDeletedState(id, deleted, cb) {
  if (!id || typeof id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/setDeletedState",
        { SId: window.VMBC.SId, PlanId: id, DeletedState: !!deleted },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

export default {
  RegisterPlan,
  AddSolution,
  RemoveSolution,

  GetAllVmTargetFolders,
  GetPlan,
  EditNamingPolicy,
  EditNetworkQuota,
  GetPlanVirtualHardware,
  GetPlanVirtualMachines,
  ...VirtualHardwareMethods,
  ...SolutionDatastoreMethods,
  ...SolutionIpPoolMethods,
  ...SolutionCdDrivesMethods,
  ...PlanTemplate,

  Statistics: PlanStatistics,

  SetPlanDisabledState,
  SetPlanDeletedState,

  Plan,
  IpPools,
};
