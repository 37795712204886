import { ManagedObject } from "../../DataTypes";

import axios from "axios";
import Utils from "../../Utils";
import HostSystem from "./../HostSystem";
import Network from "./../Network";
import Datastore from "./../Datastore";

class ComputeResource extends ManagedObject {
  constructor() {
    if (arguments.length === 1 && typeof arguments[0] === "string") {
      // ComputeResource ( string ComputeResourceId )
      super(arguments[0]);
    } else if (
      arguments.length === 1 &&
      arguments[0]._VMBC_Type &&
      arguments[0]._VMBC_Type === "ManagedObject"
    ) {
      // ComputeResource ( ManagedObject ComputeResource )
      super(arguments[0].MO_Reference);
    } else if (
      arguments.length === 1 &&
      typeof arguments[0] === "object" &&
      arguments[0]._SRC &&
      arguments[0]._SRC === "constructComputeResource"
    ) {
      // ComputeResource ( {} ComputeResourceData )
      super(arguments[0].MO_Reference);
      this.Name = arguments[0].Name;
      this.ResourcePoolId = arguments[0].ResourcePoolId;
      this.HostSystems = arguments[0].Hosts ? arguments[0].Hosts : [];
      this.Datastores = arguments[0].Datastores ? arguments[0].Datastores : [];
      this.Networks = arguments[0].Networks ? arguments[0].Networks : [];
      this._Path = arguments[0].Path;
      if (arguments[0].AutoConstruct) {
        this.ConstructProperties();
      }
    }
  }

  ConstructProperties(exceptions, cbs) {
    if (exceptions) {
      if (!exceptions.length) return; // exceptions must be array
    } else {
      exceptions = [];
    }

    exceptions = exceptions
      .filter((E) => typeof E === "string")
      .map((Exc) => Exc.toLowerCase());

    if (!exceptions.includes("hostsystems")) {
      for (var i = 0; i < this.HostSystems.length; i++) {
        if (!this.HostSystems[i]._VMBC_Type) {
          this.HostSystems[i] = new HostSystem(this.HostSystems[i].HostId);
        }
      }
      if (cbs && cbs.HostSystem) {
        cbs.HostSystem(this.HostSystems);
      }
    }

    if (!exceptions.includes("datastores")) {
      for (var i = 0; i < this.Datastores.length; i++) {
        if (!this.Datastores[i]._VMBC_Type) {
          this.Datastores[i] = new Datastore(this.Datastores[i].DatastoreId);
        }
      }
      if (cbs && cbs.Datastore) {
        cbs.Datastore(this.Datastores);
      }
    }

    if (!exceptions.includes("networks")) {
      for (var i = 0; i < this.Networks.length; i++) {
        if (!this.Networks[i]._VMBC_Type) {
          this.Networks[i] = new Network(this.Networks[i].NetworkId);
        }
      }
      if (cbs && cbs.Network) {
        cbs.Network(this.Networks);
      }
    }
  }

  GetIpPools(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/computeResource/ippools",
          { SId: window.VMBC.SId, ComputeResourceId: this.MO_Reference },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb(false);
            return;
          }
          if (res.data.res === "success") {
            this.IpPools = res.data.data;
            cb(this.IpPools);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        });
    });
  }

  GetNetworks(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/computeResource/networks",
          { SId: window.VMBC.SId, ComputeResourceId: this.MO_Reference },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb(false);
            return;
          }
          if (res.data.res === "success") {
            this.Networks = res.data.data;
            for (var i = 0; i < this.Networks.length; i++) {
              if (!this.Networks[i]._VMBC_Type) {
                var name = this.Networks[i].Name;
                this.Networks[i] = new Network(this.Networks[i].NetworkId);
                this.Networks[i].Name = name;
              }
            }
            cb(this.Networks);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch((res) => {
          cb(false);
        });
    });
  }

  GetDatastores(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin +
            "/api/inventory/computeResource/datastores",
          { SId: window.VMBC.SId, ComputeResourceId: this.MO_Reference },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb(null, "Unknown Data");
            return;
          }
          if (res.data.res === "success") {
            this.Datastores = res.data.data;
            this.ConstructProperties(["hostsystems", "networks"], {
              Datastore: (dss) => cb(dss, null),
            });
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else if (res.data.err) {
            cb(null, res.data.err);
          } else if (res.data.res === "error") {
            cb(null, res.data.data);
          } else {
            cb(null, "Unknown Error");
          }
        })
        .catch((res) => {
          cb(false);
        });
    });
  }

  GetCdIsoFiles(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin +
            "/api/inventory/computeResource/cdIsoFiles",
          { SId: window.VMBC.SId, ComputeResourceId: this.MO_Reference },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb(false);
            return;
          }
          if (res.data.res === "success") {
            this.CdIsoFiles = res.data.data ? res.data.data : [];
            cb(this.CdIsoFiles);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch((res) => {
          cb(false);
        });
    });
  }

  CreateNewCdIsoFile(Name, Desc, isoAddress, cb) {
    Desc = Desc ? Desc : "";
    if (
      typeof Name !== "string" ||
      Name === "" ||
      typeof Desc !== "string" ||
      typeof isoAddress !== "string" ||
      isoAddress === ""
    ) {
      cb("Check your inputs");
      return;
    }
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin +
            "/api/inventory/computeResource/createNewCdIsoFile",
          {
            SId: window.VMBC.SId,
            ComputeResourceId: this.MO_Reference,
            Name,
            Desc,
            IsoAddress: isoAddress,
          },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb("Unknown Error");
            return;
          }
          if (res.data.res === "success") {
            cb(false);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
            cb("Session Expired");
          } else if (res.data.err) {
            cb(res.data.err);
          } else if (res.data.res === "error") {
            cb(res.data.data);
          } else {
            cb("Unknown Error");
          }
        })
        .catch((res) => {
          cb("Request Error");
        });
    });
  }

  EditCdIsoFile(id, Name, Desc, cb) {
    Desc = Desc ? Desc : "";
    if (typeof Name !== "string" || Name === "" || typeof Desc !== "string") {
      cb("Check your inputs");
      return;
    }
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin +
            "/api/inventory/computeResource/editCdIsoFile",
          { SId: window.VMBC.SId, CdIsoFileId: id, Name, Desc },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb("Unknown Error");
            return;
          }
          if (res.data.res === "success") {
            cb(false);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
            cb("Session Expired");
          } else if (res.data.err) {
            cb(res.data.err);
          } else if (res.data.res === "error") {
            cb(res.data.data);
          } else {
            cb("Unknown Error");
          }
        })
        .catch((res) => {
          cb("Request Error");
        });
    });
  }

  DeleteCdIsoFile(id, cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin +
            "/api/inventory/computeResource/deleteCdIsoFile",
          { SId: window.VMBC.SId, CdIsoFileId: id },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb("Unknown Error");
            return;
          }
          if (res.data.res === "success") {
            cb(false);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
            cb("Session Expired");
          } else if (res.data.err) {
            cb(res.data.err);
          } else if (res.data.res === "error") {
            cb(res.data.data);
          } else {
            cb("Unknown Error");
          }
        })
        .catch((res) => {
          cb("Request Error");
        });
    });
  }

  CreateNewIpPool(Name, Desc, cb) {
    Desc = Desc ? Desc : "";
    if (typeof Name !== "string" || Name === "" || typeof Desc !== "string") {
      cb("Check your inputs");
      return;
    }
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin +
            "/api/inventory/computeResource/createNewIpPool",
          {
            SId: window.VMBC.SId,
            ComputeResourceId: this.MO_Reference,
            Name,
            Desc,
          },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb("Unknown Error");
            return;
          }
          if (res.data.res === "success") {
            cb(false);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
            cb("Session Expired");
          } else if (res.data.err) {
            cb(res.data.err);
          } else if (res.data.res === "error") {
            cb(res.data.data);
          } else {
            cb("Unknown Error");
          }
        })
        .catch((res) => {
          cb("Request Error");
        });
    });
  }

  EditIpPool(id, Name, Desc, cb) {
    Desc = Desc ? Desc : "";
    if (typeof Name !== "string" || Name === "" || typeof Desc !== "string") {
      cb("Check your inputs");
      return;
    }
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin +
            "/api/inventory/computeResource/editIpPool",
          {
            SId: window.VMBC.SId,
            ComputeResourceId: this.MO_Reference,
            IpPoolId: id,
            Name,
            Desc,
          },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb("Unknown Error");
            return;
          }
          if (res.data.res === "success") {
            cb(false);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
            cb("Session Expired");
          } else if (res.data.err) {
            cb(res.data.err);
          } else if (res.data.res === "error") {
            cb(res.data.data);
          } else {
            cb("Unknown Error");
          }
        })
        .catch((res) => {
          cb("Request Error");
        });
    });
  }

  DeleteIpPool(id, cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin +
            "/api/inventory/computeResource/deleteIpPool",
          {
            SId: window.VMBC.SId,
            ComputeResourceId: this.MO_Reference,
            IpPoolId: id,
          },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb("Unknown Error");
            return;
          }
          if (res.data.res === "success") {
            cb(false);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
            cb("Session Expired");
          } else if (res.data.err) {
            cb(res.data.err);
          } else if (res.data.res === "error") {
            cb(res.data.data);
          } else {
            cb("Unknown Error");
          }
        })
        .catch((res) => {
          cb("Request Error");
        });
    });
  }

  Name = "";
  ResourcePoolId = "";
  HostSystems = [];
  Datastores = [];
  Networks = [];
  IpPools = [];
  CdIsoFiles = [];
  _Path = "";
  _VMBC_Type = "ComputeResource";
}

export default ComputeResource;
