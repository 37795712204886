import axios from "axios";

function GetComputeResourceAndDsNames(Id, cb) {
  if (!Id || typeof Id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/machines/getCompAndDsNames",
        { SId: window.VMBC.SId, Id },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetSelectableComputesAndDss(Id, cb) {
  if (!Id || typeof Id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin +
          "/api/customers/machines/getSelectableComputesAndDss",
        { SId: window.VMBC.SId, Id },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false, reason);
      });
  });
}

function EditComputeResourceAndDs(
  Id,
  { ComputeResourceGroupId, ComputeResourceId, DatastoreGroupId, DatastoreId, Force },
  cb
) {
  if (!Id || typeof Id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin +
          "/api/customers/machines/editComputeResourceAndDs",
        {
          SId: window.VMBC.SId,
          Id,
          ComputeResourceGroupId,
          ComputeResourceId,
          DatastoreGroupId,
          DatastoreId,
          Force
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false, reason);
      });
  });
}

export default {
  GetComputeResourceAndDsNames,
  GetSelectableComputesAndDss,
  EditComputeResourceAndDs,
};
