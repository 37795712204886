import axios from "axios";

function AddTemplate(planId, { alias, templateId }, cb) {
  if (
    !planId ||
    typeof planId !== "string" ||
    !alias ||
    typeof alias !== "string" ||
    !templateId ||
    typeof templateId !== "string"
  )
    return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/addPlanTemplate",
        {
          SId: window.VMBC.SId,
          PlanId: planId,
          Alias: alias,
          TemplateId: templateId
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function GetPlanTemplates(id, cb) {
  if (!id || typeof id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/getPlanTemplates",
        { SId: window.VMBC.SId, PlanId: id },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function RemovePlanTemplate(planTemplateId, cb) {
  if (!planTemplateId || typeof planTemplateId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/removePlanTemplate",
        {
          SId: window.VMBC.SId,
          PlanTemplateId: planTemplateId
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

export default {
  AddTemplate,
  GetPlanTemplates,
  RemovePlanTemplate
};
