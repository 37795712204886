import Demo from "..";

const TableName = "Demo_Users";

var DefaultData = [
  {
    id: "0",
    username: "admin",
    password: "admin",
    fullName: "Administrator",
  },
];

function Setup() {
  var Table = [];
  Table.push(...DefaultData);
  localStorage.setItem(TableName, JSON.stringify(Table));
  Demo.Users = { Name: "Users", Data: Table };
}

export default { Setup };
