import React from "react";
import Dialog from "./Dialog";

function Dialogs(props) {
  const { Agent } = props;

  // TODO use sweetalerts2 too

  const [title, setTitle] = React.useState("");
  const [ignorable, setIgnorable] = React.useState(true);
  const [text, setText] = React.useState("");
  const [fields, setFields] = React.useState([]);
  const [actions, setActions] = React.useState([]);

  Agent.Show = function ({ Title, Ignorable, Text, Fields, Actions }) {
    setTitle(Title);
    setIgnorable(!!Ignorable);
    setText(Text);
    setFields(Fields);
    setActions(Actions);
    window.$("#vmbcDialogModal").modal({ backdrop: "static", keyboard: false });
    return () => window.$("#vmbcDialogModal").modal("hide");
  };
  Agent.Close = function () {
    window.$("#vmbcDialogModal").modal("hide");
    setTimeout(()=>{
      setTitle("");
      setText("");
      setFields("");
      setActions("");
    }, 300);
  };

  React.useEffect(() => {
    // rerender select picker on component did mount
    // window.$(".selectpicker").selectpicker("refresh");
  });

  return (
    <>
      <div
        className="modal fade"
        id="vmbcDialogModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="VMBox Dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              {typeof title === "string" ? (
                <h5 className="modal-title" id="exampleModalLabel">
                  {title}
                </h5>
              ) : (
                title
              )}
              {ignorable ? (
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              ) : undefined}
            </div>
            <div className="modal-body">
              <p className="modal-text">{text}</p>
              {fields}
            </div>
            <div className="modal-footer">
              {actions
                ? actions.map((act) => (
                    <button
                      {...act}
                      className={`btn ${act.className ? act.className : ""}`}
                    >
                      {act.text}
                    </button>
                  ))
                : undefined}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dialogs;

export {
  Dialog
}