import React from "react";
import { withRouter } from "react-router";
import logo from "./../assets/RASA-Logo.png";
// import { Grid, Paper, Typography, Button } from "@material-ui/core";
import styles from "./index.module.css";
import Login from "../Login";
import VMBC from "../VMBC";

function Entry(props) {
  VMBC.Navigator.History = props.history;
  let gotoConfig = (e) => {
    if (!!VMBC.SId || !!localStorage.getItem("LockId")) {
      props.history.push({
        pathname: "/panel",
      });
    } else {
      window.$("#loginModal").modal("show");
    }
  };

  return (
    <>
      <div className={`container-fluid`}>
        <div
          className={`row justify-content-center align-items-center ${styles.root}`}
        >
          <div className={`col-xs-12 col-sm-6 col-md-5 ${styles.paper}`}>
            <div className={`row`}>
              <VMBC.SVGs.VMBOX
                size={"200px"}
                style={{ margin: "auto", paddingTop: "25px" }}
              />
              {/* <img
                src={logo}
                style={{ width: "35%", margin: "auto", paddingBottom: "15px" }}
                alt="Rasa Telecom"
              ></img> */}
            </div>

            <div className={`row ${styles.details}`}>
              <p className={`col-12 text-center`}>
                VMBox is vCenter management system
              </p>
              <p className={`col-12 text-center`}>
                Copyright © RasaTelecom Co.
              </p>
            </div>

            <div className={`row justify-content-center align-items-center`}>
              <button className={`btn btn-dark`} onClick={gotoConfig}>
                Goto Config Panel
              </button>
            </div>
            
          </div>
        </div>
      </div>
      <Login isModal={true} />
    </>
  );

  // return (
  //   <>
  //     <Grid
  //       container
  //       direction="row"
  //       justify="center"
  //       alignItems="center"
  //       className={styles.root}
  //     >
  //       <Grid item xs={12} sm={6} md={5}>
  //         <Paper className={styles.paper}>
  //           <Grid container justify="center" style={{ width: "100%" }}>
  //             <Grid
  //               container
  //               item
  //               xs={12}
  //               justify="center"
  //               className={styles.cont}
  //             >
  //               <img
  //                 src={logo}
  //                 style={{ width: "40%", margin: "auto" }}
  //                 alt="Rasa Telecom"
  //               ></img>
  //             </Grid>

  //             <Grid
  //               container
  //               item
  //               xs={12}
  //               justify="center"
  //               className={styles.cont}
  //             >
  //               <Grid item>
  //                 <Typography
  //                   variant="body2"
  //                   style={{ paddingTop: "10px", textAlign: "center" }}
  //                 >
  //                   vmBox is vCenter management system
  //                 </Typography>
  //                 <Typography
  //                   variant="body2"
  //                   style={{ paddingTop: "10px", textAlign: "center" }}
  //                 >
  //                   Copyright © RasaTelecom Co.
  //                 </Typography>
  //               </Grid>
  //             </Grid>

  //             <Grid
  //               container
  //               item
  //               xs={12}
  //               justify="center"
  //               className={styles.cont}
  //             >
  //               <Button
  //                 variant="contained"
  //                 style={{ width: "50%", marginTop: "10px" }}
  //                 onClick={gotoConfig}
  //               >
  //                 Goto config panel
  //               </Button>
  //             </Grid>
  //             <Grid
  //               container
  //               item
  //               xs={12}
  //               justify="center"
  //               className={styles.cont}
  //             >
  //               <a
  //                 href="/SSL.zip"
  //                 style={{ fontSize: "11px", color: "black" }}
  //                 target="_blank"
  //               >
  //                 Download CA files
  //               </a>
  //             </Grid>
  //           </Grid>
  //         </Paper>
  //       </Grid>
  //     </Grid>
  //     {/* <Login
  //       isModal={true}
  //       openState={loginOpenState}
  //       close={() => {
  //         setLoginOpenState(false);
  //       }}
  //     /> */}
  //   </>
  // );
}

export default withRouter(Entry);
