import React from "react";
import VMBC from "../../../VMBC";

function English(props) {
  if (!props.SelectLanguage) {
    return null;
  }

  return (
    <div class="eulaContent">
      <div class="d-flex justify-content-between eula-head">
        <div class="eulaHeader">
          <h4>End User License Agreement</h4>
          <p>Updated Nov 19, 2020</p>
        </div>

        {props.SelectLanguage}

        <div class="get-eula-terms align-self-center d-none">
          <button class="btn btn-primary" onClick={() => {}}>
            <VMBC.SVGs.Printer />
            Print
          </button>
        </div>
      </div>

      <div class="eula-content-container">
        <section>
          <h5>Introduction</h5>
          <p>
            This End User License Agreement sets forth the provisions under
            which Rasa Telecom co is willing to grant to you, a single business
            entity, certain licenses to VMBox software. "VMBox software" is
            consisted of a Server-side Application as "VMBox Server" operating
            on any Linux Based Operating System, a Client-side WebApp for VMBox
            Configurations and other modules for communicating between VMBox
            software and other Third Party Softwares.
          </p>
          <p>
            Every License has a unique code that must be kept secret and
            contains some Packages. Every Package has a working period and a
            number of virtual machines that will be supported by VMBox during
            this period. You, owner of this License, can order, cancel or renew
            these packages by "My VMBox" Panel at{" "}
            <a href="https://my.vmbox.ir">my.vmbox.ir</a>
          </p>
          <p>
            Every virtual machine that is supported by VMBox is part of one of
            the packages of your license. When a package is expired, its virtual
            machines will be transfered to other available packages and if there
            is no available packages the remaining virtual machines will not be
            supported by VMBox anymore. Those virtual machines will not be
            terminated or powered off.
          </p>

          <p
            className={`p-2`}
            style={{
              backgroundColor: "#ffffff10",
              borderRadius: "5px",
              border: "2px dashed #ddd",
            }}
          >
            BY CLICKING "I AGREE", OR BY TAKING ANY STEP TO INSTALL OR USE THE
            SOFTWARE PRODUCT, YOU AGREE TO BE BOUND BY THE TERMS OF THIS EULA.
            IF YOU DO NOT ACCEPT THE EULA TERMS, DO NOT USE THE SOFTWARE
            PRODUCT.
          </p>
        </section>

        <section>
          <h5>Licensing of Use</h5>

          <p>
            Company grants You a revocable, non-exclusive, non-transferable,
            limited right to install and use the Application alongside other
            third party softwares licensed and controlled by You, and to access
            and use the Application to Clone, Edit or Access virtual machines on
            Virtualization and E-Commerce Softwares strictly in accordance with
            the terms and conditions of the License, the Usage Rules and any
            service agreement associated with this software.
          </p>
        </section>

        <section>
          <h5>Restrictions on Use</h5>

          <p>
            You shall use the Application strictly in accordance with the terms
            of the Related Agreements and shall not: <Tag>(a)</Tag> decompile,
            reverse engineer, disassemble, attempt to derive the source code, or
            decrypt the Application; <Tag>(b)</Tag> make any modification,
            adaptation, improvement, enhancement, translation or derivative work
            from the Application;
            <Tag>(c)</Tag> violate ant applicable laws, rules or regulations in
            connection with Your access or use of the Application;{" "}
            <Tag>(d)</Tag> remove, alter or obscure any proprietary notice
            (including any notice of copyright or trademark) of Company or its
            affiliates, partners, suppliers or the licensors of the Application;{" "}
            <Tag>(e)</Tag> use the Application for any revenue generating
            endeavor, commercial enterprise, or other purpose for which it is
            not designed or intended; <Tag>(f)</Tag> [install, use or permit the
            Application to exist on more than one virtual machine at a time or
            on any other virtual machines or computer]; <Tag>(g)</Tag>
            [distribute the Application to any other user or entity];{" "}
            <Tag>(h)</Tag> make the Application available over a network or
            other environment permitting access or use by multiple users at the
            same time; <Tag>(i)</Tag> use the Application for creating a
            product, service or software that is, directly or indirectly,
            competitive with or in any way a substitute for any services,
            product or software offered by Company; <Tag>(j)</Tag> use the
            Application to send automated queries to any servers or to send any
            unsolicited commercial e-mail; or <Tag>(k)</Tag> use any proprietary
            information or interfaces of Company or other intellectual property
            of Company in the design, development, manufacture, licensing or
            distribution of any applications, accessories or devices fir use
            with the Application.
          </p>
        </section>

        <section>
          <h5>Termination of Use</h5>
          <p>
            This agreement is effective upon your acceptance of the agreement,
            or upon your downloading, installing, accessing, and using the
            Software, even if you have not expressly accepted this Agreement.
            This Agreement shall continue in effect until expiration or
            termination of all packages of license. Packages of the license will
            be expired upon the expiration of the prepaid term unless you have
            paid all applicable fees to extend the term of each package. Upon
            any expiration of the Package, virtual machines that use the expired
            packages will be transferred to other available packages of the same
            type. remaining virtual machines with no package, will not be
            supported by Software anymore. Without prejudice to any other
            rights, this Agreement and all packages will terminate automatically
            without notice to you if you breach or fail to comply with any of
            the limitations or other requirements described herein, including
            the payment of any applicable fees, and you agree that in any such
            case Parallels may, in addition to any other remedies it may have at
            law or in equity remotely disable the Software. You may terminate
            this License Agreement at any time by providing notice of your
            decision to terminate the Agreement to Paralles and ceasing use of
            the Software and Documentation. Upon any termination or expiration
            of the Agreement for any reason, you agree to delete the Software's
            virtual machine and either return to Parallels the Software,
            Documentation, all copies thereof, and all license codes that you
            have obtained, or to destroy all such materials and provide written
            verification of such destruction to Parallels.
          </p>
        </section>

        <section>
          <h5>Limitation of Liability</h5>
          <p>
            Under no circumstances shall company or its affiliates, partners,
            suppliers, or licensors be liable for any indirect, incidental,
            consequential, special or exemplary damages arising out of or in
            connection with your access or use of or inablity to access or use
            the Software and any third party content and services, whether or
            not the damages were foreseeable and whether or not company was
            advised of the possiblity of such damages.
          </p>
        </section>

        <section>
          <h5 class="policy-info-ques">Rasa Telecom Contact</h5>
          <p>
            <a href="mailto:contact@rasatelecom.com">contact@rasatelecom.com</a>
          </p>
          <p>0413-66-77-88-0</p>
          <p>
            <a target="_blank" href="https://rasatelecom.com">RasaTelecom.com</a>
          </p>
        </section>
      </div>
    </div>
  );
}

function Tag(props) {
  return <span className={`restrictionTag`}>{props.children}</span>;
}

export default English;
