import React from "react";
import "./index.css";
import VMBC from "../../../../../VMBC";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import AssignNewIp from "./AssignNewIp";
import Demo from "../../../../../Demo";

function IpAddress(props) {
  const { vm } = props;
  const { Id } = vm;

  const [activeIpAssignments, setActiveIpAssignments] = React.useState();
  const [inActiveIpAssignments, setInActiveIpAssignments] = React.useState();
  const [disabledIpsShown, setDisabledIpsShown] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [refreshT, refresh] = React.useState(false);
  const trigerAssignNewIpFuncRef = React.useRef();

  React.useEffect(() => {
    Demo.DelayRandom(() => {
      setActiveIpAssignments([
        {
          IpAddressStr: vm.IpAddress,
          IpPoolName: "Public 1G Port IPs",
        },
      ]);
      setInActiveIpAssignments([]);
    });
  }, [refreshT]);

  const ReleaseIpAssignment = (assignment) => {
    VMBC.Alert.Show("This feature not available in demo version yet", "info");
    return;
    VMBC.Dialogs.Show({
      Title: `Are you sure you want to Release IP Address "${assignment.IpAddressStr}"?`,
      Text: "this action can not be undone.",
      Actions: [
        {
          className: "btn-outline-primary btn-sm",
          text: "No",
          onClick: () => VMBC.Dialogs.Close(),
        },
        {
          className: "btn-primary btn-sm",
          text: "Yes",
          onClick: () => {
            VMBC.Customers.VirtualMachines.ReleaseIpAssignment(
              assignment.IpAssignmentId,
              (res, err) => {
                if (err) {
                  VMBC.Alert.Show(err, "error");
                } else {
                  VMBC.Alert.Show("Done", "success");
                }
                refresh(!refreshT);
                VMBC.Dialogs.Close();
              }
            );
          },
        },
      ],
    });
  };

  const assignNewIpClicked = () => {
    VMBC.Alert.Show("This feature not available in demo version yet", "info");
    return;
    if (trigerAssignNewIpFuncRef.current != undefined) {
      setLoading(true);
      VMBC.Customers.VirtualMachines.GetSelectableIpPoolForIpAssign(
        Id,
        (res, err) => {
          if (err) {
            VMBC.Alert.Show(err, "error");
          } else {
            setLoading(false);
            trigerAssignNewIpFuncRef.current(res.Groups);
          }
        }
      );
    }
  };

  return (
    <>
      <AssignNewIp vm={vm} trigerFuncRef={trigerAssignNewIpFuncRef} />
      <div style={{ position: "relative" }}>
        {loading ? (
          <div
            className={`d-flex justify-content-center align-items-center w-100 h-100`}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              backgroundColor: "#0007",
              zIndex: "3",
            }}
          >
            <VMBC.Loader />
          </div>
        ) : undefined}
        <ScrollBar
          className={`mx-2 px-3`}
          style={{
            maxHeight: "254px",
            overflow: "auto",
          }}
        >
          <div
            className={`d-flex justify-content-center ipAddresses pt-4 pb-3`}
            style={{ flexDirection: "column" }}
          >
            {activeIpAssignments ? (
              activeIpAssignments.length === 0 ? (
                <h5 className={`text-center mt-4`}>No IP Addresses</h5>
              ) : activeIpAssignments.length === 1 ? (
                <>
                  <h4 className={`text-center`}>
                    {activeIpAssignments[0].IpAddressStr}
                  </h4>
                  <p className={`text-center`}>
                    IP Pool: {activeIpAssignments[0].IpPoolName}
                  </p>
                  <p className={`text-center`}>
                    <span
                      className={`badge outline-badge-danger`}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        ReleaseIpAssignment(activeIpAssignments[0])
                      }
                    >
                      Release this IP Address
                    </span>
                  </p>
                </>
              ) : (
                activeIpAssignments.map((assignment) => (
                  <div
                    class="ipAssignmentList"
                    style={{ width: "95%", margin: "0 auto 15px auto" }}
                  >
                    <div class="ipAssignmentItem">
                      <div class="ipAssignmentTitleContainer">
                        <div class="ipAssignmentIcon">
                          <div
                            class="icon"
                            style={{ backgroundColor: "#1b55e2" }}
                          >
                            <VMBC.SVGs.Globe />
                          </div>
                        </div>
                        <div class="ipAssignmentTitle">
                          <h4>{assignment.IpAddressStr}</h4>
                          <p class="meta-date">
                            IP Pool: {assignment.IpPoolName}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`d-flex justify-content-end itemActions ${
                          assignment.IsDefault ? "default" : ""
                        }`}
                      >
                        <VMBC.ToolTip
                          tooltip={{ delayShow: 1000, place: "left" }}
                          title={`Release IP Address`}
                        >
                          <VMBC.SVGs.RecycleBin
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#e7515a",
                              marginRight: "3px",
                              cursor: "pointer",
                            }}
                            onClick={() => ReleaseIpAssignment(assignment)}
                          />
                        </VMBC.ToolTip>

                        <VMBC.SVGs.Star
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            VMBC.Customers.VirtualMachines.SetVmIpAssignmentDefaultState(
                              {
                                VmId: Id,
                                IpAssignmentId: assignment.IpAssignmentId,
                                NewDefaultState: !assignment.IsDefault,
                              },
                              (res, err) => {
                                if (err) {
                                  VMBC.Alert.Show(err, "error");
                                } else {
                                  refresh(!refreshT);
                                }
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              )
            ) : (
              <VMBC.Loader />
            )}
          </div>
          <div
            className={`d-flex justify-content-center ${
              inActiveIpAssignments && inActiveIpAssignments.length > 0
                ? "mb-2"
                : "mb-5"
            }`}
          >
            <button className={`btn btn-primary`} onClick={assignNewIpClicked}>
              Assign New IP
            </button>
          </div>
          {disabledIpsShown &&
          inActiveIpAssignments &&
          inActiveIpAssignments.length > 0 ? (
            <div
              className={`d-flex justify-content-center ipAddresses py-3`}
              style={{ borderTop: "1px solid #aaa", flexDirection: "column" }}
            >
              {inActiveIpAssignments.map((inActiveAssignment) => (
                <div
                  class="ipAssignmentList"
                  style={{ width: "95%", margin: "0 auto 15px auto" }}
                >
                  <div class="ipAssignmentItem">
                    <div class="ipAssignmentTitleContainer">
                      <div class="ipAssignmentIcon">
                        <div
                          class="icon"
                          style={{ backgroundColor: "#3b3f5c" }}
                        >
                          <VMBC.SVGs.Globe />
                        </div>
                      </div>
                      <div class="ipAssignmentTitle">
                        <h4>{inActiveAssignment.IpAddressStr}</h4>
                        <p class="meta-date">
                          IP Pool: {inActiveAssignment.IpPoolName}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : undefined}
        </ScrollBar>
        {inActiveIpAssignments && inActiveIpAssignments.length > 0 ? (
          <div className={`d-flex justify-content-center pt-2 pb-3`}>
            <button
              className={`btn showDisabledIpAssignmentsBtn`}
              onClick={() => setDisabledIpsShown(!disabledIpsShown)}
            >
              {disabledIpsShown ? (
                <>Hide Disabled IPs</>
              ) : (
                <>Show Disabled IPs</>
              )}
              <VMBC.SVGs.ChevronDown
                style={disabledIpsShown ? { transform: "rotate(180deg)" } : {}}
              />
            </button>
          </div>
        ) : undefined}
      </div>
    </>
  );
}

export default IpAddress;
