import React from "react";
import VMBC from "../../../../../../VMBC";

export default function (props) {
  const { vm, Refresh } = props;
  const { Id, IsoFiles } = vm;

  return (
    <>
      <button
        className={`btn m-1 btn-outline-primary`}
        // onClick={() => {
        //   if (!IsoFiles || !IsoFiles.length || IsoFiles.length < 1) {
        //     VMBC.Alert.Show("No Iso files available for this machine");
        //     return;
        //   }
        //   VMBC.Dialogs.Show({
        //     Title: "Select ISO File",
        //     Ignorable: true,
        //     Text: "Select the iso file you want to insert into machine",
        //     Actions: [
        //       {
        //         onClick: () => {
        //           VMBC.Dialogs.Close();
        //         },
        //         className: "btn-sm btn-outline-secondary",
        //         text: "Cancel",
        //       },
        //     ],
        //     Fields: IsoFiles.map((iso) => (
        //       <button
        //         className={`btn m-1 btn-outline-primary`}
        //         onClick={() => {
        //           VMBC.Customers.VirtualMachines.RunAction(
        //             { Name: "InsertIso", RecordId: Id, Param: iso.Id },
        //             (res, err) => {
        //               if (err) {
        //                 VMBC.Alert.Show(err);
        //               } else {
        //                 Refresh();
        //               }
        //             }
        //           );
        //           VMBC.Dialogs.Close();
        //         }}
        //       >
        //         {iso.Name}
        //       </button>
        //     )),
        //   });
        // }}
      >
        Insert ISO
      </button>
      <button
        className={`btn m-1 btn-outline-primary`}
        // onClick={() =>
        //   VMBC.Dialogs.Show({
        //     Title: "Are you sure?",
        //     Text:
        //       "Are you sure you want to eject iso from this virtual machine?",
        //     Ignorable: true,
        //     Actions: [
        //       {
        //         onClick: () => {
        //           VMBC.Customers.VirtualMachines.RunAction(
        //             { Name: "EjectIso", RecordId: Id },
        //             (res, err) => {
        //               if (err) {
        //                 VMBC.Alert.Show(err);
        //               } else {
        //                 Refresh();
        //               }
        //             }
        //           );
        //           VMBC.Dialogs.Close();
        //         },
        //         className: "btn-sm btn-primary",
        //         text: "Yes",
        //       },
        //       {
        //         onClick: () => {
        //           VMBC.Dialogs.Close();
        //         },
        //         className: "btn-sm btn-primary",
        //         text: "No",
        //       },
        //     ],
        //   })
        // }
      >
        Eject ISO
      </button>
    </>
  );
}
