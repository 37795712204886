import Demo from "..";

const TableName = "Demo_VMPlans";

function Setup() {
  var Table = [];
  var DefaultData = [];

  var loc = ["US", "DE", "IR"];
  var Disks = ["NVMe", "HDD", "SSD"];
  var Sizes = [
    { Cpu: 1, Ram: 1, Disk: 30 },
    { Cpu: 2, Ram: 2, Disk: 50 },
    { Cpu: 5, Ram: 4, Disk: 70 },
    { Cpu: 6, Ram: 8, Disk: 100 },
  ];
  var plnCount = Demo.RandInt(5, 10);
  for (var i = 0; i <= plnCount; i++) {
    var DC = loc[Demo.RandInt(0, 3)];
    var plnSize = Sizes[Demo.RandInt(0, 4)];
    var plnDisk = Disks[Demo.RandInt(0, 3)];
    DefaultData.push({
      PlanId: i + 1,
      DC: DC,
      PlanName: `${DC}-${plnDisk}-${plnSize.Ram}G`,
      vmNamingPolicy: "{ipAddress}-{ram}-{cpu}-{templateAlias}-{vmId}",
      Profiles: plnSize,
      FreeIpAddresses: parseInt((Demo.RandInt(10, 15) / 100) * Demo.SampleSize),
      DeployableCount: parseInt((Demo.RandInt(10, 15) / 100) * Demo.SampleSize),
      DeployableDiskSpaceSize: plnSize.Disk * 1024,
      DeployableDiskSpaceDesc: `${plnSize.Disk}GB ${plnDisk}`,
      DeployableRamSwapSize: plnSize.Ram * 1024,
      DeployableRamSwapDesc: `${plnSize.Ram}G DDR4`,
    });
  }

  Table.push(...DefaultData);
  localStorage.setItem(TableName, JSON.stringify(Table));
  Demo.VMPlans = { Name: "VMPlans", Data: Table };
}

export default { Setup };
