import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import IpPools from "./IpPools";
import IpPool from "./IpPool";
window.AddToLoadingVmboxProccess(1);

function IpPoolsRoot(props) {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/`}
        exact
        children={
          <>
            <IpPools />
          </>
        }
      />
      <Route path={`${props.match.path}/:id`} children={<IpPool />} />
    </Switch>
  );
}

export default withRouter(IpPoolsRoot);
