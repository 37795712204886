import React from "react";
import IpField from "../../../../../Components/IpAddressField";
import IpAddressDataType from "../../../../../VMBC/DataTypes/IpAddress";
import VMBC from "../../../../../VMBC";
import SelectPortGroup from "./SelectPortGroup";

function AddSingle(props) {
  const { ComputeResource, OnNewIp } = props;

  const [IPA, setIPA] = React.useState();
  const [Gateway, setGateway] = React.useState();
  const [DNS1, setDNS1] = React.useState();
  const [DNS2, setDNS2] = React.useState();
  const [portGroup, setPortGroup] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  if (!OnNewIp) {
    return null;
  }

  const addBtnClicked = () => {
    if (!IPA || !Gateway) {
      VMBC.Alert.Show("Check your inputs", "warning", 3000);
      return;
    }
    if (props.ComputeResource !== false && !portGroup) {
      VMBC.Alert.Show("Please select a Port Group", "warning", 3000);
      return;
    }
    let ipObj = {
      Ip: IPA._ip,
      SubnetMask: IPA.NetPrefixToSubnetMask()._ip,
      Gateway: Gateway,
      Dns1: 0, // these dnses will be set down bellow
      Dns2: 0,
      RDNS: "",
      PortGroupId: portGroup ? portGroup.MO_Reference : "",
    };
    if (DNS1) {
      ipObj.Dns1 = DNS1;
      if (DNS2) {
        ipObj.Dns2 = DNS2;
      }
    } else if (DNS2) {
      ipObj.Dns1 = DNS2;
    }
    OnNewIp([ipObj]);
  };

  return (
    <>
      <div className={`row justify-content-center mb-2`}>
        <IpField label="IP Address" onChange={setIPA} Subnet />
      </div>
      <div className={`row justify-content-center mb-2`}>
        <IpField
          label="Gateway"
          onChange={setGateway}
          defaultValue={Number(localStorage.getItem("addIpSingleGateway"))}
        />
        <VMBC.ToolTip title={"Remember"}>
          <label class="switch s-outline s-outline-info mt-2 ml-2">
            <input
              type="checkbox"
              defaultChecked={
                localStorage.getItem("addIpSingleGateway") != undefined
              }
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem("addIpSingleGateway", Gateway);
                } else {
                  localStorage.removeItem("addIpSingleGateway");
                }
              }}
            />
            <span class="slider round"></span>
          </label>
        </VMBC.ToolTip>
      </div>
      <div className={`row justify-content-center mb-2`}>
        <IpField
          label="DNS1"
          onChange={setDNS1}
          defaultValue={Number(localStorage.getItem("addIpSingleDns1"))}
        />
        <VMBC.ToolTip title={"Remember"}>
          <label class="switch s-outline s-outline-info mt-2 ml-2">
            <input
              type="checkbox"
              defaultChecked={
                localStorage.getItem("addIpSingleDns1") != undefined
              }
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem("addIpSingleDns1", DNS1);
                } else {
                  localStorage.removeItem("addIpSingleDns1");
                }
              }}
            />
            <span class="slider round"></span>
          </label>
        </VMBC.ToolTip>
      </div>
      <div className={`row justify-content-center mb-2`}>
        <IpField
          label="DNS2"
          onChange={setDNS2}
          defaultValue={Number(localStorage.getItem("addIpSingleDns2"))}
        />
        <VMBC.ToolTip title={"Remember"}>
          <label class="switch s-outline s-outline-info mt-2 ml-2">
            <input
              type="checkbox"
              defaultChecked={
                localStorage.getItem("addIpSingleDns2") != undefined
              }
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem("addIpSingleDns2", DNS2);
                } else {
                  localStorage.removeItem("addIpSingleDns2");
                }
              }}
            />
            <span class="slider round"></span>
          </label>
        </VMBC.ToolTip>
      </div>
      {ComputeResource != undefined && ComputeResource !== false ? (
        <div className={`row justify-content-center mb-2`}>
          <SelectPortGroup
            key={refreshT ? 1 : 0}
            ComputeResource={ComputeResource}
            onChange={(ref) => setPortGroup(ref.pg)}
          />
          <VMBC.SVGs.RefreshCCW
            onClick={() => refresh(!refreshT)}
            size={20}
            style={{ cursor: "pointer", margin: "12px 0 0 8px" }}
          />
        </div>
      ) : undefined}
      <div className={`row justify-content-center`}>
        <button className={`btn btn-primary btn-sm`} onClick={addBtnClicked}>
          Add
        </button>
      </div>
    </>
  );
}

export default AddSingle;
