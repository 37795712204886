import React from "react";
import VMBC from "../../../VMBC";

function PlanCondition(props) {
  const { plan } = props;

  return (
    <tr style={{ border: "1px dashed grey" }}>
      <td>
        <div className="td-content text-center customer-name">
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {plan.PlanName}
            {plan.PlanDisabled ? (
              <VMBC.ToolTip title={`Disabled`}>
                <span class="badge outline-badge-danger ml-2">
                  <VMBC.SVGs.Lock size={14} />
                </span>
              </VMBC.ToolTip>
            ) : undefined}
          </span>
        </div>
      </td>
      <td>
        <div className="td-content text-center">{plan.FreeIpAddresses}</div>
      </td>

      <td>
        <div className="td-content text-center">{plan.DeployableCount}</div>
      </td>

      <td>
        <div className="td-content text-center">
          <VMBC.ToolTip
            tooltip={{
              place: "left",
            }}
            title={
              <span style={{ padding: "5px" }}>
                {GetSpaceSize(plan.DeployableDiskSpaceSize)} Disk Space From{" "}
                {plan.DeployableDiskSpaceDesc}
                <br />+<br />
                {GetSpaceSize(plan.DeployableRamSwapSize)} RAM for Swap From{" "}
                {plan.DeployableRamSwapDesc}
              </span>
            }
          >
            {GetSpaceSize(
              plan.DeployableDiskSpaceSize + plan.DeployableRamSwapSize
            )}
          </VMBC.ToolTip>
        </div>
      </td>

      <td>
        <div className="td-content text-center">
          <VMBC.A href={`/panel/plans/list/${plan.PlanId}`}>
            <span class="badge outline-badge-primary">Details</span>
          </VMBC.A>
        </div>
      </td>
    </tr>
  );
}

function GetSpaceSize(MB) {
  if (MB < 1024) {
    return `${MB} MB`;
  }
  var GB = MB / 1024;
  if (GB < 1024) {
    return `${GB.toFixed(2)} GB`;
  }
  var TB = GB / 1024;
  return `${TB.toFixed(2)} TB`;
}

export default PlanCondition;
