import React from "react";
import { withRouter, Switch, Route } from "react-router";

import PlansList from "./PlansList";
import Plan from "./Plan";
window.AddToLoadingVmboxProccess(1);

function List(props) {
  return (
    <>
      <Switch>
        <Route exact path={`${props.match.path}/`} children={<PlansList />} />
        <Route path={`${props.match.path}/:id`} exact children={<Plan />} />
      </Switch>
    </>
  );
}

export default withRouter(List);
