import DefaultData from "./DefaultData";
import Demo from "..";

const TableName = "Demo_Customers";

function Setup() {
  var Table = [];
  Table.push(...DefaultData);
  localStorage.setItem(TableName, JSON.stringify(Table));
  Demo.Customers = { Name: "Customers", Data: Table };
}

export default { Setup };
