import WindowsLogo from "./OsLogos/Windows.png";
import CentOsLogo from "./OsLogos/CentOs.png";
import DebianLogo from "./OsLogos/Debian.png";
import UbuntuLogo from "./OsLogos/Ubuntu.png";

const OsTypes = [
  "Windows",
  "CentOs7",
  // "Debian",
  "Ubuntu18"
];

const Logos = {
  "Windows": WindowsLogo,
  "CentOs7": CentOsLogo,
  // "Debian": DebianLogo,
  "Ubuntu18": UbuntuLogo
}; 

export default {
  OsTypes,
  Logos
};
