import React from "react";
import VMBC from "../../../../VMBC";

function RecentActions(props) {
  const { plan } = props;

  const [actions, setActions] = React.useState();
  const [refreshT, refresh] = React.useState(false);
  const Refresh = () => refresh(!refreshT);

  React.useEffect(() => {
    VMBC.Plans.Statistics.GetPlanRecentActions(plan.PlanId, (res, err) => {
      if (err) {
        VMBC.Alert.Show(err, "error");
      } else {
        var now = new Date();
        setActions(
          [...res].map((action) => ({
            ...action,
            By:
              action.ActorType === "C" ? (
                <VMBC.A href={action.WhmcsClientLink}>
                  {action.ClientName}
                </VMBC.A>
              ) : action.ActorType === "W" ? (
                "WHMCS"
              ) : action.ActorType === "U" ? (
                "Admin"
              ) : action.ActorType === "S" ? (
                "Synchronizer"
              ) : (
                `Unknown (${action.ActorType})`
              ),
            Time:
              now - action.StartDate < 3600 ? (
                VMBC.Time.SecondsToElapsed(now - action.StartDate)
              ) : (
                <VMBC.ToolTip
                  title={VMBC.Time.UnixToDateString(action.StartDate)}
                >
                  {VMBC.Time.UnixToDateString(action.StartDate, {
                    OnlyDate: true,
                  })}
                </VMBC.ToolTip>
              ),
            State:
              action.ActionState === "Pending" ? (
                <span class="badge outline-badge-primary">Pending</span>
              ) : action.ActionState === "Failed" ? (
                <span class="badge outline-badge-danger">Failed</span>
              ) : action.ActionState === "Done" ? (
                <span class="badge outline-badge-success">Done</span>
              ) : (
                <span class="badge outline-badge-dark">{`Unknown (${action.State})`}</span>
              ),
          }))
        );
      }
    });
  }, refreshT);

  return (
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-table-two">
        <div class="widget-heading">
          <h5 class="">Recent Activities</h5>
        </div>

        <div class="widget-content">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="th-content">Action</div>
                  </th>
                  <th>
                    <div class="th-content th-heading text-center">By</div>
                  </th>
                  <th>
                    <div class="th-content th-heading text-center">Time</div>
                  </th>
                  <th>
                    <div class="th-content">Service ID</div>
                  </th>
                  <th>
                    <div class="th-content">State</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {actions
                  ? actions.map((act) => (
                      <tr>
                        <td>
                          <div class="td-content product-name">
                            {Actions[act.ActionName]
                              ? Actions[act.ActionName].Name
                              : act.ActionName}
                          </div>
                        </td>
                        <td>
                          <div class="td-content text-center">
                            <span class="pricing">{act.By}</span>
                          </div>
                        </td>
                        <td>
                          <div class="td-content text-center">
                            <span class="discount-pricing">{act.Time}</span>
                          </div>
                        </td>
                        <td>
                          <div
                            class="td-content"
                            onContextMenu={(e) => {
                              e.preventDefault();
                              VMBC.Dialogs.Show({
                                Title: act.VmName,
                                Fields: (
                                  <>
                                    <div
                                      className={`d-flex align-items-center justify-content-center`}
                                      style={{ flexDirection: "column" }}
                                    >
                                      <button
                                        className={`btn btn-outline-primary mb-2`}
                                        onClick={() => {
                                          var win = window.open(
                                            act.WhmcsClientLink,
                                            "_blank"
                                          );
                                          win.focus();
                                          VMBC.Dialogs.Close();
                                        }}
                                      >
                                        Click to see Client details at WHMCS
                                      </button>
                                      <button
                                        className={`btn btn-outline-primary mb-2`}
                                        onClick={() => {
                                          var win = window.open(
                                            act.WhmcsServiceLink,
                                            "_blank"
                                          );
                                          win.focus();
                                          VMBC.Dialogs.Close();
                                        }}
                                      >
                                        Click to see Product details at WHMCS
                                      </button>
                                      <button
                                        className={`btn btn-outline-primary mb-2`}
                                        onClick={() => {
                                          VMBC.Navigate(
                                            VMBC.Navigator.History,
                                            `/panel/customers/machines/${act.VmRecordId}`
                                          );
                                          VMBC.Dialogs.Close();
                                        }}
                                      >
                                        Click to see Service details at VMBOX
                                      </button>
                                    </div>
                                  </>
                                ),
                                Ignorable: true,
                                Actions: [
                                  {
                                    className: "btn-outline-primary btn-sm",
                                    text: "Close",
                                    onClick: () => {
                                      VMBC.Dialogs.Close();
                                    },
                                  },
                                ],
                              });
                              return false;
                            }}
                          >
                            <VMBC.A target="_blank" href={act.WhmcsServiceLink}>
                              #{act.ServiceId}
                            </VMBC.A>
                          </div>
                        </td>
                        <td>
                          <div class="td-content">{act.State}</div>
                        </td>
                      </tr>
                    ))
                  : undefined}
              </tbody>
            </table>
            {actions && actions.length === 0 ? (
              <h6 className={`text-center py-3`}>No Recent Actions for this Plan</h6>
            ) : undefined}
          </div>
        </div>
      </div>
    </div>
  );
}

const Actions = {
  "Acquire WMKS Ticket": {
    IconContent: <VMBC.SVGs.AirPlay />,
    Name: "Acquire WMKS Ticket",
  },
  PowerOn: {
    IconContent: <VMBC.SVGs.Power />,
    Name: "Power On",
  },
  PowerOff: {
    IconContent: <VMBC.SVGs.Power />,
    Name: "Power Off",
  },
  NotFinding: {
    IconContent: <VMBC.SVGs.Frown />,
    Name: "VM Not Found",
  },
  Deploy: {
    IconContent: <VMBC.SVGs.Template />,
  },
  Delete: {
    IconContent: <VMBC.SVGs.RecycleBin />,
  },
  SetupOS: {
    IconContent: <VMBC.SVGs.Zap />,
    Name: "Configuring Guest OS",
  },
};

export default RecentActions;
