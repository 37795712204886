import React from "react";
import PropTypes from "prop-types";
import Table from "../../../../Components/Table";
import IpField from "../../../../Components/IpAddressField";
import AddIpAddress from "./AddIpAddress";
import VMBC from "../../../../VMBC";
import IpAddressDataType from "../../../../VMBC/DataTypes/IpAddress";

function IpPoolTable(props) {
  const { IpPool, refresh, onSelect } = props;

  const [addIpAddressShown, setAddIpAddressShown] = React.useState(false);

  const OnNewIp = (arr) => {
    IpPool.AddNewIpAddresses(arr, (err) => {
      if (err) {
        VMBC.Alert.Show(err, "error", 3000);
      } else {
        if (refresh != undefined && typeof refresh === "function") {
          refresh();
        }
      }
    });
  };

  React.useEffect(() => {
    if (addIpAddressShown) {
      window
        .$("#addIpAddressModal")
        .modal({ backdrop: "static", keyboard: false });
    } else {
      window.$("#addIpAddressModal").modal("hide");
    }
  }, [addIpAddressShown]);

  console.log("IpPoool", IpPool);

  return (
    <>
      <div className="widget mt-4">
        <div className="widget-content vertical-line-pill">
          <div className="row">
            <div className="col-xl-12 col-md-12 col-sm-12 col-12">
              <h4>{IpPool ? IpPool.Name : "Loading..."}</h4>
            </div>
          </div>
          <div className="row mb-4 mt-3">
            <div className="col-12">
              <Table
                columns={[
                  {
                    title: "IP",
                    fieldName: "IP_STR",
                    editComponent: (rowData, onChange) => {
                      return (
                        <IpField
                          className={`mb-1 input-group-sm`}
                          style={{ width: "max-content" }}
                          label="IP"
                          Subnet
                          onChange={onChange}
                          defaultValue={rowData.IP_STR}
                          defaultNetPrefix={rowData.SubnetMask.SubnetMaskToNetPrefix(
                            rowData.SubnetMask
                          )}
                        />
                      );
                    },
                  },
                  {
                    title: "Subnet",
                    fieldName: "SubnetMask_STR",
                    editable: false,
                  },
                  {
                    title: "Gateway",
                    fieldName: "Gateway_STR",
                    editComponent: (rowData, onChange) => {
                      return (
                        <IpField
                          className={`mb-1 input-group-sm`}
                          style={{ width: "max-content" }}
                          label="Gateway"
                          onChange={onChange}
                          defaultValue={rowData.Gateway_STR}
                        />
                      );
                    },
                  },
                  {
                    title: "Assigned To",
                    fieldName: "AssignmentId",
                    editable: false,
                    render: (rowData) => {
                      if (rowData.AssignmentId !== "0") {
                        return (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              var win = window.open(
                                `/panel/customers/machines/${rowData.AssignmentId}`,
                                "_blank"
                              );
                              win.focus();
                            }}
                          >
                            {rowData.AssignmentName}
                          </span>
                        );
                      }
                      return "Free";
                    },
                  },
                  {
                    title: "Port Group",
                    fieldName: "PortGroupName",
                    editable: false,
                    hidden: IpPool && IpPool.IsGeneral,
                  },
                  {
                    title: "DNS1",
                    fieldName: "Dns1_STR",
                    hidden: true,
                    editable: true,
                    editComponent: (rowData, onChange) => {
                      return (
                        <IpField
                          className={`mb-1 input-group-sm`}
                          style={{ width: "max-content" }}
                          label="DNS1"
                          onChange={onChange}
                          defaultValue={rowData.Dns1_STR}
                        />
                      );
                    },
                  },
                  {
                    title: "DNS2",
                    fieldName: "Dns2_STR",
                    hidden: true,
                    editable: true,
                    editComponent: (rowData, onChange) => {
                      return (
                        <IpField
                          className={`mb-1 input-group-sm`}
                          style={{ width: "max-content" }}
                          label="DNS2"
                          onChange={onChange}
                          defaultValue={rowData.Dns2_STR}
                        />
                      );
                    },
                  },
                  {
                    title: "MAC",
                    fieldName: "Mac",
                    hidden: true,
                    editable: true,
                    editValidator: (newValue) => {
                      if (newValue != undefined && newValue !== "") {
                        var sections = newValue.split(":");
                        if (sections.length != 6) {
                          return "Invalid Mac Address";
                        } else if (
                          [...sections]
                            .map((section) =>
                              section.length < 3 && section !== "" ? "1" : "0"
                            )
                            .join("")
                            .indexOf("0") != -1
                        ) {
                          return "Invalid Mac Address";
                        }
                      }
                    },
                  },
                  {
                    title: "RDNS",
                    fieldName: "RDNS",
                    hidden: true,
                    editable: true,
                  },
                ]}
                data={IpPool ? IpPool.IpAddresses : null}
                SearchFieldNames={[
                  "IP_STR",
                  "SubnetMask_STR",
                  "Gateway_STR",
                  "AssignmentName",
                  // {
                  //   fieldName: "AssignmentName",
                  //   SearchFunction: (rowData, searchText) => {
                  //     if (rowData.AssignmentId !== "0") {
                  //       if (
                  //         ("" + rowData.AssignmentName)
                  //           .toLowerCase()
                  //           .includes(("" + searchText).toLowerCase())
                  //       ) {
                  //         return true;
                  //       }
                  //     } else if (
                  //       "free".includes(("" + searchText).toLowerCase())
                  //     ) {
                  //       return true;
                  //     }
                  //     return false;
                  //   },
                  // },
                  "PortGroupName",
                ]}
                actions={{
                  onDelete: {
                    Title: "Delete",
                    EventHandler: (rowData, done) => {
                      rowData._tableRaw.Delete((error) => {
                        if (error) {
                          VMBC.Alert.Show(error, "error", 6000);
                        } else if (refresh != undefined) {
                          refresh();
                        }
                        done();
                      });
                    },
                  },
                  onEdit: {
                    Title: "Edit Details",
                    EventHandler: (oldData, newData, done) => {
                      oldData._tableRaw.IP = new IpAddressDataType(
                        newData.IP_STR.ToString()
                      );
                      oldData._tableRaw.SubnetMask = new IpAddressDataType(
                        newData.IP_STR.NetPrefixToSubnetMask().ToString()
                      );
                      oldData._tableRaw.Gateway = new IpAddressDataType(
                        newData.Gateway_STR
                      );
                      oldData._tableRaw.Dns1 = new IpAddressDataType(
                        newData.Dns1_STR
                      );
                      oldData._tableRaw.Dns2 = new IpAddressDataType(
                        newData.Dns2_STR
                      );
                      oldData._tableRaw.RDNS = newData.RDNS;
                      oldData._tableRaw.Mac = newData.Mac;

                      oldData._tableRaw.SubmitChanges((err) => {
                        if (err) {
                          VMBC.Alert.Show(err, "info");
                        } else {
                          VMBC.Alert.Show("Done", "success");
                        }
                        done();
                      });
                    },
                  },
                  FreeActions: [
                    {
                      Title: "Refresh",
                      onClick: () => {
                        if (
                          refresh != undefined &&
                          typeof refresh === "function"
                        ) {
                          refresh();
                        }
                      },
                      Icon: (
                        <VMBC.SVGs.RefreshCCW style={{ position: "initial" }} />
                      ),
                    },
                  ],
                  onAdd: {
                    Title: "Add New IP Address",
                    onClick: () => {
                      setAddIpAddressShown(true);
                      window
                        .$("#addIpAddressModal")
                        .modal({ backdrop: "static", keyboard: false });
                    },
                  },
                  Others: onSelect
                    ? [
                        {
                          Icon: (rowData) => (
                            <span
                              onClick={() =>
                                typeof onSelect === "function" &&
                                onSelect(rowData)
                              }
                            >
                              <VMBC.ToolTip title={`Select`}>
                                <VMBC.SVGs.CheckIcon
                                  style={{
                                    marginTop: "-4px",
                                    marginLeft: "3px",
                                    width: "24px",
                                    height: "24px",
                                  }}
                                />
                              </VMBC.ToolTip>
                            </span>
                          ),
                        },
                      ]
                    : undefined,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <VMBC.Dialogs.Dialog id={"addIpAddressModal"}>
        <div className="modal-body">
          {addIpAddressShown ? (
            <AddIpAddress
              ComputeResource={IpPool ? IpPool.ComputeResource : false}
              OnNewIp={OnNewIp}
              Close={() => setAddIpAddressShown(false)}
            />
          ) : undefined}
        </div>
        <div className="modal-footer">
          <button
            className={`btn btn-outline-primary btn-sm`}
            onClick={() => {
              setAddIpAddressShown(false);
            }}
          >
            Close
          </button>
        </div>
      </VMBC.Dialogs.Dialog>
    </>
  );
}

IpPoolTable.propTypes = {
  IpPool: PropTypes.shape({
    AddNewIpAddresses: PropTypes.func,
    IsGeneral: PropTypes.bool,
    Name: PropTypes.string,
    IpAddresses: PropTypes.array, // TODO: explain more
    ComputeResource: PropTypes.object, // TODO: explain more
  }),
};

export default IpPoolTable;
