import React from "react";
import { withRouter } from "react-router";
import VMBC from "../../../VMBC";

import AddTemplate from "./AddTemplate";
import TemplateCard from "./TemplateCard";

function Templates(props) {
  const [datacenters, setDatacenters] = React.useState();

  React.useEffect(() => {
    VMBC.Inventory.GetDatacenters((DCs) => setDatacenters(DCs));
  }, []);

  return (
    <>
      {datacenters ? (
        datacenters.length > 0 ? (
          datacenters.map((dc, i) => (
            <Datacenter
              Datacenter={dc}
              key={i}
              EditId={props.edit ? props.match.params.id : false}
            />
          ))
        ) : (
          <div className="widget mt-4 text-center py-4">
            <h6 className="align-self-center" style={{ margin: "auto" }}>
              No Datacenters
            </h6>
          </div>
        )
      ) : (
        <div className="widget mt-4 text-center py-4">
          <div
            className="spinner-border align-self-center loader-lg"
            style={{ width: "3rem", height: "3rem" }}
          ></div>
        </div>
      )}
    </>
  );
}

function Datacenter(props) {
  const { Datacenter } = props;

  const [templates, setTemplates] = React.useState(Datacenter.templates);
  const [refreshS, refresh] = React.useState(false);

  React.useEffect(() => {
    Datacenter.GetTemplates((temps) => {
      if (temps != null) {
        setTemplates(temps);
      } else {
        setTemplates([]);
      }
      Datacenter.GetTemplateVMs(() =>
        Datacenter.templateVMsLoaded
          ? Datacenter.templateVMsLoaded()
          : undefined
      );
    });
  }, [refreshS]);

  const onEdit = (editObj) => {
    Datacenter.EditTemplate(
      editObj.TemplateId,
      VMBC.Enums.OsTypes[editObj.OsType],
      editObj.Alias,
      {
        Password: editObj.Password,
        EtherName: editObj.EtherName,
      },
      () => {
        refresh(!refreshS);
      }
    );
    return true;
  };

  const onDelete = (tempId) => {
    Datacenter.DeleteTemplate(tempId, () => {
      refresh(!refreshS);
    });
  };

  const onAdd = (temp) => {
    for (var i = 0; i < templates.length; i++) {
      if (templates[i].Alias == temp.alias) {
        VMBC.Alert.Show(`Alias '${temp.alias}' is duplicated`, "error", 3000);
        return false;
      }
    }
    Datacenter.AddNewTemplate(
      temp.templateId,
      VMBC.Enums.OsTypes[temp.osType],
      temp.templateName,
      temp.alias,
      VMBC.Config.OsTypeDefaultOptions[VMBC.Enums.OsTypes[temp.osType]],
      () => {
        refresh(!refreshS);
      }
    );
    return true;
  };

  return (
    <>
      <div className="widget mt-4">
        <div className="widget-content">
          <div className="row justify-content-between px-3">
            <span>
              <h4>{Datacenter.Name}</h4>
            </span>
            <AddTemplate Datacenter={Datacenter} onSelect={onAdd} />
          </div>
          <div className="row justify-content-center">
            {templates ? (
              <>
                {templates.length
                  ? templates.map((temp) => (
                      <TemplateCard
                        Alias={temp.Alias}
                        OsType={temp.OsType}
                        TemplateName={temp.TemplateName}
                        TemplateId={temp.TemplateId}
                        Password={temp.Password}
                        EtherName={temp.EtherName}
                        onEdit={onEdit}
                        editable={
                          props.EditId
                            ? props.EditId === temp.TemplateId
                            : false
                        }
                        onDelete={onDelete}
                      />
                    ))
                  : undefined}
              </>
            ) : undefined}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Templates);
