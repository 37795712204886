


function IpAddress() {

  // public methods
  this.ToString = (isSubnet) => {
    var octets = this.ToOctets(isSubnet);
    let Str = "" + octets[0] + "." + octets[1] + "." + octets[2] + "." + octets[3];
    if (!isSubnet) return Str;
    return Str + "/" + this._netPrefix;
  }
  this.ToOctets = (isSubnet) => {
    var tempIp = this._toUint(this._ip);
    let octet3 = tempIp & 255;
    tempIp = this._toUint(tempIp >> 8);
    let octet2 = tempIp & 255;
    tempIp = this._toUint(tempIp >> 8);
    let octet1 = tempIp & 255;
    tempIp = this._toUint(tempIp >> 8);
    let octet0 = tempIp & 255;
    if(!isSubnet){
      return [octet0, octet1, octet2, octet3];
    }
    return [octet0, octet1, octet2, octet3, this._netPrefix];
  }
  this.Parse = (IpStr, NetPrefix) => {
    if (!IpStr) {
      return new IpAddress(0);
    }
    let OctetArray = IpStr.split(".");
    let ipNum = this._toUint(0);
    if (OctetArray[0]) {
      ipNum += this._toUint(this._toUint(Number(OctetArray[0])) << 24);
    }
    if (OctetArray[1]) {
      ipNum += this._toUint(this._toUint(Number(OctetArray[1])) << 16);
    }
    if (OctetArray[2]) {
      ipNum += this._toUint(this._toUint(Number(OctetArray[2])) << 8);
    }
    if (OctetArray[3]) {
      ipNum += this._toUint(this._toUint(Number(OctetArray[3])) << 0);
    }
    return new IpAddress(ipNum, NetPrefix && typeof NetPrefix === "number" && NetPrefix > 0 && NetPrefix < 32 ? NetPrefix : 24);
  }

  this.SubnetMaskToNetPrefix = ipAdd => {
    let subnetMaskNum = this._toUint(ipAdd._ip);
    let zerosAhead = 0;
    while (subnetMaskNum > 0 && zerosAhead !== 31) {
      if ((subnetMaskNum & 1) === 1) {
        break;
      }
      zerosAhead++;
      subnetMaskNum = this._toUint(subnetMaskNum >> 1);
    }
    return 32 - zerosAhead;
  }
  this.NetPrefixToSubnetMask = netPref => {
    let ipNum = this._toUint(1);
    if (!netPref) netPref = this._netPrefix;
    let tempNetPref = netPref;
    while (tempNetPref > 0) {
      tempNetPref--;
      if (tempNetPref === 0) {
        break;
      }
      ipNum = this._toUint(ipNum << 1);
      ipNum++;
    }
    ipNum = this._toUint(ipNum << (32 - netPref));
    return new IpAddress(ipNum);
  }
  this.ToSubnet = () => {
    return (new IpAddress(this._ip & this.NetPrefixToSubnetMask()._ip, this._netPrefix)).ToString(true);
  }
  this.GetAllIpAddressesInSubnet = () => {
    let Ret = [];
    let SubnetMask = this.NetPrefixToSubnetMask();
    let NetworkId = this._toUint(this._ip & SubnetMask._ip);
    const ConstantNetworkId = NetworkId;
    while (ConstantNetworkId === this._toUint((NetworkId + 1) & SubnetMask._ip)) {
      NetworkId++;
      Ret = Ret.concat([new IpAddress(NetworkId, this._netPrefix)])
    }
    return Ret;
  }






  // private methods
  this._toUint = SInt => {
    let uint32Arri = new Uint32Array(1);
    uint32Arri[0] = SInt;
    return uint32Arri[0];
  }



  // public properties
  this._ip = this._toUint(0);
  this._netPrefix = 24;


  // private properties
  this._VMBC_Type = "VMBC_IpAddress";







  // constructors

  // IpAddress( int32 Ip )
  if (arguments.length === 1 && typeof arguments[0] === "number") {
    this._ip = this._toUint(arguments[0]);
    this._netPrefix = 24;
    return;
  }

  // IpAddress( string Ip )
  if (arguments.length === 1 && typeof arguments[0] === "string") {
    let tempIp = this.Parse(arguments[0]);
    this._ip = tempIp._ip;
    this._netPrefix = 24;
    return;
  }

  // IpAddress( int32 Ip, int netprefix )
  if (arguments.length === 2 && typeof arguments[0] === "number" && typeof arguments[1] === "number" && !arguments[1]._VMBC_Type) {
    this._ip = this._toUint(arguments[0]);
    this._netPrefix = arguments[1];
    return;
  }

  // IpAddress( string Ip, int netprefix )
  if (arguments.length === 2 && typeof arguments[0] === "string" && typeof arguments[1] === "number" && !arguments[1]._VMBC_Type) {
    let tempIp = this.Parse(arguments[0]);
    this._ip = tempIp._ip;
    this._netPrefix = arguments[1];
    return;
  }

  // IpAddress( int32 Ip, IpAddress subnetmask )
  if (arguments.length === 2 && typeof arguments[0] === "number" && arguments[1]._VMBC_Type && arguments[1]._VMBC_Type === "VMBC_IpAddress") {
    this._ip = this._toUint(arguments[0]);
    this._netPrefix = this.SubnetMaskToNetPrefix(arguments[1]);
    return;
  }

  // IpAddress( string Ip, IpAddress subnetmask )
  if (arguments.length === 2 && typeof arguments[0] === "string" && arguments[1]._VMBC_Type && arguments[1]._VMBC_Type === "VMBC_IpAddress") {
    let tempIp = this.Parse(arguments[0]);
    this._ip = tempIp._ip;
    this._netPrefix = this.SubnetMaskToNetPrefix(arguments[1]);
    return;
  }
}

export default IpAddress;