import React from "react";
import VMBC from "../../../../../VMBC";
import { IpPoolTable } from "../../../../Plans/IpPools/IpPool";

function AssignNewIp(props) {
  const { trigerFuncRef, vm, Refresh } = props;
  const { Misc } = vm;

  const [ipPools, setIpPools] = React.useState();
  const [selectedIpPoolIndex, setSelectedIpPoolIndex] = React.useState();
  const [selectedIpPool, setSelectedIpPool] = React.useState();

  const [selectedIpAddress, setSelectedIpAddress] = React.useState();

  const [loading, setLoading] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState();

  React.useEffect(() => {
    if (trigerFuncRef) {
      trigerFuncRef.current = (Groups) => {
        setIpPools(Groups);
        Groups.forEach((grp) =>
          grp.GroupIpPools.forEach((ippl) => {
            ippl.GroupId = grp.GroupId;
          })
        );
        window.$("#assignNewIpModal").modal("show");
      };
    }
  }, []);

  React.useEffect(() => {
    window.$(".selectIpPoolForAssignSelectPicker").selectpicker("refresh");
  });

  const SelectedIpPoolChange = (e) => {
    if (!!confirmMessage) {
      VMBC.Alert.Show(
        "Changing the Options will have no effect, Please reopen the page.",
        "warning"
      );
      return;
    }
    var [groupIndex, ipplIndex] = e.target.value.split("-");
    if (ipPools[groupIndex] && ipPools[groupIndex].GroupIpPools[ipplIndex]) {
      setSelectedIpPool(ipPools[groupIndex].GroupIpPools[ipplIndex]);
      setSelectedIpPoolIndex(e.target.value);
      setSelectedIpAddress();
    }
  };

  const IpAddressSelected = (IpAddress) => {
    if (
      typeof IpAddress.AssignmentId === "string" &&
      IpAddress.AssignmentId != "0"
    ) {
      VMBC.Alert.Show("This IP Address is already assigned.");
      return;
    }
    setSelectedIpAddress(IpAddress);
    window.$("#selectIpAddress").modal("hide");
  };

  const doneClicked = () => {
    setLoading(true);
    VMBC.Customers.VirtualMachines.AssignNewIpToVM(
      {
        VmId: vm.Id,
        GroupId: selectedIpPool.GroupId,
        IpPoolId: selectedIpPool.IpPoolId,
        IpAddressId: selectedIpAddress.Id,
      },
      (res, err) => {
        if (err) {
          VMBC.Alert.Show(err, "error");
        } else {
          VMBC.Alert.Show("Done", "success");
          window.$("#assignNewIpModal").modal("hide");
          setTimeout(() => window.location.reload(), 1000);
        }
        setLoading(false);
      }
    );
  };

  return (
    <>
      <div
        className="modal fade"
        id="assignNewIpModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AssignNewIp"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ position: "relative" }}>
            {loading ? (
              <div
                className={`d-flex justify-content-center align-items-center w-100 h-100`}
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  backgroundColor: "#0007",
                  zIndex: "3",
                }}
              >
                <VMBC.Loader />
              </div>
            ) : undefined}
            <div className="modal-header">
              <h5 className="modal-title">Assign New IP</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <VMBC.SVGs.CloseXIcon />
              </button>
            </div>
            <div className="modal-body">
              {Misc.IpAddress || Misc.MoreIpAddresses ? (
                <div
                  className={`d-flex justify-content-end`}
                  style={{ cursor: "pointer" }}
                >
                  <VMBC.ToolTip
                    tooltip={{
                      delayShow: "1000",
                      place: "left",
                    }}
                    title={
                      Misc.MoreIpAddresses
                        ? [
                            <>
                              Others:<br></br>
                            </>,
                            ...Misc.MoreIpAddresses.map((ip) => (
                              <>
                                {ip}
                                <br></br>
                              </>
                            )),
                          ]
                        : "No more IP Addresses"
                    }
                  >
                    <span className={`badge badge-info`}>
                      Possible IP Addresses:{" "}
                      {Misc.IpAddress ? Misc.IpAddress : "Unknown"}
                    </span>
                  </VMBC.ToolTip>
                </div>
              ) : undefined}
              <div className="input-group d-flex justify-content-center mx-auto mt-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">IP Pool</div>
                </div>

                <select
                  className="selectIpPoolForAssignSelectPicker"
                  title="Choose an Option"
                  data-width="fit"
                  onChange={SelectedIpPoolChange}
                  value={selectedIpPoolIndex}
                >
                  {ipPools
                    ? ipPools.map((group, gId) => (
                        <optgroup label={group.GroupName}>
                          {group.GroupIpPools &&
                          group.GroupIpPools.length !== 0 ? (
                            group.GroupIpPools.map((ippl, cId) => (
                              <option
                                value={`${gId}-${cId}`}
                                disabled={ippl.Disabled}
                              >
                                {ippl.IpPoolName}
                              </option>
                            ))
                          ) : (
                            <option value={`${gId}-noIppl`} disabled={true}>
                              No IP Pools
                            </option>
                          )}
                        </optgroup>
                      ))
                    : undefined}
                </select>
              </div>

              {selectedIpPool ? (
                <>
                  <div
                    className={`input-group d-flex justify-content-center mx-auto mt-3`}
                    style={{ position: "relative" }}
                  >
                    <div className="input-group-prepend">
                      <div className="input-group-text">IP Address</div>
                    </div>

                    <span
                      className={`d-flex justify-content-center align-items-center px-4`}
                      style={{
                        backgroundColor: "#1b2e4b",
                        fontSize: "15px",
                        color: "#009688",
                      }}
                    >
                      {selectedIpAddress
                        ? selectedIpAddress.IP_STR
                        : "Not Selected"}
                    </span>

                    <div className={`input-group-append`}>
                      <button
                        className={`btn btn-primary`}
                        onClick={() => {
                          window.$("#selectIpAddress").modal("show");
                        }}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                  <IpAddresses
                    IpPoolId={selectedIpPool.IpPoolId}
                    onSelect={IpAddressSelected}
                  />
                </>
              ) : undefined}
              {!!confirmMessage ? (
                <div>
                  <h6>Are you sure?</h6>
                  <p>{confirmMessage}</p>
                </div>
              ) : undefined}
            </div>
            <div className="modal-footer">
              <button
                className={`btn btn-outline-primary btn-sm`}
                onClick={() => {
                  window.$("#assignNewIpModal").modal("hide");
                }}
              >
                {!!confirmMessage ? "No" : "Close"}
              </button>
              <button
                className={`btn btn-primary btn-sm`}
                onClick={() => selectedIpAddress && !loading && doneClicked()}
                disabled={!selectedIpAddress}
              >
                {!!confirmMessage ? "Yes" : "Done"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function IpAddresses(props) {
  const { IpPoolId, onSelect } = props;

  const [ipPool, setIpPool] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setIpPool();
    VMBC.Inventory.GetIpPool(IpPoolId, (ipp) => {
      console.log("IPP", ipp)
      if (!ipp.IsGeneral) {
        ipp.ComputeResource = new VMBC.Inventory.ComputeResource(
          ipp.ComputeResourceId
        );
        ipp.ComputeResource.GetNetworks();
      } else {
        ipp.ComputeResource = false;
      }
      // filter IpAddresses that are already assigned
      var IpAddresses = [];
      IpAddresses = ipp.IpAddresses.filter((addr) => addr.AssignmentId === "0");
      ipp.IpAddresses = IpAddresses;
      setIpPool(ipp);
    });
  }, [refreshT, IpPoolId]);

  return (
    <>
      <div
        className="modal fade"
        id="selectIpAddress"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="selectIpAddress"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            {/* <div className="modal-header">
              <h5 className="modal-title">Assign New IP</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <VMBC.SVGs.CloseXIcon />
              </button>
            </div>
            <div className="modal-body">
              
            </div>
            <div className="modal-footer">
              <button
                className={`btn btn-outline-primary btn-sm`}
                onClick={() => {
                  window.$("#selectIpAddress").modal("hide");
                }}
              >
                Close
              </button>
            </div> */}

            <IpPoolTable
              IpPool={ipPool}
              refresh={() => refresh(!refreshT)}
              onSelect={(r) => onSelect(r)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AssignNewIp;
