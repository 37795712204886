import React from "react";
import VMBC from "../../../VMBC";
import Demo from "../../../Demo";

function License(props) {
  const [name, setName] = React.useState("...");
  const [email, setEmail] = React.useState("...");
  const [total, setTotal] = React.useState("...");
  const [nearestExpire, setNearestExpire] = React.useState({});
  const [services, setServices] = React.useState([]);

  React.useEffect(() => {
    Demo.DelayRandom(() => {
      setName("FireServer");
      setEmail("info@fireserver.com");
      setTotal(2300);
      var now = parseInt(Date.now() / 1000);
      var offset0 = Demo.RandInt(1200, 12 * 3600);
      var offset1 = Demo.RandInt(1200, 12 * 3600);
      var offset2 = Demo.RandInt(1200, 12 * 3600);
      var Services = [
        {
          PackageName: "Monthly",
          PackageUid: "999",
          AllMachinesCount: 800,
          FreeMachinesCount: 292,
          CreatedDate: new Date(
            (now - 4 * 24 * 3600 + offset0) * 1000
          ).toLocaleString(),
          CreatedDateUnix: now - 4 * 24 * 3600 + offset0 * 1000,
          ExpireDate: new Date(
            (now + 26 * 24 * 3600 + offset0) * 1000
          ).toLocaleString(),
          ExpireDateUnix: now - 4 * 24 * 3600 + offset0 * 1000,
        },
        {
          PackageName: "Quarterly",
          PackageUid: "998",
          AllMachinesCount: 600,
          FreeMachinesCount: 198,
          CreatedDate: new Date(
            (now - 78 * 24 * 3600 + offset1) * 1000
          ).toLocaleString(),
          CreatedDateUnix: now - 4 * 24 * 3600 + offset1 * 1000,
          ExpireDate: new Date(
            (now + 12 * 24 * 3600 + offset1) * 1000
          ).toLocaleString(),
          ExpireDateUnix: now - 4 * 24 * 3600 + offset1 * 1000,
        },
        {
          PackageName: "Semi-Annually",
          PackageUid: "997",
          AllMachinesCount: 900,
          FreeMachinesCount: 420,
          CreatedDate: new Date(
            (now - 65 * 24 * 3600 + offset2) * 1000
          ).toLocaleString(),
          CreatedDateUnix: now - 4 * 24 * 3600 + offset2 * 1000,
          ExpireDate: new Date(
            (now + 115 * 24 * 3600 + offset2) * 1000
          ).toLocaleString(),
          ExpireDateUnix: now - 4 * 24 * 3600 + offset2 * 1000,
        },
      ];
      setServices(
        Services.sort((a, b) =>
          a.ExpireDateUnix > b.ExpireDateUnix ? 1 : -1
        ).map((service) => ({
          ...service,
          FreePercentage: parseInt(
            (100 * service.FreeMachinesCount) / service.AllMachinesCount
          ),
        }))
      );
      setNearestExpire(Services[1]);
    });
  }, []);

  return (
    <>
      <div className="row justify-content-center layout-top-spacing">
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-account-invoice-one">
            <div className="widget-heading">
              <h5 className="">License Info</h5>
            </div>

            <div className="widget-content">
              <div className="invoice-box">
                <div className="acc-total-info">
                  <h5>Total Machines</h5>
                  <p className="acc-amount">{total}</p>
                </div>

                <div className="inv-detail">
                  <div className="info-detail-1">
                    <p>Name:</p>
                    <p>{name}</p>
                  </div>
                  <div className="info-detail-2">
                    <p>Email:</p>
                    <p>{email}</p>
                  </div>
                  <div className="info-detail-3 info-sub">
                    <div className="info-detail">
                      <p>Nearest Service Expire:</p>
                      <p>{nearestExpire.ExpireDate}</p>
                    </div>
                    <div className="info-detail-sub">
                      <p>Service Name:</p>
                      <p>{nearestExpire.PackageName}</p>
                    </div>
                    <div className="info-detail-sub">
                      <p>Machines Count:</p>
                      <p>{nearestExpire.AllMachinesCount}</p>
                    </div>
                  </div>
                </div>

                <div className="inv-action">
                  <button href="" className="btn btn-primary">
                    <VMBC.A target="_blank" href="https://my.vmbox.ir/">
                      Goto My VMBOX
                    </VMBC.A>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {services ? (
          <div
            className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing`}
          >
            <div className={`container-fluid`}>
              <div className={`row`}>
                {services.map((service) => (
                  <div className={`col-12 mb-2`}>
                    <div className="widget widget-card-four">
                      <div className="widget-content">
                        <div className="w-content">
                          <div className="w-info">
                            <h6 className="value mb-2">
                              {service.PackageName}
                            </h6>
                            <p style={{ fontSize: "14px" }} className="">
                              Until: {service.ExpireDate}
                            </p>
                          </div>
                          <div className="d-flex align-items-center">
                            <h5
                              className={`text-info mx-2`}
                              style={{ fontSize: "1.2rem" }}
                            >
                              {`#${service.PackageUid}`}
                            </h5>
                            <div className="w-icon">
                              <VMBC.SVGs.Package />
                            </div>
                          </div>
                        </div>
                        <div className={`mt-4 d-flex justify-content-between`}>
                          <span style={{ color: "#fff", fontSize: ".9rem" }}>
                            Free: {service.FreeMachinesCount}
                          </span>
                          <span style={{ color: "#fff", fontSize: ".9rem" }}>
                            All: {service.AllMachinesCount}
                          </span>
                        </div>
                        <div className="progress mt-2">
                          <div
                            className="progress-bar bg-gradient-secondary"
                            role="progressbar"
                            style={{ width: `${service.FreePercentage}%` }}
                            aria-valuenow={`${service.FreePercentage}`}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div className="progress-title">
                              <span>Available</span>
                              <span>{service.FreePercentage}%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
    </>
  );
}

export default License;
