import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import VMBC from "..";

function A(props) {
  const { staticContext, ...rest } = props;
  return (
    <a
      {...rest}
      href={props.href === "#" ? window.location.href : props.href}
      onClick={(e) => {
        if (props.href === "#") {
          e.preventDefault();
          return;
        }
        if (
          !("" + props.href).includes("?") &&
          !(props.target && props.target === "_blank")
        ) {
          VMBC.Navigate(VMBC.Navigator.History, props.href);
          e.preventDefault();
        }
      }}
    >
      {props.children}
    </a>
  );
}

A.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
};

export default withRouter(A);
