import React from "react";
import "./PlansList.css";
import { withRouter } from "react-router";

import VMBC from "../../../VMBC";
import Table from "../../../Components/Table";

function PlansList(props) {
  const [datacenters, setDatacenters] = React.useState();

  React.useEffect(() => {
    VMBC.Inventory.GetDatacenters((DCs) => setDatacenters(DCs));
  }, []);

  if (!datacenters) {
    return (
      <div className="widget mt-4 text-center py-4">
        <div
          className="spinner-border align-self-center loader-lg"
          style={{ width: "3rem", height: "3rem" }}
        ></div>
      </div>
    );
  }

  if (datacenters.length <= 0) {
    return (
      <div className="widget mt-4 text-center py-4">
        <h6 className="align-self-center" style={{ margin: "auto" }}>
          No Datacenters
        </h6>
      </div>
    );
  }

  return (
    <div className="widget mt-4">
      <div className="widget-content">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 tab-justify-centered">
            <ul
              className="nav nav-tabs mb-3 mt-3 justify-content-center"
              id="justifyCenterTab"
              role="tablist"
            >
              {datacenters.map((dc, i) => (
                <li className="nav-item">
                  <a
                    className={`nav-link ${i === 0 ? "active" : ""}`}
                    id={`${dc.MO_Reference}-tab`}
                    data-toggle="tab"
                    href={`#${dc.MO_Reference}`}
                    role="tab"
                    aria-controls={`${dc.MO_Reference}`}
                    aria-selected={i === 0 ? "true" : "false"}
                  >
                    {dc.Name}
                  </a>
                </li>
              ))}
            </ul>

            <div className="tab-content">
              {datacenters.map((dc, i) => (
                <div
                  className={`tab-pane fade ${i === 0 ? "show active" : ""}`}
                  id={dc.MO_Reference}
                  role="tabpanel"
                  aria-labelledby={`${dc.MO_Reference}-tab`}
                >
                  <DcPlans DC={dc} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DcPlans(props) {
  const { DC } = props;

  const [plans, setPlans] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setPlans();
    DC.GetPlans((plns) => {
      plns = plns ? plns : [];
      setPlans(plns);
    });
  }, [refreshT]);

  const Refresh = () => refresh(!refreshT);

  if (!plans) {
    return (
      <div className="d-flex justify-content-center">
        <VMBC.Loader />
      </div>
    );
  }

  return (
    <Table
      columns={[
        { title: "Name", fieldName: "Name" },
        { title: "CPU", fieldName: "DefaultCpu" },
        { title: "Memory", fieldName: "DefaultMemory" },
        { title: "Disk", fieldName: "DefaultHardDisk" },
        {
          title: "State",
          fieldName: "Disabled",
          render: (rowData) =>
            rowData.Disabled ? (
              <span className="badge outline-badge-danger">Disabled</span>
            ) : (
              <span className="badge outline-badge-success">Enabled</span>
            ),
        },
      ]}
      data={plans}
      actions={{
        Others: [
          {
            Title: "Settings",
            Icon: (rowData) => (
              // <VMBC.A href={`/panel/plans/list/${rowData.Id}`}>
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  VMBC.Alert.Show(
                    "This feature not available in Demo version yet",
                    "info"
                  )
                }
              >
                <VMBC.SVGs.Settings style={{ width: "24px", height: "24px" }} />
              </span>
            ),
          },
        ],
        FreeActions: [
          {
            Title: "Add New Plan",
            Icon: (
              <VMBC.A href={`/panel/plans/add`}>
                <button className={`btn btn-primary`}>Add New Plan</button>
              </VMBC.A>
            ),
          },
        ],
      }}
    />
  );
}

export default withRouter(PlansList);
