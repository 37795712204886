import { ManagedObject } from "../../../DataTypes";

import axios from "axios";
import Utils from "../../../Utils"

class IpPool extends ManagedObject {

  constructor() {
    if (arguments.length === 1 && typeof arguments[0] === "string") { // IpPool ( string IpPoolId )
      super(arguments[0]);
    } else if (arguments.length === 1 && arguments[0]._VMBC_Type && arguments[0]._VMBC_Type === "ManagedObject") { // IpPool ( ManagedObject IpPool )
      super(arguments[0].MO_Reference);
    } else if (arguments.length === 0) { // new IpPool
      super("");
    }
  }

  SetDetails(Name, ComputeResource, Description) {
    if (this.MO_Reference !== "")
      return false;
    this.Name = Name ? Name : this.Name;
    this.Description = Description ? Description : this.Description;
    this.ComputeResourceId = ComputeResource ? ComputeResource.MO_Reference : "";
    return true;
  }

  AddNewIpAddress(ipAddressObject, gatewayObject, portGroupObject, dns1, dns2) {
    this.IpAddresses.push({
      Ip: ipAddressObject._ip,
      Subnet: ipAddressObject.NetPrefixToSubnetMask(ipAddressObject._netPrefix)._ip,
      Gateway: gatewayObject._ip,
      PortGroupId: portGroupObject.MO_Reference,
      Dns1: dns1, Dns2: dns2
    });
  }

  AddNewIpAddresses(IpAddresses, cb) {
    if(!IpAddresses || !IpAddresses.length || IpAddresses.length < 1){
      return;
    }
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(window.VMBC.Config.Origin + "/api/inventory/ipPool/addIpAddresses", {
          SId: window.VMBC.SId,
          IpPoolId: this.MO_Reference,
          IpAddresses: IpAddresses,
        })
        .then(res => {
          if (!res.data) {
            cb("Unknown Error");
          }
          if (res.data.res === "success") {
            cb(false);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
            cb("Session Expired");
          } else {
            cb("Unknown Error");
          }
        })
        .catch(reason => {
          cb("Network Error");
        });
    });
  }

  Register() {
    if (this.MO_Reference !== "")
      return false;
    Utils.CheckSId(() => {
      axios
        .post(window.VMBC.Config.Origin + "/api/inventory/network/registerIpPool", {
          SId: window.VMBC.SId,
          Name: this.Name,
          Description: this.Description,
          ComputeResource: this.ComputeResourceId,
          IpAddresses: this.IpAddresses,
        })
        .then(res => {
          console.log(res)
        })
        .catch(reason => {
          console.log("error", reason)
        });
    });
  }

  Name = "";
  Description = "";
  FreeIpCount = 0;
  ComputeResourceId = "";
  IpAddresses = [];
}

export default IpPool