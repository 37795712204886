import React from "react";
import VMBC from "../../../../../VMBC";

const Cols = [
  {
    Title: "ID",
    FieldName: "Id",
  },
  {
    Title: "Name",
    FieldName: "VmName",
  },
  {
    Title: "IP Address",
    FieldName: "IpAddress",
  },
  {
    Title: "State",
    FieldName: "State",
  },
];

function ExportMachinesList(props) {
  const { plan } = props;

  const exportMachinesList = (vms, format) => {
    if (!vms) {
      VMBC.Alert.Show("Virtual Machines List not ready", "error");
      return;
    }
    VMBC.Dialogs.Close();
    if (format === "Print") {
      VMBC.Export.PrintTable(Cols, vms, {
        PageTitle: plan.Name,
        ShowIndex: true,
      });
    } else if (format === "CSV") {
      VMBC.Export.ExportTableAsCSV(Cols, vms, { ShowIndex: true });
    } else if (format === "Excel") {
      VMBC.Export.ExportTableAsExcel(Cols, vms, { ShowIndex: true });
    } else if (format === "JSON") {
      VMBC.Export.ExportJSON({
        Machines: vms.map((vm) => ({
          Id: vm.Id,
          VmName: vm.VmName,
          IpAddress: vm.IpAddress,
          State: vm.State,
        })),
      });
    }
  };

  const exportMachinesListClicked = () => {
    VMBC.Dialogs.Show({
      Title: "Loading Machines List",
      Text: "Please wait...",
    });
    VMBC.Plans.GetPlanVirtualMachines(plan.PlanId, (virtualMachines, err) => {
      if (err) {
        VMBC.Alert.Show(err, "error");
      } else {
        VMBC.Dialogs.Show({
          Title: "Exporting Machines List",
          Text: <h6>Choose file format.</h6>,
          Actions: [
            {
              className: "btn-outline-primary btn-sm",
              onClick: () => VMBC.Dialogs.Close(),
              text: "Cancel",
            },
            {
              className: "btn-primary btn-sm",
              text: "Print",
              onClick: () => exportMachinesList(virtualMachines, "Print"),
            },
            {
              className: "btn-primary btn-sm",
              text: "Excel",
              onClick: () => exportMachinesList(virtualMachines, "Excel"),
            },
            {
              className: "btn-primary btn-sm",
              text: "JSON",
              onClick: () => exportMachinesList(virtualMachines, "JSON"),
            },
          ],
        });
      }
    });
  };
  return (
    <>
      <a
        class="dropdown-item"
        href="javascript:void(0);"
        onClick={exportMachinesListClicked}
      >
        Export Machines List
      </a>
    </>
  );
}

export default ExportMachinesList;
