import React from "react";
import VMBC from "../../VMBC";
import Demo from "../../Demo";
window.AddToLoadingVmboxProccess(1);

function WebServerConfigurations(props) {
  const { Config } = props;

  const [editable, setEditable] = React.useState(false);
  const [actionInProgress, setActionInProgress] = React.useState(false);
  const [configIFAddr, setConfigIFAddr] = React.useState(Config.ConfigIFAddr);
  const [configIFAddrSSL, setConfigIFAddrSSL] = React.useState(
    Config.ConfigIFAddrSSL
  );
  const [configIFHostname, setConfigIFHostname] = React.useState(
    Config.ConfigIFHostname
  );
  const [clientIFAddr, setClientIFAddr] = React.useState(Config.ClientIFAddr);
  const [clientIFSSL, setClientIFSSL] = React.useState(Config.ClientIFSSL);
  const [clientIFHostname, setClientIFHostname] = React.useState(
    Config.ClientIFHostname
  );
  const [customerIFAddr, setCustomerIFAddr] = React.useState(
    Config.CustomerIFAddr
  );
  const [customerIFSSL, setCustomerIFSSL] = React.useState(
    Config.CustomerIFSSL
  );
  const [customerIFHostname, setCustomerIFHostname] = React.useState(
    Config.CustomerIFHostname
  );
  const [customerIFACME, setCustomerIFACME] = React.useState(
    Config.CustomerIFACME
  );
  const [customerIFACMEBuiltIn, setCustomerIFACMEBuiltIn] = React.useState(
    Config.CustomerIFACMEBuiltIn
  );
  const cIaBiRef = React.useRef(Config.CustomerIFACMEBuiltIn);
  const [
    customerIFACMEBuiltInOnSet,
    setCustomerIFACMEBuiltInOnSet,
  ] = React.useState(Config.CustomerIFACMEBuiltIn);

  const submitData = () => {
    if (
      configIFAddr == "" ||
      configIFAddrSSL == "" ||
      configIFHostname == "" ||
      clientIFAddr == "" ||
      clientIFHostname == "" ||
      customerIFAddr == "" ||
      customerIFHostname == ""
    ) {
      VMBC.Alert.Show("Fields can not be empty");
      return;
    }
    if (
      !configIFAddr.includes(":") ||
      !configIFAddrSSL.includes(":") ||
      !clientIFAddr.includes(":") ||
      !customerIFAddr.includes(":")
    ) {
      VMBC.Alert.Show("One of fields are not in right format");
      return;
    }
    VMBC.Dialogs.Show({
      Title: "Are you sure you want to edit VMBox configuration?",
      Ignorable: true,
      Actions: [
        {
          className: "btn-outline-primary btn-sm",
          onClick: () => {
            VMBC.Dialogs.Close();
          },
          text: "No",
        },
        {
          className: "btn-primary btn-sm",
          onClick: () => {
            if (actionInProgress) return;
            setActionInProgress(true);
            Demo.DelayRandom(() =>
              Demo.DelayRandom(() =>
                Demo.DelayRandom(() => {
                  VMBC.Alert.Show("Done", "success");
                  props.refresh();
                  setEditable(false);
                  setActionInProgress(false);
                  VMBC.Dialogs.Close();
                })
              )
            );
          },
          text: "Yes",
        },
      ],
    });
  };

  const renewSSL = (type) => () => {
    var typeName = type;
    if (type === "config") {
      typeName = "Configuration";
    } else if (type === "client") {
      typeName = "Module";
    } else if (type === "customer") {
      typeName = "Customer";
    }
    VMBC.Dialogs.Show({
      Title: `Are you sure you want to renew SSL for ${typeName} interface?`,
      Text: "you should restart after generating new SSL certificates",
      Ignorable: true,
      Actions: [
        {
          className: "btn-outline-primary btn-sm",
          onClick: () => {
            VMBC.Dialogs.Close();
          },
          text: "No",
        },
        {
          disabled: actionInProgress,
          className: "btn-primary btn-sm",
          onClick: () => {
            if (actionInProgress) return;
            VMBC.Dialogs.Show({
              Title: "Generating SSL Certificate...",
              Text: "Please wait...",
            });
            setActionInProgress(true);
            Demo.DelayRandom(() =>
              Demo.DelayRandom(() => {
                VMBC.Alert.Show("Generating SSL Certificate Done", "success");
                props.refresh();
                setActionInProgress(false);
                VMBC.Dialogs.Close();
              })
            );
          },
          text: "Yes",
        },
      ],
    });
  };
  const setSslState = (type, Enabled) => () => {
    VMBC.Dialogs.Show({
      Title: `Are you sure you want to ${
        Enabled ? "enable" : "disable"
      } SSL for ${type} interface?`,
      Ignorable: true,
      Actions: [
        {
          className: "btn-outline-primary btn-sm",
          onClick: () => {
            VMBC.Dialogs.Close();
          },
          text: "No",
        },
        {
          disabled: actionInProgress,
          className: "btn-primary btn-sm",
          onClick: () => {
            if (actionInProgress) return;
            setActionInProgress(true);
            Demo.DelayRandom(() =>
              Demo.DelayRandom(() => {
                VMBC.Alert.Show("Done", "success");
                props.refresh();
                setActionInProgress(false);
                VMBC.Dialogs.Close();
              })
            );
          },
          text: "Yes",
        },
      ],
    });
  };
  const setCustomerACMEState = (Enabled) => {
    VMBC.Dialogs.Show({
      Title: `Are you sure you want to ${Enabled ? "enable" : "disable"} ACME?`,
      Text: Enabled
        ? "VMBox will try to fetch ssl certificates from an ACME vendor (LetsEncrypt). customer interface https will be trusted then."
        : "VMBox will use self signed certificates in customer interface ssl, so the https connection will not be trusted anymore.",
      Ignorable: true,
      Fields: Enabled ? (
        <AcmeTypeField
          value={customerIFACMEBuiltInOnSet}
          onChange={(e) => {
            cIaBiRef.current = e;
            setCustomerIFACMEBuiltInOnSet(e);
          }}
        />
      ) : undefined,
      Actions: [
        {
          className: "btn-outline-primary btn-sm",
          onClick: () => {
            VMBC.Dialogs.Close();
          },
          text: "No",
        },
        {
          disabled: actionInProgress,
          className: "btn-primary btn-sm",
          onClick: () => {
            if (actionInProgress) return;
            setActionInProgress(true);
            setCustomerIFACME(Enabled);
            Demo.DelayRandom(() =>
              Demo.DelayRandom(() => {
                VMBC.Alert.Show("Done", "success");
                props.refresh();
                setActionInProgress(false);
                VMBC.Dialogs.Close();
              })
            );
          },
          text: "Yes",
        },
      ],
    });
  };

  return (
    <div className="col-12 mt-2">
      <div className={`widget`}>
        <div class="widget-heading d-flex justify-content-between">
          <span>
            <h5>WebServer Configurations</h5>
          </span>
          <VMBC.A target="_blank" download href={`/SSL.zip`}>
            <button className={`btn btn-outline-primary`}>
              Download CA Files
            </button>
          </VMBC.A>
        </div>
        <div class="widget-content">
          <div className={`row justify-content-center`}>
            <div className={`col-lg-4 col-md-6 col-xs-12 col-12 mb-lg-0 mb-5`}>
              <h6 className={`mb-1`}>Config Interface</h6>
              <div class="input-group mt-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">IP Address (HTTP):</div>
                </div>
                <input
                  class="form-control"
                  value={configIFAddr}
                  onChange={(e) => editable && setConfigIFAddr(e.target.value)}
                />
              </div>
              <div class="input-group mt-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">IP Address (HTTPS):</div>
                </div>
                <input
                  class="form-control"
                  value={configIFAddrSSL}
                  onChange={(e) =>
                    editable && setConfigIFAddrSSL(e.target.value)
                  }
                />
              </div>
              <div class="input-group mt-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">Hostname:</div>
                </div>
                <input
                  class="form-control"
                  value={configIFHostname}
                  onChange={(e) =>
                    editable && setConfigIFHostname(e.target.value)
                  }
                />
              </div>
              <div className={`btn-group d-flex justify-content-center mt-2`}>
                <button
                  className={`btn btn-outline-primary ${
                    editable ? "disabled" : ""
                  }`}
                  onClick={editable ? undefined : renewSSL("config")}
                >
                  Renew SSL Certificate
                </button>
              </div>
            </div>

            <div className={`col-lg-4 col-md-6 col-xs-12 col-12 mb-lg-0 mb-5`}>
              <h6 className={`mb-1`}>Module Interface</h6>
              <div class="input-group mt-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    IP Address{!!clientIFSSL ? " (HTTPS)" : ""}:
                  </div>
                </div>
                <input
                  class="form-control"
                  value={clientIFAddr}
                  onChange={(e) => editable && setClientIFAddr(e.target.value)}
                />
              </div>

              {!!clientIFSSL ? (
                <div class="input-group mt-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Hostname:</div>
                  </div>
                  <input
                    class="form-control"
                    value={clientIFHostname}
                    onChange={(e) =>
                      editable && setClientIFHostname(e.target.value)
                    }
                  />
                </div>
              ) : undefined}

              <div className={`btn-group d-flex justify-content-center mt-2`}>
                {!!clientIFSSL ? (
                  <>
                    <button
                      className={`btn btn-outline-primary ${
                        editable ? "disabled" : ""
                      }`}
                      onClick={
                        editable ? undefined : setSslState("client", false)
                      }
                    >
                      Disable SSL
                    </button>
                    <button
                      className={`btn btn-outline-primary ${
                        editable ? "disabled" : ""
                      }`}
                      onClick={editable ? undefined : renewSSL("client")}
                    >
                      Renew SSL Certificate
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className={`btn btn-outline-primary ${
                        editable ? "disabled" : ""
                      }`}
                      onClick={
                        editable ? undefined : setSslState("client", true)
                      }
                    >
                      Enable SSL
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className={`col-lg-4 col-md-6 col-xs-12 col-12 mb-lg-0 mb-5`}>
              <h6 className={`mb-1`}>Customer Interface</h6>
              <div class="input-group mt-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">IP Address:</div>
                </div>
                <input
                  class="form-control"
                  value={customerIFAddr}
                  onChange={(e) =>
                    editable && setCustomerIFAddr(e.target.value)
                  }
                />
              </div>

              {!!customerIFACME || !!customerIFSSL ? (
                <>
                  <div class="input-group mt-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Hostname:</div>
                    </div>
                    <input
                      class="form-control"
                      value={customerIFHostname}
                      onChange={(e) =>
                        editable && setCustomerIFHostname(e.target.value)
                      }
                    />
                  </div>

                  <div className={`d-flex justify-content-center mt-3`}>
                    <label
                      class="switch s-primary mb-0 mr-1"
                      style={editable ? { opacity: ".5" } : {}}
                    >
                      <input
                        type="checkbox"
                        style={editable ? { cursor: "not-allowed" } : {}}
                        checked={customerIFACME}
                        onChange={(e) =>
                          !editable && setCustomerACMEState(e.target.checked)
                        }
                      />
                      <span
                        class="slider round"
                        style={editable ? { cursor: "not-allowed" } : {}}
                      ></span>
                    </label>
                    {`Use ACME ${
                      customerIFACME
                        ? customerIFACMEBuiltIn
                          ? "(Built-In)"
                          : "(CertBot)"
                        : ""
                    }`}
                  </div>
                </>
              ) : undefined}

              <div className={`btn-group d-flex justify-content-center my-3`}>
                {!!customerIFACME || !!customerIFSSL ? (
                  <>
                    <button
                      className={`btn btn-outline-primary ${
                        editable ? "disabled" : ""
                      }`}
                      onClick={
                        editable ? undefined : setSslState("customer", false)
                      }
                    >
                      Disable SSL
                    </button>
                    {!customerIFACME || !customerIFACMEBuiltIn ? (
                      <button
                        className={`btn btn-outline-primary ${
                          editable ? "disabled" : ""
                        }`}
                        onClick={editable ? undefined : renewSSL("customer")}
                      >
                        Renew SSL Certificate
                      </button>
                    ) : undefined}
                  </>
                ) : (
                  <>
                    <button
                      className={`btn btn-outline-primary ${
                        editable ? "disabled" : ""
                      }`}
                      onClick={
                        editable ? undefined : setSslState("customer", true)
                      }
                    >
                      Enable SSL
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className={`col-12 row justify-content-end`}>
              {editable ? (
                <>
                  <button
                    className={`btn btn-outline-primary mx-1`}
                    onClick={() => {
                      setConfigIFAddr(Config.ConfigIFAddr);
                      setConfigIFAddrSSL(Config.ConfigIFAddrSSL);
                      setConfigIFHostname(Config.ConfigIFHostname);
                      setClientIFAddr(Config.ClientIFAddr);
                      setClientIFSSL(Config.ClientIFSSL);
                      setClientIFHostname(Config.ClientIFHostname);
                      setCustomerIFAddr(Config.CustomerIFAddr);
                      setCustomerIFSSL(Config.CustomerIFSSL);
                      setCustomerIFHostname(Config.CustomerIFHostname);
                      setCustomerIFACME(Config.CustomerIFACME);
                      setEditable(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className={`btn btn-primary mx-1`}
                    onClick={submitData}
                  >
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <button
                    className={`btn btn-primary mx-1`}
                    onClick={() => {
                      setConfigIFAddr(Config.ConfigIFAddr);
                      setConfigIFAddrSSL(Config.ConfigIFAddrSSL);
                      setConfigIFHostname(Config.ConfigIFHostname);
                      setClientIFAddr(Config.ClientIFAddr);
                      setClientIFSSL(Config.ClientIFSSL);
                      setClientIFHostname(Config.ClientIFHostname);
                      setCustomerIFAddr(Config.CustomerIFAddr);
                      setCustomerIFSSL(Config.CustomerIFSSL);
                      setCustomerIFHostname(Config.CustomerIFHostname);
                      setCustomerIFACME(Config.CustomerIFACME);
                      setEditable(true);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className={`btn btn-primary mx-1`}
                    onClick={() => {
                      VMBC.Dialogs.Show({
                        Title: `Are you sure you want to Restart?`,
                        Ignorable: true,
                        Text: "Any unfinished action in vmbox might be lost.",
                        Actions: [
                          {
                            className: "btn-outline-primary btn-sm",
                            onClick: () => {
                              VMBC.Dialogs.Close();
                            },
                            text: "No",
                          },
                          {
                            disabled: actionInProgress,
                            className: "btn-primary btn-sm",
                            onClick: () => {
                              if (actionInProgress) return;
                              setActionInProgress(true);
                              Demo.DelayRandom(() =>
                                Demo.DelayRandom(() => {
                                  VMBC.Alert.Show(
                                    "VMBox restarted succesfuly",
                                    "success"
                                  );
                                  setActionInProgress(false);
                                  VMBC.Dialogs.Close();
                                })
                              );
                            },
                            text: "Yes",
                          },
                        ],
                      });
                    }}
                  >
                    Restart
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AcmeTypeField(props) {
  const [value, setValue] = React.useState(props.value);

  return (
    <div className={`mt-2`}>
      <label class="new-control new-radio new-radio-text radio-classic-primary">
        <input
          type="radio"
          value="CertBot"
          class="new-control-input"
          name="custom-radio-5"
          checked={value === false}
          onChange={(e) => {
            setValue(false);
            props.onChange && props.onChange(false);
          }}
        />
        <span class="new-control-indicator"></span>
        <span>Use Certbot (default)</span>
      </label>

      <label class="new-control new-radio new-radio-text radio-classic-primary">
        <input
          type="radio"
          value="BuiltIn"
          class="new-control-input"
          name="custom-radio-5"
          checked={value === true}
          onChange={(e) => {
            setValue(true);
            props.onChange && props.onChange(true);
          }}
        />
        <span class="new-control-indicator"></span>
        <span>Use Built-in ACME Manager</span>
      </label>
    </div>
  );
}

export default WebServerConfigurations;
