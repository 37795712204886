import React from "react";
import "./HardDisk.css";
import VMBC from "../../../../../../VMBC";

function HardDisk(props) {
  var oldData =
    props.Data &&
    props.Data.current.Customization &&
    props.Data.current.Customization.HardDisk;

  const [expanded, setExpanded] = React.useState(
    oldData ? oldData.expanded : "hardDiskPanel"
  );
  const [rerender, Rerender] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const AddNewProfile = () => {
    let found = false;
    props.Data.current.Customization.HardDisk.forEach(
      (prof) =>
        (found = prof.Alias === "New Additional Hard Disk" ? true : found)
    );
    if (found) {
      VMBC.Alert.Show(
        "Additional Hard Disk named 'New Additional Hard Disk' already exists.",
        "error",
        3000
      );
      return;
    }
    if (expanded !== "hardDiskPanel") {
      setExpanded("hardDiskPanel");
    }
    props.Data.current.Customization.HardDisk.push({
      Alias: "New Additional Hard Disk",
      Sockets: 1,
      CoresPerSocket: 1,
      Reserve: false,
      Reservation: 0,
      ReservationUnit: 0,
      Limit: false,
      LimitBy: 0,
      LimitByUnit: 0,
    });
    Rerender(!rerender);
  };

  const DeleteProfile = (index) => {
    props.Data.current.Customization.HardDisk.splice(index, 1);
    Rerender(!rerender);
  };

  const onSetDefault = (index) => () => {
    for (var i = 0; i < props.Data.current.Customization.HardDisk.length; i++) {
      props.Data.current.Customization.HardDisk[i].isDefault = i === index;
    }
    Rerender(!rerender);
  };

  if (props.Data.current.Customization.HardDisk.length === 1) {
    if (!props.Data.current.Customization.HardDisk[0].isDefault) {
      props.Data.current.Customization.HardDisk[0].isDefault = true;
      Rerender(!rerender);
    }
  }

  React.useEffect(() => {
    // rerender select picker on component did mount
    window.$(".HardDiskSelectpicker").selectpicker("refresh");
  });

  return (
    <div className={`container-fluid`}>
      <div className={`row justify-content-center`}>
        {props.Data.current.Customization.HardDisk.map((profile, index) => (
          <HardDiskProfile
            key={index}
            new={!profile.saved}
            isDefault={profile.isDefault}
            Index={index}
            Profile={profile}
            Data={props.Data}
            onDelete={DeleteProfile}
            onSetDefault={onSetDefault(index)}
          />
        ))}
        {props.Data.current.Customization.HardDisk.length === 0 ? (
          <>
            <h5 style={{ margin: "20px", color: "gray" }}>
              No Additional HardDisk
            </h5>
            <h6 style={{ margin: "20px", color: "gray" }}>
              Virtual Machine's Hard Disk configuration will be as same as the
              template's
            </h6>
          </>
        ) : undefined}
        <div
          className={`container`}
          style={{ cursor: "pointer" }}
          onClick={() => AddNewProfile()}
        >
          <div className={`row justify-content-center align-items-center`}>
            <VMBC.SVGs.CircleAdd size={80} />
            <span className={`ml-4`}>Add New Profile</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function HardDiskProfile(props) {
  const [editable, setEditable] = React.useState(props.new);

  const [alias, setAlias] = React.useState(props.Profile.Alias);
  const [capacity, setCapacity] = React.useState(
    props.Profile.Capacity ? Number(props.Profile.Capacity) : 0
  );
  const [capacityUnit, setCapacityUnit] = React.useState(
    props.Profile.CapacityUnit ? Number(props.Profile.CapacityUnit) : 0
  );

  const saveProfile = () => {
    if (alias === "" || alias.length > 100) {
      VMBC.Alert.Show(`Alias cannot be empty`, "error", 3000);
      return;
    }
    if (alias.length > 100) {
      VMBC.Alert.Show(
        `Alias cannot be more than 100 characters`,
        "error",
        3000
      );
      return;
    }
    if (alias.includes(",")) {
      VMBC.Alert.Show(`Alias cannot contain unknown characters`, "error", 3000);
      return;
    }
    let found = false;
    props.Data.current.Customization.HardDisk.forEach(
      (prof, i) =>
        (found = prof.Alias === alias && i !== props.Index ? true : found)
    );
    if (found) {
      VMBC.Alert.Show(
        `Additional Hard Disk named '${alias}' already exists.`,
        "error",
        3000
      );
      return;
    }

    if (isNaN(capacity)) {
      VMBC.Alert.Show(`Please Check your inputs`, "error", 3000);
      return;
    }

    props.Data.current.Customization.HardDisk[props.Index] = {
      Alias: alias,
      Capacity: Number(capacity),
      CapacityUnit: Number(capacityUnit),
      saved: true,
      isDefault:
        props.Data.current.Customization.HardDisk[props.Index].isDefault,
    };
    setEditable(false);
  };

  React.useEffect(() => {
    // rerender select picker on component did mount
    window.$(".HardDiskSelectpicker").selectpicker("refresh");
  }, [editable]);

  return (
    <div className={`col-12`}>
      <div
        className={`hardDiskAdditionalCard`}
        style={
          props.isDefault
            ? editable
              ? { boxShadow: "0px 0px 15px 0px #8DEE9B", scale: "1.01" }
              : { boxShadow: "0px 0px 15px 0px #8DEE9B" }
            : editable
            ? {
                boxShadow: "0px 0px 15px 0px rgba(255,255,255,0.35)",
                scale: "1.01",
              }
            : {}
        }
      >
        <div className={`container`}>
          <div className={`row justify-content-around align-items-center`}>
            <div className={`container`}>
              <div className={`row hardDiskAdditionalCardPropertyContainer`}>
                <div className={`input-group mb-1`}>
                  <div className={`input-group-prepend`}>
                    <div class="input-group-text">Alias</div>
                  </div>
                  <input
                    class="form-control"
                    value={alias}
                    onChange={(e) => editable && setAlias(e.target.value)}
                  />
                </div>
              </div>

              <div className={`row hardDiskAdditionalCardPropertyContainer`}>
                <div class="input-group mb-1">
                  <div class="input-group-prepend">
                    <div class="input-group-text">HardDisk</div>
                  </div>
                  <input
                    class="form-control"
                    aria-label="switch"
                    value={capacity}
                    onChange={(e) =>
                      editable &&
                      setCapacity(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!editable}
                      className="HardDiskSelectpicker"
                      data-width="100%"
                      value={capacityUnit}
                      onChange={(e) =>
                        editable && setCapacityUnit(e.target.value)
                      }
                    >
                      <option value={0}>MB</option>
                      <option value={1}>GB</option>
                    </select>
                  </div>
                </div>
              </div>

              <div
                className={`row hardDiskAdditionalCardPropertyContainer justify-content-end`}
              >
                {!props.isDefault && !editable ? (
                  // <VMBC.ToolTip title={"Mark as Default"}>
                  <button
                    className="btn btn-primary mb-2 mr-2 rounded-circle"
                    onClick={() => props.onSetDefault(true)}
                  >
                    <VMBC.SVGs.Star />
                  </button>
                ) : // </VMBC.ToolTip>
                undefined}
                {editable ? (
                  <>
                    <button
                      className="btn btn-danger mb-2 mr-2 rounded-circle"
                      onClick={() => {
                        setEditable(false);
                        let Old =
                          props.Data.current.Customization.HardDisk[
                            props.Index
                          ];
                        setAlias(Old.Alias);
                        setCapacity(Old.Capacity);
                        setCapacityUnit(Old.CapacityUnit);
                      }}
                    >
                      <VMBC.SVGs.CloseXIcon />
                    </button>
                    <button
                      className="btn btn-primary mb-2 mr-2 rounded-circle"
                      onClick={saveProfile}
                    >
                      <VMBC.SVGs.CheckIcon />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-danger mb-2 mr-2 rounded-circle"
                      onClick={() => props.onDelete(props.Index)}
                    >
                      <VMBC.SVGs.RecycleBin />
                    </button>
                    <button
                      className="btn btn-primary mb-2 mr-2 rounded-circle"
                      onClick={() => setEditable(true)}
                    >
                      <VMBC.SVGs.EditByPen />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HardDisk;
