import React from "react";
import VMBC from "../../../VMBC";

function Farsi(props) {
  if (!props.SelectLanguage) {
    return null;
  }

  return (
    <div class="eulaContent">
      <div class="d-flex justify-content-between eula-head">
        <div class="eulaHeader">
          <h4>توافق نامه مجوز کاربر</h4>
          <p>تاریخ بروزرسانی ۲۹ آبانماه ۱۳۹۹</p>
        </div>

        {props.SelectLanguage}

        <div class="get-eula-terms align-self-center d-none">
          <button class="btn btn-primary" onClick={() => {}}>
            <VMBC.SVGs.Printer />
            Print
          </button>
        </div>
      </div>

      <div
        class="eula-content-container text-right"
        style={{ direction: "rtl" }}
      >
        <section>
          <h5>معرفی</h5>
          <p>
            این توافق نامه مجوز کاربر، مقرراتی را ارائه می دهد که تحت آن شرکت
            انتقال داده فرا موج رسا (رساتلکام) به شماره ثبت ۵۰۱۴۰ مایل است
            مجوزهای خاصی را به شما، (یک نهاد تجاری واحد)، برای نرم افزار VMBox
            اعطا کند. "نرم افزار VMBox" شامل یک برنامه سمت سرور به عنوان "VMBox
            Server" است که بر روی هر سیستم عامل مبتنی بر لینوکس کار می کند ، یک
            نرم افزار تحت وب سمت مشتری برای تنظیمات VMBox و سایر ماژول ها برای
            برقراری ارتباط بین نرم افزار VMBox و سایر نرم افزارها.(فروشکاه ها و
            مجازی ساز ها)
          </p>
          <p>
            هر لایسنسی دارای یک کد یکتای مخفی و تعدادی پکیج است. هر پکیج در یک
            دوره زمانی فعال است و شامل تعدادی ماشین مجازی است که در این دوره
            زمانی توسط نرم افزار پشتیبانی خواهند شد. شما، به عنوان صاحب لایسنس
            میتوانید این پکیج ها را خریداری، لغو و یا تمدید کنید. برای این منظور
            میتوانید از پنل <a href="https://my.vmbox.ir">My VMBox</a> اقدام
            کنید.
          </p>
          <p>
            هر ماشین مجازی که توسط نرم افزار پشتیبانی میشود، بخشی از یکی از پکیج
            های لایسنس شماست. هنگامی که دوره پکیجی به اتمام میرسد. ماشین های
            مجازی فعال آن به پکیج های آزاد دیگر منتقل خواهند شد و در صورتی که
            پکیج آزادی برای انتقال وجود نداشته باشد ماشین های مجازی باقی مانده
            دیگر توسط نرم افزار پشتیبانی نخواهند شد. این ماشین های مجازی حذف و
            یا خاموش نخواهند شد.
          </p>

          <p
            className={`p-2`}
            style={{
              backgroundColor: "#ffffff10",
              borderRadius: "5px",
              border: "2px dashed #ddd",
            }}
          >
            با کلیک بر روی "من موافقم"، یا با اقدام به هر عملی برای نصب یا
            استفاده از محصول نرم افزاری، شما موافقت می کنید که با شرایط این
            توافق نامه محدود شده اید. اگر شرایط توافق نامه را قبول نمی کنید، از
            محصول نرم افزاری استفاده نکنید.
          </p>
        </section>

        {/* <h5 class="eula-info-question">
          چه اطلاعاتی از کاربران جمع آوری میکنیم؟ و چرا؟
        </h5> */}

        <section>
          <h5>مجوز استفاده</h5>

          <p>
            این شرکت به شما حق قابل فسخ، غیر انحصاری، غیر قابل انتقال و محدود
            برای نصب و استفاده از برنامه در کنار سایر نرم افزارهای فروشگاهی و
            مجازی سازی دارای مجوز و کنترل شده توسط شما و دسترسی و استفاده از
            برنامه برای کلون کردن خودکار، ویرایش یا دسترسی به ماشین های مجازی در
            نرم افزارهای مجازی ساز و فروشگاهی کاملاً مطابق با شرایط و ضوابط
            مجوز، قوانین استفاده و هرگونه توافق نامه خدمات مرتبط با این نرم
            افزار را به شما می دهد.
          </p>
        </section>

        <section>
          <h5>محدودیت های استفاده</h5>

          <p>
            شما باید از نرم افزار کاملاً مطابق با شرایط توافق نامه مربوطه
            استفاده کنید و نباید:
            <br />
            <br />
            <Tag>(الف)</Tag> دیکامپایل، مهندسی معکوس، تجزیه مجدد، تلاش برای
            استخراج کد منبع یا رمزگشایی برنامه؛ <br />
            <br />
            <Tag>(ب)</Tag> هرگونه اصلاح، انطباق، بهبود، ترجمه یا کار حاصل شده از
            نرم افزار را انجام دهید؛
            <br />
            <br />
            <Tag>(ج)</Tag> در رابطه با دسترسی یا استفاده از برنامه، قوانین یا
            مقررات قابل اجرا را نقض کنید؛ <br />
            <br />
            <Tag>(د)</Tag> اخطار مالکیت (یا شامل اعلان حق کپی رایت یا علامت
            تجاری) مربوط به شرکت یا شرکت های وابسته، شرکا، تأمین کنندگان یا مجوز
            دهندگان نرم افزار را حذف، تغییر داده یا مبهم کنید؛
            <br />
            <br />
            <Tag>(ه)</Tag> از نرم افزار برای هرگونه تلاش برای درآمدزایی یا هدف
            دیگری که برای آن طراحی یا در نظر گرفته نشده است استفاده کنید؛
            <br />
            <br />
            <Tag>(و)</Tag> نصب، استفاده و یا اجرای همزمان نرم افزار در بیش از یک
            ماشین مجازی یا در هر ماشین مجازی یا هر نوع سرور و یا کامپیوتر دیگر؛{" "}
            <br />
            <br />
            <Tag>(ز)</Tag> توزیع نرم افزار به هر کاربر یا نهاد؛
            <br />
            <br />
            <Tag>(ح)</Tag> در دسترس قرار دادن نرم افزار، از طریق شبکه یا هر نوع
            محیط دیگر که اجازه دسترسی یا استفاده همزمان از چندین کاربر را می
            دهد؛
            <br />
            <br />
            <Tag>(ط)</Tag> از نرم افزار برای ایجاد محصول، خدمات یا نرم افزاری
            استفاده کنید که به طور مستقیم یا غیرمستقیم رقیب یا به هر روشی
            جایگزین خدمات، محصول یا نرم افزارهای ارائه شده توسط شرکت باشد؛{" "}
            <br />
            <br />
            <Tag>(ی)</Tag> از نرم افزار برای ارسال کوئری های ناخواسته به هر سرور
            یا ارسال هرگونه ایمیل تجاری ناخواسته استفاده کنید؛
            <br />
            <br />
            <Tag>(ک)</Tag> استفاده از هرگونه اطلاعات اختصاصی یا رابط های شرکت یا
            سایر دارایی های معنوی شرکت در طراحی، توسعه، ساخت، صدور مجوز یا توزیع
            هرگونه برنامه، لوازم جانبی یا دستگاهی که با نرم افزار استفاده می
            شود.
          </p>
        </section>

        <section>
          <h5>فسخ توافق نامه</h5>
          <p>
            این توافق نامه در صورت پذیرش، یا دانلود، نصب، دسترسی و استفاده از
            نرم افزار، حتی اگر صریحاً این توافق نامه را قبول نکرده باشید، موثر و
            پابرجا است. این توافق نامه تا زمان فسخ یا اتمام دوره زمانی تمامی
            پکیج های لایسنس ادامه خواهد داشت. پکیج های لایسنس با اتمام دوره پیش
            پرداخت، منقضی میشوند مگر اینکه تمام هزینه ها را برای تمدید دوره هر
            پکیج پرداخت کرده باشید. با اتمام دوره پکیج، ماشین های مجازیی که از
            این پکیج منقضی شده استفاده می کنند به سایر پکیج های موجود و فعال از
            همان نوع منتقل می شوند. ماشین های مجازی فاقد پکیج، دیگر توسط نرم
            افزار پشتیبانی نمی شوند. در صورت نقض یا عدم رعایت هر یک از محدودیت
            ها یا سایر الزامات شرح داده شده در این توافق نامه، از جمله پرداخت
            هزینه های تمدید، این توافق نامه و کلیه پکیج های لایسنس به طور خودکار
            منقضی خواهند شد، و توافق می کنید که در این صورت تحت هر شرایطی، نرم
            افزار را غیرفعال کنید. با اعلام تصمیم خود مبنی بر فسخ توافق نامه،
            قطع استفاده از نرم افزار و مستندات، شما می توانید این قرارداد را فسخ
            کنید. با هر فسخ یا انقضای توافق نامه به هر دلیلی، شما موافقت می کنید
            که ماشین مجازی نرم افزار را حذف کنید و نرم افزار، مستندات، تمام نسخه
            های آن، و همه لایسنس کد را که به دست آورده اید بازگردانید یا همه این
            مواد را حذف کنید.
          </p>
        </section>

        <section>
          <h5>محدودیت مسئولیت</h5>
          <p>
            تحت هیچ شرایطی شرکت یا شرکت های وابسته به آن، تأمین کنندگان یا مجوز
            دهنده ها هیچگونه مسئولیت خسارت غیرمستقیم، حادثه ای، ویژه مثال های
            ناشی از آن یا در ارتباط با دسترسی یا استفاده شما یا عدم توانایی
            دسترسی به نرم افزار یا استفاده از نرم افزار و هرگونه محتوا و خدمات
            شخص ثالث، خواه خسارت قابل پیش بینی باشد یا خیر و هم اینکه شرکت از
            احتمال بروز چنین خساراتی مطلع شده است یا خیر، بر عهده نخواهد گرفت.
          </p>
        </section>

        <section>
          <h5 class="policy-info-ques">ارتباط با ما</h5>
          <p>
            <a href="mailto:contact@rasatelecom.com">contact@rasatelecom.com</a>
          </p>
          <p>۰۴۱۳-۶۶۷۷۸۸۰</p>
          <p>
            <a target="_blank" href="https://rasatelecom.com">وبسایت رساتلکام</a>
          </p>
        </section>
      </div>
    </div>
  );
}

function Tag(props) {
  return <span className={`restrictionTag`}>{props.children}</span>;
}

export default Farsi;
