import axios from "axios";

function AddSolutionCdDrive(solutionId, { Id }, cb) {
  if (
    !solutionId ||
    typeof solutionId !== "string" ||
    !Id ||
    typeof Id !== "string"
  )
    return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/addSolutionCdDrive",
        {
          SId: window.VMBC.SId,
          SolutionId: solutionId,
          CdIsoFileId: Id
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function GetSolutionCdDrives(id, cb) {
  if (!id || typeof id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/getSolutionCdDrives",
        { SId: window.VMBC.SId, SolutionId: id },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

function RemoveSolutionCdDrives(solutionCdDrivesId, cb) {
  if (!solutionCdDrivesId || typeof solutionCdDrivesId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/removeSolutionCdDrives",
        {
          SId: window.VMBC.SId,
          SolutionCdDrivesId: solutionCdDrivesId
        },
        { timeout: 300000 }
      )
      .then(res => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          console.log(res)
          cb(null, "Unknown Error");
        }
      })
      .catch(reason => {
        cb(false);
      });
  });
}

export default {
  AddSolutionCdDrive,
  GetSolutionCdDrives,
  RemoveSolutionCdDrives
};
