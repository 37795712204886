import HostSystem from "./HostSystem";
import axios from "axios";
import { ManagedObject, IpAddress } from "../DataTypes";
import Datacenter from "./Datacenter";
import ComputeResource from "./ComputeResource";
import Datastore from "./Datastore";
import IpPoolClass from "./Network/IpPool";
import IpAddressModel from "./Network/IpAddress";
import Vmbox from "./Vmbox";
import Demo from "../../Demo";
window.AddToLoadingVmboxProccess(1);

function GetvCenterSummary(cb) {
  window.VMBC.CheckSId(() => {
    cb = cb ? cb : () => {};
    axios
      .post(
        window.VMBC.Config.Origin + "/api/inventory/vcenterInfo",
        { SId: window.VMBC.SId },
        { timeout: 4000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(false);
        }
        if (res.data.res === "success") {
          cb(res.data.data);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else {
          cb(false);
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetEsxiList(cb) {
  window.VMBC.CheckSId(() => {
    cb = cb ? cb : () => {};
    axios
      .post(window.VMBC.Config.Origin + "/api/inventory/esxiHosts", {
        SId: window.VMBC.SId,
      })
      .then((res) => {
        if (!res.data) {
          cb(false);
        }
        if (res.data.res === "success") {
          var Resp = res.data.data;
          var EsxiList = [];
          for (var i = 0; i < Resp.length; i++) {
            // the esxi list contains ManagedObjectReference and Summary
            var Host = new HostSystem(
              new ManagedObject(Resp[i].Reference.Value)
            );
            Host.Summary = Resp[i].Summary;
            EsxiList.push(Host);
          }
          cb(EsxiList);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else {
          cb(false);
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetDatacenters(cb) {
  cb = cb ? cb : () => {};
  Demo.DelayRandom(() => {
    window.VMBC.CheckSId(() => {
      Demo.DelayRandom(() => {
        var plnFormatter = (plans) =>
          plans.map((pln) => ({
            Id: pln.PlanId,
            Name: pln.PlanName,
            DefaultCpu: `${pln.Profiles.Cpu}vCore`,
            DefaultMemory: pln.DeployableRamSwapDesc,
            DefaultHardDisk: pln.DeployableDiskSpaceDesc,
            DefaultCpuId: "1",
            DefaultMemoryId: "1",
            DefaultHardDiskId: "1",
          }));
        var Iran = {
          Name: "IRAN",
          MO_Reference: "datacenter-ir",
        };
        Iran.GetPlans = (cb) => {
          cb = cb ? cb : () => {};
          Demo.DelayRandom(() => {
            var irPlans = Demo.VMPlans.Data.filter((pln) => pln.DC === "IR");
            cb(plnFormatter(irPlans));
          });
        };
        var De = {
          Name: "GERMANY",
          MO_Reference: "datacenter-de",
        };
        De.GetPlans = (cb) => {
          cb = cb ? cb : () => {};
          Demo.DelayRandom(() => {
            var dePlans = Demo.VMPlans.Data.filter((pln) => pln.DC === "DE");
            cb(plnFormatter(dePlans));
          });
        };
        var Us = {
          Name: "US",
          MO_Reference: "datacenter-us",
        };
        Us.GetPlans = (cb) => {
          cb = cb ? cb : () => {};
          Demo.DelayRandom(() => {
            var usPlans = Demo.VMPlans.Data.filter((pln) => pln.DC === "US");
            cb(plnFormatter(usPlans));
          });
        };
        cb([Iran, De, Us]);
      });
    });
  });
}

function GetDatacentersDetails(cb) {
  window.VMBC.CheckSId(() => {
    cb = cb ? cb : () => {};
    axios
      .post(window.VMBC.Config.Origin + "/api/inventory/datacentersDetails", {
        SId: window.VMBC.SId,
      })
      .then((res) => {
        if (!res.data) {
          cb(false);
        }
        if (res.data.res === "success") {
          cb(Object.values(res.data.data));
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else {
          cb(false);
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetIpPool(IpPoolId, cb) {
  if (!IpPoolId || typeof IpPoolId !== "string" || IpPoolId === "") {
    return;
  }
  window.VMBC.CheckSId(() => {
    cb = cb ? cb : () => {};
    axios
      .post(window.VMBC.Config.Origin + "/api/inventory/ipPool/detail", {
        SId: window.VMBC.SId,
        IpPoolId,
      })
      .then((res) => {
        if (!res.data) {
          cb(false);
        }
        if (res.data.res === "success") {
          var ipPool = new IpPoolClass(res.data.data.Id);
          ipPool.Name = res.data.data.Name;
          ipPool.Description = res.data.data.Desc;
          ipPool.FreeIpCount = res.data.data.FreeIpCount;
          ipPool.ComputeResourceId = res.data.data.ComputeResourceId;
          ipPool.IsGeneral = ipPool.ComputeResourceId === "*";
          res.data.data.IpAddresses.forEach((ipAdd) => {
            var IPAdd = new IpAddressModel(ipAdd.Id);
            IPAdd.SubnetMask = new IpAddress(ipAdd.SubnetMask);
            IPAdd.IP = new IpAddress(ipAdd.Ip, IPAdd.SubnetMask);
            IPAdd.Gateway = new IpAddress(ipAdd.Gateway);
            IPAdd.PortGroupId = ipAdd.PortGroupId;
            IPAdd.PortGroupName = ipAdd.PortGroupName;
            IPAdd.Dns1 = new IpAddress(ipAdd.DNS1);
            IPAdd.Dns2 = new IpAddress(ipAdd.DNS2);
            IPAdd.RDNS = ipAdd.RDNS;
            IPAdd.Mac = ipAdd.Mac;
            IPAdd.AssignmentId = "" + ipAdd.AssignmentId;
            IPAdd.AssignmentName = "" + ipAdd.AssignmentName;
            IPAdd.ConstructIPs();
            ipPool.IpAddresses.push(IPAdd);
          });
          cb(ipPool);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else {
          cb(false);
        }
      })
      .catch((reason) => {
        cb(false, reason);
      });
  });
}

export default {
  HostSystem,
  GetvCenterSummary,
  GetEsxiList,
  GetDatacenters,
  GetDatacentersDetails,
  GetIpPool,

  Datacenter,
  ComputeResource,
  Datastore,
  Vmbox,
};
