import React from "react";

function PlanInfo(props) {
  const { plan } = props;

  return (
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-account-invoice-one">
        <div class="widget-heading">
          <h5 class="">Plan Info</h5>
        </div>

        <div class="widget-content">
          <div class="invoice-box">
            <div class="acc-total-info">
              <h5>Total Services</h5>
              <p class="acc-amount">{plan.AllServicesCount}</p>
            </div>

            <div class="inv-detail">
              <div class="info-detail-1">
                <p>Datacenter</p>
                <p>{plan.DatacenterName}</p>
              </div>
              <div class="info-detail-2">
                <p>Folder</p>
                <p>{plan.TargetFolderPath}</p>
              </div>
              <div class="info-detail-3">
                <p>State:</p>
                <p>
                  <span className="badge outline-badge-success">Good</span>
                </p>
              </div>
              {/* <div class="info-detail-3 info-sub">
                <div class="info-detail">
                  <p>Extras this month</p>
                  <p>$ -0.68</p>
                </div>
                <div class="info-detail-sub">
                  <p>Netflix Yearly Subscription</p>
                  <p>$ 0</p>
                </div>
                <div class="info-detail-sub">
                  <p>Others</p>
                  <p>$ -0.68</p>
                </div>
              </div> */}
            </div>

            <div class="inv-action" style={{ position: "relative" }}>
              <span
                style={{
                  position: "absolute",
                  top: "10px",
                  margin: "auto",
                  zIndex: "2",
                }}
                className="badge badge-warning badge-lg"
              >
                Coming Soon ...
              </span>
              <button style={{ opacity: "0.3" }} class="btn btn-dark disabled">
                Summary
              </button>
              <button
                style={{ opacity: "0.3" }}
                class="btn btn-danger disabled"
              >
                Transfer
              </button>
              <button
                style={{ opacity: "0.3" }}
                class="btn btn-primary disabled"
              >
                Change Folder
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanInfo;
