const Origin = window.location.origin;
// const Origin = "https://vmbox.rasatelecom.com:4443";
// const Origin = "http://localhost:15401";


const OsTypeDefaultOptions = {
  Windows: {
    Password: "AdminRasa123",
    EtherName: "Ethernet0"
  },
  Ubuntu18: {
    Password: "AdminRasa123",
    EtherName: "ens160"
  },
  CentOs7: {
    Password: "AdminRasa123",
    EtherName: "ens160"
  },
//   Debian: {
//     Password: "AdminRasa123",
//     EtherName: "ens160"
//   }
};

export default {
  Origin,
  OsTypeDefaultOptions
};
