import React from "react";
import VMBC from "../../../../../VMBC";

function PlansList(props) {
  const { ipPoolId } = props;

  const [plansList, setPlansList] = React.useState();

  React.useEffect(() => {
    VMBC.Plans.IpPools.GetIpPoolPlans(ipPoolId, (res, err) => {
      if (err) {
        VMBC.Alert.Show(err, "error");
      } else {
        setPlansList(res);
      }
    });
  }, []);

  return (
    <>
      <div class="widget widget-table-one" style={{ height: "100%" }}>
        <div
          class="widget-heading"
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "20px",
            borderBottom: "1px dashed #3b3f5c",
          }}
        >
          <h5 class="">Plans using this IP Pool</h5>
          <span>
            {/* <button
              className={`btn btn-primary btn-sm`}
              onClick={() => openAddPlanTemplate.current()}
            >
              Add
            </button> */}
            {/* <AddPlanTemplate
              onOpen={openAddPlanTemplate}
              plan={plan}
              onSelect={handleAddTemplate}
            /> */}
          </span>
        </div>

        {!plansList ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              className="spinner-border align-self-center loader-lg"
              style={{ width: "3rem", height: "3rem" }}
            ></div>
          </div>
        ) : undefined}

        {plansList && plansList.length === 0 ? (
          <h6 className={`text-center mt-5`}>No Plans Yet</h6>
        ) : undefined}

        <div
          class="widget-content"
          style={{
            height: "315px",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "5px 0",
          }}
        >
          {plansList && plansList.length != undefined
            ? plansList.map((plan) => (
                <VMBC.A href={`/panel/plans/list/${plan.Id}`}>
                  <div
                    class="transactions-list"
                    style={{
                      width: "95%",
                      margin: "0 auto 15px auto",
                      cursor: "pointer",
                    }}
                  >
                    <div class="t-item">
                      <div class="t-company-name">
                        <div class="t-icon">
                          <div
                            class="icon"
                            style={{ backgroundColor: "#1b55e2" }}
                          >
                            <VMBC.SVGs.Plans />
                          </div>
                        </div>
                        <div class="t-name">
                          <h4>{plan.Name}</h4>
                          <p class="meta-date">
                            {plan.Disabled ? (
                              <span className={`badge outline-badge-danger mt-1`}>
                                Disabled
                              </span>
                            ) : undefined}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </VMBC.A>
              ))
            : undefined}
        </div>
      </div>
    </>
  );
}

export default PlansList;
