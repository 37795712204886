import React from "react";
import { withRouter } from "react-router";
import NetworkUsage from "./NetworkUsage";
import TotalCountBar from "./TotalCountBar";
import RecentAssignments from "./RecentAssignments";
import PlansList from "./PlansList";

function Statistics(props) {
  return (
    <>
      <div class="row layout-top-spacing">
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <PlansList ipPoolId={props.ipPoolId} />
        </div>

        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <RecentAssignments ipPoolId={props.ipPoolId} />
        </div>

        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <TotalCountBar ipPoolId={props.ipPoolId} />
        </div>
      </div>
    </>
  );
}

export default withRouter(Statistics);
