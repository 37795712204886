import React from "react";
import VMBC from "../../VMBC";
import PropTypes from "prop-types";
import "./index.css";
import OnDelete from "./onDelete";
import OnEdit from "./onEdit";
import OnAdd from "./onAdd";

const defaultSpecs = {
  pageSize: 7,
  pageSizes: [7, 10, 20, 50],
  actionsColumnIndex: 100,
};

function Table(props) {
  var { columns, data } = props;
  columns = columns && typeof columns.length === "number" ? columns : [];
  data = data && typeof data.length === "number" ? data : [];

  const [pageSize, setPageSize] = React.useState(
    props.PageSize && typeof props.PageSize === "number"
      ? props.PageSize
      : defaultSpecs.pageSize
  );
  const [pageNum, setPageNum] = React.useState(
    props.Page && typeof props.Page === "number" ? props.Page : 1
  );
  var dataCount =
    props.Count && typeof props.Count === "number" ? props.Count : data.length;
  const [dataSortFunction, setDataSortFunction] = React.useState(
    (a, b) => false
  );
  const [sortType, setSortType] = React.useState(
    props.SortType ? props.SortType : undefined
  );
  const [searchText, setSearchText] = React.useState(
    props.SearchText ? props.SearchText : undefined
  );

  React.useEffect(() => {
    setDataSortFunction((a, b) => {
      if (
        sortType &&
        sortType.fieldName &&
        a[sortType.fieldName] !== undefined &&
        b[sortType.fieldName] !== undefined
      ) {
        return false;
      }
      return false;
    });
  }, [sortType]);

  // generate actions column if there is an action prop in Component
  const Actions = [];
  if (props.actions !== undefined && typeof props.actions === "object") {
    [
      { name: "onEdit", defaultTitle: "Edit", defaultIcon: SvgIcons.onEdit },
      {
        name: "onDelete",
        defaultTitle: "Delete",
        defaultIcon: SvgIcons.onDelete,
      },
    ].forEach((act) => {
      if (
        props.actions[act.name] !== undefined &&
        typeof props.actions[act.name] === "object"
      ) {
        var icon = props.actions[act.name].Icon;
        icon = !icon ? act.defaultIcon : icon;

        var title = props.actions[act.name].Title;
        title = !title ? act.defaultTitle : title;

        var eventHandler = props.actions[act.name].EventHandler;
        eventHandler = !eventHandler ? () => {} : eventHandler;

        Actions.push({
          name: act.name,
          title,
          icon,
          eventHandler: props.actions[act.name].EventHandler,
          onClick: props.actions[act.name].onClick,
        });
      }
    });
    if (props.actions.Others !== undefined && props.actions.Others.length > 0) {
      props.actions.Others.forEach((act, i) => {
        Actions.push({
          name: `otherAct-${i}`,
          title: act.Title,
          icon: act.Icon,
          onClick: act.onClick,
        });
      });
    }
  }

  var SearchKeys =
    props.SearchFieldNames && props.SearchFieldNames.length != undefined
      ? props.SearchFieldNames
      : [...columns].map((col) => ({
          fieldName: col.fieldName,
          SearchFunction: col.SearchFunction,
        }));
  var unSearchableKeys = [...columns]
    .filter(
      (col) => !!col && (col.unsearchable || typeof col.fieldName !== "string")
    )
    .map((col) => col.fieldName);

  var DataToShow = [...data]
    .map((rawRow) => ({
      ...rawRow,
      _tableRaw: rawRow,
    }))
    .filter((row, index) => {
      // Apply search

      if (
        props.SearchText === undefined &&
        searchText &&
        typeof searchText === "string"
      ) {
        var found = false;
        for (var i = 0; i < SearchKeys.length && !found; i++) {
          var SearchKey =
            typeof SearchKeys[i] === "string"
              ? SearchKeys[i]
              : SearchKeys[i].fieldName;

          // check if searching this column is valid or not
          if (unSearchableKeys.includes(SearchKey)) {
            continue;
          }

          // SearchKey is field name
          // row[SearchKey] is data itself
          if (
            typeof SearchKeys[i] === "object" &&
            SearchKeys[i].SearchFunction != undefined &&
            typeof SearchKeys[i].SearchFunction === "function"
          ) {
            // There is a Search Function for this column
            // call it and set found true if it returns true
            if (SearchKeys[i].SearchFunction(row, searchText)) {
              found = true;
            }
          } else if (typeof row[SearchKey] === "string") {
            if (
              ("" + row[SearchKey])
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) !== -1
            ) {
              found = true;
              var start = ("" + row[SearchKey])
                .toLowerCase()
                .indexOf(searchText.toLowerCase());
              var end = start + searchText.length;
              row[SearchKey] = (
                <>
                  {("" + row[SearchKey]).substring(0, start)}
                  <span style={{ backgroundColor: "rgb(231, 81, 90)" }}>
                    {("" + row[SearchKey]).substring(start, end)}
                  </span>
                  {("" + row[SearchKey]).substring(end, row[SearchKey].length)}
                </>
              );
            }
          } else if (typeof row[SearchKey] === "object") {
            if (
              props.SearchFunctionName !== undefined &&
              typeof props.SearchFunctionName === "string" &&
              row[SearchKey]["props"] !== undefined &&
              row[SearchKey]["props"][props.SearchFunctionName] !== undefined &&
              row[SearchKey]["props"][props.SearchFunctionName]["current"] !=
                undefined &&
              typeof row[SearchKey]["props"][props.SearchFunctionName][
                "current"
              ] === "function"
            ) {
              // check if there is a search function in objects in row
              if (
                row[SearchKey]["props"][props.SearchFunctionName]["current"](
                  searchText
                )
              ) {
                // run it with search text and set found true if the function returns true
                found = true;
              }
            } else if (props.DeepSearch) {
              // no search functions found, deeply search if props.DeepSearch is true
              var seen = [];
              var StringifiedRow = JSON.stringify(
                row[SearchKey],
                function (key, val) {
                  if (val !== null && typeof val === "object") {
                    if (seen.indexOf(val) >= 0) {
                      return;
                    }
                    seen.push(val);
                  }
                  return val;
                }
              );
              if (
                StringifiedRow.toLowerCase().indexOf(
                  searchText.toLowerCase()
                ) !== -1
              ) {
                found = true;
              }
            }
          }
        }
        if (!found) {
          return false;
        }
      }
      return true;
    })
    .sort((a, b) => {
      if (!sortType) {
        return false;
      }
      if (
        a[sortType.fieldName] === undefined &&
        b[sortType.fieldName] !== undefined
      ) {
        return true;
      }

      if (
        a[sortType.fieldName] !== undefined &&
        b[sortType.fieldName] !== undefined
      ) {
        if (
          typeof a[sortType.fieldName] === "string" &&
          typeof b[sortType.fieldName] === "string"
        ) {
          var compareResult = a[sortType.fieldName].localeCompare(
            b[sortType.fieldName]
          );

          if (sortType.asc) {
            return compareResult;
          } else {
            return -1 * compareResult;
          }
          // should return 1 or -1 or zero, not boolean

          return compareResult === 1
            ? sortType.asc
            : compareResult === -1
            ? !sortType.asc
            : false;
          // this expression is equivalant to

          // if (compareResult === 1) {
          //   // a > b
          //   if (sortType.asc) {
          //     return true;
          //   } else {
          //     return false;
          //   }
          // } else if (compareResult === -1) {
          //   // a < b
          //   if (sortType.asc) {
          //     return false;
          //   } else {
          //     return true;
          //   }
          // }
        } else if (
          typeof a[sortType.fieldName] === "object" &&
          typeof b[sortType.fieldName] === "object"
        ) {
          if (
            props.SortFunctionName !== undefined &&
            typeof props.SortFunctionName === "string" &&
            a[sortType.fieldName]["props"] !== undefined &&
            a[sortType.fieldName]["props"][props.SortFunctionName] !=
              undefined &&
            a[sortType.fieldName]["props"][props.SortFunctionName]["current"] !=
              undefined &&
            typeof a[sortType.fieldName]["props"][props.SortFunctionName][
              "current"
            ] === "function"
          ) {
            if (
              a[sortType.fieldName]["props"][props.SortFunctionName]["current"](
                a,
                b,
                sortType.fieldName,
                sortType.asc
              )
            ) {
              return true;
            }
          }
        } else if (
          typeof a[sortType.fieldName] === "number" &&
          typeof b[sortType.fieldName] === "number"
        ) {
          if (a[sortType.fieldName] === b[sortType.fieldName]) {
            return 0;
          }
          let comp = a[sortType.fieldName] > b[sortType.fieldName] ? 1 : -1;
          if (sortType.asc) {
            return comp;
          } else {
            return -1 * comp;
          }
        }
      }

      return false;
    })
    .map((row, ind) => (
      <tr key={ind} role="row" ref={row.trRef}>
        {columns
          .filter((col) => !col.hidden)
          .sort((...args) =>
            dataSortFunction ? dataSortFunction(...args) : false
          )
          .map((col, i) => {
            var value = "-";
            if (col.render != undefined && typeof col.render === "function") {
              value = col.render(row);
            } else if (row[col.fieldName] != undefined) {
              value = row[col.fieldName];
            }
            return (
              <td key={i} style={{ color: "rgb(255, 255, 255)" }}>
                {value}
              </td>
            );
          })}
        {Actions.length > 0 ? (
          <td style={{ color: "rgb(255, 255, 255)" }}>
            <ul className="table-actions">
              {Actions.map((act, actIndex) =>
                act.name === "onDelete" ? (
                  <OnDelete
                    key={actIndex}
                    index={actIndex}
                    act={act}
                    row={row}
                    rowIndex={ind}
                  />
                ) : act.name === "onEdit" ? (
                  <OnEdit
                    key={actIndex}
                    index={actIndex}
                    act={act}
                    row={row}
                    rowIndex={ind}
                    columns={columns}
                    dataSortFunction={dataSortFunction}
                  />
                ) : (
                  <li
                    key={actIndex}
                    onClick={(e) => {
                      if (act.onClick) {
                        act.onClick(e, row);
                      }
                    }}
                  >
                    {act.title ? (
                      <VMBC.ToolTip title={act.title}>
                        {typeof act.icon === "function"
                          ? act.icon(row)
                          : act.icon}
                      </VMBC.ToolTip>
                    ) : typeof act.icon === "function" ? (
                      act.icon(row)
                    ) : (
                      act.icon
                    )}
                  </li>
                )
              )}
            </ul>
          </td>
        ) : undefined}
      </tr>
    ));
  dataCount = DataToShow.length;
  // Apply Pagination
  DataToShow = DataToShow.filter((row, i) => {
    if (props.Page === undefined) {
      if ((pageNum - 1) * pageSize <= i && pageNum * pageSize > i) {
        return true;
      }
      return false;
    }
    return true;
  });

  const onPageChanged = (nextNum) => (e) => {
    if (props.Page === undefined) {
      setPageNum(nextNum);
    } else {
      if (props.onPageChanged) {
        props.onPageChanged(nextNum);
      }
    }
  };

  const pageCount = Math.ceil(dataCount / pageSize);

  return (
    <div className="table-responsive" style={{ overflow: "hidden" }}>
      <div className="dataTables_wrapper container-fluid dt-bootstrap4">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <div className="dataTables_length">
              <label>
                Results :
                <select
                  className="form-control"
                  value={
                    props.PageSize !== undefined ? props.PageSize : pageSize
                  }
                  onChange={(e) => {
                    if (props.onPageSizeChanged) {
                      props.onPageSizeChanged(e.target.value);
                    } else {
                      setPageSize(e.target.value);
                      setPageNum(1);
                    }
                  }}
                >
                  {defaultSpecs.pageSizes.map((size, i) => (
                    <option key={i} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="dataTables_filter">
              {props.actions !== undefined &&
              props.actions.onAdd !== undefined ? (
                <OnAdd
                  {...props.actions.onAdd}
                  columns={columns}
                  dataSortFunction={dataSortFunction}
                />
              ) : undefined}

              <label>
                <VMBC.SVGs.Magnifier />
                <input
                  type="search"
                  value={
                    props.SearchText !== undefined
                      ? props.SearchText
                      : searchText
                  }
                  onChange={(e) => {
                    if (props.onSearchTextChanged) {
                      props.onSearchTextChanged(e.target.value);
                    } else {
                      setSearchText(e.target.value);
                      setPageNum(1);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (props.onSearchTextSubmited) {
                      if (e.keyCode === 13) {
                        props.onSearchTextSubmited();
                      }
                    }
                  }}
                  className="form-control"
                  placeholder="Search..."
                />
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <table
              className="table table-hover dataTable"
              style={{ width: "100%" }}
              role="grid"
            >
              <thead>
                <tr role="row">
                  {columns
                    .filter((col) => !col.hidden)
                    .sort(SortByIndex)
                    .map((col, i) => (
                      <th
                        key={i}
                        tabIndex="0"
                        rowSpan="1"
                        colSpan="1"
                        style={{ width: "0px" }}
                        className={
                          sortType && sortType.fieldName === col.fieldName
                            ? sortType.asc
                              ? "sorting_asc"
                              : "sorting_desc"
                            : "sorting"
                        }
                        onClick={() => {
                          if (props.onSortTypeChanged) {
                            props.onSortTypeChanged({
                              fieldName: col.fieldName,
                              asc:
                                sortType && sortType.asc !== undefined
                                  ? !sortType.asc
                                  : true,
                            });
                          } else {
                            setSortType({
                              fieldName: col.fieldName,
                              asc:
                                sortType && sortType.asc !== undefined
                                  ? !sortType.asc
                                  : true,
                            });
                          }
                        }}
                      >
                        {col.title}
                      </th>
                    ))}
                  {Actions.length > 0 ? (
                    <th
                      tabIndex="0"
                      rowSpan="1"
                      colSpan="1"
                      style={{ width: "0px" }}
                    >
                      Actions
                    </th>
                  ) : undefined}
                </tr>
              </thead>
              <tbody>
                {DataToShow}
                {DataToShow && DataToShow.length < 1 ? (
                  <tr role="row">
                    <td
                      colSpan={"" + columns.filter((col) => !col.hidden).length}
                      style={{
                        color: "rgb(255, 255, 255)",
                        textAlign: "center",
                        padding: "20px 0 30px",
                      }}
                    >
                      {props.data ? "No matching records found" : "Loading..."}
                    </td>
                  </tr>
                ) : undefined}
              </tbody>
              {/* <tfoot>
                <tr>
                  {columns.sort(SortByIndex).map((col, i) => (
                    <th key={i} rowSpan="1" colSpan="1">
                      {col.title}
                    </th>
                  ))}
                  {props.actions ? (
                    <th rowSpan="1" colSpan="1">
                      Actions
                    </th>
                  ) : undefined}
                </tr>
              </tfoot> */}
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-5">
            <div className="dataTables_info" role="status" aria-live="polite">
              Showing page {pageNum} of {Math.ceil(dataCount / pageSize)} (
              {dataCount} Records)
            </div>
          </div>
          <div className="col-sm-12 col-md-7">
            <div className={`row justify-content-between`}>
              <div className="dataTables_paginate paging_simple_numbers">
                <ul className="pagination">
                  <li
                    className={`paginate_button page-item previous ${
                      pageNum > 1 ? "" : "disabled"
                    }`}
                    onClick={
                      pageNum > 1 ? onPageChanged(pageNum - 1) : () => {}
                    }
                  >
                    <a className="page-link">
                      <VMBC.SVGs.ArrowLeft />
                    </a>
                  </li>

                  {pageNum !== 1 ? (
                    <li
                      className="paginate_button page-item"
                      onClick={onPageChanged(1)}
                    >
                      <a className="page-link">1</a>
                    </li>
                  ) : undefined}

                  {pageNum > 4 ? (
                    <li className="paginate_button page-item">
                      <a className="page-link">...</a>
                    </li>
                  ) : undefined}

                  {pageNum > 3 ? (
                    <li
                      className="paginate_button page-item"
                      onClick={onPageChanged(pageNum - 2)}
                    >
                      <a className="page-link">{pageNum - 2}</a>
                    </li>
                  ) : undefined}

                  {pageNum > 2 ? (
                    <li
                      className="paginate_button page-item"
                      onClick={onPageChanged(pageNum - 1)}
                    >
                      <a className="page-link">{pageNum - 1}</a>
                    </li>
                  ) : undefined}

                  <li className="paginate_button page-item active">
                    <a className="page-link">{pageNum}</a>
                  </li>

                  {pageCount - pageNum > 1 ? (
                    <li
                      className="paginate_button page-item"
                      onClick={onPageChanged(pageNum + 1)}
                    >
                      <a className="page-link">{pageNum + 1}</a>
                    </li>
                  ) : undefined}
                  {pageCount - pageNum > 2 ? (
                    <li
                      className="paginate_button page-item"
                      onClick={onPageChanged(pageNum + 2)}
                    >
                      <a className="page-link">{pageNum + 2}</a>
                    </li>
                  ) : undefined}

                  {pageCount - pageNum > 3 ? (
                    <li className="paginate_button page-item">
                      <a className="page-link">...</a>
                    </li>
                  ) : undefined}

                  {pageNum !== pageCount && pageCount > 1 ? (
                    <li
                      className="paginate_button page-item"
                      onClick={onPageChanged(pageCount)}
                    >
                      <a className="page-link">{pageCount}</a>
                    </li>
                  ) : undefined}

                  <li
                    className={`paginate_button page-item next ${
                      pageNum < pageCount ? "" : "disabled"
                    }`}
                    onClick={
                      pageNum < pageCount
                        ? onPageChanged(pageNum + 1)
                        : () => {}
                    }
                  >
                    <a className="page-link">
                      <VMBC.SVGs.ArrowRight />
                    </a>
                  </li>
                </ul>
              </div>

              <span className={`mr-lg-5`}>
                {props.actions !== undefined &&
                props.actions.FreeActions !== undefined &&
                props.actions.FreeActions.length !== undefined
                  ? props.actions.FreeActions.map((act, i) => (
                      <span onClick={act.onClick} key={i}>
                        {act.Title ? (
                          <VMBC.ToolTip title={act.Title}>
                            {act.Icon}
                          </VMBC.ToolTip>
                        ) : (
                          act.Icon
                        )}
                      </span>
                    ))
                  : undefined}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SortByIndex(a, b) {
  if (
    a.index &&
    typeof a.index === "number" &&
    b.index &&
    typeof b.index === "number"
  ) {
    return a.index > b.index;
  }
  return false;
}

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      fieldName: PropTypes.string.isRequired,
      render: PropTypes.func,
      editComponent: PropTypes.func,
      SearchFunction: PropTypes.func,
      editable: PropTypes.bool,
      addable: PropTypes.bool,
      editValidator: PropTypes.func,
      addValidator: PropTypes.func,
      unsearchable: PropTypes.bool,
      index: PropTypes.number,
      hidden: PropTypes.bool,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.shape({
    onEdit: PropTypes.shape({
      EventHandler: PropTypes.func.isRequired,
      onClick: PropTypes.func,
      Icon: PropTypes.any,
      Title: PropTypes.string,
    }),
    onAdd: PropTypes.shape({
      EventHandler: PropTypes.func.isRequired,
      onClick: PropTypes.func,
      Icon: PropTypes.any,
      Title: PropTypes.string,
    }),
    onDelete: PropTypes.shape({
      EventHandler: PropTypes.func.isRequired,
      onClick: PropTypes.func,
      Icon: PropTypes.any,
      Title: PropTypes.string,
    }),
    Others: PropTypes.arrayOf(
      PropTypes.shape({
        onClick: PropTypes.func,
        Icon: PropTypes.any,
        Title: PropTypes.string,
      })
    ),
    FreeActions: PropTypes.arrayOf(
      PropTypes.shape({
        onClick: PropTypes.func,
        Icon: PropTypes.any,
        Title: PropTypes.string,
      })
    ),
  }),

  PageSize: PropTypes.number,
  Page: PropTypes.number,
  Count: PropTypes.number,
  SortType: PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
    asc: PropTypes.bool,
  }),
  DeepSearch: PropTypes.bool,
  SearchFieldNames: PropTypes.arrayOf(PropTypes.string),
  SearchFunctionName: PropTypes.string,
  SortFunctionName: PropTypes.string,
  SearchText: PropTypes.string,
  onPageSizeChanged: PropTypes.func,
  onPageChanged: PropTypes.func,
  onSortTypeChanged: PropTypes.func,
  onSearchTextChanged: PropTypes.func,
  onSearchTextSubmited: PropTypes.func,
};

const SvgIcons = {
  onEdit: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-edit-2 p-1 br-6 mb-1"
    >
      <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
    </svg>
  ),
  onDelete: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-trash p-1 br-6 mb-1"
    >
      <polyline points="3 6 5 6 21 6"></polyline>
      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
    </svg>
  ),
};
export default Table;
