import React from "react";

import FolderExplorer, {
  LinkParents,
} from "../../../Components/FolderExplorer";
import VMBC from "../../../VMBC";
import Demo from "../../../Demo";

function TargetFolder(props) {
  const [planName, setPlanName] = React.useState(
    props.Data && props.Data.current.PlanAndTarget
      ? props.Data.current.PlanAndTarget.PlanName
      : ""
  );
  const [currentFolder, setCurrentFolder] = React.useState(
    props.Data && props.Data.current.PlanAndTarget
      ? props.Data.current.PlanAndTarget.CurrentFolder
      : {}
  );
  const [selectedDatacenter, setSelectedDatacenter] = React.useState(
    props.Data && props.Data.current.PlanAndTarget
      ? props.Data.current.PlanAndTarget.TargetDatacenter
      : {}
  );
  const [selectedObject, setSelectedObject] = React.useState(
    props.Data && props.Data.current.PlanAndTarget
      ? props.Data.current.PlanAndTarget.TargetFolder
      : null
  );
  const [refreshTrigIndex, refreshTrigger] = React.useState(false);

  var validator = (next) => {
    if (!props.Data || !props.Data.current.PlanAndTarget) {
      return false;
    }
    const {
      PlanName,
      TargetFolder,
      DisableNext,
    } = props.Data.current.PlanAndTarget;
    if (!PlanName || PlanName === "") {
      VMBC.Alert.Show("Plan name can not be empty", "error", 3000);
      return false;
    }
    if (DisableNext) {
      VMBC.Alert.Show(
        "Loading Data; Please wait a few seconds, then retry.",
        "info",
        3000
      );
      return false;
    }
    if (!TargetFolder) {
      VMBC.Alert.Show("Target Folder must be selected", "error", 3000);
      return false;
    }
    return true;
  };

  const BlockExplorer = () => {
    window.$(window.$("#folderExplorer")).block({
      message:
        '<span class="text-semibold"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader spin position-left"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg></i>&nbsp; Updating data</span>',
      timeout: 200000,
      overlayCSS: {
        backgroundColor: "#191e3a",
        opacity: 0.7,
        cursor: "wait",
      },
      css: {
        border: 0,
        padding: "10px 15px",
        color: "#0e1726",
        width: "auto",
        "-webkit-border-radius": 2,
        "-moz-border-radius": 2,
        backgroundColor: "#009688",
      },
    });
  };

  React.useEffect(() => {
    props.SubmitNextValidator && props.SubmitNextValidator(validator);
    if (
      props.Data &&
      props.Data.current.PlanAndTarget &&
      props.Data.current.PlanAndTarget.CurrentFolder &&
      props.Data.current.PlanAndTarget.CurrentFolder.Name
    ) {
      return;
    }

    BlockExplorer();

    Demo.DelayRandom(() => {
      var folders = [
        {
          Name: "IRAN",
          DatacenterMOID: "datacenter-ir",
          ManagedObjectId: "folder-ir",
          SubItems: [
            { Name: "Folder0", ManagedObjectId: "folder-ir-0" },
            { Name: "Folder1", ManagedObjectId: "folder-ir-1" },
            { Name: "Folder2", ManagedObjectId: "folder-ir-2" },
          ],
        },
        {
          Name: "Germany",
          DatacenterMOID: "datacenter-de",
          ManagedObjectId: "folder-de",
          SubItems: [
            { Name: "Folder0", ManagedObjectId: "folder-de-0" },
            { Name: "Folder1", ManagedObjectId: "folder-de-1" },
            { Name: "Folder2", ManagedObjectId: "folder-de-2" },
          ],
        },
        {
          Name: "US",
          DatacenterMOID: "datacenter-us",
          ManagedObjectId: "folder-us",
          SubItems: [
            { Name: "Folder0", ManagedObjectId: "folder-us-0" },
            { Name: "Folder1", ManagedObjectId: "folder-us-1" },
            { Name: "Folder2", ManagedObjectId: "folder-us-2" },
          ],
        },
      ];
      var RootFolder = {
        isFolder: true,
        Name: "root",
        items: [],
      };
      folders.forEach((folder) => {
        RootFolder.items.push({
          isFolder: true,
          isDatacenter: true,
          Name: folder.Name,
          DatacenterId: folder.DatacenterMOID,
          FolderId: folder.ManagedObjectId,
          icon: (
            <span style={{ padding: "5px" }}>
              <VMBC.SVGs.FolderContained size="70" />
            </span>
          ),
          items: parseTargetFolders(folder.SubItems),
        });
      });
      LinkParents(RootFolder);
      setCurrentFolder(RootFolder);
      window.$(window.$("#folderExplorer")).unblock();
    });
  }, [refreshTrigIndex]);

  React.useEffect(() => {
    if (!selectedDatacenter || !selectedDatacenter.DatacenterId) return;
    if (selectedDatacenter.DatacenterMO) return;
    var DC = { DatacenterMoId: selectedDatacenter.DatacenterId, Templates: [] };
    DC.GetComputeResources = (cb) => cb();
    DC.GetTemplates = () => {};
    DC.Templates = [
      {
        Alias: "Cent os 7",
        TemplateId: "centos7",
        TemplateName: "Cent OS 7",
        OsType: "CentOs7",
      },
      {
        Alias: "Ubuntu 20.04",
        TemplateId: "ubuntuServer20",
        TemplateName: "Ubuntu Server 20.04",
        OsType: "Ubuntu18",
      },
      {
        Alias: "WinServer2016",
        TemplateId: "winServer2016",
        TemplateName: "Windows Server 2016",
        OsType: "Windows",
      },
    ];
    var GeneralIpPools = [
      { Name: "10G Port IPs", IpPoolMO: { Name: "10G Port IPs" } },
      {
        Name: "Public 1G Port IPs",
        IsGeneral: true,
        IpPoolMO: { Name: "Public 1G Port IPs" },
      },
    ];
    if (DC.DatacenterMoId === "datacenter-ir") {
      DC.ComputeResources = [
        { Name: "148.251.100.5", ComputeResourceMO: {} },
        { Name: "148.251.120.5", ComputeResourceMO: {} },
      ];
      DC.ComputeResources[0].CdIsoFiles = [];
      DC.ComputeResources[0].IpPools = GeneralIpPools;
      DC.ComputeResources[0].ConstructProperties = () => {};
      DC.ComputeResources[0].GetIpPools = () => {};
      DC.ComputeResources[0].GetCdIsoFiles = () => {};
      DC.ComputeResources[0].Datastores = [
        { _VMBC_Type: "DS", Name: "NVMe 1" },
        { _VMBC_Type: "DS", Name: "NVMe 2" },
      ];
      DC.ComputeResources[0].Datastores[0].GetSummary = () => {};
      DC.ComputeResources[0].Datastores[1].GetSummary = () => {};

      DC.ComputeResources[1].CdIsoFiles = [];
      DC.ComputeResources[1].IpPools = GeneralIpPools;
      DC.ComputeResources[1].ConstructProperties = () => {};
      DC.ComputeResources[1].GetIpPools = () => {};
      DC.ComputeResources[1].GetCdIsoFiles = () => {};
      DC.ComputeResources[1].Datastores = [
        { _VMBC_Type: "DS", Name: "SSD 1" },
        { _VMBC_Type: "DS", Name: "SSD 2" },
      ];
      DC.ComputeResources[1].Datastores[0].GetSummary = () => {};
      DC.ComputeResources[1].Datastores[1].GetSummary = () => {};
    } else if (DC.DatacenterMoId === "datacenter-de") {
      DC.ComputeResources = [
        { Name: "148.252.140.5", ComputeResourceMO: {} },
        { Name: "148.252.141.5", ComputeResourceMO: {} },
        { Name: "148.252.142.5", ComputeResourceMO: {} },
      ];
      DC.ComputeResources[0].CdIsoFiles = [];
      DC.ComputeResources[0].IpPools = GeneralIpPools;
      DC.ComputeResources[0].ConstructProperties = () => {};
      DC.ComputeResources[0].GetIpPools = () => {};
      DC.ComputeResources[0].GetCdIsoFiles = () => {};
      DC.ComputeResources[0].Datastores = [
        { _VMBC_Type: "DS", Name: "SSD 1" },
        { _VMBC_Type: "DS", Name: "NVMe 2" },
      ];
      DC.ComputeResources[0].Datastores[0].GetSummary = () => {};
      DC.ComputeResources[0].Datastores[1].GetSummary = () => {};

      DC.ComputeResources[1].CdIsoFiles = [];
      DC.ComputeResources[1].IpPools = GeneralIpPools;
      DC.ComputeResources[1].ConstructProperties = () => {};
      DC.ComputeResources[1].GetIpPools = () => {};
      DC.ComputeResources[1].GetCdIsoFiles = () => {};
      DC.ComputeResources[1].Datastores = [
        { _VMBC_Type: "DS", Name: "SSD 1" },
        { _VMBC_Type: "DS", Name: "NVMe 2" },
      ];
      DC.ComputeResources[1].Datastores[0].GetSummary = () => {};
      DC.ComputeResources[1].Datastores[1].GetSummary = () => {};

      DC.ComputeResources[2].CdIsoFiles = [];
      DC.ComputeResources[2].IpPools = GeneralIpPools;
      DC.ComputeResources[2].ConstructProperties = () => {};
      DC.ComputeResources[2].GetIpPools = () => {};
      DC.ComputeResources[2].GetCdIsoFiles = () => {};
      DC.ComputeResources[2].Datastores = [
        { _VMBC_Type: "DS", Name: "SSD 1" },
        { _VMBC_Type: "DS", Name: "HDD 2" },
      ];
      DC.ComputeResources[2].Datastores[0].GetSummary = () => {};
      DC.ComputeResources[2].Datastores[1].GetSummary = () => {};
    } else if (DC.DatacenterMoId === "datacenter-us") {
      DC.ComputeResources = [
        { Name: "148.253.96.5", ComputeResourceMO: {} },
        { Name: "148.253.97.5", ComputeResourceMO: {} },
      ];
      DC.ComputeResources[0].CdIsoFiles = [];
      DC.ComputeResources[0].IpPools = GeneralIpPools;
      DC.ComputeResources[0].ConstructProperties = () => {};
      DC.ComputeResources[0].GetIpPools = () => {};
      DC.ComputeResources[0].GetCdIsoFiles = () => {};
      DC.ComputeResources[0].Datastores = [
        { _VMBC_Type: "DS", Name: "HDD 2" },
        { _VMBC_Type: "DS", Name: "SSD 3" },
      ];
      DC.ComputeResources[0].Datastores[0].GetSummary = () => {};
      DC.ComputeResources[0].Datastores[1].GetSummary = () => {};

      DC.ComputeResources[1].CdIsoFiles = [];
      DC.ComputeResources[1].IpPools = GeneralIpPools;
      DC.ComputeResources[1].ConstructProperties = () => {};
      DC.ComputeResources[1].GetIpPools = () => {};
      DC.ComputeResources[1].GetCdIsoFiles = () => {};
      DC.ComputeResources[1].Datastores = [
        { _VMBC_Type: "DS", Name: "NVMe 3" },
        { _VMBC_Type: "DS", Name: "NVMe 4" },
      ];
      DC.ComputeResources[1].Datastores[0].GetSummary = () => {};
      DC.ComputeResources[1].Datastores[1].GetSummary = () => {};
    }

    if (props.Data && props.Data.current.PlanAndTarget) {
      props.Data.current.PlanAndTarget.DisableNext = true;
    }
    setTimeout(() => {
      if (
        props.Data &&
        props.Data.current.PlanAndTarget &&
        props.Data.current.PlanAndTarget.DisableNext
      ) {
        BlockExplorer();
      }
    }, 200);
    DC.GetComputeResources(() => {
      if (props.Data && props.Data.current.PlanAndTarget) {
        props.Data.current.PlanAndTarget.DisableNext = false;
        window.$(window.$("#folderExplorer")).unblock();
      }
    });
    DC.GetTemplates();
    props.Data.current.ComputeResources.oldCollection = false;
    props.Data.current.ComputeResources.SelectedComputeResources = [];
    props.Data.current.Datastores.oldCollection = false;
    props.Data.current.Templates = {};
    setSelectedDatacenter({
      ...selectedDatacenter,
      DatacenterMO: DC,
    });
  }, [selectedDatacenter]);

  if (!props.Data || !props.Data.current.PlanAndTarget) {
    return null;
  }

  // update parent Data Object
  props.Data.current.PlanAndTarget.PlanName = planName;
  props.Data.current.PlanAndTarget.CurrentFolder = currentFolder;
  props.Data.current.PlanAndTarget.TargetDatacenter = selectedDatacenter;
  props.Data.current.PlanAndTarget.TargetFolder = selectedObject;

  return (
    <>
      <div
        className={`col-xs-12`}
        style={{ padding: "20px", marginTop: "10px" }}
      >
        <div className={`row align-items-center`}>
          <span
            style={{
              padding: "0 10px",
              textAlign: "right",
              color: "#fff",
              fontSize: "16px",
            }}
          >
            Plan Name:
          </span>
        </div>
        <div className={`row align-items-center`}>
          <input
            type="text"
            placeholder="Plan Name"
            className={`form-control col`}
            value={planName}
            onChange={(e) => {
              setPlanName(e.target.value.replace(",", ""));
            }}
          />
        </div>
      </div>

      <FolderExplorer
        id="folderExplorer"
        style={{ paddingTop: "15px" }}
        Title={"Select Target Folder:"}
        Folder={currentFolder}
        SelectedObject={selectedObject}
        onAddressClicked={setCurrentFolder}
        onRefresh={() => {
          setCurrentFolder({});
          refreshTrigger(!refreshTrigIndex);
        }}
        onObjectSelect={(Obj) => {
          if (Obj.isDatacenter) {
            setSelectedDatacenter(Obj);
            setSelectedObject(Obj);
          }
          if (Obj.isVmFolder) setSelectedObject(Obj);
        }}
        onObjectDoubleClick={(Obj) => {
          if (Obj.isFolder) {
            if (Obj.isDatacenter) {
              setSelectedDatacenter(Obj);
            }
            setCurrentFolder(Obj);
          } else if (Obj.isVmFolder) {
            setSelectedObject(Obj);
          }
        }}
      />

      <div className={`col-xs-10`} style={{ padding: "1rem 1rem 0" }}>
        {selectedDatacenter ? (
          <h6 style={{ marging: "5px", fontSize: "14px" }}>
            Datacenter: {selectedDatacenter.Name}
          </h6>
        ) : undefined}
        {selectedObject ? (
          <h6 style={{ marging: "5px", fontSize: "14px" }}>
            Target Folder: {selectedObject.Name}
          </h6>
        ) : undefined}
      </div>
    </>
  );
}

function parseTargetFolders(tFolders) {
  if (!tFolders) return [];
  var Ret = [];
  tFolders.forEach((tFolder) => {
    Ret.push({
      isFolder: !!tFolder.SubItems,
      isVmFolder: true,
      Name: tFolder.Name,
      FolderId: tFolder.ManagedObjectId,
      icon: (
        <span style={{ padding: "5px" }}>
          <VMBC.SVGs.FolderContained size="70" />
        </span>
      ),
      items: parseTargetFolders(tFolder.SubItems),
    });
  });
  return Ret;
}

export default TargetFolder;
