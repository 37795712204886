import React from "react";
import VMBC from "../../../../../VMBC";

function TotalCountBar(props) {
  const { ipPoolId } = props;

  const [data, setData] = React.useState();

  React.useEffect(() => {
    VMBC.Plans.IpPools.GetIpPoolIPsCountByState(ipPoolId, (res, err) => {
      if (err) {
        VMBC.Alert.Show(err, "error");
      } else {
        setData(res);
      }
    });
    if (document.getElementById("totalCountBarChart")) {
      document.getElementById("totalCountBarChart").innerHTML = "";
      var chart1 = new window.ApexCharts(
        document.querySelector("#totalCountBarChart"),
        GetChartOptions()
      );
      chart1.render();
    }
  }, []);

  React.useEffect(() => {
    if (data && document.getElementById("totalCountBarChart")) {
      document.getElementById("totalCountBarChart").innerHTML = "";
      var chart1 = new window.ApexCharts(
        document.querySelector("#totalCountBarChart"),
        GetChartOptions([data.Free, data.Used, data.Disabled])
      );
      chart1.render();
    }
  }, [data]);

  return (
    <>
      <div class="widget widget-chart-two">
        <div
          class="widget-heading"
          style={{
            paddingBottom: "20px",
            borderBottom: "1px dashed #3b3f5c",
          }}
        >
          <h5 class="">IP Addresses by Category</h5>
        </div>
        <div class="widget-content">
          <div className={`row justify-content-center`}>
            <div id="totalCountBarChart" class=""></div>
          </div>
        </div>
      </div>
    </>
  );
}

function GetChartOptions(series) {
  return {
    chart: {
      type: "donut",
      width: 380,
    },
    colors: ["#5c1ac3", "#e2a03f", "#e7515a", "#e2a03f"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      markers: {
        width: 10,
        height: 10,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 8,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          background: "transparent",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "29px",
              fontFamily: "Quicksand, sans-serif",
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: "26px",
              fontFamily: "Quicksand, sans-serif",
              color: "#bfc9d4",
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: series ? "Total" : "Loading...",
              color: "#888ea8",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce(function (a, b) {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    stroke: {
      show: true,
      width: 25,
      colors: "#0e1726",
    },
    series: series ? series : [0, 0, 0],
    labels: ["Free", "Used", "Disabled"],
    responsive: [
      {
        breakpoint: 1599,
        options: {
          chart: {
            width: "350px",
            height: "400px",
          },
          legend: {
            position: "bottom",
          },
        },

        breakpoint: 1439,
        options: {
          chart: {
            width: "250px",
            height: "380px",
          },
          legend: {
            position: "bottom",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "65%",
              },
            },
          },
        },
      },
    ],
  };
}

export default TotalCountBar;
