import Session from "./Session";
import Users from "./Users";
import VMPlans from "./VMPlans";
import Customers from "./Customers";
import IpAddresses from "./IpAddresses";
import VMachines from "./VMachines";
import VMBC from "../VMBC";
import WHMCS from "./WHMCS";

const Tables = [
  "Customers",
  "Session",
  "Users",
  "VMachines",
  "VMPlans",
  "IpAddresses",
];

function Setup() {
  if (localStorage.getItem("DemoIsReady")) {
    return true;
  }

  Session.Setup();
  Users.Setup();
  VMPlans.Setup();
  Customers.Setup();
  IpAddresses.Setup();
  VMachines.Setup();
  WHMCS.Setup();

  localStorage.setItem("DemoIsReady", true);
}

function loadTable(TableName) {
  if (!Tables.includes(TableName)) {
    console.log("DEMO: " + TableName + " not valid as TableName");
    return false;
  }
  var tblStr = localStorage.getItem("Demo_" + TableName);
  if (!tblStr) {
    console.log("DEMO: " + TableName + " not found at localStorage");
    return false;
  }

  try {
    var tbl = JSON.parse(tblStr);
    if (typeof tbl.length !== "number") {
      console.log("DEMO: " + TableName + " not an array");
      return false;
    }
    return tbl;
  } catch (exc) {
    console.log(
      "DEMO: Error on parsing Demo Table: " + TableName,
      "Err: " + exc
    );
    return false;
  }
}

function Demo(Table) {
  var tbl = loadTable(Table.Name);
  if (!tbl) {
    return [];
  }
  tbl.TableName = Table.Name;
  tbl.push = function (Record) {
    var data = [...this];
    data[data.length] = Record;
    Table.Data = [...data];
    localStorage.setItem("Demo_" + this.TableName, JSON.stringify([...data]));
  };
  tbl.pop = function (callback) {
    var data = [...this];
    var index = 0;
    var res = [];
    for (index = 0; index < data.length; index++) {
      if (callback(data[index]) === true) {
        res.push(data.splice(index, 1));
      }
    }

    Table.Data = [...data];
    localStorage.setItem("Demo_" + this.TableName, JSON.stringify([...data]));

    return res;
  };
  tbl.fill = function () {
    var data = [...this];
    Table.Data = [...data];
    localStorage.setItem("Demo_" + this.TableName, JSON.stringify([...data]));
  };
  return tbl;
}

Demo.Setup = Setup;
Demo.Reset = () => {
  localStorage.clear();
};
Demo.DelayRandom = (cb) => setTimeout(cb, parseInt(Math.random() * 500));
Demo.Random = (start, end) => Math.random() * (end - start) + start;
Demo.RandInt = (start, end) => parseInt(Demo.Random(start, end));

Demo.SampleSize = Demo.RandInt(300, 2000);

Demo.Session = { Name: "Session", Data: loadTable("Session") };
Demo.Users = { Name: "Users", Data: loadTable("Users") };
Demo.VMPlans = { Name: "VMPlans", Data: loadTable("VMPlans") };
Demo.Customers = { Name: "Customers", Data: loadTable("Customers") };
Demo.VMachines = { Name: "VMachines", Data: loadTable("VMachines") };
Demo.IpAddresses = { Name: "IpAddresses", Data: loadTable("IpAddresses") };
Demo.WHMCS = { Name: "WHMCS", Data: loadTable("WHMCS") };

window.Demo = Demo;

export default Demo;
