import { ManagedObject } from "../../DataTypes";

import axios from "axios";
import Utils from "../../Utils"
import Network from "../Network";

class HostSystem extends ManagedObject {

  constructor() {
    if (arguments.length === 1 && typeof arguments[0] === "string") { // HostSystem ( string HostId )
      super(arguments[0]);
    } else if (arguments.length === 1 && arguments[0]._VMBC_Type && arguments[0]._VMBC_Type === "ManagedObject") { // HostSystem ( ManagedObject Host )
      super(arguments[0].MO_Reference);
    }
  }

  GetSummary(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(window.VMBC.Config.Origin + "/api/inventory/hostsystem/summary", {
          SId: window.VMBC.SId,
          HostId: this.MO_Reference
        })
        .then(res => {
          if (!res.data) {
            cb(false);
            return;
          }
          if (res.data.res === "success") {
            cb(res.data.data);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(reason => {
          cb(false);
        });
    });
  }

  GetPortGroupList(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/hostsystem/portgroups",
          { SId: window.VMBC.SId, HostId: this.MO_Reference },
          { timeout: 30000 }
        )
        .then(res => {
          if (!res.data) {
            cb(false)
            return;
          }
          if (res.data.res === "success") {
            var Resp = res.data.data.Networks;
            var PortGroupList = [];
            for (var i = 0; i < Resp.length; i++) {
              PortGroupList.push(new Network(new ManagedObject(Resp[i].Value)));
            }
            this.Networks = PortGroupList;
            cb(this.Networks);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(() => {
          cb(false);
        });
    });
  }

  Networks = [];
  Summary = {};
  _VMBC_Type = "HostSystem";
}



export default HostSystem;
