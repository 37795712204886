import React from "react";
import "./IpPools.css";
import Table from "../../../Components/Table";
import VMBC from "../../../VMBC";

function IpPools(props) {
  return (
    <>
      <GeneralIpPools />
      <SpecificIpPools />
    </>
  );
}

function SpecificIpPools(props) {
  const [DCs, setDCs] = React.useState();

  React.useEffect(() => {
    VMBC.Inventory.GetDatacenters((dcs) => {
      dcs = dcs ? dcs : [];
      setDCs(dcs);
    });
  }, []);

  return (
    <>
      {DCs ? (
        DCs.length > 0 ? (
          DCs.map((dc, i) => <Datacenter DC={dc} key={i} />)
        ) : (
          <div className="widget mt-4 text-center py-4">
            <h6 className="align-self-center" style={{ margin: "auto" }}>
              No Datacenters
            </h6>
          </div>
        )
      ) : (
        <div className="widget mt-4 text-center py-4">
          <div
            className="spinner-border align-self-center loader-lg"
            style={{ width: "3rem", height: "3rem" }}
          ></div>
        </div>
      )}
    </>
  );
}

function GeneralIpPools(props) {
  const [ipPools, setIpPools] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setIpPools();
    VMBC.Plans.IpPools.GetAllGeneralIpPools((ippls) => {
      ippls = ippls ? ippls : [];
      ippls = ippls.map((ippl) => ({
        id: ippl.Id,
        Name: ippl.Name,
        Desc: !ippl.Desc || ippl.Desc === "" ? "No Description" : ippl.Desc,
        FreeIpCount:
          ippl.FreeIpCount === undefined || typeof ippl.FreeIpCount !== "number"
            ? "Unknown"
            : ippl.FreeIpCount,
      }));
      setIpPools(ippls);
    });
  }, [refreshT]);

  const Refresh = () => {
    refresh(!refreshT);
  };

  const callbackHandler = (done) => (res, error) => {
    if (error) {
      VMBC.Alert.Show(error, "error", 3000);
    } else {
      refresh(!refreshT);
    }
    done();
  };

  return (
    <div className="widget mt-4">
      <div className="widget-content vertical-line-pill">
        <div className="row">
          <div className="col-xl-12 col-md-12 col-sm-12 col-12">
            <h4>General IP Pools</h4>
          </div>
        </div>
        <div className="row mb-4 mt-3">
          <div className="col-12">
            <Table
              columns={[
                {
                  title: "Name",
                  fieldName: "Name",
                  editValidator: (newVal) => {
                    if (typeof newVal !== "string" || newVal === "") {
                      return "Name can not be empty";
                    }
                    return;
                  },
                  addValidator: (newVal) => {
                    if (typeof newVal !== "string" || newVal === "") {
                      return "Name can not be empty";
                    }
                    return;
                  },
                },
                {
                  title: "Description",
                  fieldName: "Desc",
                  editValidator: (newVal) => {
                    if (newVal === "") {
                      return "Desc can not be empty";
                    }
                    return;
                  },
                },
                {
                  title: "Free IP Addresses",
                  fieldName: "FreeIpCount",
                  editable: false,
                  addable: false,
                },
              ]}
              data={ipPools}
              actions={{
                onAdd: {
                  EventHandler: (newData, done) => {
                    VMBC.Plans.IpPools.CreateGeneralIpPool(
                      newData.Name,
                      newData.Desc,
                      callbackHandler(done)
                    );
                  },
                },
                onEdit: {
                  EventHandler: (oldRow, newRow, done) => {
                    VMBC.Plans.IpPools.EditGeneralIpPool(
                      oldRow.id,
                      newRow.Name,
                      newRow.Desc,
                      callbackHandler(done)
                    );
                  },
                },
                onDelete: {
                  EventHandler: (row, done) => {
                    VMBC.Plans.IpPools.DeleteGeneralIpPool(
                      row.id,
                      callbackHandler(done)
                    );
                  },
                },
                FreeActions: [
                  {
                    Title: "Refresh",
                    onClick: () => {
                      Refresh();
                    },
                    Icon: (
                      <VMBC.SVGs.RefreshCCW style={{ position: "initial" }} />
                    ),
                  },
                ],
                Others: [
                  {
                    Title: "Settings",
                    Icon: (row) => (
                      <VMBC.A href={`/panel/plans/ipPools/${row.id}`}>
                        <VMBC.SVGs.Settings
                          style={{
                            marginTop: "-4px",
                            marginLeft: "3px",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </VMBC.A>
                    ),
                    onClick: (e, row) => {},
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Datacenter(props) {
  const { DC } = props;

  const [CRs, setCRs] = React.useState();

  React.useEffect(() => {
    DC.GetComputeResources((crs) => {
      crs = crs ? crs : [];
      setCRs(crs);
    });
  }, []);

  return (
    <div className="widget mt-4">
      <div className="widget-content vertical-line-pill">
        <div className="row">
          <div className="col-xl-12 col-md-12 col-sm-12 col-12">
            <h4>{DC.Name}</h4>
          </div>
        </div>
        {!CRs ? (
          <div className={`row justify-content-center`}>
            <div
              className="spinner-border align-self-center loader-lg"
              style={{ width: "2rem", height: "2rem" }}
            ></div>
          </div>
        ) : CRs.length === 0 ? (
          <div className={`row justify-content-center text-center mb-3`}>
            <h6 className="align-self-center" style={{ margin: "auto" }}>
              No Compute Resources
            </h6>
          </div>
        ) : (
          <div className="row mb-4 mt-3">
            <div className="col-sm-2 col-12 pt-5 pr-4"
                style={{ borderRadius: "20px", backgroundColor: "#123" }}>
              <div
                className="nav flex-column nav-pills mb-sm-0 mb-3 text-center mx-auto"
                id="v-line-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                {CRs.map((cr, i) => (
                  <a
                    key={i}
                    className={`nav-link mb-3 ${i === 0 ? "active" : ""}`}
                    id={`v-line-pills-${cr.MO_Reference}-tab`}
                    data-toggle="pill"
                    href={`#v-line-pills-${cr.MO_Reference}`}
                    role="tab"
                    aria-controls={`v-line-pills-${cr.MO_Reference}`}
                    aria-selected={i === 0 ? "true" : "false"}
                  >
                    {cr.Name}
                  </a>
                ))}
              </div>
            </div>

            <div className="col-sm-10 col-12">
              <div className="tab-content" id="v-line-pills-tabContent">
                {CRs.map((cr, i) => (
                  <div
                    key={i}
                    className={`tab-pane fade ${i === 0 ? "show active" : ""}`}
                    id={`v-line-pills-${cr.MO_Reference}`}
                    role="tabpanel"
                    aria-labelledby={`v-line-pills-${cr.MO_Reference}-tab`}
                  >
                    <IpPoolsList CR={cr} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function IpPoolsList(props) {
  const { CR } = props;

  const [ipPools, setIpPools] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setIpPools();
    CR.GetIpPools((ipPls) => {
      ipPls = ipPls ? ipPls : [];
      setIpPools(ipPls.filter((elem) => !elem.IsGeneral));
    });
  }, [refreshT]);

  const Refresh = () => {
    refresh(!refreshT);
  };

  const callbackHandler = (done) => (error) => {
    if (error) {
      VMBC.Alert.Show(error, "error", 3000);
    } else {
      refresh(!refreshT);
    }
    done();
  };

  return (
    <>
      {ipPools ? (
        <Table
          columns={[
            {
              title: "Name",
              fieldName: "Name",
              addValidator: (newVal) => {
                if (
                  newVal === undefined ||
                  typeof newVal !== "string" ||
                  newVal === ""
                ) {
                  return "Name can not be empty";
                }
              },
              editValidator: (newVal) => {
                if (
                  newVal === undefined ||
                  typeof newVal !== "string" ||
                  newVal === ""
                ) {
                  return "Name can not be empty";
                }
              },
            },
            { title: "Description", fieldName: "Description" },
            {
              title: "Free IP Addresses",
              fieldName: "FreeIpCount",
              editable: false,
              addable: false,
            },
          ]}
          data={ipPools}
          actions={{
            onAdd: {
              EventHandler: (newData, done) => {
                CR.CreateNewIpPool(
                  newData.Name,
                  newData.Description,
                  callbackHandler(done)
                );
              },
            },
            onEdit: {
              EventHandler: (oldData, newData, done) => {
                CR.EditIpPool(
                  oldData.Id,
                  newData.Name,
                  newData.Description,
                  callbackHandler(done)
                );
              },
            },
            onDelete: {
              EventHandler: (oldData, done) => {
                CR.DeleteIpPool(oldData.Id, callbackHandler(done));
              },
            },
            FreeActions: [
              {
                Title: "Refresh",
                onClick: () => {
                  Refresh();
                },
                Icon: <VMBC.SVGs.RefreshCCW style={{ position: "initial" }} />,
              },
            ],
            Others: [
              {
                Title: "Settings",
                Icon: (row) => (
                  <VMBC.A href={`/panel/plans/ipPools/${row.Id}`}>
                    <VMBC.SVGs.Settings
                      style={{
                        marginTop: "-4px",
                        marginLeft: "3px",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </VMBC.A>
                ),
                onClick: (e, row) => {},
              },
            ],
          }}
        />
      ) : (
        <div className={`row justify-content-center mt-5`}>
          <div
            className="spinner-border align-self-center loader-lg"
            style={{ width: "2rem", height: "2rem" }}
          ></div>
        </div>
      )}
    </>
  );
}

export default IpPools;
