import React from "react";
import VMBC from "../../../../../../VMBC";

const Tags = {
  0: {
    id: 0,
    name: "{ipAddress}",
    title: "IP Address",
    example: "192.168.1.2",
    unique: true,
  },
  1: { id: 1, name: "{planName}", title: "Plan Name", example: "Gold" },
  2: { id: 2, name: "{cpu}", title: "CPU", example: "8vCore" },
  3: { id: 3, name: "{ram}", title: "RAM", example: "32GB" },
  4: { id: 4, name: "{osType}", title: "OS Type", example: "Windows" },
  5: {
    id: 5,
    name: "{templateAlias}",
    title: "Template Alias",
    example: "Windows Server 2019",
  },
  6: { id: 6, name: "{vmId}", title: "VM-id", example: "VM867", unique: true },
};

function NamingOptions(props) {
  const { ToCol, FromCol } = props.data;

  const fromCol = React.useRef(FromCol);
  const toCol = React.useRef(ToCol);
  const [isUnique, setIsUnique] = React.useState(true);
  const [example, setExample] = React.useState(
    ToCol.map((tag) =>
      tag.name === "{planName}" ? { example: props.PlanName } : tag
    )
      .map((tag) => tag.example)
      .join("-")
  );

  // React.useEffect(() => {
  //   // for saving current selected tags for next time if user come back to this section
  //   // shown tags in html are not from states, only from FromCol and ToCol
  //   // so we're saving selected tags in those
  //   return () => {
  //     // componentWillUnmount
  //     FromCol = fromCol.current;
  //     ToCol = toCol.current;
  //   };
  // }, []);

  React.useEffect(() => {
    window
      .dragula(
        [
          document.getElementById("toTags"),
          document.getElementById("fromTags"),
        ],
        {
          accepts: function (el, target, source, sibling) {
            return true;
          },
        }
      )
      .on("drag", function (el) {
        el.className += " el-drag-ex-1";
      })
      .on("drop", (el) => {
        el.className = el.className.replace("el-drag-ex-1", "");
        Rearrange();
      })
      .on("cancel", function (el) {
        el.className = el.className.replace("el-drag-ex-1", "");
      });
  }, []);

  const Rearrange = () => {
    var newTo = [];
    let newToIsUnique = false;
    var exampl = [];
    document.getElementById(`toTags`).childNodes.forEach((childNode) => {
      var nodeId = Number(childNode.id);
      newTo.push(Tags[nodeId]);
      if (Tags[nodeId].name === "{planName}") {
        exampl.push(props.PlanName);
      } else {
        exampl.push(Tags[nodeId].example);
      }
      newToIsUnique = newToIsUnique ? true : Tags[nodeId].unique;
    });
    var newFrom = [];
    document.getElementById(`fromTags`).childNodes.forEach((childNode) => {
      var nodeId = Number(childNode.id);
      newFrom.push(Tags[nodeId]);
    });
    toCol.current = newTo;
    fromCol.current = newFrom;
    setIsUnique(newToIsUnique);
    setExample(exampl.join("-"));
  };

  const submitNamingPolicy = (renameAll) => {
    VMBC.Plans.EditNamingPolicy(
      props.PlanId,
      {
        NamingPolicy: toCol.current.map((tag) => tag.name).join("-"),
        RenameAll: renameAll,
      },
      (res, err) => {
        if (err) {
          VMBC.Alert.Show(err);
        } else {
          VMBC.Alert.Show(res, "success");
          if (props.Refresh) props.Refresh();
        }
      }
    );
  };

  const submitBtnClicked = () => {
    if (!isUnique) {
      VMBC.Alert.Show("VM Name must contain at least one unique parameter");
      return;
    }

    // all the comments are for the feature that is not yet complete.
    // the feature is renaming all machines when user changes the plan's Naming policy

    VMBC.Dialogs.Show({
      Title: "Editing Naming Policy",
      Text:
        "Are you sure you want to change Naming Policy, newly created machines' name will be different than the former ones.",
      //Text: "Naming policy is going to change, do you want to rename former virtual machines to new naming policy?",
      Actions: [
        // {
        //   text: "Yes, change all",
        //   className: "btn-primary btn-sm",
        //   disabled: true,
        //   onClick: () => {
        //     submitNamingPolicy(true);
        //     VMBC.Dialogs.Close();
        //   },
        // },
        {
          // text: "no, just set to the new machines",
          text: "Yes",
          className: "btn-primary btn-sm",
          onClick: () => {
            submitNamingPolicy(false);
            VMBC.Dialogs.Close();
          },
        },
        {
          text: "No",
          className: "btn-outline-primary btn-sm",
          onClick: () => {
            VMBC.Dialogs.Close();
          },
        },
      ],
    });
  };

  return (
    <>
      <div className={`card no-outer-spacing`}>
        <div className="card-header" id={"namingOptions"}>
          <section className="mb-0 mt-0">
            <div
              role="menu"
              className={`collapsed`}
              data-toggle="collapse"
              data-target={`#namingOptionsAcc`}
              aria-expanded="true"
              aria-controls={`namingOptionsAcc`}
            >
              <span style={{ color: "#fff" }}>Naming Virtual Machine</span>
              <div className="icons">
                <VMBC.SVGs.ChevronDown />
              </div>
            </div>
          </section>
        </div>

        <div
          id={`namingOptionsAcc`}
          className="collapse show"
          aria-labelledby={"namingOptions"}
          data-parent="#toggleAccordion"
        >
          <div className="card-body">
            <div className={`container`}>
              <div
                className={`row align-items-center`}
                style={{ padding: "0 20px 20px 20px", minHeight: "60px" }}
              >
                <div style={{ color: "#fff", fontSize: "15px" }}>VM Name:</div>
                <div style={{ flexGrow: 1 }}>
                  <div className={`container-fluid`}>
                    <div
                      id={"toTags"}
                      className={`row justify-content-center`}
                      style={{ minHeight: "40px" }}
                    >
                      {ToCol.map((tag, i) => (
                        <span
                          id={tag.id}
                          data-name={tag.title}
                          className={`nameTag`}
                          style={{
                            margin: "3px",
                            padding: "5px 10px",
                            borderRadius: "20px",
                            cursor: "pointer",
                            color: "#000",
                            fontSize: "15px",
                            backgroundColor: "#999",
                          }}
                          key={i}
                        >
                          {tag.title}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`row align-items-center`}
                style={{
                  padding: "10px",
                  minHeight: "60px",
                  backgroundColor: "#ffffff0a",
                  borderRadius: "12px",
                  border: "2px dashed #666",
                }}
              >
                <div style={{ color: "#fff", fontSize: "15px" }}>
                  Drag and Drop:
                </div>
                <div style={{ flexGrow: 1 }}>
                  <div className={`container-fluid`}>
                    <div
                      id={"fromTags"}
                      className={`row justify-content-center`}
                      style={{ minHeight: "40px" }}
                    >
                      {FromCol.map((tag, i) => (
                        <span
                          id={tag.id}
                          data-name={tag.title}
                          className={`nameTag`}
                          style={{
                            margin: "3px",
                            padding: "5px 10px",
                            borderRadius: "20px",
                            cursor: "pointer",
                            color: "#000",
                            fontSize: "15px",
                            backgroundColor: "#999",
                          }}
                          key={i}
                        >
                          {tag.title}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {!isUnique ? (
                <h6
                  style={{ width: "100%", margin: "10px 0 0 0", color: "red" }}
                >
                  VM Name must contain at least one unique parameter(IP Address
                  or VM-id)
                </h6>
              ) : undefined}
              <h6
                style={{ width: "100%", margin: "15px 0 0 0", color: "#bbb" }}
              >
                {example !== "" ? `Example: ${example}` : ""}
              </h6>

              {[...toCol.current].map((tag) => tag.name).join("-") !==
              [...ToCol].map((tag) => tag.name).join("-") ? (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className={`btn btn-primary`}
                    onClick={submitBtnClicked}
                  >
                    Submit Changes
                  </button>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NamingOptions;
