import React from "react";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import IsoFiles from "./IsoFiles";
window.AddToLoadingVmboxProccess(1);

function IsoFilesRoot(props) {
  return (
    <Switch>
      <Route path={`${props.match.path}/`} exact children={<IsoFiles />} />
    </Switch>
  );
}

export default withRouter(IsoFilesRoot);
