import Demo from "..";

const TableName = "Demo_VMachines";

var States = [
  "Registered",
  "Registered",
  "Deployed",
  "Deployed",
  "PoweredOn",
  "PoweredOn",
  "PoweredOn",
  "PoweredOn",
  "PoweredOn",
  "PoweredOn",
  "PoweredOn",
  "PoweredOn",
  "PoweredOn",
  "PoweredOn",
  "PoweredOn",
  "PoweredOn",
  "PoweredOn",
  "PoweredOff",
  "PoweredOff",
  "VmSuspended",
];
var OSs = [
  "Windows Server 2012",
  "Windows Server 2016",
  "Windows Server 2019",
  "CentOS 7",
  "CentOS 8",
  "Ubuntu 16.04",
  "Ubuntu 18.04",
  "Ubuntu 20.04",
];

function Setup() {
  var Table = [];
  var DefaultData = [];

  var statesLen = States.length;
  var getRandomState = () => States[Demo.RandInt(0, statesLen)];
  var OSsLen = OSs.length;
  var getRandomOS = () => OSs[Demo.RandInt(0, OSsLen)];

  // var vmsCount = Demo.RandInt(500, 1000);
  var vmsCount = Demo.RandInt(50, 100);

  var customersCount = Demo.Customers.Data.length;
  var plansCount = Demo.VMPlans.Data.length;
  var IpAddresses = Demo(Demo.IpAddresses);
  var IpAddressesCount = IpAddresses.length;
  var getRandomIp = (vmId) => {
    var ipId = Demo.RandInt(0, IpAddressesCount);
    var ip = IpAddresses[ipId];
    if (ip.LINK_vmachinesId !== 0) {
      return getRandomIp(vmId);
    }
    IpAddresses[ipId].LINK_vmachinesId = vmId;
    if (IpAddresses.fill) IpAddresses.fill();
    return IpAddresses[ipId].ip;
  };

  for (var i = 1; i <= vmsCount; i++) {
    var ownerId = Demo.RandInt(1, customersCount);
    var planId = Demo.RandInt(1, plansCount);
    var plan = Demo.VMPlans.Data[planId];
    if (!plan) {
      window.p = plan;
      console.log("Plan: " + planId + " not defined");
    }
    var ip = getRandomIp(i);

    DefaultData.push({
      id: i,
      vmName: `${ip}-${plan.Profiles.Ram}G DDR4-${
        plan.Profiles.Cpu
      }vCore-${getRandomOS()}-VM${i}`,
      LINK_ownerId: ownerId,
      LINK_planId: planId,
      Cpu: plan.Profiles.Cpu,
      Memory: plan.Profiles.Ram,
      HardDisk: plan.Profiles.Disk,
      State: getRandomState(),
      Ip: ip,
    });
  }

  Table.push(...DefaultData);
  localStorage.setItem(TableName, JSON.stringify(Table));
  Demo.VMachines = { Name: "VMachines", Data: Table };
}

export default { Setup };
