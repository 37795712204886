import React from "react";
import NamingOptions from "./NamingOptions";
import LimitNetworkUsage from "./LimitNetworkUsage";
import VMBC from "../../../../../../VMBC";

const Tags = [
  {
    id: 0,
    name: "{ipAddress}",
    title: "IP Address",
    example: "192.168.1.1",
    unique: true,
  },
  { id: 1, name: "{planName}", title: "Plan Name", example: "Light" },
  { id: 2, name: "{cpu}", title: "CPU", example: "8vCore" },
  { id: 3, name: "{ram}", title: "RAM", example: "32GB" },
  { id: 4, name: "{osType}", title: "OS Type", example: "Windows" },
  {
    id: 5,
    name: "{templateAlias}",
    title: "Template Alias",
    example: "Windows Server 2016",
  },
  { id: 6, name: "{vmId}", title: "VM-id", example: "VM174", unique: true },
];

function VmOptions(props) {
  const { plan } = props;

  const [data, setData] = React.useState();

  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    var Data = {
      NamingOptions: { FromCol: [], ToCol: [] },
      LimitNetworkUsage: {
        PlanId: plan.PlanId,
        enabled: false,
      },
    };
    var fromDef = plan.VmNamingPolicy.split("-");
    fromDef.forEach((tag) => {
      for (var i = 0; i < Tags.length; i++) {
        if (Tags[i].name == tag) {
          Data.NamingOptions.ToCol.push(Tags[i]);
          break;
        }
      }
    });
    Tags.forEach((tag) => {
      if (!fromDef.includes(tag.name)) {
        Data.NamingOptions.FromCol.push(tag);
      }
    });

    if (plan.PlanNetworkQuotaId != "0") {
      Data.LimitNetworkUsage.networkQuotaId = plan.PlanNetworkQuotaId;
      Data.LimitNetworkUsage.enabled = true;
      if (plan.TxLimit > 0) {
        Data.LimitNetworkUsage.limitTx = true;
        if (plan.TxLimit % 1024 == 0) {
          Data.LimitNetworkUsage.limitTxByUnit = 1;
          Data.LimitNetworkUsage.limitTxBy = plan.TxLimit / 1024;
        } else {
          Data.LimitNetworkUsage.limitTxByUnit = 0;
          Data.LimitNetworkUsage.limitTxBy = plan.TxLimit;
        }
      }
      if (plan.RxLimit > 0) {
        Data.LimitNetworkUsage.limitRx = true;
        if (plan.RxLimit % 1024 == 0) {
          Data.LimitNetworkUsage.limitRxByUnit = 1;
          Data.LimitNetworkUsage.limitRxBy = plan.RxLimit / 1024;
        } else {
          Data.LimitNetworkUsage.limitRxByUnit = 0;
          Data.LimitNetworkUsage.limitRxBy = plan.RxLimit;
        }
      }
      if (plan.UsageLimit > 0) {
        Data.LimitNetworkUsage.limitUsage = true;
        if (plan.UsageLimit % 1024 == 0) {
          Data.LimitNetworkUsage.limitUsageByUnit = 1;
          Data.LimitNetworkUsage.limitUsageBy = plan.UsageLimit / 1024;
        } else {
          Data.LimitNetworkUsage.limitUsageByUnit = 0;
          Data.LimitNetworkUsage.limitUsageBy = plan.UsageLimit;
        }
      }
      if (plan.ResetInterval > 0) {
        Data.LimitNetworkUsage.resetQuota = true;
        if (plan.ResetInterval % 24 == 0) {
          Data.LimitNetworkUsage.resetAfterUnit = 1;
          Data.LimitNetworkUsage.resetAfter = plan.ResetInterval / 24;
        } else {
          Data.LimitNetworkUsage.resetAfterUnit = 0;
          Data.LimitNetworkUsage.resetAfter = plan.ResetInterval;
        }
      }
      Data.LimitNetworkUsage.limitByRatio = plan.RatioLimitType != 0;
      Data.LimitNetworkUsage.limitRatioType = plan.RatioLimitType;
      Data.LimitNetworkUsage.maxAllowedRatio = plan.RatioAllowed;
      Data.LimitNetworkUsage.limitByRatioCheckpoint = plan.RatioCheckpoint;
      Data.LimitNetworkUsage.limitByRatioCheckpointType =
        plan.RatioCheckpointType;
    }
    setData(Data);
  }, [refreshT]);

  const Refresh = () => refresh(!refreshT);

  return (
    <>
      <div id="toggleAccordion" className={`no-outer-spacing`}>
        {data ? (
          <>
            <NamingOptions
              data={data.NamingOptions}
              PlanId={plan.PlanId}
              PlanName={plan.Name}
              isLoading={!data}
              Refresh={Refresh}
              Reload={props.Refresh}
            />
            <LimitNetworkUsage
              data={data.LimitNetworkUsage}
              isLoading={!data}
              Refresh={Refresh}
              Reload={props.Refresh}
            />
          </>
        ) : undefined}
      </div>
    </>
  );
}

export default VmOptions;
