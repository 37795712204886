import React from "react";
import VMBC from "../../../VMBC";
import PlanCondition from "./PlanCondition";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

function PlansConditions(props) {
  const [plansList, setPlansList] = React.useState();

  React.useEffect(() => {
    VMBC.Dashboard.GetPlansConditions((res, err) => {
      if (err) {
        VMBC.Alert.Show(err, "error");
      } else {
        setPlansList(res);
      }
    });
  }, []);

  return (
    <div className="widget widget-table-two">
      <div
        className="widget-heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5>Plans Condition</h5>
      </div>

      <div className="widget-content">
        <ScrollBar className="table-responsive" style={{ maxHeight: "300px" }}>
          <table className="table">
            <thead>
              <tr>
                <th>
                  <div className="th-content text-center">Plan Name</div>
                </th>
                <th>
                  <div className="th-content text-center">Available IPs</div>
                </th>
                <th>
                  <div className="th-content text-center">Deployable VMs</div>
                </th>
                <th>
                  <div className="th-content text-center">Largest VM Disk Usage</div>
                </th>
                <th>
                  <div className="th-content text-center">Actions</div>
                </th>
              </tr>
            </thead>
            {plansList ? (
              <tbody>
                {plansList.map((plan) => (
                  <PlanCondition plan={plan} />
                ))}
              </tbody>
            ) : undefined}
          </table>
        </ScrollBar>
        {plansList ? (
          plansList.length != undefined && plansList.length === 0 ? (
            <div className={`my-4 text-center`}>No Plans found</div>
          ) : undefined
        ) : (
          <div
            className={`my-2`}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="spinner-border align-self-center loader-lg"
              style={{ width: "3rem", height: "3rem" }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PlansConditions;
