import React from "react";
import VMBC from "../../../../../VMBC";

function Change(props) {
  const { trigerFuncRef, vm, Refresh } = props;

  const [comps, setComps] = React.useState();
  const [selectedCompIndex, setSelectedCompIndex] = React.useState();
  const [selectedComp, setSelectedComp] = React.useState();

  const [selectedDsIndex, setSelectedDsIndex] = React.useState();
  const [selectedDs, setSelectedDs] = React.useState();

  const [loading, setLoading] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState();

  React.useEffect(() => {
    if (trigerFuncRef) {
      trigerFuncRef.current = (Groups) => {
        setComps(Groups);
        Groups.forEach((grp, grpIndex) =>
          grp.GroupCRs.forEach((comp, compIndex) => {
            comp.GroupId = grp.GroupId;
            comp.DatastoreGroups.forEach((dsGrp) => {
              dsGrp.Datastores.forEach((ds) => {
                ds.GroupId = dsGrp.GroupId;
              });
            });
            if (comp.Selected) {
              setSelectedComp(comp);
              setSelectedCompIndex(`${grpIndex}-${compIndex}`);
            }
          })
        );
        window.$("#changeCompAndDsModal").modal("show");
      };
    }
  }, []);

  React.useEffect(() => {
    window.$(".selectCompSelectpicker").selectpicker("refresh");
    window.$(".selectDsSelectpicker").selectpicker("refresh");
  });

  const SelectedCompChange = (e) => {
    if (!!confirmMessage) {
      VMBC.Alert.Show(
        "Changing the Options will have no effect, Please reopen the page.",
        "warning"
      );
      return;
    }
    var [groupIndex, CompIndex] = e.target.value.split("-");
    if (comps[groupIndex] && comps[groupIndex].GroupCRs[CompIndex]) {
      setSelectedComp(comps[groupIndex].GroupCRs[CompIndex]);
      setSelectedCompIndex(e.target.value);
      setSelectedDs();
      setSelectedDsIndex("");
      comps[groupIndex].GroupCRs[CompIndex].DatastoreGroups.forEach(
        (group, gId) =>
          group.Datastores.forEach((ds, dsIndex) => {
            if (ds.Selected) {
              setSelectedDs(ds);
              setSelectedDsIndex(`ds-${gId}-${dsIndex}`);
            }
          })
      );
    }
  };

  const SelectedDsChange = (e) => {
    if (!!confirmMessage) {
      VMBC.Alert.Show(
        "Changing the Options will have no effect, Please reopen the page.",
        "warning"
      );
      return;
    }
    var [nothing, groupIndex, dsIndex] = e.target.value.split("-");
    if (
      selectedComp &&
      selectedComp.DatastoreGroups[groupIndex].Datastores[dsIndex]
    ) {
      setSelectedDs(
        selectedComp.DatastoreGroups[groupIndex].Datastores[dsIndex]
      );
      setSelectedDsIndex(`ds-${groupIndex}-${dsIndex}`);
    }
  };

  const submitChanges = () => {
    if (!selectedComp) {
      VMBC.Alert.Show("Please select a Compute Resource", "info");
    } else if (!selectedDs) {
      VMBC.Alert.Show("Please select a Datastore", "info");
    } else if (selectedComp.Selected && selectedDs.Selected) {
      VMBC.Alert.Show("No Changes have been made", "info");
    } else {
      setLoading(true);
      VMBC.Customers.VirtualMachines.CompsAndDs.EditComputeResourceAndDs(
        vm.Id,
        {
          ComputeResourceGroupId: selectedComp.GroupId,
          ComputeResourceId: selectedComp.Id,
          DatastoreGroupId: selectedDs.GroupId,
          DatastoreId: selectedDs.Id,
          Force: !!confirmMessage,
        },
        (res, err) => {
          if (err) {
            VMBC.Alert.Show(err, "error");
          } else if (res.Type) {
            if (res.Type.toLowerCase() === "confirm") {
              setConfirmMessage(res.Message);
            } else if (res.Type.toLowerCase() === "success") {
              VMBC.Alert.Show("Done", "success");
              window.$("#changeCompAndDsModal").modal("hide");
              setTimeout(() => window.location.reload(), 1000);
            }
          }
          setLoading(false);
        }
      );
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="changeCompAndDsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Change Compute Resource and Datastore"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Change Compute Resource and Datastore
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <VMBC.SVGs.CloseXIcon />
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group d-flex justify-content-center mx-auto mt-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">Compute Resource</div>
                </div>

                <select
                  className="selectCompSelectpicker"
                  title="Choose an Option"
                  data-width="fit"
                  onChange={SelectedCompChange}
                  value={selectedCompIndex}
                >
                  {comps
                    ? comps.map((group, gId) => (
                        <optgroup label={group.GroupName}>
                          {group.GroupCRs.map((comp, cId) => (
                            <option
                              value={`${gId}-${cId}`}
                              disabled={comp.Disabled}
                            >
                              {comp.Name}
                            </option>
                          ))}
                        </optgroup>
                      ))
                    : undefined}
                </select>
              </div>

              {selectedComp ? (
                <div
                  className={`input-group d-flex justify-content-center mx-auto mt-3`}
                >
                  <div className="input-group-prepend">
                    <div className="input-group-text">Datastore</div>
                  </div>

                  <select
                    className="selectDsSelectpicker"
                    title={"Choose an Option"}
                    data-width="fit"
                    onChange={SelectedDsChange}
                    value={selectedDsIndex}
                  >
                    {selectedComp.DatastoreGroups.map((group, gId) => (
                      <optgroup label={group.GroupName}>
                        {group.Datastores.length > 0 ? (
                          group.Datastores.map((ds, ind) => {
                            return (
                              <option
                                value={`ds-${gId}-${ind}`}
                                disabled={ds.Disabled}
                              >
                                {ds.Name}
                              </option>
                            );
                          })
                        ) : (
                          <option value={`noDss-${gId}`} disabled>
                            No Datastores
                          </option>
                        )}
                      </optgroup>
                    ))}
                  </select>
                </div>
              ) : undefined}
              <div className={`d-flex justify-content-center py-4`}>
                {loading ? <VMBC.Loader /> : undefined}
              </div>
              {!!confirmMessage ? (
                <div>
                  <h6>Are you sure?</h6>
                  <p>{confirmMessage}</p>
                </div>
              ) : undefined}
              {/* <div className={`p-2 d-flex justify-content-around`}>
                <span>
                  CR: {selectedComp ? selectedComp.Name : "Not Selected"}
                </span>
                <span>DS: {selectedDs ? selectedDs.Name : "Not Selected"}</span>
              </div>
              <div className={`p-2 d-flex justify-content-around`}>
                <span>CR Index: {selectedCompIndex}</span>
                <span>DS Index: {selectedDsIndex}</span>
              </div>
              <div className={`p-2 d-flex justify-content-around`}>
                <span>
                  CR Id: {selectedComp ? selectedComp.Id : "not selected"}
                </span>
                <span>
                  DS Id: {selectedDs ? selectedDs.Id : "not selected"}
                </span>
              </div> */}
            </div>
            <div className="modal-footer">
              <button
                className={`btn btn-outline-primary btn-sm`}
                onClick={() => {
                  window.$("#changeCompAndDsModal").modal("hide");
                }}
              >
                {!!confirmMessage ? "No" : "Close"}
              </button>
              <button
                className={`btn btn-primary btn-sm`}
                onClick={() => !loading && submitChanges()}
              >
                {!!confirmMessage ? "Yes" : "Submit Changes"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Change;
