import React from "react";
import Table from "../../../../Components/Table";
import VMBC from "../../../../VMBC";
import { withRouter } from "react-router";
import Demo from "../../../../Demo";

function VmList(props) {
  const [customers, setCustomers] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setCustomers();
    Demo.DelayRandom(() => {
      var res = Demo(Demo.Customers);
      res = res ? res : [];
      res = res.map((customer) => ({
        ...customer,
        Id: Number(customer.id),
        ClientId: Number(customer.id),
        Name: `${customer.firstname} ${customer.lastname}`,
        WhmcsName: "FireServer",
      }));
      res.reverse();
      setCustomers(res);
    });
  }, [refreshT]);

  const Refresh = () => refresh(!refreshT);

  return (
    <div className="row layout-top-spacing">
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div className="widget widget-table-two">
          <Table
            columns={[
              { title: "ID", fieldName: "Id" },
              { title: "Name", fieldName: "Name" },
              { title: "Email", fieldName: "email" },
              {
                title: "WHMCS",
                fieldName: "WhmcsName",
                render: (rowData) => (
                  <span>
                    {rowData.WhmcsName}, ClientId: {rowData.ClientId}
                  </span>
                ),
              },
              // { title: "Client id at WHMCS", fieldName: "ClientId" },
            ]}
            data={customers}
            actions={{
              Others: [
                {
                  Icon: (rowData) => (
                    <VMBC.A
                      href={`/panel/customers/machines?client=${rowData.Id}`}
                    >
                      <button className={`btn btn-primary btn-sm`}>
                        Show All VMs
                      </button>
                    </VMBC.A>
                  ),
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
}

function GetQuery(search, query) {
  if (search.includes("?")) {
    let queries = search
      .split("?")[1]
      .split("&")
      .filter((q) => (typeof q == "string" ? q.startsWith(query) : false));
    if (queries.length > 0) {
      let qq = queries[0].split("=");
      if (qq.length == 2) {
        return qq[1];
      }
    }
  }
  return "";
}

export default withRouter(VmList);
