import axios from "axios";
import Utils from "../../../Utils";

class IpAddress {
  constructor() {
    if (arguments.length === 1 && typeof arguments[0] === "string") {
      // IpAddress ( string IpAddressRecordId )
      this.Id = arguments[0];
    }
  }

  ConstructIPs() {
    this.IP_STR = this.IP ? this.IP.ToString() : this.IP_STR;

    if (this.IP && this.SubnetMask) {
      this.IP._netPrefix = this.SubnetMask.SubnetMaskToNetPrefix(
        this.SubnetMask
      );
    }

    this.SubnetMask_STR = this.SubnetMask
      ? this.IP.ToSubnet()
      : this.SubnetMask_STR;

    this.Gateway_STR = this.Gateway
      ? this.Gateway.ToString()
      : this.Gateway_STR;
    this.Dns1_STR = this.Dns1 ? this.Dns1.ToString() : this.Dns1_STR;
    this.Dns2_STR = this.Dns2 ? this.Dns2.ToString() : this.Dns2_STR;
  }

  SubmitChanges(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/ipPool/editIpAddress",
          {
            SId: window.VMBC.SId,
            IpAddressId: this.Id,
            Ip: this.IP._ip,
            SubnetMask: this.SubnetMask._ip,
            Gateway: this.Gateway._ip,
            Dns1: this.Dns1._ip,
            Dns2: this.Dns2._ip,
            RDNS: this.RDNS,
            Mac: this.Mac,
            PortGroupId: this.PortGroupId,
          },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb("Unknown Error");
            return;
          }
          if (res.data.res === "success") {
            cb(false);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
            cb("Session Expired");
          } else {
            cb("Unknown Error");
          }
        })
        .catch((res) => {
          cb("Request Error");
        });
    });
  }

  Delete(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => {};
      axios
        .post(
          window.VMBC.Config.Origin + "/api/inventory/ipPool/deleteIpAddress",
          { SId: window.VMBC.SId, IpAddressId: this.Id },
          { timeout: 30000 }
        )
        .then((res) => {
          if (!res.data) {
            cb("Unknown Error");
            return;
          }
          if (res.data.res === "success") {
            cb(false);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
            cb("Session Expired");
          } else if (res.data.err) {
            cb(res.data.err);
          } else if (res.data.res === "error") {
            cb(res.data.data);
          } else {
            cb("Unknown Error");
          }
        })
        .catch((res) => {
          cb("Request Error");
        });
    });
  }

  Id = "";

  IP = null;
  SubnetMask = null;
  Gateway = null;
  Dns1 = null;
  Dns2 = null;
  RDNS = "";
  Mac = "";
  PortGroupId = "";
  PortGroupName = "";
  AssignmentId = "";
  AssignmentName = "";

  IP_STR = "";
  SubnetMask_STR = "";
  Gateway_STR = "";
  Dns1_STR = "";
  Dns2_STR = "";
}

export default IpAddress;
