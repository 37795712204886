import React from "react";
import VMBC from "../../../../../VMBC";
import A from "../../../../../VMBC/Utils/A";

function RecentAssignments(props) {
  const { ipPoolId } = props;

  const [assignments, setAssignments] = React.useState();

  React.useEffect(() => {
    VMBC.Plans.IpPools.GetIpPoolRecentAssignments(ipPoolId, (res, err) => {
      if (err) {
        VMBC.Alert.Show(err, "error");
      } else {
        setAssignments(
          res.map((assignment) => {
            var when =
              parseInt(Date.now() / 1000) -
              (assignment.ReleasedDate > assignment.AssignedDate
                ? assignment.ReleasedDate
                : assignment.AssignedDate);
            var Ago = "";
            if (when < 10) {
              Ago = "Just Now";
            } else if (when < 60) {
              Ago = `${when}s ago`;
            } else if (when < 3600) {
              Ago = `${parseInt(when / 60)}min${
                parseInt(when / 60) === 1 ? "" : "s"
              } ago`;
            } else if (when < 86400) {
              Ago = `${parseInt(when / 3600)}hour${
                parseInt(when / 3600) === 1 ? "" : "s"
              } ago`;
            } else if (when < 7 * 86400) {
              Ago = `${parseInt(when / 86400)}day${
                parseInt(when / 86400) === 1 ? "" : "s"
              } ago`;
            } else if (when < 30 * 86400) {
              Ago = `${parseInt(when / (7 * 86400))}week${
                parseInt(when / (7 * 86400)) === 1 ? "" : "s"
              } ago`;
            } else if (when < 365 * 86400) {
              Ago = `${parseInt(when / (30 * 86400))}month${
                parseInt(when / (30 * 86400)) === 1 ? "" : "s"
              } ago`;
            } else {
              Ago = `${parseInt(when / (365 * 86400))}year${
                parseInt(when / (365 * 86400)) === 1 ? "" : "s"
              } ago`;
            }
            return {
              ...assignment,
              Ago,
            };
          })
        );
      }
    });
  }, []);

  return (
    <>
      <div
        className="widget widget-activity-one d-flex"
        style={{ height: "100%", flexDirection: "column" }}
      >
        <div
          className="widget-heading"
          style={{
            paddingBottom: "20px",
            borderBottom: "1px dashed #3b3f5c",
          }}
        >
          <h5 className="">Recent Assignments</h5>
        </div>

        {assignments && assignments.length === 0 ? (
          <h6 className={`text-center mt-5`}>No Assignments yet</h6>
        ) : undefined}

        <div
          className="widget-content d-flex"
          style={{ flexGrow: "1", flexDirection: "column" }}
        >
          <div className="activity-selector d-flex justify-content-end">
            {assignments &&
            assignments.length !== undefined &&
            assignments.length > 0 ? (
              <span>Latest {assignments.length} assignments</span>
            ) : undefined}
          </div>

          {!assignments ? (
            <div className={`d-flex justify-content-center`}>
              <VMBC.Loader />
            </div>
          ) : undefined}

          {assignments && assignments.length > 0 ? (
            <div className="mt-container mx-auto" style={{ flexGrow: "1" }}>
              <div className="timeline-line">
                {assignments.map((assignment) => (
                  <div
                    className={`item-timeline ${
                      !assignment.Disabled ? "timeline-assigned" : ""
                    }`}
                  >
                    <p className="t-time mr-3">{assignment.Ago}</p>
                    <div
                      className="t-dot"
                      data-original-title=""
                      title="A"
                    ></div>
                    <div className="t-text">
                      <p>
                        <span>{assignment.Ip}</span> assigned to{" "}
                        <VMBC.A
                          href={`/panel/machines/list/${assignment.VmRecordId}`}
                        >
                          {" "}
                          {assignment.CustomerName}{" "}
                        </VMBC.A>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : undefined}
        </div>
      </div>
    </>
  );
}

export default RecentAssignments;
