import React from "react";
import { Switch, Route, withRouter } from "react-router";

import License from "./License";
window.AddToLoadingVmboxProccess(1);

function Administration(props) {
  return (
    <>
      <Switch>
        <Route path={`${props.match.path}/license`} children={License} />
      </Switch>
    </>
  );
}

export default withRouter(Administration);
