import React from "react";
import VMBC from "../../../VMBC";
import { withRouter } from "react-router";
import Demo from "../../../Demo";

function WHMCS(props) {
  const [allWHMCS, setAllWHMCS] = React.useState();
  const [refreshT, refresh] = React.useState(true);

  const newWhmcsName = React.useRef("");
  const newWhmcsDesc = React.useRef("");
  const newWhmcsAdmin = React.useRef("");

  const editWhmcsName = React.useRef("");
  const editWhmcsDesc = React.useRef("");
  const editWhmcsAdmin = React.useRef("");

  React.useEffect(() => {
    if (!allWHMCS) {
      setAllWHMCS();
      Demo.DelayRandom(() => {
        setAllWHMCS(Demo(Demo.WHMCS));
      });
    }
  }, [refreshT]);

  const GenerateModule = (e, whmcs) => {
    VMBC.Alert.Show("This feature is not available in Demo Version yet", "info");
    return;
    VMBC.Customers.WHMCS.GetSetting("", (res, err) => {
      if (err) {
        VMBC.Alert.Show(err);
      } else {
        VMBC.Dialogs.Show({
          Title: "Generate Module",
          Text: "Please confirm below settings for new module.",
          Ignorable: true,
          Fields: (
            <GenerateModuleSettings
              setting={{ ...res, VMBOX_TOKEN: whmcs.Token }}
            />
          ),
          Actions: [
            {
              text: "Cancel",
              className: "btn-outline-primary btn-sm",
              onClick: () => VMBC.Dialogs.Close(),
            },
          ],
        });
      }
    });
  };

  if (!allWHMCS) {
    return (
      <div className="widget mt-4 text-center py-4">
        <div
          className="spinner-border align-self-center loader-lg"
          style={{ width: "3rem", height: "3rem" }}
        ></div>
      </div>
    );
  }

  const addWhmcsClicked = () => {
    VMBC.Dialogs.Show({
      Title: "Add WHMCS Module",
      Fields: (
        <div className={`container`}>
          <div className={`row justify-content-center`}>
            <div className={`col-12 mt-2`}>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text">Name</div>
                </div>
                <input
                  class="form-control"
                  id={"addWhmcsName"}
                  aria-label="switch"
                  placeholder={"FireServer"}
                  defaultValue={newWhmcsName.current}
                  onChange={(e) => (newWhmcsName.current = e.target.value)}
                />
              </div>
            </div>

            <div className={`col-12 mt-2`}>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text">Description</div>
                </div>
                <input
                  class="form-control"
                  id={"addWhmcsDesc"}
                  aria-label="switch"
                  aria-multiline={true}
                  defaultValue={newWhmcsDesc.current}
                  onChange={(e) => (newWhmcsDesc.current = e.target.value)}
                />
              </div>
            </div>

            <div className={`col-12 mt-2`}>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text">Admin Area Link Address</div>
                </div>
                <input
                  class="form-control"
                  id={"addWhmcsAdminLink"}
                  aria-label="switch"
                  aria-multiline={true}
                  placeholder={"https://clients.fireserver.org/admin"}
                  defaultValue={newWhmcsAdmin.current}
                  onChange={(e) => (newWhmcsAdmin.current = e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      ),
      Actions: [
        {
          text: "Close",
          className: "btn-outline-primary btn-sm",
          onClick: () => VMBC.Dialogs.Close(),
        },
        {
          text: "Add",
          className: "btn-primary btn-sm",
          onClick: () => {
            Demo.DelayRandom(() => {
              var whmcses = [...allWHMCS];
              whmcses.push({
                Id:
                  typeof Demo.WHMCS.Data.length === "number"
                    ? Demo.WHMCS.Data.length + 1
                    : 1,
                Name: newWhmcsName.current,
                Desc: newWhmcsDesc.current,
                AdminAddress: newWhmcsAdmin.current,
                Token: Demo.RandInt(1000000000, 10000000000),
                CreatedDate: (new Date()).toLocaleString(),
                CustomerCount: Demo.RandInt(100, 1000),
              });
              VMBC.Alert.Show(
                newWhmcsName.current + " added successfuly.",
                "success",
                3000
              );
              setAllWHMCS(whmcses);
            });
            VMBC.Dialogs.Close();
          },
        },
      ],
    });
  };

  const editWhmcsClicked = ({ Id, Name, Desc, AdminAddress }) => {
    editWhmcsName.current = Name;
    editWhmcsDesc.current = Desc;
    editWhmcsAdmin.current = AdminAddress;
    VMBC.Dialogs.Show({
      Title: "Edit WHMCS Module",
      Fields: (
        <div className={`container`}>
          <div className={`row justify-content-center`}>
            <div className={`col-12 mt-2`}>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text">Name</div>
                </div>
                <input
                  class="form-control"
                  id={"editWhmcsName"}
                  aria-label="switch"
                  placeholder={"FireServer"}
                  defaultValue={editWhmcsName.current}
                  onChange={(e) => (editWhmcsName.current = e.target.value)}
                />
              </div>
            </div>

            <div className={`col-12 mt-2`}>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text">Description</div>
                </div>
                <input
                  class="form-control"
                  id={"editWhmcsDesc"}
                  aria-label="switch"
                  aria-multiline={true}
                  defaultValue={editWhmcsDesc.current}
                  onChange={(e) => (editWhmcsDesc.current = e.target.value)}
                />
              </div>
            </div>

            <div className={`col-12 mt-2`}>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text">Admin Address</div>
                </div>
                <input
                  class="form-control"
                  id={"editWhmcsAdminLink"}
                  aria-label="switch"
                  aria-multiline={true}
                  placeholder={"https://clients.fireserver.org/admin"}
                  defaultValue={editWhmcsAdmin.current}
                  onChange={(e) => (editWhmcsAdmin.current = e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      ),
      Actions: [
        {
          text: "Cancel",
          className: "btn-outline-primary btn-sm",
          onClick: () => VMBC.Dialogs.Close(),
        },
        {
          text: "Edit",
          className: "btn-primary btn-sm",
          onClick: () => {
            VMBC.Alert.Show(
              "This feature is not available in Demo Version yet",
              "info"
            );
            VMBC.Dialogs.Close();
          },
        },
      ],
    });
  };

  const deleteWhmcsClicked = ({ Id }) => {
    VMBC.Dialogs.Show({
      Title: "Delete WHMCS Module",
      Text: "Are you sure you want to delete this WHMCS?",
      Actions: [
        {
          text: "No",
          className: "btn-outline-primary btn-sm",
          onClick: () => VMBC.Dialogs.Close(),
        },
        {
          text: "Yes",
          className: "btn-primary btn-sm",
          onClick: () => {
            VMBC.Alert.Show(
              "This feature is not available in Demo Version yet",
              "info"
            );
            VMBC.Dialogs.Close();
          },
        },
      ],
    });
  };

  return (
    <>
      <div className="widget mt-4 text-center py-4">
        <div className={`container`}>
          <div className={`row justify-content-end`}>
            {allWHMCS && allWHMCS.length !== 0 ? (
              <button className={`btn btn-primary`} onClick={addWhmcsClicked}>
                Add New WHMCS
              </button>
            ) : undefined}
          </div>
          <div className={`row justify-content-center`}>
            {allWHMCS && allWHMCS.length !== 0 ? (
              allWHMCS.map((whmcs, i) => (
                <>
                  <div
                    key={i}
                    class="card component-card_3 mt-4"
                    style={{
                      width: "20rem",
                      borderRadius: "8px",
                      border: "1px solid rgb(27, 46, 75)",
                      backgroundColor: "rgb(25, 30, 58)",
                    }}
                  >
                    <div class="card-body">
                      <h4 className={`mb-3`}>{whmcs.Name}</h4>
                      {whmcs.Desc !== "" ? (
                        <p class="card-user_occupation">
                          Description: {whmcs.Desc}
                        </p>
                      ) : undefined}
                      <p
                        class="card-text"
                        onClick={() => {
                          navigator.clipboard.writeText(whmcs.Token);
                          VMBC.Alert.Show("Token copied to Clipboard", "info");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <VMBC.ToolTip title={"Click to Copy"}>
                          Token: {("" + whmcs.Token).substr(0, 15)}...
                        </VMBC.ToolTip>
                      </p>

                      <p class="card-text">
                        Created Date:
                        <br />
                        {whmcs.CreatedDate}
                      </p>
                      <p class="card-text">Customers: {whmcs.CustomerCount}</p>
                      <div className={`d-flex justify-content-center`}>
                        <button
                          class="btn btn-primary mb-2 mx-1"
                          onClick={(e) => GenerateModule(e, whmcs)}
                        >
                          <VMBC.SVGs.Codesandbox />
                          Generate Module
                        </button>
                      </div>
                      <div className={`d-flex justify-content-center`}>
                        <button
                          class="btn btn-outline-primary mb-2 mx-1"
                          onClick={() => editWhmcsClicked(whmcs)}
                        >
                          <VMBC.SVGs.EditByPen />
                          Edit
                        </button>
                        <button
                          class="btn btn-outline-danger mb-2 mx-1"
                          onClick={() => deleteWhmcsClicked(whmcs)}
                        >
                          <VMBC.SVGs.RecycleBin />
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <div
                style={{
                  padding: "10px",
                }}
              >
                <div className={`text-center`}>
                  <h5>No WHMCS available</h5>
                </div>
                <div className={`text-center mt-3`}>
                  <button
                    className={`btn btn-primary`}
                    onClick={addWhmcsClicked}
                  >
                    Click to Add new one
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function GenerateModuleSettings(props) {
  const { setting } = props;

  const [VMBOX_URL, setVMBOX_URL] = React.useState(setting.VMBOX_URL);
  const [SSL_ENABLED, setSSL_ENABLED] = React.useState(setting.SSL_ENABLED);
  const [VMBOX_Config, setVMBOX_Config] = React.useState(setting.VMBOX_Config);
  const [VMBOX_WS, setVMBOX_WS] = React.useState(setting.VMBOX_WS);
  const [VMBOX_TOKEN, setVMBOX_TOKEN] = React.useState(setting.VMBOX_TOKEN);
  const [WebSocket, setWebSocket] = React.useState(setting.WebSocket);
  const [AutoActivatation, setAutoActivatation] = React.useState(
    setting.AutoActivatation
  );
  const [SendEmailAfterDeploy, setSendEmailAfterDeploy] = React.useState(
    setting.SendEmailAfterDeploy
  );
  const [generating, setGenerating] = React.useState(false);

  return (
    <div className={`container`}>
      <div className={`row`}>
        <div className={`col-12 mt-2`}>
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <div class="input-group-text">Client URL</div>
            </div>
            <input
              disabled={generating}
              class="form-control"
              aria-label="switch"
              value={VMBOX_URL}
              onChange={(e) => setVMBOX_URL(e.target.value)}
            />
          </div>
        </div>

        <div className={`col-12 mt-2`}>
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <div class="input-group-text">Config URL</div>
            </div>
            <input
              disabled={generating}
              class="form-control"
              aria-label="switch"
              value={VMBOX_Config}
              onChange={(e) => setVMBOX_Config(e.target.value)}
            />
          </div>
        </div>

        <div className={`col-12 mt-2`}>
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <div class="input-group-text">WebSocket URL</div>
            </div>
            <input
              disabled={generating}
              class="form-control"
              aria-label="switch"
              value={VMBOX_WS}
              onChange={(e) => setVMBOX_WS(e.target.value)}
            />
          </div>
        </div>

        <div className={`col-12 mt-2`}>
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <div class="input-group-text">Token</div>
            </div>
            <input
              disabled={generating}
              class="form-control"
              aria-label="switch"
              value={VMBOX_TOKEN}
              onChange={(e) => setVMBOX_TOKEN(e.target.value)}
            />
          </div>
        </div>

        <div className={`col-6 mt-3`}>
          <div class="custom-control custom-checkbox">
            <input
              id={`sslEnabledCheckbox`}
              disabled={generating}
              type="checkbox"
              class="custom-control-input"
              value={SSL_ENABLED}
              onChange={(e) => setSSL_ENABLED(e.target.checked)}
            />
            <label
              class="custom-control-label"
              for={`sslEnabledCheckbox`}
              style={{ cursor: "pointer" }}
            >
              Use SSL Connection to VMBOX
            </label>
          </div>
        </div>

        <div className={`col-6 mt-3`}>
          <div class="custom-control custom-checkbox">
            <input
              id={`wsEnabledCheckbox`}
              disabled={generating}
              type="checkbox"
              class="custom-control-input"
              value={WebSocket}
              onChange={(e) => setWebSocket(e.target.checked)}
            />
            <label
              class="custom-control-label"
              for={`wsEnabledCheckbox`}
              style={{ cursor: "pointer" }}
            >
              WebSocket Enabled
            </label>
          </div>
        </div>

        <div className={`col-6 mt-3`}>
          <div class="custom-control custom-checkbox">
            <input
              id={`autoActivationEnabledCheckbox`}
              disabled={generating && true}
              type="checkbox"
              class="custom-control-input"
              value={AutoActivatation}
              onChange={(e) => setAutoActivatation(e.target.checked)}
            />
            <label
              class="custom-control-label"
              for={`autoActivationEnabledCheckbox`}
              style={{ cursor: "pointer" }}
            >
              Auto Activatation
            </label>
          </div>
        </div>

        <div className={`col-6 mt-3`}>
          <div class="custom-control custom-checkbox">
            <input
              id={`sendEmailEnabledCheckbox`}
              disabled={generating}
              type="checkbox"
              class="custom-control-input"
              value={SendEmailAfterDeploy}
              onChange={(e) => setSendEmailAfterDeploy(e.target.checked)}
            />
            <label
              class="custom-control-label"
              for={`sendEmailEnabledCheckbox`}
              style={{ cursor: "pointer" }}
            >
              Send Email to Customer After Deploy
            </label>
          </div>
        </div>
        <div className={`col-12 mt-3 d-flex justify-content-center`}>
          <button
            className={`btn btn-primary mx-auto`}
            onClick={() => {
              if (generating) return;
              setGenerating(true);
              VMBC.Customers.WHMCS.GenerateModule(
                {
                  VMBOX_URL,
                  SSL_ENABLED,
                  VMBOX_Config,
                  VMBOX_WS,
                  VMBOX_TOKEN,
                  WebSocket,
                  AutoActivatation,
                  SendEmailAfterDeploy,
                },
                (res, err) => {
                  if (err) {
                    VMBC.Alert.Show(err);
                  } else {
                    VMBC.Dialogs.Show({
                      Title: "Download Module",
                      Fields: (
                        <>
                          <div
                            className={`d-flex flex-column align-items-center`}
                          >
                            <button
                              className={`btn btn-primary btn-lg mt-2`}
                              onClick={() => {
                                var SId = window.localStorage.getItem("SId");
                                var ModuleName = res;
                                window.open(
                                  VMBC.Config.Origin +
                                    "/api/customers/whmcs/getModule?SId=" +
                                    SId +
                                    "&Name=" +
                                    ModuleName,
                                  "_blank"
                                );
                              }}
                            >
                              Download ZIP File
                            </button>
                          </div>
                        </>
                      ),
                      Actions: [
                        {
                          text: "Close",
                          className: "btn-outline-primary btn-sm",
                          onClick: () => VMBC.Dialogs.Close(),
                        },
                      ],
                    });
                  }
                }
              );
            }}
          >
            {generating ? "Generating..." : "Generate and Download"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(WHMCS);
