import React from "react";
import VMBC from "../../../../../VMBC";
import "./NamingOptions.css";

const Tags = {
  0: {
    id: 0,
    name: "{ipAddress}",
    title: "IP Address",
    example: "192.168.1.2",
    unique: true,
  },
  1: { id: 1, name: "{planName}", title: "Plan Name", example: "Gold" },
  2: { id: 2, name: "{cpu}", title: "CPU", example: "8vCore" },
  3: { id: 3, name: "{ram}", title: "RAM", example: "32GB" },
  4: { id: 4, name: "{osType}", title: "OS Type", example: "Windows" },
  5: {
    id: 5,
    name: "{templateAlias}",
    title: "Template Alias",
    example: "Windows Server 2019",
  },
  6: { id: 6, name: "{vmId}", title: "VM-id", example: "VM867", unique: true },
};

var DefaultFromCol = [Tags[2], Tags[3], Tags[4], Tags[5], Tags[6]];

var DefaultToCol = [Tags[0], Tags[1]];

function NamingOptions(props) {
  var oldData =
    props.Data &&
    props.Data.current.Customization &&
    props.Data.current.Customization.NamingOptions &&
    props.Data.current.Customization.NamingOptions.fromCol
      ? props.Data.current.Customization.NamingOptions
      : false;

  const [expanded, setExpanded] = React.useState(
    oldData ? oldData.expanded : false
  );

  const fromCol = React.useRef(oldData ? oldData.fromCol : [...DefaultFromCol]);
  const toCol = React.useRef(oldData ? oldData.toCol : [...DefaultToCol]);
  const [isUnique, setIsUnique] = React.useState(
    oldData ? oldData.isUnique : true
  );
  const [example, setExample] = React.useState(
    oldData ? oldData.example : DefaultToCol.map((tag) => tag.example).join("-")
  );

  React.useEffect(() => {
    // for saving current selected tags for next time if user come back to this section
    // shown tags in html are not from states, only from DefaultFromCol and DefaultToCol
    // so we're saving selected tags in those
    return () => {
      // componentWillUnmount
      DefaultFromCol = fromCol.current;
      DefaultToCol = toCol.current;
    };
  }, []);

  props.Data.current.Customization.NamingOptions = {
    name: toCol.current.map((to) => to.name).join("-"),
    fromCol: fromCol.current,
    toCol: toCol.current,
    isUnique: isUnique,
    expanded: expanded,
    example: example,
  };

  React.useEffect(() => {
    window
      .dragula(
        [
          document.getElementById("toTags"),
          document.getElementById("fromTags"),
        ],
        {
          accepts: function (el, target, source, sibling) {
            return true;
          },
        }
      )
      .on("drag", function (el) {
        el.className += " el-drag-ex-1";
      })
      .on("drop", (el) => {
        el.className = el.className.replace("el-drag-ex-1", "");
        Rearrange();
      })
      .on("cancel", function (el) {
        el.className = el.className.replace("el-drag-ex-1", "");
      });
  }, []);

  const Rearrange = () => {
    var newTo = [];
    let newToIsUnique = false;
    var exampl = [];
    document.getElementById(`toTags`).childNodes.forEach((childNode) => {
      var nodeId = Number(childNode.id);
      newTo.push(Tags[nodeId]);
      exampl.push(Tags[nodeId].example);
      newToIsUnique = newToIsUnique ? true : Tags[nodeId].unique;
    });
    var newFrom = [];
    document.getElementById(`fromTags`).childNodes.forEach((childNode) => {
      var nodeId = Number(childNode.id);
      newFrom.push(Tags[nodeId]);
    });
    toCol.current = newTo;
    fromCol.current = newFrom;
    setIsUnique(newToIsUnique);
    setExample(exampl.join("-"));
  };

  return (
    <>
      <div className={`card no-outer-spacing`}>
        <div className="card-header" id={"namingOptions"}>
          <section className="mb-0 mt-0">
            <div
              role="menu"
              className={`collapsed`}
              data-toggle="collapse"
              data-target={`#namingOptionsAcc`}
              aria-expanded="false"
              aria-controls={`namingOptionsAcc`}
            >
              <span style={{ color: "#fff" }}>Naming Virtual Machine</span>
              <div className="icons">
                <VMBC.SVGs.ChevronDown />
              </div>
            </div>
          </section>
        </div>

        <div
          id={`namingOptionsAcc`}
          className="collapse"
          aria-labelledby={"namingOptions"}
          data-parent="#toggleAccordion"
        >
          <div className="card-body">
            <div className={`container`}>
              <div
                className={`row align-items-center`}
                style={{ padding: "20px", minHeight: "60px" }}
              >
                <div style={{ color: "#fff", fontSize: "15px" }}>VM Name:</div>
                <div style={{ flexGrow: 1 }}>
                  <div className={`container-fluid`}>
                    <div
                      id={"toTags"}
                      className={`row justify-content-center`}
                      style={{ minHeight: "40px" }}
                    >
                      {DefaultToCol.map((tag, i) => (
                        <span
                          id={tag.id}
                          data-name={tag.title}
                          className={`nameTag`}
                          style={{
                            margin: "3px",
                            padding: "5px 10px",
                            borderRadius: "20px",
                            cursor: "pointer",
                            color: "#000",
                            fontSize: "15px",
                            backgroundColor: "#999",
                          }}
                          key={i}
                        >
                          {tag.title}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`row align-items-center`}
                style={{
                  padding: "20px",
                  minHeight: "60px",
                  backgroundColor: "#ffffff0a",
                  borderRadius: "12px",
                  border: "2px dashed #666",
                }}
              >
                <div style={{ color: "#fff", fontSize: "15px" }}>
                  Drag and Drop:
                </div>
                <div style={{ flexGrow: 1 }}>
                  <div className={`container-fluid`}>
                    <div
                      id={"fromTags"}
                      className={`row justify-content-center`}
                      style={{ minHeight: "40px" }}
                    >
                      {DefaultFromCol.map((tag, i) => (
                        <span
                          id={tag.id}
                          data-name={tag.title}
                          className={`nameTag`}
                          style={{
                            margin: "3px",
                            padding: "5px 10px",
                            borderRadius: "20px",
                            cursor: "pointer",
                            color: "#000",
                            fontSize: "15px",
                            backgroundColor: "#999",
                          }}
                          key={i}
                        >
                          {tag.title}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {!isUnique ? (
                <h6 style={{ width: "100%", margin: "0", color: "red" }}>
                  VM Name must contain at least one unique parameter(IP Address
                  or VM-id)
                </h6>
              ) : undefined}
              <h6 style={{ width: "100%", margin: "15px 0", color: "#bbb" }}>
                {example !== "" ? `Example: ${example}` : ""}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { DefaultFromCol, DefaultToCol };

export default NamingOptions;
