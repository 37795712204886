import React from "react";
import VMBC from "../../VMBC";

function OnEdit(props) {
  const { index, act, rowIndex, row, columns, dataSortFunction } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const newData = React.useRef();

  return (
    <li key={index}>
      <div className="dropdown d-inline">
        <a
          className="dropdown-toggle"
          href="#"
          role="button"
          id={`onEdit-${rowIndex}-${index}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <VMBC.ToolTip title={act.title}>{act.icon}</VMBC.ToolTip>
        </a>
        <div
          className="dropdown-menu dropdown-menu-right"
          id={`onEditCont-${rowIndex}-${index}`}
          style={{
            backgroundColor: "#0e1726",
            boxShadow: "0 0 4px 4px #0006",
          }}
          aria-labelledby={`onEdit-${rowIndex}-${index}`}
        >
          {columns
            .filter((col) =>
              col.editable !== undefined ? col.editable : !col.hidden
            )
            .sort((...args) =>
              dataSortFunction ? dataSortFunction(...args) : false
            )
            .map((col, colIndex) => {
              if (typeof row[col.fieldName] === "object") {
                return undefined;
              }
              if (
                col.editComponent != undefined &&
                typeof col.editComponent === "function"
              ) {
                return col.editComponent(row, (newValue) => {
                  if (row[col.fieldName] !== undefined) {
                    if (newData.current === undefined) {
                      newData.current = {};
                    }
                    newData.current[col.fieldName] = newValue;
                    if (col.editValidator !== undefined) {
                      var notValid = col.editValidator(newValue);
                      if (notValid) {
                        setMessage(notValid);
                      } else {
                        setMessage("");
                      }
                    }
                  }
                });
              }
              return (
                <div
                  key={colIndex}
                  className="input-group input-group-sm mb-1"
                  style={{ minWidth: "280px" }}
                >
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon5">
                      {col.title}
                    </span>
                  </div>

                  <input
                    type="text"
                    className="form-control mt-0"
                    onChange={(e) => {
                      if (row[col.fieldName] !== undefined) {
                        if (newData.current === undefined) {
                          newData.current = {};
                        }
                        newData.current[col.fieldName] = e.target.value;
                        if (col.editValidator !== undefined) {
                          var notValid = col.editValidator(e.target.value);
                          if (notValid) {
                            setMessage(notValid);
                          } else {
                            setMessage("");
                          }
                        }
                      }
                    }}
                    defaultValue={row[col.fieldName]}
                  />
                </div>
              );
            })}
          <span
            style={{
              display: "inline-block",
              color: "red",
              fontSize: "12px",
              width: "120px",
            }}
          >
            {message}
          </span>
          <button
            className={`btn btn-primary btn-sm mt-1 float-right`}
            onClick={(e) => {
              if (isLoading) {
                return;
              }
              var notValid = false;
              if (newData.current !== undefined) {
                columns
                  .filter((col) =>
                    col.editable !== undefined ? col.editable : !col.hidden
                  )
                  .sort((...args) =>
                    dataSortFunction ? dataSortFunction(...args) : false
                  )
                  .forEach((col) => {
                    if (newData.current[col.fieldName] !== undefined) {
                      if (col.editValidator !== undefined) {
                        var thisOneNotValid = col.editValidator(
                          newData.current[col.fieldName]
                        );
                        if (thisOneNotValid) {
                          // this column's new data is not valid, set the message
                          notValid = thisOneNotValid;
                        }
                      } // else { there is no validator, so move on }
                    } else {
                      // data has not been changed
                      newData.current[col.fieldName] = row[col.fieldName];
                    }
                  });
              } else {
                // nothing has been changed
                return;
              }
              if (notValid) {
                // some of inputs are not valid
                setMessage(notValid);
                VMBC.Alert.Show(notValid, "error");
                return;
              }
              act.eventHandler(row, newData.current, () => {
                setIsLoading(false);
              });
              setIsLoading(true);
            }}
          >
            {isLoading ? (
              <div
                className="spinner-border text-white align-self-center loader-sm"
                style={{ width: "1rem", height: "1rem" }}
              ></div>
            ) : (
              `Edit`
            )}
          </button>
        </div>
      </div>
    </li>
  );
}

export default OnEdit;
