import React from "react";

import FolderExplorer, {
  LinkParents,
} from "../../../../../Components/FolderExplorer";
import VMBC from "../../../../../VMBC";

function AddPlanTemplate(props) {
  const { plan } = props;

  const [currentFolder, setCurrentFolder] = React.useState({});
  const [selectedObject, setSelectedObject] = React.useState();
  const [alias, setAlias] = React.useState("");
  const [osType, setOsType] = React.useState();

  React.useEffect(() => {
    if (props.onOpen != undefined) {
      props.onOpen.current = () => {
        window.$("#addTemplateToPlanModal").modal("show");
      };
    }

    var temps = plan.Datacenter.Templates ? plan.Datacenter.Templates : [];
    var RootFolder = {
      isFolder: true,
      Name: "root",
      items: temps.map((temp) => ({
        isFolder: false,
        Name: temp.Alias,
        TemplateObj: temp,
        icon: <VMBC.SVGs.Template size={80} />,
      })),
    };
    LinkParents(RootFolder);
    setCurrentFolder(RootFolder);
  }, []);

  const selectHandler = () => {
    if (!props.onSelect) return;
    if (!selectedObject) {
      VMBC.Alert.Show("Please select a Template first", "error", 3000);
      return;
    }
    if (!alias || alias === "" || alias.includes(",")) {
      VMBC.Alert.Show("Please enter a Alias for template", "error", 3000);
      return;
    }
    let TemplateObj = {
      templateId: selectedObject.TemplateObj.TemplateId,
      templateName: selectedObject.TemplateObj.TemplateName,
      alias: alias,
      osType: selectedObject.TemplateObj.OsType,
    };
    props.onSelect(TemplateObj);
    window.$("#addTemplateToPlanModal").modal("hide");
    setSelectedObject(null);
    setAlias("");
    setOsType(null);
  };

  const onRefresh = () => {
    setCurrentFolder();
    plan.Datacenter.GetTemplates((temps) => {
      var RootFolder = {
        isFolder: true,
        Name: "root",
        items: temps
          ? temps.map((temp) => ({
              isFolder: false,
              Name: temp.Alias,
              TemplateObj: temp,
              icon: <VMBC.SVGs.Template size={80} />,
            }))
          : [],
      };
      LinkParents(RootFolder);
      setCurrentFolder(RootFolder);
    });
  };

  React.useEffect(() => {
    // rerender select picker on component did mount
    window.$(".planTemplateSelectpicker").selectpicker("refresh");
  });

  return (
    <>
      <div
        className="modal fade"
        id="addTemplateToPlanModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Select Template"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Select Template:
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <VMBC.SVGs.CloseXIcon />
              </button>
            </div>
            <div className="modal-body">
              <div className={`container-fluid`}>
                <div className={`row`}>
                  <div className={`col-12`}>
                    <FolderExplorer
                      height={250}
                      className={`addTemplateFolderExplorer`}
                      Folder={currentFolder}
                      HideAddress={true}
                      SelectedObject={selectedObject}
                      onRefresh={onRefresh}
                      onAddressClicked={setCurrentFolder}
                      onObjectSelect={setSelectedObject}
                      onObjectDoubleClick={(Obj) => {
                        if (Obj.isFolder) {
                          setCurrentFolder(Obj);
                        }
                      }}
                    />
                  </div>

                  <div className={`col-7 detailsContainer`}>
                    <input
                      id="templateAlias"
                      type="text"
                      name="alias"
                      placeholder="Template name: (shown to customer)"
                      className={`form-control`}
                      style={{ height: "48.5px" }}
                      value={alias}
                      onChange={(e) => setAlias(e.target.value)}
                    />
                  </div>

                  <div className={`col-5 detailsContainer`}>
                    <select
                      disabled
                      className="planTemplateSelectpicker"
                      title={`Select a Template`}
                      data-width="100%"
                      value={
                        selectedObject
                          ? VMBC.Enums.OsTypes.indexOf(
                              selectedObject.TemplateObj.OsType
                            )
                          : -1
                      }
                    >
                      {VMBC.Enums.OsTypes.map((os, i) => (
                        <option value={i}>{os}</option>
                      ))}
                    </select>
                  </div>

                  <div className={`col-12 detailsContainer`}>
                    <h6>
                      {selectedObject
                        ? `Virtual Machine Name: ${selectedObject.TemplateObj.TemplateName}`
                        : ""}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className={`btn btn-dark btn-sm`}
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button className={`btn btn-primary btn-sm`} onClick={selectHandler}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPlanTemplate;
