/*

'Columns' is an array of object.shape({Title,FieldName})

'Rows' is an array of object containing FieldName

*/
function ExportTableAsExcel(Columns, Rows, { ShowIndex }) {
  var tableText = "<table border='1px'><tr bgcolor='#87AFC6'>";
  if (!!ShowIndex) {
    tableText += `<th>#</th>`;
  }
  Columns.forEach((col) => {
    tableText += `<th>${col.Title}</th>`;
  });
  tableText += `</tr>`;
  var len = Rows.length;
  var ind = 1;
  for (var i = len - 1; i >= 0; i--, ind++) {
    tableText += `<tr>${!!ShowIndex ? `<td>${ind}</td>` : ""}`;
    Columns.forEach((col) => {
      tableText += `<td>${Rows[i][col.FieldName]}</td>`;
    });
    tableText += `</tr>`;
  }
  tableText = tableText + "</table>";
  tableText = tableText.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
  tableText = tableText.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
  tableText = tableText.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

  var uri = "data:application/vnd.ms-excel;base64,";
  var worksheet = "Worksheet";
  var data = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>${tableText}</body></html>`;
  var base64 = function (s) {
    return window.btoa(unescape(encodeURIComponent(s)));
  };

  var link = document.createElement("a");
  link.download = "Machines.xls";
  link.href = uri + base64(data);
  link.click();
}

function ExportJSON(JsonObject) {
  var uri = "data:application/json;base64,";
  var base64 = function (s) {
    return window.btoa(unescape(encodeURIComponent(s)));
  };
  var link = document.createElement("a");
  link.download = "Machines.json";
  link.href = uri + base64(JSON.stringify(JsonObject));
  link.click();
}

/*

'Columns' is an array of object.shape({Title,FieldName})

'Rows' is an array of object containing FieldName

*/
function PrintTable(Columns, Rows, { PageTitle, ShowIndex }) {
  var winPrint = window.open(
    "",
    "",
    "left=0,top=0,toolbar=0,scrollbars=0,status=0"
  );
  winPrint.document.write(
    `<html><head><title>Machines List of ${PageTitle}</title><style>${TableStyle}</style></head><body><table class="styled-table"><thead><tr>`
  );
  if (!!ShowIndex) {
    winPrint.document.write(`<th>#</th>`);
  }
  Columns.forEach((col) => {
    winPrint.document.write(`<th>${col.Title}</th>`);
  });
  winPrint.document.write(`</tr></thead><tbody>`);
  var len = Rows.length;
  var ind = 1;
  for (var i = len - 1; i >= 0; i--, ind++) {
    winPrint.document.write(`<tr>${!!ShowIndex ? `<td>${ind}</td>` : ""}`);
    Columns.forEach((col) => {
      winPrint.document.write(`<td>${Rows[i][col.FieldName]}</td>`);
    });
    winPrint.document.write(`</tr>`);
  }
  winPrint.document.write("</tbody></table></body></html>");
  winPrint.document.close();
  winPrint.focus();
  winPrint.print();
  winPrint.close();
}

const TableStyle = `
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  width: 100%;
}
.styled-table thead tr {
  border-bottom: 1px solid black;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
`;

export default { PrintTable, ExportJSON, ExportTableAsExcel };
