import React from "react";
import VMBC from "../../../../../VMBC";
import ComputeResources from "../ComputeResources";

function CdDrivesList(props) {
  const { PlanSolution } = props;
  const { ComputeResourceId, SolutionId } = PlanSolution;

  const [cdDrives, setCdDrives] = React.useState();
  const [allCdDrives, setAllCdDrives] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setCdDrives();
    VMBC.Plans.GetSolutionCdDrives(SolutionId, (cdDrives, err) => {
      if (!err) {
        setCdDrives(cdDrives);
      } else {
        VMBC.Alert.Show(err);
      }
    });
    setAllCdDrives();
    var ComputeResource = new VMBC.Inventory.ComputeResource(ComputeResourceId);
    ComputeResource.GetCdIsoFiles((cdIsoFiles, err) => {
      if (!err) {
        setAllCdDrives(cdIsoFiles);
      } else {
        VMBC.Alert.Show(err);
      }
    });
  }, [refreshT, PlanSolution]);

  const Refresh = () => refresh(!refreshT);

  const onRemoveClicked = (cdDrive) => {
    VMBC.Dialogs.Show({
      Title: "Are you sure?",
      Text: `Are you sure you want to remove "${cdDrive.Name}" from this Plan? No VMachines will be connected this ISO file from this plan anymore`,
      Ignorable: true,
      Actions: [
        {
          onClick: () => {
            VMBC.Plans.RemoveSolutionCdDrives(
              cdDrive.SolutionCdDriveId,
              (res, err) => {
                if (err) {
                  VMBC.Alert.Show(err);
                } else {
                  VMBC.Alert.Show(res, "success");
                  Refresh();
                }
              }
            );
            VMBC.Dialogs.Close();
          },
          className: "btn btn-outline-primary btn-sm",
          text: "Yes",
        },
        {
          onClick: () => VMBC.Dialogs.Close(),
          className: "btn btn-primary btn-sm",
          text: "No",
        },
      ],
    });
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
                <div className="th-content text-center">Name</div>
              </th>
              <th>
                <div className="th-content text-center">Description</div>
              </th>
              <th>
                <div className="th-content text-center">ISO File</div>
              </th>
              <th>
                <div className="th-content text-center">Actions</div>
              </th>
            </tr>
          </thead>
          {cdDrives ? (
            <tbody>
              {cdDrives.map((cdDrive) => (
                <tr style={{ border: "1px dashed grey" }}>
                  <td>
                    <div className="td-content text-center product-brand">
                      {cdDrive.Name}
                    </div>
                  </td>
                  <td>
                    <div className="td-content text-center">
                      {cdDrive.Description.length < 10 ? (
                        cdDrive.Description.length
                      ) : (
                        <VMBC.ToolTip title={cdDrive.Description}>
                          {`${("" + cdDrive.Description).substring(0, 10)}...`}
                        </VMBC.ToolTip>
                      )}
                    </div>
                  </td>
                  <td>
                    <div
                      className="td-content text-center"
                      onClick={() => {
                        VMBC.Alert.Show(cdDrive.IsoAddress, "info");
                      }}
                    >
                      {cdDrive.IsoAddress.length < 20 ? (
                        cdDrive.IsoAddress.length
                      ) : (
                        <VMBC.ToolTip title={"Click to see full Address"}>
                          {`${("" + cdDrive.IsoAddress).substring(0, 20)}...`}
                        </VMBC.ToolTip>
                      )}
                    </div>
                  </td>

                  <td>
                    <div className="td-content text-center">
                      <span
                        class="badge outline-badge-danger"
                        onClick={() => onRemoveClicked(cdDrive)}
                      >
                        Remove
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : undefined}
        </table>
      </div>
      {cdDrives ? (
        cdDrives.length != undefined && cdDrives.length === 0 ? (
          <div className={`my-4 text-center`}>No CD/DVD Drives</div>
        ) : undefined
      ) : (
        <div
          className={`my-2`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="spinner-border align-self-center loader-lg"
            style={{ width: "3rem", height: "3rem" }}
          ></div>
        </div>
      )}

      {allCdDrives ? (
        <>
          <div
            className={`mt-3`}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              className={`btn btn-primary btn-sm`}
              onClick={() => {
                window.$("#addCdDriveDialog").modal("show");
                window.$(".planCdDriveSelectpicker").selectpicker("refresh");
              }}
            >
              Add
            </button>
          </div>

          <AddCdDrive
            allCdDrives={allCdDrives}
            Refresh={Refresh}
            cdDrives={cdDrives}
            SolutionId={SolutionId}
          />
        </>
      ) : undefined}
    </>
  );
}

function AddCdDrive(props) {
  const { allCdDrives, Refresh, cdDrives, SolutionId } = props;

  const selectedCdDriveToAddObj = React.useRef();
  const [selectedCdDriveToAdd, setSelectedCdDriveToAdd] = React.useState();

  const handleSelectedCdDriveChange = (e) => {
    if (allCdDrives != undefined) {
      if (allCdDrives.length > e) {
        //check array index bound
        selectedCdDriveToAddObj.current = allCdDrives[e];
        setSelectedCdDriveToAdd(allCdDrives[e]);
      }
    }
  };

  const handleAddCdDrive = () => {
    if (
      cdDrives
        .map((cdDrive) => cdDrive.CdDriveName)
        .includes(selectedCdDriveToAddObj.current.Name)
    ) {
      VMBC.Alert.Show(
        `CdDrive '${selectedCdDriveToAddObj.current.Name}' is Already in Plan for this Compute Resource`,
        "error"
      );
      return;
    }
    VMBC.Plans.AddSolutionCdDrive(
      SolutionId,
      selectedCdDriveToAddObj.current,
      (res, err) => {
        if (err) {
          VMBC.Alert.Show(err);
        } else {
          VMBC.Alert.Show(res, "success");
        }
        handleCloseAddCdDrive();
        Refresh();
      }
    );
  };

  const handleCloseAddCdDrive = () => {
    window.$("#addCdDriveDialog").modal("hide");
    setSelectedCdDriveToAdd();
    selectedCdDriveToAddObj.current = null;
  };

  return (
    <div>
      <div
        className="modal fade"
        id="addCdDriveDialog"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Add CdDrive Dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6>Choose an ISO File</h6>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <p className="modal-text" style={{ color: "#fff" }}>
                Choose the ISO File you want to add to this Plan for this
                Compute Resource
              </p>
              <select
                className="planCdDriveSelectpicker"
                title={`Choose an ISO File`}
                data-width="100%"
                value={selectedCdDriveToAdd}
                onChange={(e) => handleSelectedCdDriveChange(e.target.value)}
              >
                {allCdDrives.map((Ds, i) => {
                  return (
                    <option value={i}>
                      {Ds.Name !== "" ? Ds.Name : "No Name"}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="modal-footer">
              <button
                className={`btn btn-primary btn-sm`}
                onClick={handleAddCdDrive}
              >
                Add
              </button>
              <button
                className={`btn btn-dark btn-sm`}
                onClick={handleCloseAddCdDrive}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CdDrivesList;
