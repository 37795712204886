import React from "react";
import VMBC from "../../../../VMBC";

function PlanCondition(props) {
  const { plan } = props;

  const [data, setData] = React.useState();

  React.useEffect(() => {
    VMBC.Plans.Statistics.GetPlanDeployCondition(plan.PlanId, (res, err) => {
      if (err) {
        VMBC.Alert.Show(err, "error");
      } else {
        setData(res);
      }
    });
  }, []);

  return (
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
      <div class="widget widget-five">
        <div
          class="widget-content"
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div class="header" style={{ paddingBottom: "18px" }}>
            <div class="header-body">
              <h6>Plan Condition</h6>
              <p class="meta-date">
                {new Date().toString().split(" ").splice(1, 3).join(" ")}
              </p>
            </div>
            {/* <div class="task-action">
              <div class="dropdown  custom-dropdown">
                <a
                  class="dropdown-toggle"
                  href="#"
                  role="button"
                  id="pendingTask"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-more-horizontal"
                  >
                    <circle cx="12" cy="12" r="1"></circle>
                    <circle cx="19" cy="12" r="1"></circle>
                    <circle cx="5" cy="12" r="1"></circle>
                  </svg>
                </a>

                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="pendingTask"
                >
                  <a class="dropdown-item" href="javascript:void(0);">
                    Add New Service
                  </a>
                  <a class="dropdown-item" href="javascript:void(0);">
                    View
                  </a>
                  <a class="dropdown-item" href="javascript:void(0);">
                    Suspend All
                  </a>
                </div>
              </div>
            </div> */}
          </div>

          <div
            class="w-content"
            style={{
              flexGrow: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {data ? (
              <div class="">
                <p class="task-left">
                  {data.FreeIpAddresses < data.DeployableCount
                    ? data.FreeIpAddresses
                    : data.DeployableCount}
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#25d5e4",
                    marginBottom: "30px",
                  }}
                >
                  Machines can be deployed
                </p>
                <p class="task-completed">
                  <span>{data.FreeIpAddresses} IP Addresses Free</span>
                </p>

                <p class="task-hight-priority">
                  <VMBC.ToolTip
                    title={
                      <span style={{ padding: "5px" }}>
                        {GetSpaceSize(data.DeployableDiskSpaceSize)} Disk Space
                        From {data.DeployableDiskSpaceDesc}
                        <br />+<br />
                        {GetSpaceSize(data.DeployableRamSwapSize)} RAM for Swap
                        From {data.DeployableRamSwapDesc}
                      </span>
                    }
                  >
                    <span>{data.DeployableCount} Deployable </span>
                  </VMBC.ToolTip>{" "}
                  Machine with Maximum Disk Usage
                </p>
              </div>
            ) : (
              <VMBC.Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function GetSpaceSize(MB) {
  if (MB < 1024) {
    return `${MB} MB`;
  }
  var GB = MB / 1024;
  if (GB < 1024) {
    return `${GB.toFixed(2)} GB`;
  }
  var TB = GB / 1024;
  return `${TB.toFixed(2)} TB`;
}

export default PlanCondition;
