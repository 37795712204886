import React from "react";

import VMBC from "../VMBC";

import { withRouter } from "react-router";
window.AddToLoadingVmboxProccess(1);

function TopBar(props) {
  // parse PathName for active classes
  var PathName = props.location.pathname;
  // remove panel from the begining of pathname
  PathName = PathName.substr(
    "/panel".length,
    PathName.length - "/panel".length
  );
  if (PathName.length === 0) {
    PathName = "/";
  }
  return (
    <>
      <div className="topbar-nav header navbar" role="banner">
        <nav id="topbar">
          <ul className="navbar-nav theme-brand flex-row  text-center">
            <li className="nav-item theme-logo">
              <a href="index.html">
                <img
                  src="/assets/img/90x90.jpg"
                  className="navbar-logo"
                  alt="logo"
                />
              </a>
            </li>
            <li className="nav-item theme-text">
              <a href="" className="nav-link">
                VMBOX
              </a>
            </li>
          </ul>

          <ul className="list-unstyled menu-categories" id="topAccordion">
            <li
              className={`menu single-menu ${PathName === "/" ? "active" : ""}`}
            >
              <VMBC.A href="/panel" className="dropdown-toggle autodroprown">
                <div className="">
                  <VMBC.SVGs.Dashboard />
                  <span>Dashboard</span>
                </div>
              </VMBC.A>
            </li>

            {/* <li
              className={`menu single-menu ${
                PathName.startsWith("/inventory") ? "active" : ""
              }`}
            >
              <VMBC.A href="/panel/inventory" className="dropdown-toggle">
                <div className="">
                  <VMBC.SVGs.Inventory />
                  <span>Inventory</span>
                </div>
              </VMBC.A>
            </li> */}

            <li
              className={`menu single-menu ${
                PathName.startsWith("/plans") ? "active" : ""
              }`}
            >
              <a
                href="#plans"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div className="">
                  <VMBC.SVGs.Plans />
                  <span>Plans</span>
                </div>
                <VMBC.SVGs.ChevronDown />
              </a>
              <ul
                className="collapse submenu list-unstyled"
                id="plans"
                data-parent="#topAccordion"
              >
                <li className={PathName === "/plans/list" ? "active" : ""}>
                  <VMBC.A href="/panel/plans/list">All Plans</VMBC.A>
                </li>
                <li className={PathName === "/plans/add" ? "active" : ""}>
                  <VMBC.A href="/panel/plans/add">Add New Plan</VMBC.A>
                </li>
                <li className={PathName === "/plans/ipPools" ? "active" : ""}>
                  <VMBC.A href="#">IP Pools</VMBC.A>
                </li>
                <li className={PathName === "/plans/templates" ? "active" : ""}>
                  <VMBC.A href="#">Templates</VMBC.A>
                </li>
                <li className={PathName === "/plans/isoFiles" ? "active" : ""}>
                  <VMBC.A href="#">ISO Files</VMBC.A>
                </li>
              </ul>
            </li>

            <li
              className={`menu single-menu ${
                PathName.startsWith("/customers") ? "active" : ""
              }`}
            >
              <a
                href="#customers"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div className="">
                  <VMBC.SVGs.Zap />
                  <span>Customers</span>
                </div>
                <VMBC.SVGs.ChevronDown />
              </a>
              <ul
                className="collapse submenu list-unstyled"
                id="customers"
                data-parent="#topAccordion"
              >
                <li className={PathName === "/customers/whmcs" ? "active" : ""}>
                  <VMBC.A href="/panel/customers/whmcs">WHMCS</VMBC.A>
                </li>
                <li
                  className={PathName === "/customers/machines" ? "active" : ""}
                >
                  <VMBC.A href="/panel/customers/machines">Machines</VMBC.A>
                </li>
                <li className={PathName === "/customers/list" ? "active" : ""}>
                  <VMBC.A href="/panel/customers/list">Customers List</VMBC.A>
                </li>
              </ul>
            </li>

            <li
              className={`menu single-menu ${
                PathName.startsWith("/administration") ? "active" : ""
              }`}
            >
              <a
                href="#administration"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div className="">
                  <VMBC.SVGs.Administration />
                  <span>Administration</span>
                </div>
                <VMBC.SVGs.ChevronDown />
              </a>
              <ul
                className="collapse submenu list-unstyled"
                id="administration"
                data-parent="#topAccordion"
              >
                <li
                  className={
                    PathName === "/administration/license" ? "active" : ""
                  }
                >
                  <VMBC.A href="/panel/administration/license">License</VMBC.A>
                </li>
                <li
                  className={
                    PathName === "/administration/about" ? "active" : ""
                  }
                >
                  <VMBC.A target="_blank" href="https://vmbox.ir/about">
                    About
                  </VMBC.A>
                </li>
              </ul>
            </li>

            <li
              className={`menu single-menu ${
                PathName.startsWith("/settings") ? "active" : ""
              }`}
            >
              <VMBC.A href="/panel/settings" className="dropdown-toggle">
                <div className="">
                  <VMBC.SVGs.Settings />
                  <span>Settings</span>
                </div>
              </VMBC.A>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default withRouter(TopBar);
