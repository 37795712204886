import { ManagedObject } from "../../DataTypes";

import axios from "axios";
import Utils from "../../Utils"

class Datatore extends ManagedObject {

  constructor() {
    if (arguments.length === 1 && typeof arguments[0] === "string") { // Datastore ( string DatastoreId )
      super(arguments[0]);
    } else if (arguments.length === 1 && arguments[0]._VMBC_Type && arguments[0]._VMBC_Type === "ManagedObject") { // Datastore ( ManagedObject Datastore )
      super(arguments[0].MO_Reference);
    }
  }

  GetSummary(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(window.VMBC.Config.Origin + "/api/inventory/datastore/details", {
          SId: window.VMBC.SId,
          DatastoreId: this.MO_Reference
        })
        .then(res => {
          if (!res.data) {
            cb(false);
            return;
          }
          if (res.data.res === "success") {
            this.Name = res.data.data.Name;
            this._Raw = res.data.data;
            cb(res.data.data);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(reason => {
          cb(false);
        });
    });
  }

  GetIsoFiles(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(window.VMBC.Config.Origin + "/api/inventory/datastore/isoFiles", {
          SId: window.VMBC.SId,
          DatastoreId: this.MO_Reference
        })
        .then(res => {
          if (!res.data) {
            cb([], "data undefined");
            return;
          }
          if (res.data.res === "success") {
            this.IsoFiles = res.data.data;
            cb(this.IsoFiles, null);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else if(res.data.err) {
            cb([], res.data.err);
          } else if(res.data.res === "error") {
            cb([], res.data.data);
          } else {
            cb([], "Unknown Error");
          }
        })
        .catch(reason => {
          cb([], "Request Error");
        });
    });
  }

  Name = "";
  IsoFiles = [];
  _Raw = {};
  _VMBC_Type = "Datastore";
}



export default Datatore;
