import React from "react";
import VMBC from "../../../../../VMBC";
window.AddToLoadingVmboxProccess(1);

function OtherActions(props) {
  const { plan } = props;

  const EnableDisablePlan = (enable) => () => {
    // enable plan if 'enable' is true
    VMBC.Dialogs.Show({
      Title: `${enable ? "Enabling" : "Disabling"} ${plan.Name}`,
      Text: enable ? (
        <>
          <p>You should edit product's details in Module to submit changes</p>
          <p>Are you sure you want to enable this Plan?</p>
        </>
      ) : (
        <>
          <p>
            You must edit product's details in Module to submit changes, no
            machines will be registered to this plan anymore
          </p>
          <p>Are you sure you want to disable this Plan?</p>
        </>
      ),
      Ignorable: true,
      Actions: [
        {
          className: "btn-outline-primary btn-sm",
          text: "No",
          onClick: () => VMBC.Dialogs.Close(),
        },
        {
          className: "btn-primary btn-sm",
          text: "Yes",
          onClick: () => {
            VMBC.Plans.SetPlanDisabledState(
              plan.PlanId,
              !enable,
              (res, err) => {
                if (err) {
                  VMBC.Alert.Show(err, "error");
                } else {
                  VMBC.Alert.Show(
                    `Plan ${enable ? "Enabled" : "Disabled"} successfully`,
                    "success"
                  );
                }
                window.location.reload()
              }
            );
            VMBC.Dialogs.Close();
          },
        },
      ],
    });
  };

  const DeletePlan = (Delete) => () => {
    // Delete Plan if Delete is true
    VMBC.Dialogs.Show({
      Title: `${Delete ? "Deleting" : "UnDeleting"} ${plan.Name}`,
      Text: Delete ? (
        <>
          <p>You should edit product's details in Module to submit changes</p>
          <p>Are you sure you want to Delete this Plan?</p>
        </>
      ) : (
        <>
          <p>
            You must edit product's details in Module to submit changes, no
            machines will be registered to this plan anymore
          </p>
          <p>Are you sure you want to UnDelete this Plan?</p>
        </>
      ),
      Ignorable: true,
      Actions: [
        {
          className: "btn-outline-primary btn-sm",
          text: "No",
          onClick: () => VMBC.Dialogs.Close(),
        },
        {
          className: "btn-primary btn-sm",
          text: "Yes",
          onClick: () => {
            VMBC.Plans.SetPlanDeletedState(
              plan.PlanId,
              Delete,
              (res, err) => {
                if (err) {
                  VMBC.Alert.Show(err, "error");
                } else {
                  VMBC.Alert.Show(
                    `Plan ${Delete ? "Deleted" : "Disabled"} successfully`,
                    "success"
                  );
                }
                window.location.reload()
              }
            );
            VMBC.Dialogs.Close();
          },
        },
      ],
    });
  };

  return (
    <>
      <a
        class="dropdown-item"
        href="javascript:void(0);"
        onClick={EnableDisablePlan(plan.Disabled)}
      >
        {`${plan.Disabled ? "Enable" : "Disable"} Plan`}
      </a>
      <a class="dropdown-item" href="javascript:void(0);" onClick={DeletePlan(!plan.Deleted)}>
        {`${plan.Deleted ? "UnDelete" : "Delete"}`}
      </a>
    </>
  );
}

export default OtherActions;
