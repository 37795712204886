import Demo from "..";

const TableName = "Demo_Session";

var DefaultData = [];

function Setup() {
  var Table = [];
  Table.push(...DefaultData);
  localStorage.setItem(TableName, JSON.stringify(Table));
  Demo.Session = { Name: "Session", Data: Table };
}

export default { Setup };
