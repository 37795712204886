import { ManagedObject } from "../../DataTypes";

import axios from "axios";
import Utils from "../../Utils"

class Network extends ManagedObject {

  constructor() {
    if (arguments.length === 1 && typeof arguments[0] === "string") { // Network ( string NetworkId )
      super(arguments[0]);
    } else if (arguments.length === 1 && arguments[0]._VMBC_Type && arguments[0]._VMBC_Type === "ManagedObject") { // Network ( ManagedObject Network )
      super(arguments[0].MO_Reference);
    }
  }

  GetSummary(cb) {
    Utils.CheckSId(() => {
      cb = cb ? cb : () => { }
      axios
        .post(window.VMBC.Config.Origin + "/api/inventory/network/details", {
          SId: window.VMBC.SId,
          NetworkId: this.MO_Reference
        })
        .then(res => {
          if (!res.data) {
            cb(false);
            return;
          }
          if (res.data.res === "success") {
            this.Name = res.data.data.Name;
            this._Raw = res.data.data;
            cb(res.data.data);
          } else if (res.data.err && res.data.err === "Session Expired") {
            window.VMBC.Alert.SessionExpired();
          } else {
            cb(false);
          }
        })
        .catch(reason => {
          cb(false);
        });
    });
  }

  Name = "";
  _Raw = {};
  _VMBC_Type = "Network";
}



export default Network;
