import React from "react";
import Stepper from "../Components/Stepper";
import VMBC from "../VMBC";

function License(props) {
  const { InstallData, Block, Unblock } = props;
  const [code, setCodeState] = React.useState(
    InstallData.current ? InstallData.current.License.Code : ""
  );
  const [refreshT, refresh] = React.useState(false);

  InstallData.current.License.Code = code;

  const setCode = (code) => {
    code = removeDash(code);
    setCodeState(code);
  };

  const submit = () => {
    Block();
    VMBC.Install.checkLicenseCode(code, (err) => {
      if (err) {
        VMBC.Alert.Show(err, "error");
      } else {
        VMBC.Alert.Show("License Code accepted", "success");
        InstallData.current.License.ConfirmedCode = code;
      }
      Unblock();
      refresh(!refreshT);
    });
  };

  return (
    <>
      <Stepper
        steps={[
          {
            title: "EULA",
            onClick: (e) => {
              e.preventDefault();
              if (props.Goto) {
                props.Goto(0);
              }
            },
          },
          {
            title: "License",
            onClick: (e) => {
              e.preventDefault();
            },
          },
          {
            title: "vCenter",
            onClick: (e) => {
              e.preventDefault();
              if (
                props.onNext &&
                !(
                  InstallData.current.License.Code === "" ||
                  InstallData.current.License.Code !==
                    InstallData.current.License.ConfirmedCode
                )
              ) {
                props.onNext();
              }
            },
          },
          // {
          //   title: "Finish",
          //   onClick: (e) => {
          //     e.preventDefault();
          //   },
          // },
        ]}
        activeStep={1}
      />
      <div class="input-group mt-4 px-5">
        <div class="input-group-prepend">
          <span class="input-group-text">License Code</span>
        </div>
        <input
          type="text"
          spellCheck={false}
          value={formatLicenseCode(code)}
          onChange={(e) => setCode(e.target.value)}
          className="text-center form-control"
          style={{ minWidth: "260px" }}
        />
      </div>
      <div className={`d-flex justify-content-center mt-1`}>
        Or get a new license code from
        <a className={`ml-1`} target="_blank" href={"https://vmbox.ir"}>
          vmbox.ir
        </a>
      </div>
      <div className={`d-flex justify-content-center mt-3`}>
        <button className={`btn btn-primary`} onClick={submit}>
          Submit
        </button>
      </div>
      <div className={`d-flex justify-content-end mt-4`}>
        <button
          disabled={
            InstallData.current.License.Code === "" ||
            InstallData.current.License.Code !==
              InstallData.current.License.ConfirmedCode
          }
          className={`btn btn-primary`}
          onClick={props.onNext}
        >
          Next
        </button>
      </div>
    </>
  );
}

function formatLicenseCode(code) {
  code = removeDash(code);
  var res = "";
  for (var i = 0; i < code.length && i < 16; i++) {
    if (i === 4 || i === 8 || i === 12) {
      res += "-";
    }
    res += code[i];
  }
  return res;
}

function removeDash(code) {
  while (code.indexOf("-") !== -1) {
    code = code.replace("-", "");
  }
  return code.slice(0, 16);
}

export default License;
