import Demo from "..";

const TableName = "Demo_IpAddresses";

function Setup() {
  var Table = [];

  var DefaultData = [];
  var id = 1;
  for (var oc3 = 1; oc3 < 255; oc3++) {
    for (var oc4 = 1; oc4 < 255; oc4++) {
      DefaultData.push({
        id: id,
        ip: `46.4.${oc3}.${oc4}`,
        gateway: `46.4.${oc3}.1`,
        LINK_vmachinesId: 0,
      });
      id++;
    }
  }

  Table.push(...DefaultData);
  localStorage.setItem(TableName, JSON.stringify(Table));
  Demo.IpAddresses = { Name: "IpAddresses", Data: Table };
}

export default { Setup };
