import React from "react";
import VMBC from "../../../../VMBC";

function RecentOrders(props) {
  const { plan } = props;

  const [orders, setOrders] = React.useState();
  const [refreshT, refresh] = React.useState(false);
  const Refresh = () => refresh(!refreshT);

  React.useEffect(() => {
    VMBC.Plans.Statistics.GetPlanRecentOrders(plan.PlanId, (res, err) => {
      if (err) {
        VMBC.Alert.Show(err, "error");
      } else {
        var now = new Date();
        setOrders(
          [...res].map((order) => ({
            ...order,
            Time:
              now - order.RegistrationDate < 3600
                ? VMBC.Time.SecondsToElapsed(now - order.RegistrationDate)
                : VMBC.Time.UnixToDateString(order.RegistrationDate),
          }))
        );
      }
    });
  }, refreshT);

  return (
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-table-two">
        <div class="widget-heading">
          <h5 class="">Recent Orders</h5>
        </div>

        <div class="widget-content">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="th-content">Customer</div>
                  </th>
                  <th>
                    <div class="th-content text-center">Template</div>
                  </th>
                  <th>
                    <div class="th-content text-center">Service ID</div>
                  </th>
                  <th>
                    <div class="th-content th-heading">Time</div>
                  </th>
                  <th>
                    <div class="th-content">Status</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders
                  ? orders.map((order) => (
                      <tr>
                        <td>
                          <div class="td-content customer-name">
                            <VMBC.A
                              target="_blank"
                              href={order.WhmcsClientLink}
                            >
                              {order.ClientName}
                            </VMBC.A>
                          </div>
                        </td>
                        <td>
                          <div class="td-content product-brand text-center">
                            {order.TemplateName}
                          </div>
                        </td>
                        <td>
                          <div
                            class="td-content text-center"
                            onContextMenu={(e) => {
                              e.preventDefault();
                              VMBC.Dialogs.Show({
                                Title: order.VmName,
                                Fields: (
                                  <>
                                    <div
                                      className={`d-flex align-items-center justify-content-center`}
                                      style={{ flexDirection: "column" }}
                                    >
                                      <button
                                        className={`btn btn-outline-primary mb-2`}
                                        onClick={() => {
                                          var win = window.open(
                                            order.WhmcsClientLink,
                                            "_blank"
                                          );
                                          win.focus();
                                          VMBC.Dialogs.Close();
                                        }}
                                      >
                                        Click to see Client details at WHMCS
                                      </button>
                                      <button
                                        className={`btn btn-outline-primary mb-2`}
                                        onClick={() => {
                                          var win = window.open(
                                            order.WhmcsServiceLink,
                                            "_blank"
                                          );
                                          win.focus();
                                          VMBC.Dialogs.Close();
                                        }}
                                      >
                                        Click to see Product details at WHMCS
                                      </button>
                                      <button
                                        className={`btn btn-outline-primary mb-2`}
                                        onClick={() => {
                                          VMBC.Navigate(
                                            VMBC.Navigator.History,
                                            `/panel/customers/machines/${order.VmRecordId}`
                                          );
                                          VMBC.Dialogs.Close();
                                        }}
                                      >
                                        Click to see Service details at VMBOX
                                      </button>
                                    </div>
                                  </>
                                ),
                                Ignorable: true,
                                Actions: [
                                  {
                                    className: "btn-outline-primary btn-sm",
                                    text: "Close",
                                    onClick: () => {
                                      VMBC.Dialogs.Close();
                                    },
                                  },
                                ],
                              });
                              return false;
                            }}
                          >
                            <VMBC.A
                              target="_blank"
                              href={order.WhmcsServiceLink}
                            >
                              #{order.ServiceId}
                            </VMBC.A>
                          </div>
                        </td>
                        <td>
                          <div class="td-content pricing">
                            <span class="">{order.Time}</span>
                          </div>
                        </td>
                        <td>
                          <div class="td-content">
                            <VMBC.A
                              href={`/panel/customers/machines/${order.VmRecordId}`}
                            >
                              {order.State === "Registered" ? (
                                <span class="badge outline-badge-primary">
                                  Registered
                                </span>
                              ) : order.State === "Deployed" ? (
                                <span class="badge outline-badge-success">
                                  Deployed
                                </span>
                              ) : order.State === "Terminated" ? (
                                <span class="badge outline-badge-dark">
                                  Terminated
                                </span>
                              ) : order.State === "Pending" ? (
                                <span class="badge outline-badge-success">
                                  Pending
                                </span>
                              ) : (
                                <span class="badge outline-badge-warning">
                                  Unknown
                                </span>
                              )}
                            </VMBC.A>
                          </div>
                        </td>
                      </tr>
                    ))
                  : undefined}
              </tbody>
            </table>
            {orders && orders.length === 0 ? (
              <h6 className={`text-center py-3`}>No Recent Orders for this Plan</h6>
            ) : undefined}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentOrders;
