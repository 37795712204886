import React from "react";
import Registered from "./Registered";
import Deployed from "./Deployed";
import PoweredOn from "./PoweredOn";
import PoweredOff from "./PoweredOff";
import VmSuspended from "./VmSuspended";
import Deleted from "./Deleted";
import NotFound from "./NotFound";
import Pending from "./Pending";
// import ReserveVmComponent from "../ReserveVm";


function Actions(props) {
  const { vm } = props;
  let { State } = vm;

  var ReserveVmComp = undefined;
  if (!vm.IsReserved) {
    ReserveVmComp = <ReserveVm vm={vm} />;
  }

  switch (State) {
    case "Registered":
      return (
        <>
          <Registered {...props} />
        </>
      );
    case "Deployed":
      return (
        <>
          {ReserveVmComp}
          <Deployed {...props} />
        </>
      );
    case "PoweredOn":
      return (
        <>
          {ReserveVmComp}
          <PoweredOn {...props} />
        </>
      );
    case "PoweredOff":
      return (
        <>
          {ReserveVmComp}
          <PoweredOff {...props} />
        </>
      );
    case "VmSuspended":
      return (
        <>
          {ReserveVmComp}
          <VmSuspended {...props} />
        </>
      );
    case "Deleted":
      return (
        <>
          {ReserveVmComp}
          <Deleted {...props} />
        </>
      );
    case "NotFound":
      return (
        <>
          <NotFound {...props} />
        </>
      );
    case "pending":
      return (
        <>
          {ReserveVmComp}
          <Pending {...props} />
        </>
      );
    default:
      return null;
  }
}

function ReserveVm(props) {

  const [openState, setOpenState] = React.useState(false);

  return (
    <>
      {/* <ReserveVmComponent
        Vm={props.Vm}
        open={openState}
        setOpen={(e) => setOpenState(e)}
        onSelect
      /> */}

      <button
        className={`btn m-1 btn-outline-primary`}
        onClick={() => {
          setOpenState(true);
        }}
      >
        Reserve VM
      </button>
    </>
  );
}

export default Actions;
