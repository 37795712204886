import React from "react";
import En from "../../../assets/flags/ca.png";
import De from "../../../assets/flags/de.png";
import Fr from "../../../assets/flags/fr.png";
import Fa from "../../../assets/flags/fa.png";
import VMBC from "../../../VMBC";

import EnglishContent from "./English";
import FarsiContent from "./Farsi";

function SelectLanguage(props) {
  const { language, SetLanguage } = props;

  const selectLanguage = (lang) => {
    if (SetLanguage) {
      if (Languages[lang]) {
        SetLanguage(Languages[lang]);
      } else {
        SetLanguage(Languages.En);
      }
    }
  };

  return (
    <span className={`eulaLanguage`}>
      <div class="dropdown custom-dropdown-icon">
        <a
          class="dropdown-toggle btn"
          href="#"
          role="button"
          id="customDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src={language.Flag} class="flag-width" alt="flag" />
          <VMBC.SVGs.ChevronDown />
        </a>

        <div
          class="dropdown-menu dropdown-menu-right animated fadeInUp"
          aria-labelledby="customDropdown"
        >
          {Object.keys(Languages).map((langName) => (
            <a
              class="dropdown-item"
              data-img-value={langName}
              data-value={langName}
              href="javascript:void(0);"
              onClick={() => selectLanguage(langName)}
            >
              <img
                src={Languages[langName].Flag}
                class="flag-width"
                alt="flag"
              />{" "}
              {Languages[langName].Title}
            </a>
          ))}
        </div>
      </div>
    </span>
  );
}

const Languages = {
  Fa: {
    Name: "Fa",
    Title: "Farsi",
    Flag: Fa,
    Content: FarsiContent,
  },
  En: {
    Name: "En",
    Title: "English",
    Flag: En,
    Content: EnglishContent,
  },
};

export { Languages, SelectLanguage };
