import React from "react";
import "./IpPool.css";
import styles from "./index.module.css";
import VMBC from "../../../../VMBC";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

function IpPool(props) {
  const [addDisabled, setAddDisabled] = React.useState(true);
  const [removeDisabled, setRemoveDisabled] = React.useState(true);

  const [collection, setCollection] = React.useState(
    props.Data &&
      props.Data.current.IpPools &&
      props.Data.current.IpPools.oldCollection
      ? props.Data.current.IpPools.oldCollection
      : props.Data && props.Data.current.ComputeResources
      ? props.Data.current.ComputeResources.SelectedComputeResources.map(
          (Comp) => ({
            name: Comp.Name,
            parent: Comp,
            fromItems:
              Comp.ComputeResourceMO && Comp.ComputeResourceMO.IpPools
                ? Comp.ComputeResourceMO.IpPools.map((IpPool) => ({
                    name: IpPool.Name + (IpPool.IsGeneral ? " * " : ""),
                    IpPoolObj: IpPool,
                  }))
                : [],
            toItems: [],
          })
        )
      : []
  );
  const [rearrangeT, rearrangeSelectedItems] = React.useState(false);

  var validator = () => {
    if (!props.Data || !props.Data.current.IpPools) {
      return false;
    }
    const { SelectedComputeResources } = props.Data.current.ComputeResources;
    let gotError = false;
    SelectedComputeResources.forEach((Comp) => {
      if (gotError) return;
      if (
        !Comp.SelectedIpPools ||
        !Comp.SelectedIpPools.length ||
        Comp.SelectedIpPools.length === 0
      ) {
        VMBC.Alert.Show(
          `No IP Pools have been selected for ${Comp.Name}`,
          "error",
          3000
        );
        gotError = true;
      }
    });
    return !gotError;
  };

  React.useEffect(() => {
    props.SubmitNextValidator && props.SubmitNextValidator(validator);
    collection.forEach((items, i) => {
      window
        .dragula([document.getElementById("ipplTo-" + items.name)], {
          accepts: function (el, target, source, sibling) {
            // console.log(el.id, target.id, source.id);
            return target.id === source.id; // elements can be dropped in any of the `containers` by default
          },
        })
        .on("drag", function (el) {
          el.className += " el-drag-ex-1";
        })
        .on("drop", (el) => {
          el.className = el.className.replace("el-drag-ex-1", "");
          rearrangeSelectedItems(!rearrangeT); // trigger a few lines bellow React useEffect to rearrange the list of selected Objects
        })
        .on("cancel", function (el) {
          el.className = el.className.replace("el-drag-ex-1", "");
        });
    });
  }, []);

  React.useEffect(() => {
    // submit realocations by dragula
    // and save it in collection
    collection.forEach((col) => {
      const selectedIpPools = [];
      document
        .getElementById(`ipplTo-${col.name}`)
        .childNodes.forEach((childNode) => {
          var nodeId = Number(childNode.id);
          selectedIpPools.push({
            Name: col.toItems[nodeId].name,
            IpPoolMO: col.toItems[nodeId].IpPoolMO,
          });
        });
      col.parent.SelectedIpPools = selectedIpPools;
    });

    const newCol = [...collection];
    for (var colIndex = 0; colIndex < newCol.length; colIndex++) {
      const newToItems = [];
      document
        .getElementById(`ipplTo-${newCol[colIndex].name}`)
        .childNodes.forEach((childNode) => {
          var nodeId = Number(childNode.id);
          newToItems.push(newCol[colIndex].toItems[nodeId]);
        });
      newCol[colIndex].toItems = newToItems;
    }
    setCollection(newCol);
  }, [rearrangeT]);

  if (!props.Data || !props.Data.current.IpPools) {
    return null;
  }

  props.Data.current.IpPools.oldCollection = [
    ...collection.map((g) => ({
      name: g.name,
      parent: g.parent,
      fromItems: [...g.fromItems],
      toItems: [...g.toItems],
    })),
  ];
  collection.forEach((Comp) => {
    Comp.parent.SelectedIpPools = Comp.toItems.map((item) => {
      return item.IpPoolObj;
    });
  });

  const selectItem = () => {
    collection.forEach((g) => {
      var selectedItems = [];
      var selectedItemsIndex = [];
      g.fromItems.forEach((item, index) => {
        if (item.selected) {
          item.selected = false;
          selectedItemsIndex.push(index);
          selectedItems.push(item);
        }
      });
      g.fromItems = g.fromItems.filter(
        (v, i) => !selectedItemsIndex.includes(i)
      );
      g.toItems.push(...selectedItems);
    });
    setCollection([
      ...collection.map((g) => ({
        ...g,
        fromItems: [...g.fromItems],
        toItems: [...g.toItems],
      })),
    ]);
    checkAddRemoveDisabled();
  };

  const unSelectItem = () => {
    collection.forEach((g) => {
      var selectedItems = [];
      var selectedItemsIndex = [];
      g.toItems.forEach((item, index) => {
        if (item.selected) {
          item.selected = false;
          selectedItemsIndex.push(index);
          selectedItems.push(item);
        }
      });
      g.toItems = g.toItems.filter((v, i) => !selectedItemsIndex.includes(i));
      g.fromItems.push(...selectedItems);
    });
    setCollection([
      ...collection.map((g) => ({
        ...g,
        fromItems: [...g.fromItems],
        toItems: [...g.toItems],
      })),
    ]);
    checkAddRemoveDisabled();
  };

  const refresh = () => {
    setCollection(
      props.Data && props.Data.current.ComputeResources
        ? props.Data.current.ComputeResources.SelectedComputeResources.map(
            (Comp) => ({
              name: Comp.Name,
              parent: Comp,
              fromItems:
                Comp.ComputeResourceMO && Comp.ComputeResourceMO.IpPools
                  ? Comp.ComputeResourceMO.IpPools.map((IpPool) => ({
                      name: IpPool.Name + (IpPool.IsGeneral ? " * " : ""),
                      IpPoolObj: IpPool,
                    }))
                  : [],
              toItems: [],
            })
          )
        : []
    );
    checkAddRemoveDisabled();
  };

  const checkAddRemoveDisabled = () => {
    var add = false;
    var rem = false;
    collection.forEach((g) => {
      for (var i = 0; i < g.fromItems.length && !add; i++) {
        if (g.fromItems[i].selected) {
          add = true;
          break;
        }
      }
      for (var i = 0; i < g.toItems.length && !rem; i++) {
        if (g.toItems[i].selected) {
          rem = true;
          break;
        }
      }
    });
    setAddDisabled(!add);
    setRemoveDisabled(!rem);
  };

  return (
    <>
      <div className={`container`}>
        <div className={`row`}>
          <h6>Select IP Pools:</h6>
        </div>

        <div className={`row`}>
          <ScrollBar className={`${styles.SelectPanel} col`}>
            <ul className={`${styles.TreeViewRoot} file-tree`}>
              {collection.map((items, index) => (
                <React.Fragment key={index}>
                  <li
                    className={`file-tree-folder`}
                    onClick={function (e) {
                      window
                        .$(e.target)
                        .children("ul")
                        .slideToggle(400, function () {
                          window.$(this).parent("li").toggleClass("open");
                        });
                    }}
                  >
                    {items.name}
                    <ul>
                      {items.fromItems && items.fromItems.length > 0 ? (
                        items.fromItems.map((item, i) => (
                          <SortableTreeItemComponent
                            key={i}
                            value={
                              item.name !== ""
                                ? item.name
                                : item.IpPoolMO
                                ? item.IpPoolMO.Name
                                : ""
                            }
                            onChange={(e) => {
                              item.selected = e;
                              checkAddRemoveDisabled();
                            }}
                          />
                        ))
                      ) : (
                        <li> {"No IP Pools"} </li>
                      )}
                    </ul>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </ScrollBar>

          <div>
            <div
              className={`${styles.MoveButtonsContainer} container align-items-center`}
            >
              <div className={`row`}>
                <span
                  className={styles.MoveButtons}
                  disabled={addDisabled}
                  onClick={selectItem}
                >
                  <VMBC.SVGs.ChevronsRight
                    size="35"
                    style={{ color: "#eee" }}
                  />
                </span>
              </div>
              <div className={`row`}>
                <span
                  className={styles.MoveButtons}
                  onClick={unSelectItem}
                  disabled={removeDisabled}
                >
                  <VMBC.SVGs.ChevronsLeft size="35" style={{ color: "#eee" }} />
                </span>
              </div>

              <div className={`row justify-content-center`}>
                <span
                  className={styles.MoveButtons}
                  style={{ color: "#eee" }}
                  onClick={refresh}
                >
                  <VMBC.SVGs.RefreshCCW size="25" />
                </span>
              </div>
            </div>
          </div>

          <ScrollBar className={`${styles.SelectPanel} col`}>
            <ul className={`${styles.TreeViewRoot} file-tree`}>
              {collection.map((items, index) => (
                <React.Fragment key={index}>
                  <li
                    className={`file-tree-folder`}
                    onClick={(e) => {
                      window
                        .$(e.target)
                        .children("ul")
                        .slideToggle(400, function () {
                          window.$(this).parent("li").toggleClass("open");
                        });
                    }}
                  >
                    {items.name}
                    <ul
                      id={`ipplTo-${items.name}`}
                      className={`selectableContainer`}
                    >
                      {items.toItems && items.toItems.length > 0
                        ? items.toItems.map((item, i) => (
                            <SortableTreeItemComponent
                              id={i}
                              key={item.name}
                              value={item.name}
                              index={i}
                              collection={index}
                              onChange={(e) => {
                                item.selected = e;
                                checkAddRemoveDisabled();
                              }}
                              icon={
                                <>{i === 0 ? "Drag Drop to rearrange" : ""}</>
                              }
                            />
                          ))
                        : undefined}
                    </ul>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </ScrollBar>
        </div>
      </div>
    </>
  );
}

function SortableTreeItemComponent(props) {
  const { value, isMsg, onChange, icon } = props;
  const [checked, setChecked] = React.useState(false);
  React.useEffect(() => setChecked(false), [value]);

  return (
    <li
      id={props.id}
      onClick={(e) => {
        onChange(!checked);
        setChecked(!checked);
      }}
      nodeId={"" + value}
      onFocus={(e) => {
        if (e.currentTarget === e.target) {
          window.focus(e.target);
        }
      }}
    >
      <div className={`container`}>
        <div className={`row justify-content-between align-items-center`}>
          <span>
            {!isMsg ? (
              <label
                class="new-control new-checkbox checkbox-primary"
                style={{ color: "#fff" }}
              >
                <input
                  type="checkbox"
                  class="new-control-input"
                  onChange={(e) => {
                    setChecked(!e.target.checked);
                    onChange(!e.target.checked);
                  }}
                  checked={checked}
                />
                <span class="new-control-indicator"></span>
                {value}
              </label>
            ) : undefined}
          </span>
          {icon}
        </div>
      </div>
    </li>
  );
}

export default IpPool;
