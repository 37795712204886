import React from "react";
import VMBC from "../../../../../../../VMBC";
import HardDiskProfile from "./HardDiskProfile";

function HardDisk(props) {
  const { HardDisk, PlanId } = props;

  const [rerender, Rerender] = React.useState(false);

  const AddNewProfile = () => {
    window.$("#addHardDiskProfileDialog").modal("show");
  };

  const DeleteProfile = (ProfileId) => {
    VMBC.Dialogs.Show({
      Title: "Are you sure?",
      Text:
        "Are you sure you want to remove this Profile from this Plan. No VMachines will be deployed as this profile from this plan anymore",
      Ignorable: true,
      Actions: [
        {
          onClick: () => {
            VMBC.Plans.RemoveHardDiskProfile(ProfileId, (res, err) => {
              if (err) {
                VMBC.Alert.Show(err);
              } else {
                if (props.Refresh) props.Refresh();
              }
            });
            VMBC.Dialogs.Close();
          },
          className: "btn-primary btn-sm",
          text: "Yes",
        },
        {
          onClick: () => VMBC.Dialogs.Close(),
          className: "btn-outline-primary btn-sm",
          text: "No",
        },
      ],
    });
  };

  const onSetDefault = (profileId, UnMark) => {
    VMBC.Plans.MarkProfileAsDefault(
      {
        HardDiskProfileId: profileId,
        UnMark,
      },
      (res, err) => {
        if (err) {
          VMBC.Alert.Show(err);
        } else {
          if (props.Refresh) props.Refresh();
        }
      }
    );
  };

  return (
    <>
      <div
        className="modal fade"
        id="addHardDiskProfileDialog"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Add Profile Dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Hard Disk Profile
              </h5>
            </div>
            <HardDiskProfile
              key={-1}
              Index={-1}
              isNew={true}
              PlanId={props.PlanId}
              onClose={() => {
                window.$("#addHardDiskProfileDialog").modal("hide");
              }}
              AllProfiles={HardDisk}
              Profile={{
                isDefault: false,
              }}
              onSetDefault={() => {}}
              onDelete={() => {}}
              Refresh={props.Refresh}
            />
          </div>
        </div>
      </div>
      <div
        className={`mb-2`}
        style={{
          display: "flex",
          justifyContent: HardDisk && HardDisk.length ? "flex-end" : "center",
        }}
      >
        <button className={`btn btn-primary btn-sm`} onClick={AddNewProfile}>
          Add New
        </button>
      </div>
      {HardDisk && HardDisk.length
        ? HardDisk.map((profile, index) => (
            <HardDiskProfile
              key={index}
              Index={index}
              PlanId={props.PlanId}
              AllProfiles={HardDisk}
              Profile={profile}
              onSetDefault={onSetDefault}
              onDelete={DeleteProfile}
              Refresh={props.Refresh}
            />
          ))
        : undefined}
    </>
  );
}

export default HardDisk;
