import axios from "axios";
import ComputeResourceAndDatastore from "./ComputeResourceAndDatastore";
import IpAddresses from "./IpAddresses";

function GetAllVms({ CustomerId }, cb) {
  if (!!CustomerId && typeof CustomerId !== "string") {
    return;
  }
  CustomerId = CustomerId ? CustomerId : "";
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/machines/getAllVms",
        { SId: window.VMBC.SId, CustomerId },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetMachine({ Id, PackageId }, cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/machines/getVm",
        { SId: window.VMBC.SId, Id, PackageId },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetVmScreenShot({ Id, VmObjId }, cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/machines/getVmScreenShot",
        { SId: window.VMBC.SId, Id, VmObjId },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetMachineRecentActions({ Id, Limit }, cb) {
  cb = cb ? cb : () => {};
  if (Limit != undefined) {
    if (
      typeof Limit !== "number" ||
      Limit > 1000 ||
      Limit === 0 ||
      Limit < -1
    ) {
      Limit = undefined;
    }
  }
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/machines/getRecentActions",
        { SId: window.VMBC.SId, Id, Limit },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetMachineResourceUsage(Id, cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin +
          "/api/customers/machines/getMachineResourceUsage",
        { SId: window.VMBC.SId, Id },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function GetFreeMachines(cb) {
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/machines/getFreeVms",
        { SId: window.VMBC.SId },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function AssignVmToRecord(vmRecordId, vmId, cb) {
  if (
    !vmRecordId ||
    typeof vmRecordId !== "string" ||
    !vmId ||
    typeof vmId !== "string"
  )
    return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/machines/assignVmToRecord",
        { SId: window.VMBC.SId, RecordId: vmRecordId, VmId: vmId },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function ReserveVm(vmRecordId, selectedDs, selectedIp, cb) {
  if (
    !vmRecordId ||
    typeof vmRecordId !== "string" ||
    !selectedDs ||
    typeof selectedDs !== "string" ||
    !selectedIp ||
    typeof selectedIp !== "string"
  )
    return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/machines/reserveVm",
        {
          SId: window.VMBC.SId,
          RecordId: vmRecordId,
          SolutionDatastoreId: selectedDs,
          IpAddressId: selectedIp,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function CreateNewVmForRecord(vmRecordId, cb) {
  if (!vmRecordId || typeof vmRecordId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin +
          "/api/customers/machines/createNewVmForRecord",
        { SId: window.VMBC.SId, RecordId: vmRecordId },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function RunAction({ Name, RecordId, Param }, cb) {
  if (
    !RecordId ||
    typeof RecordId !== "string" ||
    !Name ||
    typeof Name !== "string"
  )
    return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/customers/machines/runAction",
        { SId: window.VMBC.SId, ActionName: Name, RecordId, Param },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

export default {
  GetAllVms,
  GetMachine,
  GetVmScreenShot,
  GetMachineRecentActions,
  GetMachineResourceUsage,
  GetFreeMachines,
  ReserveVm,
  AssignVmToRecord,
  CreateNewVmForRecord,
  RunAction,
  CompsAndDs: ComputeResourceAndDatastore,
  ...IpAddresses,
};
