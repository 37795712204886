function Show(Text, Type, Duration) {
  window.alert(`${Type}:\n${Text}`);
}

function RedirectToVcenterCheckUp() {
}

function ShowHard(content, text) {
  if (!content) {
    window.alert(`Error:\n${text}`);
  }
}

function SessionExpired() {
  if(this.Expired === true){
    return;
  }
  window.VMBC.Alert.Show("Session Expired. Please login again.", "error", 5000);
  setTimeout(() => {
    // window.location.href = "/logout";
  }, 5000);
  this.Expired = true;
}

function CloseHard() {}

export default {
  Show,
  ShowHard,
  RedirectToVcenterCheckUp,
  CloseHard,
  SessionExpired
};
