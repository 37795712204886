import React from "react";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import CustomersList from "./CustomersList";
// import Customer from "./Customer";
window.AddToLoadingVmboxProccess(1);

function CustomersRoot(props) {
  return (
    <Switch>
      <Route path={`${props.match.path}/`} exact children={<CustomersList />} />
      {/* <Route path={`${props.match.path}/:id`} exact children={<Customer />} /> */}
    </Switch>
  );
}

export default withRouter(CustomersRoot);
