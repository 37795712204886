import React from "react";
import VMBC from "../../../../../VMBC";

function LimitNetworkUsage(props) {
  var oldData =
    props.Data &&
    props.Data.current.Customization &&
    props.Data.current.Customization.NetworkQuota
      ? props.Data.current.Customization.NetworkQuota
      : false;

  const [expanded, setExpanded] = React.useState(
    oldData && oldData.expanded !== undefined ? oldData.expanded : true
  );

  const [enabled, setEnabled] = React.useState(
    oldData && oldData.enabled !== undefined ? oldData.enabled : false
  );

  const [limitTx, setLimitTx] = React.useState(
    oldData && oldData.limitTx !== undefined ? oldData.limitTx : false
  );
  const [limitTxBy, setLimitTxBy] = React.useState(
    oldData && oldData.limitTxBy !== undefined ? oldData.limitTxBy : ""
  );
  const [limitTxByUnit, setLimitTxByUnit] = React.useState(
    oldData && oldData.limitTxByUnit !== undefined ? oldData.limitTxByUnit : 0
  );

  const [limitRx, setLimitRx] = React.useState(
    oldData && oldData.limitRx !== undefined ? oldData.limitRx : false
  );
  const [limitRxBy, setLimitRxBy] = React.useState(
    oldData && oldData.limitRxBy !== undefined ? oldData.limitRxBy : ""
  );
  const [limitRxByUnit, setLimitRxByUnit] = React.useState(
    oldData && oldData.limitRxByUnit !== undefined ? oldData.limitRxByUnit : 0
  );

  const [limitUsage, setLimitUsage] = React.useState(
    oldData && oldData.limitUsage !== undefined ? oldData.limitUsage : false
  );
  const [limitUsageBy, setLimitUsageBy] = React.useState(
    oldData && oldData.limitUsageBy !== undefined ? oldData.limitUsageBy : ""
  );
  const [limitUsageByUnit, setLimitUsageByUnit] = React.useState(
    oldData && oldData.limitUsageByUnit !== undefined
      ? oldData.limitUsageByUnit
      : 0
  );

  const [resetQuota, setResetQuota] = React.useState(
    oldData && oldData.resetQuota !== undefined ? oldData.resetQuota : false
  );
  const [resetAfter, setResetAfter] = React.useState(
    oldData && oldData.resetAfter !== undefined ? oldData.resetAfter : 30
  );
  const [resetAfterUnit, setResetAfterUnit] = React.useState(
    oldData && oldData.resetAfterUnit !== undefined ? oldData.resetAfterUnit : 1
  );

  const [limitByRatio, setLimitByRatio] = React.useState(
    oldData && oldData.limitByRatio !== undefined ? oldData.limitByRatio : false
  );
  const [limitRatioType, setLimitRatioType] = React.useState(
    oldData &&
      oldData.limitRatioType !== undefined &&
      oldData.limitRatioType !== 0
      ? oldData.limitRatioType
      : undefined
  );
  const [maxAllowedRatio, setMaxAllowedRatio] = React.useState(
    oldData && oldData.maxAllowedRatio !== undefined
      ? oldData.maxAllowedRatio
      : ""
  );
  const [limitByRatioCheckpoint, setLimitByRatioCheckpoint] = React.useState(
    oldData && oldData.limitByRatioCheckpoint !== undefined
      ? oldData.limitByRatioCheckpoint
      : undefined
  );
  const [
    limitByRatioCheckpointType,
    setLimitByRatioCheckpointType,
  ] = React.useState(
    oldData && oldData.limitByRatioCheckpointType !== undefined
      ? oldData.limitByRatioCheckpointType
      : 0
  );

  React.useEffect(() => {
    window.$(".limitNetSelectpicker").selectpicker("refresh");
  });

  props.Data.current.Customization.NetworkQuota = {
    enabled: enabled,
    limitTx: limitTx,
    limitTxBy: limitTxBy,
    limitTxByUnit: limitTxByUnit,
    LimitTx: limitTx ? limitTxBy * (limitTxByUnit === 1 ? 1024 : 1) : 0, // based on MB
    limitRx: limitRx,
    limitRxBy: limitRxBy,
    limitRxByUnit: limitRxByUnit,
    LimitRx: limitRx ? limitRxBy * (limitRxByUnit === 1 ? 1024 : 1) : 0,
    limitUsage: limitUsage,
    limitUsageBy: limitUsageBy,
    limitUsageByUnit: limitUsageByUnit,
    LimitUsage: limitUsage
      ? limitUsageBy * (limitUsageByUnit === 1 ? 1024 : 1)
      : 0,
    resetQuota: resetQuota,
    resetAfter: resetAfter,
    resetAfterUnit: resetAfterUnit,
    ResetAfter: resetQuota ? resetAfter * (resetAfterUnit === 1 ? 24 : 1) : 0,

    limitByRatio: !!limitByRatio,
    limitRatioType: limitRatioType,
    maxAllowedRatio: maxAllowedRatio,
    limitByRatioCheckpoint: limitByRatioCheckpoint,
    limitByRatioCheckpointType: limitByRatioCheckpointType,

    expanded: expanded,
  };

  return (
    <>
      <div className={`card no-outer-spacing`}>
        <div className="card-header" id={"limitNetworkUsage"}>
          <section className="mb-0 mt-0">
            <div
              role="menu"
              className={`collapsed`}
              data-toggle="collapse"
              data-target={`#limitNetworkUsageAcc`}
              aria-expanded="false"
              aria-controls={`limitNetworkUsageAcc`}
            >
              <label
                class="new-control new-checkbox checkbox-primary"
                style={{ color: "#fff" }}
              >
                <input
                  type="checkbox"
                  class="new-control-input"
                  checked={enabled}
                  onChange={(e) => {
                    setEnabled(e.target.checked);
                  }}
                />
                <span class="new-control-indicator"></span>
                Measure Network Usage
              </label>

              <div className="icons">
                <VMBC.SVGs.ChevronDown />
              </div>
            </div>
          </section>
        </div>

        <div
          id={`limitNetworkUsageAcc`}
          className="collapse"
          aria-labelledby={"limitNetworkUsage"}
          data-parent="#toggleAccordion"
        >
          <div className="card-body">
            <div className={`container`}>
              <div className={`row mb-3`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <label class="switch s-primary mb-0 mr-2">
                        <input
                          disabled={!enabled}
                          type="checkbox"
                          checked={limitTx}
                          onChange={(e) =>
                            enabled && setLimitTx(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                      Limit Transmiting Data
                    </div>
                  </div>
                  <input
                    disabled={!enabled}
                    class="form-control"
                    aria-label="switch"
                    value={limitTxBy}
                    onChange={(e) =>
                      enabled &&
                      limitTx &&
                      setLimitTxBy(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!enabled || !limitTx}
                      className="limitNetSelectpicker"
                      data-width="100%"
                      value={limitTxByUnit}
                      onChange={(e) =>
                        enabled &&
                        limitTx &&
                        setLimitTxByUnit(Number(e.target.value))
                      }
                    >
                      <option value={0}>MB</option>
                      <option value={1}>GB</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`row mb-3`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <label class="switch s-primary mb-0 mr-2">
                        <input
                          disabled={!enabled}
                          type="checkbox"
                          checked={limitRx}
                          onChange={(e) =>
                            enabled && setLimitRx(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                      Limit Recieving Data
                    </div>
                  </div>
                  <input
                    disabled={!enabled}
                    class="form-control"
                    aria-label="switch"
                    value={limitRxBy}
                    onChange={(e) =>
                      enabled &&
                      limitRx &&
                      setLimitRxBy(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!enabled || !limitRx}
                      className="limitNetSelectpicker"
                      data-width="100%"
                      value={limitRxByUnit}
                      onChange={(e) =>
                        enabled &&
                        limitRx &&
                        setLimitRxByUnit(Number(e.target.value))
                      }
                    >
                      <option value={0}>MB</option>
                      <option value={1}>GB</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`row mb-3`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <label class="switch s-primary mb-0 mr-2">
                        <input
                          disabled={!enabled}
                          type="checkbox"
                          checked={limitUsage}
                          onChange={(e) =>
                            enabled && setLimitUsage(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                      Limit Both Recieving and Transmiting Data
                    </div>
                  </div>
                  <input
                    disabled={!enabled}
                    class="form-control"
                    aria-label="switch"
                    value={limitUsageBy}
                    onChange={(e) =>
                      enabled &&
                      limitUsage &&
                      setLimitUsageBy(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!enabled || !limitUsage}
                      className="limitNetSelectpicker"
                      data-width="100%"
                      value={limitUsageByUnit}
                      onChange={(e) =>
                        enabled &&
                        limitUsage &&
                        setLimitUsageByUnit(Number(e.target.value))
                      }
                    >
                      <option value={0}>MB</option>
                      <option value={1}>GB</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`row mb-3`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <label class="switch s-primary mb-0 mr-2">
                        <input
                          disabled={!enabled}
                          type="checkbox"
                          checked={resetQuota}
                          onChange={(e) =>
                            enabled && setResetQuota(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                      Reset Quota after
                    </div>
                  </div>
                  <input
                    disabled={!enabled}
                    class="form-control"
                    aria-label="switch"
                    value={resetAfter}
                    onChange={(e) =>
                      enabled &&
                      resetQuota &&
                      setResetAfter(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!enabled || !resetQuota}
                      className="limitNetSelectpicker"
                      data-width="100%"
                      value={resetAfterUnit}
                      onChange={(e) =>
                        enabled &&
                        resetQuota &&
                        setResetAfterUnit(Number(e.target.value))
                      }
                    >
                      <option value={0}>Hours</option>
                      <option value={1}>Days</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`row mb-1`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <label class="switch s-primary mb-0 mr-2">
                        <input
                          disabled={!enabled}
                          type="checkbox"
                          checked={limitByRatio}
                          onChange={(e) =>
                            enabled && setLimitByRatio(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                      if
                    </div>

                    <select
                      disabled={!enabled || !limitByRatio}
                      className="limitNetSelectpicker"
                      title={
                        limitRatioType === undefined
                          ? "Select a Ratio Type"
                          : undefined
                      }
                      data-width="100%"
                      value={limitRatioType}
                      onChange={(e) =>
                        enabled &&
                        limitByRatio &&
                        setLimitRatioType(Number(e.target.value))
                      }
                    >
                      <option value={1}>the upload to download</option>
                      <option value={2}>the download to upload</option>
                    </select>

                    <div class="input-group-text">ratio is more than</div>
                  </div>
                  <input
                    disabled={!enabled}
                    class="form-control text-center"
                    placeholder={`Maximum Allowed`}
                    aria-label="switch"
                    value={maxAllowedRatio}
                    onChange={(e) =>
                      enabled &&
                      limitByRatio &&
                      setMaxAllowedRatio(
                        e.target.value.replace(/[^0-9\.]/g, "")
                      )
                    }
                  />
                </div>
              </div>

              <div className={`row mb-1`}>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      then disconnect the network if
                    </div>
                  </div>

                  <input
                    disabled={!enabled}
                    class="form-control text-center"
                    placeholder={`Ratio Checkpoint`}
                    aria-label="switch"
                    value={limitByRatioCheckpoint}
                    onChange={(e) =>
                      enabled &&
                      limitByRatio &&
                      setLimitByRatioCheckpoint(
                        e.target.value.replace(/[^0-9]/g, "")
                      )
                    }
                  />

                  <div className={`input-group-append`}>
                    <select
                      disabled={!enabled || !limitByRatio}
                      className="limitNetSelectpicker"
                      title={
                        limitByRatioCheckpointType === undefined
                          ? "Select a Checkpoint Type"
                          : undefined
                      }
                      data-width="100%"
                      value={limitByRatioCheckpointType}
                      onChange={(e) =>
                        enabled &&
                        limitByRatio &&
                        setLimitByRatioCheckpointType(Number(e.target.value))
                      }
                    >
                      <option value={0}>Hours have elapsed</option>
                      <option value={1}>Days have elapsed</option>
                      <option value={2}>MB have been used</option>
                      <option value={3}>GB have been used</option>
                    </select>
                    <div class="input-group-text">
                      since the begining of the network quota{" "}
                    </div>
                  </div>
                  <div
                    className={`d-flex align-items-center px-1`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      VMBC.Dialogs.Show({
                        Title: "Network Limit By Ratio",
                        Text: limitByRatio
                          ? !limitRatioType || limitRatioType === 0
                            ? "Please select a Ratio Type first"
                            : !maxAllowedRatio || maxAllowedRatio === ""
                            ? "Maximum Allowed Ratio is required"
                            : !limitByRatioCheckpoint ||
                              limitByRatioCheckpoint === ""
                            ? "Ratio Checkpoint is required"
                            : typeof limitByRatioCheckpointType !== "number" ||
                              limitByRatioCheckpointType < 0 ||
                              limitByRatioCheckpointType > 3
                            ? "Please select a Checkpoint Type first"
                            : `VM's Network will be disconnected if ${
                                limitRatioType === 1
                                  ? "uploading traffic from vm"
                                  : "downloading traffic to vm"
                              } is more than ( ${maxAllowedRatio} x ${
                                limitRatioType === 1
                                  ? "downloading traffic to vm"
                                  : "uploading traffic from vm"
                              } ), after ${
                                limitByRatioCheckpointType < 2
                                  ? `${limitByRatioCheckpoint} ${
                                      limitByRatioCheckpointType === 0
                                        ? "Hour(s)"
                                        : "Day(s)"
                                    }`
                                  : `${limitByRatioCheckpoint} ${
                                      limitByRatioCheckpointType === 2
                                        ? "MB"
                                        : "GB"
                                    } of traffic have been used by the vm`
                              } since the begining of the network quota.`
                          : "Limiting by Network Usage Ratio is disabled",
                        Ignorable: true,
                        Actions: [
                          {
                            className: "btn-outline-primary btn-sm",
                            text: "Close",
                            onClick: () => VMBC.Dialogs.Close(),
                          },
                        ],
                      });
                    }}
                  >
                    <VMBC.SVGs.Info />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LimitNetworkUsage;
