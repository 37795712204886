import React from "react";
import VMBC from "../../../../VMBC";

function RegistrationStatistics(props) {
  const { plan } = props;

  const [timeSpan, setTimeSpan] = React.useState(
    localStorage.getItem("planRegStatisticsTimePer")
      ? localStorage.getItem("planRegStatisticsTimePer").startsWith("y")
        ? "yearly"
        : "monthly"
      : "monthly"
  );

  const Data = React.useRef();

  const RegistrationsChartOptions = (timeSpan, cb) => {
    if (!!Data.current) {
      cb(GenerateChartOptions(timeSpan, Data.current), false);
    } else {
      VMBC.Plans.Statistics.GetRegistrationTerminationStatistics(
        plan.PlanId,
        (res, err) => {
          if (err) {
            cb(null, err);
          } else {
            Data.current = res;
            cb(GenerateChartOptions(timeSpan, Data.current), false);
          }
        }
      );
    }
  };

  React.useEffect(() => {
    if (document.getElementById("registrationsChart")) {
      RegistrationsChartOptions(timeSpan, (options, err) => {
        if (err) {
          VMBC.Alert.Show(err, "error");
          return;
        }
        document.getElementById("registrationsChart").innerHTML = "";
        var chart1 = new window.ApexCharts(
          document.querySelector("#registrationsChart"),
          options
        );
        chart1.render();
      });
    }
  }, [timeSpan]);

  React.useEffect(() => {
    document.getElementById("registrationsChart").innerHTML = "";
    var chart1 = new window.ApexCharts(
      document.querySelector("#registrationsChart"),
      GenerateChartOptions(timeSpan, {
        yearly: {
          TerminationSeries: [],
          RegistrationSeries: [],
          categories: [],
        },
        monthly: {
          TerminationSeries: [],
          RegistrationSeries: [],
          categories: [],
        },
      })
    );
    chart1.render();
  }, []);

  return (
    <>
      <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div className="widget widget-chart-one">
          <div className="widget-heading">
            <h5 className="">Registrations</h5>
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                type="button"
                className={`btn btn-dark ${
                  timeSpan === "monthly" ? "disabled" : ""
                }`}
                style={{
                  cursor: timeSpan === "yearly" ? "pointer" : "default",
                }}
                onClick={() => {
                  if (timeSpan === "yearly") {
                    localStorage.setItem("planRegStatisticsTimePer", "monthly");
                    setTimeSpan("monthly");
                  }
                }}
              >
                Monthly
              </button>
              <button
                type="button"
                className={`btn btn-dark ${
                  timeSpan === "yearly" ? "disabled" : ""
                }`}
                style={{
                  cursor: timeSpan === "monthly" ? "pointer" : "default",
                }}
                onClick={() => {
                  if (timeSpan === "monthly") {
                    localStorage.setItem("planRegStatisticsTimePer", "yearly");
                    setTimeSpan("yearly");
                  }
                }}
              >
                Yearly
              </button>
            </div>
          </div>

          <div className="widget-content">
            <div className="tabs tab-content">
              <div className="tabcontent">
                <div id="registrationsChart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function GenerateChartOptions(timeSpan, Statistics) {
  if (!Statistics[timeSpan]) {
    return null;
  }
  return {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: "#515365",
        opacity: 0.3,
      },
    },
    colors: ["#ffbb44", "#5c1ac3"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      markers: {
        width: 10,
        height: 10,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 8,
      },
    },
    grid: {
      borderColor: "#191e3a",
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    series: [
      {
        name: "Terminate",
        data: Object.values(Statistics[timeSpan].TerminationSeries),
      },
      {
        name: "Register",
        data: Object.values(Statistics[timeSpan].RegistrationSeries),
      },
    ],
    xaxis: {
      categories: Statistics[timeSpan].categories,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.3,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.8,
        stops: [0, 100],
      },
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
}

export default RegistrationStatistics;
