import React from "react";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import Templates from "./Templates";
window.AddToLoadingVmboxProccess(1);

function TemplatesRoot(props) {
  return (
    <Switch>
      <Route path={`${props.match.path}/`} exact children={<Templates />} />
      <Route path={`${props.match.path}/:id`} exact children={<Templates edit />} />
    </Switch>
  );
}

export default withRouter(TemplatesRoot)