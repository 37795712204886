import React from "react";
import VMBC from "../../../VMBC";
import Table from "../../../Components/Table";
import AddIsoFile from "./AddIsoFile";

function IsoFiles(props) {
  const [DCs, setDCs] = React.useState();

  React.useEffect(() => {
    VMBC.Inventory.GetDatacenters((dcs) => {
      dcs = dcs ? dcs : [];
      setDCs(dcs);
    });
  }, []);

  return (
    <>
      {DCs ? (
        DCs.length > 0 ? (
          DCs.map((dc, i) => <Datacenter DC={dc} key={i} />)
        ) : (
          <div className="widget mt-4 text-center py-4">
            <h6 className="align-self-center" style={{ margin: "auto" }}>
              No Datacenters
            </h6>
          </div>
        )
      ) : (
        <div className="widget mt-4 text-center py-4">
          <div
            className="spinner-border align-self-center loader-lg"
            style={{ width: "3rem", height: "3rem" }}
          ></div>
        </div>
      )}
    </>
  );
}

function Datacenter(props) {
  const { DC } = props;

  const [CRs, setCRs] = React.useState();

  React.useEffect(() => {
    DC.GetComputeResources((crs) => {
      crs = crs ? crs : [];
      setCRs(crs);
    });
  }, []);

  return (
    <div className="widget mt-4">
      <div className="widget-content vertical-line-pill">
        <div className="row">
          <div className="col-xl-12 col-md-12 col-sm-12 col-12">
            <h4>{DC.Name}</h4>
          </div>
        </div>
        {!CRs ? (
          <div className={`row justify-content-center`}>
            <div
              className="spinner-border align-self-center loader-lg"
              style={{ width: "2rem", height: "2rem" }}
            ></div>
          </div>
        ) : CRs.length === 0 ? (
          <div className={`row justify-content-center text-center mb-3`}>
            <h6 className="align-self-center" style={{ margin: "auto" }}>
              No Compute Resources
            </h6>
          </div>
        ) : (
          <div className="row mb-4 mt-3">
            <div
              className="col-sm-2 col-12 pt-5 pr-4"
              style={{ borderRadius: "20px", backgroundColor: "#123" }}
            >
              <div
                className="nav flex-column nav-pills mb-sm-0 mb-3 text-center mx-auto"
                role="tablist"
                aria-orientation="vertical"
              >
                {CRs.map((cr, i) => (
                  <a
                    key={i}
                    className={`nav-link mb-3 ${i === 0 ? "active" : ""}`}
                    id={`v-line-pills-${cr.MO_Reference}-tab`}
                    data-toggle="pill"
                    href={`#v-line-pills-${cr.MO_Reference}`}
                    role="tab"
                    aria-controls={`v-line-pills-${cr.MO_Reference}`}
                    aria-selected={i === 0 ? "true" : "false"}
                  >
                    {cr.Name}
                  </a>
                ))}
              </div>
            </div>

            <div className="col-sm-10 col-12">
              <div className="tab-content" id="v-line-pills-tabContent">
                {CRs.map((cr, i) => (
                  <div
                    key={i}
                    className={`tab-pane fade ${i === 0 ? "show active" : ""}`}
                    id={`v-line-pills-${cr.MO_Reference}`}
                    role="tabpanel"
                    aria-labelledby={`v-line-pills-${cr.MO_Reference}-tab`}
                  >
                    <IsoFilesList CR={cr} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function IsoFilesList(props) {
  const { CR } = props;

  const [isoFiles, setIsoFiles] = React.useState();
  const [refreshT, refresh] = React.useState(false);
  const onOpenAddIsoFiles = React.useRef();

  React.useEffect(() => {
    setIsoFiles();
    CR.GetCdIsoFiles((isofls) => {
      isofls = isofls ? isofls : [];
      setIsoFiles(isofls);
    });
    CR.ConstructProperties(["HostSystems", "Networks"], {
      Datastore: (dss) => dss.forEach((ds) => ds.GetSummary()),
    });
  }, [refreshT]);

  const onAdd = (isoFileObj) => {
    CR.CreateNewCdIsoFile(
      isoFileObj.Name,
      isoFileObj.Desc,
      isoFileObj.isoFileAddress,
      (err) => {
        if (!err) {
          refresh(!refreshT);
        } else {
          VMBC.Alert.Show(err, "error", 3000);
        }
      }
    );
    return true;
  };

  const callbackHandler = (done) => (error) => {
    if (error) {
      VMBC.Alert.Show(error, "error", 3000);
    } else {
      refresh(!refreshT);
    }
    done();
  };

  const Refresh = () => refresh(!refreshT);

  return (
    <>
      {isoFiles ? (
        <>
          <Table
            columns={[
              {
                title: "Name",
                fieldName: "Name",
                addValidator: (newVal) => {
                  if (
                    newVal === undefined ||
                    typeof newVal !== "string" ||
                    newVal === ""
                  ) {
                    return "Name can not be empty";
                  }
                },
                editValidator: (newVal) => {
                  if (
                    newVal === undefined ||
                    typeof newVal !== "string" ||
                    newVal === ""
                  ) {
                    return "Name can not be empty";
                  }
                },
              },
              { title: "Description", fieldName: "Description" },
            ]}
            data={isoFiles}
            actions={{
              onAdd: {
                onClick: () => {
                  if (onOpenAddIsoFiles.current) {
                    onOpenAddIsoFiles.current();
                  }
                },
              },
              onEdit: {
                EventHandler: (oldData, newData, done) => {
                  CR.EditCdIsoFile(
                    oldData.Id,
                    newData.Name,
                    newData.Description,
                    callbackHandler(done)
                  );
                },
              },
              onDelete: {
                EventHandler: (oldData, done) => {
                  CR.DeleteCdIsoFile(oldData.Id, callbackHandler(done));
                },
              },
              FreeActions: [
                {
                  Title: "Refresh",
                  onClick: () => {
                    Refresh();
                  },
                  Icon: (
                    <VMBC.SVGs.RefreshCCW style={{ position: "initial" }} />
                  ),
                },
              ],
            }}
          />
          <AddIsoFile
            ComputeResource={CR}
            onSelect={onAdd}
            onOpen={onOpenAddIsoFiles}
          />
        </>
      ) : (
        <div className={`row justify-content-center mt-5`}>
          <div
            className="spinner-border align-self-center loader-lg"
            style={{ width: "2rem", height: "2rem" }}
          ></div>
        </div>
      )}
    </>
  );
}

export default IsoFiles;
