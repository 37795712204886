import React from "react";
import IpField from "../../../../../Components/IpAddressField";
import IpAddressDataType from "../../../../../VMBC/DataTypes/IpAddress";
import VMBC from "../../../../../VMBC";
import SelectPortGroup from "./SelectPortGroup";

function AddRange(props) {
  const { ComputeResource, OnNewIp, Close } = props;

  const [fromIp, setFromIp] = React.useState();
  const [toIp, setToIp] = React.useState();
  const [Subnet, setSubnet] = React.useState();
  const [Gateway, setGateway] = React.useState();
  const [DNS1, setDNS1] = React.useState();
  const [DNS2, setDNS2] = React.useState();
  const [portGroup, setPortGroup] = React.useState();
  const [refreshT, refresh] = React.useState(false);
  const [messageShown, setMessageShown] = React.useState(false);
  const warningMessage = React.useRef("");

  const ipAddrArray = React.useRef([]);

  if (!OnNewIp) {
    return null;
  }

  const addBtnClicked = () => {
    if (!fromIp || !toIp || !Gateway) {
      VMBC.Alert.Show("Check your inputs", "warning", 3000);
      return;
    }
    if (props.ComputeResource !== false && !portGroup) {
      VMBC.Alert.Show("Please select a Port Group", "warning", 3000);
      return;
    }
    var SubnetIpObj = new IpAddressDataType(Subnet);
    var NetPerfix = SubnetIpObj.SubnetMaskToNetPrefix(SubnetIpObj);
    var GateWayIpObj = new IpAddressDataType(Gateway, Number(NetPerfix));

    // list of all subnets of entered ip addresses
    // if there is a subnet that the gateway is not reachable in it,
    // show some warning before add
    var ListOfSubnets = [GateWayIpObj.ToSubnet()];
    warningMessage.current = ""; // reset the warning message

    var step = fromIp > toIp ? -1 : 1;

    ipAddrArray.current = [];
    for (var ip = fromIp; ip <= toIp; ip += step) {
      var ipAdd = new IpAddressDataType(ip, Number(NetPerfix));
      if (!ListOfSubnets.includes(ipAdd.ToSubnet())) {
        ListOfSubnets.push(ipAdd.ToSubnet());
        warningMessage.current = (
          <span
            style={{ color: "#f00", textAlign: "center", fontSize: "15px" }}
          >
            Gateway will be unreachable for {ipAdd.ToString()}/{NetPerfix}
            <br />
            There is {ListOfSubnets.length} different subnets among these ip
            addresses
          </span>
        );
      }
      if (ipAdd._ip === GateWayIpObj._ip) {
        continue; // if this ip is the gateway. do not add
      }
      var ipObj = {
        Ip: ipAdd._ip,
        SubnetMask: ipAdd.NetPrefixToSubnetMask()._ip,
        Gateway: GateWayIpObj._ip,
        Dns1: 0,
        Dns2: 0,
        RDNS: "",
        PortGroupId: portGroup ? portGroup.MO_Reference : "",
      };
      if (DNS1) {
        ipObj.Dns1 = DNS1;
        if (DNS2) {
          ipObj.Dns2 = DNS2;
        }
      } else if (DNS2) {
        ipObj.Dns1 = DNS2;
      }
      ipAddrArray.current = [...ipAddrArray.current, ipObj];
    }

    if (ipAddrArray.current.length === 0) {
      VMBC.Alert.Show("No IP Addresses found in this range", "error", 3000);
      return;
    }
    setMessageShown(true);
  };

  return (
    <>
      <div className={`row justify-content-center mb-2`}>
        <IpField label="From" onChange={setFromIp} />
      </div>
      <div className={`row justify-content-center mb-2`}>
        <IpField label="To" onChange={setToIp} />
      </div>
      <div className={`row justify-content-center mb-2`}>
        <IpField label="Subnet Mask" onChange={setSubnet} />
      </div>
      <div className={`row justify-content-center mb-2`}>
        <IpField
          label="Gateway"
          onChange={setGateway}
          defaultValue={Number(localStorage.getItem("addIpRangeGateway"))}
        />
        <VMBC.ToolTip title={"Remember"}>
          <label class="switch s-outline s-outline-info mt-2 ml-2">
            <input
              type="checkbox"
              defaultChecked={
                localStorage.getItem("addIpRangeGateway") != undefined
              }
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem("addIpRangeGateway", Gateway);
                } else {
                  localStorage.removeItem("addIpRangeGateway");
                }
              }}
            />
            <span class="slider round"></span>
          </label>
        </VMBC.ToolTip>
      </div>
      <div className={`row justify-content-center mb-2`}>
        <IpField
          label="DNS1"
          onChange={setDNS1}
          defaultValue={Number(localStorage.getItem("addIpRangeDns1"))}
        />
        <VMBC.ToolTip title={"Remember"}>
          <label class="switch s-outline s-outline-info mt-2 ml-2">
            <input
              type="checkbox"
              defaultChecked={
                localStorage.getItem("addIpRangeDns1") != undefined
              }
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem("addIpRangeDns1", DNS1);
                } else {
                  localStorage.removeItem("addIpRangeDns1");
                }
              }}
            />
            <span class="slider round"></span>
          </label>
        </VMBC.ToolTip>
      </div>
      <div className={`row justify-content-center mb-2`}>
        <IpField
          label="DNS2"
          onChange={setDNS2}
          defaultValue={Number(localStorage.getItem("addIpRangeDns2"))}
        />
        <VMBC.ToolTip title={"Remember"}>
          <label class="switch s-outline s-outline-info mt-2 ml-2">
            <input
              type="checkbox"
              defaultChecked={
                localStorage.getItem("addIpRangeDns2") != undefined
              }
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem("addIpRangeDns2", DNS2);
                } else {
                  localStorage.removeItem("addIpRangeDns2");
                }
              }}
            />
            <span class="slider round"></span>
          </label>
        </VMBC.ToolTip>
      </div>
      {ComputeResource != undefined && ComputeResource !== false ? (
        <div className={`row justify-content-center mb-2`}>
          <SelectPortGroup
            key={refreshT ? 1 : 0}
            ComputeResource={ComputeResource}
            onChange={(ref) => setPortGroup(ref.pg)}
          />
          <VMBC.SVGs.RefreshCCW
            onClick={() => refresh(!refreshT)}
            size={20}
            style={{ cursor: "pointer", margin: "12px 0 0 8px" }}
          />
        </div>
      ) : undefined}
      {messageShown ? (
        <>
          <div className={`row justify-content-center`}>
            {ipAddrArray.current.length} Ip Addresses are going to be added to
            IP Addresses List.
            <br />
            {warningMessage.current}
            <br />
            Are you sure you want to continue?
          </div>
          <div className={`row justify-content-center mt-2`}>
            <button
              className={`btn btn-primary btn-sm`}
              onClick={() => {
                OnNewIp([...ipAddrArray.current]);
                setMessageShown(false);
                if (Close != undefined && typeof Close === "function") {
                  Close();
                }
              }}
            >
              Yes
            </button>
            <button
              className={`btn btn-primary btn-sm`}
              onClick={() => setMessageShown(false)}
            >
              No
            </button>
          </div>
        </>
      ) : (
        <div className={`row justify-content-center`}>
          <button className={`btn btn-primary btn-sm`} onClick={addBtnClicked}>
            Add
          </button>
        </div>
      )}
    </>
  );
}

export default AddRange;
