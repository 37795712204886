import React from "react";

function CdDvdDevice(props) {
  var oldData =
    props.Data &&
    props.Data.current.Customization &&
    props.Data.current.Customization.CdDrive;

  const [expanded, setExpanded] = React.useState(
    oldData ? oldData.expanded : "cdDrivePanel"
  );
  // deprecated
  // remove this state if there will be no checkbox for enabled
  // if there is going to be a checkbox for enabling, set props.Data.current.Customization.CdDrive.enabled as enabled not tempEnabled (a few lines bellow)
  const [enabled, setEnabled] = React.useState(
    oldData ? oldData.enabled : true
  );
  const collection = React.useRef(oldData ? oldData.collection.current : {});
  const [rerender, Rerender] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  var tempEnabled = false;
  Object.keys(collection.current).forEach((key) => {
    if (!collection.current[key].parent) {
      return;
    }
    if (collection.current[key].selected) {
      tempEnabled = true;
      var isoObj = collection.current[key];
      if (isoObj.parent.SelectedCdIsoFiles) {
        if (!isoObj.parent.SelectedCdIsoFiles.includes(isoObj.Id)) {
          isoObj.parent.SelectedCdIsoFiles.push(isoObj.Id);
        }
      } else {
        isoObj.parent.SelectedCdIsoFiles = [isoObj.Id];
      }
    } else {
      var isoObj = collection.current[key];
      if (isoObj.parent.SelectedCdIsoFiles) {
        isoObj.parent.SelectedCdIsoFiles = isoObj.parent.SelectedCdIsoFiles.filter(
          (id) => id !== isoObj.Id
        );
      }
    }
  });

  props.Data.current.Customization.CdDrive.enabled = tempEnabled;
  props.Data.current.Customization.CdDrive.expanded = expanded;
  props.Data.current.Customization.CdDrive.collection = collection;

  return (
    <>
      <ul className={`file-tree`}>
        {props.Data &&
          props.Data.current.ComputeResources.SelectedComputeResources.map(
            (Comp, index) => (
              <li
                className={`file-tree-folder`}
                onClick={function (e) {
                  window
                    .$(e.target)
                    .children("ul")
                    .slideToggle(400, function () {
                      window.$(this).parent("li").toggleClass("open");
                    });
                }}
              >
                {Comp.Name}

                {Comp.ComputeResourceMO ? (
                  <ul>
                    {Comp.ComputeResourceMO.CdIsoFiles.map((cdIsoFile, i) => (
                      <TreeItemComponent
                        id={"" + index + i}
                        key={"" + index + i + cdIsoFile.Name}
                        value={cdIsoFile.Name}
                        index={i}
                        collection={index}
                        Checked={
                          !!collection.current[cdIsoFile.Id] &&
                          collection.current[cdIsoFile.Id].selected
                        }
                        onChange={(e) => {
                          cdIsoFile.parent = Comp;
                          if (!!collection.current[cdIsoFile.Id]) {
                            collection.current[
                              cdIsoFile.Id
                            ].selected = collection.current[cdIsoFile.Id]
                              .selected
                              ? !collection.current[cdIsoFile.Id].selected
                              : true;
                            Rerender(!rerender);
                          } else {
                            collection.current[cdIsoFile.Id] = cdIsoFile;
                            cdIsoFile.selected = true;
                            Rerender(!rerender);
                          }
                        }}
                      />
                    ))}
                  </ul>
                ) : undefined}
              </li>
            )
          )}
      </ul>
    </>
  );
}

function TreeItemComponent(props) {
  const { value, isMsg, onChange, icon } = props;
  const [checked, setChecked] = React.useState(props.Checked);
  React.useEffect(() => setChecked(false), [value]);

  return (
    <li
      id={props.id}
      onClick={(e) => {
        onChange(!checked);
        setChecked(!checked);
      }}
      nodeId={"" + value}
      onFocus={(e) => {
        if (e.currentTarget === e.target) {
          window.focus(e.target);
        }
      }}
    >
      <div className={`container`}>
        <div className={`row justify-content-between align-items-center`}>
          <span>
            {!isMsg ? (
              <label
                class="new-control new-checkbox checkbox-primary"
                style={{ color: "#fff" }}
              >
                <input
                  type="checkbox"
                  class="new-control-input"
                  onChange={(e) => {
                    setChecked(!e.target.checked);
                    onChange(!e.target.checked);
                  }}
                  checked={checked}
                />
                <span class="new-control-indicator"></span>
                {value}
              </label>
            ) : undefined}
          </span>
          {icon}
        </div>
      </div>
    </li>
  );
}

export default CdDvdDevice;
