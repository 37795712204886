import React from "react";
import VMBC from "../../../VMBC";

function TemplateCard(props) {
  const [editDialogOpen, setEditDialogOpen] = React.useState(props.editable);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const alias = React.useRef(props.Alias);
  const osType = React.useRef(VMBC.Enums.OsTypes.indexOf(props.OsType));

  const password = React.useRef(props.Password);
  const etherName = React.useRef(props.EtherName);

  const onEditButtonClicked = () => {
    VMBC.Dialogs.Show({
      Title: `Edit ${props.Alias}`,
      Ignorable: true,
      Fields: (
        <EditFields
          alias={alias}
          osType={osType}
          password={password}
          etherName={etherName}
          {...props}
        />
      ),
      Actions: [
        {
          text: "Cancel",
          className: "btn-outline-primary btn-sm",
          onClick: () => VMBC.Dialogs.Close(),
        },
        {
          text: "Edit",
          className: "btn-primary btn-sm",
          onClick: () => {
            props.onEdit &&
              props.onEdit({
                Alias: alias.current,
                OsType: osType.current,
                TemplateId: props.TemplateId,

                Password: password.current,
                EtherName: etherName.current,
              }) &&
              VMBC.Dialogs.Close();
          },
        },
      ],
    });
  };

  const onDeleteButtonClicked = () => {
    VMBC.Dialogs.Show({
      Title: `Delete ${props.Alias}`,
      Ignorable: true,
      Text: (
        <>
          Are you sure you want to remove this template.
          <br />
          removing template dosen't delete the coresponding Machine. just
          removes it from vmbox.
        </>
      ),
      Actions: [
        {
          text: "No",
          className: "btn-outline-primary btn-sm",
          onClick: () => VMBC.Dialogs.Close(),
        },
        {
          text: "Yes",
          className: "btn-primary btn-sm",
          onClick: () => {
            props.onDelete && props.onDelete(props.TemplateId);
            VMBC.Dialogs.Close();
          },
        },
      ],
    });
  };

  return (
    <div
      class="card component-card_3 mt-4"
      style={{
        // width: "12rem",
        borderRadius: "8px",
        border: "1px solid #1b2e4b",
        backgroundColor: "#191e3a",
      }}
    >
      <div class="card-body">
        <img
          src={
            VMBC.Enums.Logos[props.OsType] ? VMBC.Enums.Logos[props.OsType] : ""
          }
          class="card-img-top"
          alt={props.OsType}
          style={{
            width: "150px",
            height: "auto",
            borderRadius: "10px",
            padding: "12px",
            backgroundColor: "#0000",
          }}
        />
        <h5 class="card-user_name">{props.Alias}</h5>
        <p class="card-user_occupation">OS Type: {props.OsType}</p>

        <p class="card-text">VM Name: {props.TemplateName}</p>

        <button class="btn btn-primary mb-2 mx-1" onClick={onEditButtonClicked}>
          <VMBC.SVGs.EditByPen />
          Edit
        </button>
        <button
          class="btn btn-danger mb-2 mx-1"
          onClick={onDeleteButtonClicked}
        >
          <VMBC.SVGs.RecycleBin />
          Delete
        </button>
      </div>
    </div>
  );
}

function EditFields(props) {
  const { alias, osType, password, etherName } = props;

  React.useEffect(()=>{
    window.$(".editTamplateSelecpicker").selectpicker("refresh");
  }, [])

  return (
    <div className={`container`}>
      <div className={`row justify-content-center mb-4`}>
        <img
          src={
            VMBC.Enums.Logos[props.OsType] ? VMBC.Enums.Logos[props.OsType] : ""
          }
          class="card-img-top"
          alt={props.OsType}
          style={{ width: "100px", height: "auto", borderRadius: "0" }}
        />
      </div>

      {/* Alias */}
      <div className={`row justify-content-center mt-2`}>
        <span
          className={`input-group`}
          style={{ width: "auto", display: "flex" }}
        >
          <div className="input-group-prepend">
            <span className="input-group-text">Alias</span>
          </div>

          <input
            className={`form-control`}
            onChange={(e) => (alias.current = e.target.value)}
            defaultValue={alias.current}
          />
        </span>
      </div>

      {/* OS Type */}
      <div className={`row justify-content-center mt-2`}>
        <span
          className={`input-group`}
          style={{ width: "auto", display: "flex" }}
        >
          <div className="input-group-prepend">
            <span className="input-group-text">OS Type</span>
          </div>

          <select
            className="editTamplateSelecpicker"
            title={`Select an OS Type`}
            defaultValue={osType.current}
            onChange={(e) => {
              osType.current = e.target.value;
            }}
          >
            {VMBC.Enums.OsTypes.map((os, i) => (
              <option value={i}>{os}</option>
            ))}
          </select>
        </span>
      </div>

      {/* Password */}
      <div className={`row justify-content-center mt-2`}>
        <span
          className={`input-group`}
          style={{ width: "auto", display: "flex" }}
        >
          <div className="input-group-prepend">
            <span className="input-group-text">Password</span>
          </div>

          <input
            className={`form-control`}
            onChange={(e) => (password.current = e.target.value)}
            defaultValue={password.current}
          />
        </span>
      </div>

      {/* Ether name */}
      <div className={`row justify-content-center mt-2`}>
        <span
          className={`input-group`}
          style={{ width: "auto", display: "flex" }}
        >
          <div className="input-group-prepend">
            <span className="input-group-text">Ethernet Name</span>
          </div>

          <input
            className={`form-control`}
            onChange={(e) => (etherName.current = e.target.value)}
            defaultValue={etherName.current}
          />
        </span>
      </div>
    </div>
  );
}

export default TemplateCard;
