import React from "react";
import "./index.css";
import VMBC from "../../../../../VMBC";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Demo from "../../../../../Demo";

function RecentActions(props) {
  const { vm } = props;
  const { Id } = vm;
  const { FirstName, LastName } = vm.Customer;
  const CustomerName = FirstName + " " + LastName;

  const [recentActions, setRecentActions] = React.useState();
  const [recentActionsCount, setRecentActionsCount] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setRecentActions();
    Demo.DelayRandom(() =>
      Demo.DelayRandom(() =>
        Demo.DelayRandom(() => {
          var nowTime = parseInt(Date.now() / 1000);
          var time1 = nowTime - Demo.RandInt(180, 12 * 3600);
          nowTime = time1;
          var time2 = nowTime - Demo.RandInt(180, 12 * 3600);
          nowTime = time2;
          var time3 = nowTime - Demo.RandInt(180, 12 * 3600);
          var recentActions = {
            RecentActions: [
              {
                Id: "2750",
                ActionName: "SetupOS",
                ActorType: "C",
                ActorName: "",
                StartDate: time1,
                EndDate: time1 + 80,
                Failed: false,
                Failure: null,
              },
              {
                Id: "2749",
                ActionName: "PowerOn",
                ActorType: "C",
                ActorName: "",
                StartDate: time2,
                EndDate: time2 + 2,
                Failed: false,
                Failure: null,
              },
              {
                Id: "2748",
                ActionName: "Deploy",
                ActorType: "C",
                ActorName: "",
                StartDate: time3,
                EndDate: time3 + 138,
                Failed: false,
                Failure: null,
              },
            ],
            Count: 3,
            Limit: 10,
          };

          var acts = recentActions.RecentActions
            ? recentActions.RecentActions
            : [];
          let now = parseInt(Date.now() / 1000);
          acts = acts.map((act) => {
            let when = now - act.StartDate;
            let WhenStr = "";
            if (when < 60) {
              WhenStr = `${when}s ago`;
            } else if (when < 3600) {
              WhenStr = `${parseInt(when / 60)}m ${when % 60}s ago`;
            } else if (when < 86400) {
              WhenStr = `${parseInt(when / 3600)}h ago`;
            }

            let IsPending = false;
            let Took = false;
            if (act.EndDate < act.StartDate) {
              IsPending = true;
            } else if (act.EndDate === act.StartDate) {
              Took = "Less than 1s";
            } else {
              Took = VMBC.Time.SecondsToElapsed(act.EndDate - act.StartDate);
            }

            const ActionDetails = Actions[act.ActionName];

            let Icon =
              ActionDetails !== undefined
                ? {
                    ClassName: ActionDetails.IconClassName,
                    Content: ActionDetails.IconContent,
                  }
                : {
                    ClassName: "t-dark",
                    Content: <VMBC.SVGs.Server />,
                  };

            return {
              ...act,
              when,
              Icon,
              Actor:
                act.ActorType === "C" ? (
                  CustomerName
                ) : act.ActorType === "W" ? (
                  "WHMCS"
                ) : act.ActorType === "U" ? (
                  act.ActorName
                ) : act.ActorType === "S" ? (
                  <VMBC.ToolTip
                    tooltip={{ delayShow: 500 }}
                    title={`These Actions take place when machine's state changes unexpectedly.\nlike powering off the machine from vCenter or ESXi Host.`}
                  >
                    <span style={{ cursor: "default" }}>Synchronizer</span>
                  </VMBC.ToolTip>
                ) : (
                  `Unknown (${act.ActorType})`
                ),
              ActionName:
                ActionDetails && ActionDetails.Name
                  ? ActionDetails.Name
                  : act.ActionName,
              IsPending,
              Took,
              WhenFullString: VMBC.Time.UnixToDateString(act.StartDate),
              WhenStr,
            };
          });
          setRecentActions(acts);
          setRecentActionsCount(recentActions.Count);
        })
      )
    );
  }, [refreshT]);

  const ShowFailure = (act) => {
    VMBC.Dialogs.Show({
      Title: (
        <div>
          <span style={{ color: "#fff", fontSize: "1.2rem" }}>
            {act.ActionName}
          </span>{" "}
          By {act.Actor}
        </div>
      ),
      Text: (
        <>
          <h5>Time:</h5>
          <p>{VMBC.Time.UnixToDateString(act.Failure.CreatedDate)}</p>
          <h5 className={`mt-2`}>Message:</h5>
          <p style={{ color: "#ccc" }}>{act.Failure.Message}</p>
        </>
      ),
      Ignorable: true,
      Actions: [
        {
          onClick: () => VMBC.Dialogs.Close(),
          className: "btn-sm btn-outline-primary",
          text: "Close",
        },
      ],
    });
  };

  return (
    <ScrollBar
      className={`recentActionsContainer mx-2 px-3`}
      style={{
        minHeight: "150px",
        maxHeight: "300px",
        overflow: "auto",
      }}
    >
      {recentActions ? (
        <div className="timeline-line">
          <div className={`mt-2`}></div>

          {recentActions.map((act) => (
            <div className="item-timeline">
              <div className="t-dot">
                <div className={act.Icon.ClassName}>{act.Icon.Content}</div>
              </div>
              <div className="t-content">
                <div className="t-uppercontent">
                  <h5>{act.ActionName}</h5>
                  <span className="">
                    {act.when >= 86400 ? (
                      act.WhenFullString
                    ) : (
                      <VMBC.ToolTip
                        tooltip={{
                          delayHide: 0,
                          delayShow: 0,
                        }}
                        title={act.WhenFullString}
                      >
                        {act.WhenStr}
                      </VMBC.ToolTip>
                    )}
                  </span>
                </div>
                <p>
                  <span>By</span> {act.Actor}
                </p>
                <div className="tags" style={{ cursor: "default" }}>
                  {act.Took ? (
                    <div className="badge badge-primary mr-1">{act.Took}</div>
                  ) : undefined}
                  {act.IsPending ? (
                    <div className="badge badge-warning mr-1">Pending</div>
                  ) : undefined}
                  {act.Failed ? (
                    <div
                      className="badge badge-danger mr-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => ShowFailure(act)}
                    >
                      Failed
                    </div>
                  ) : undefined}
                </div>
              </div>
            </div>
          ))}
          {/* 
          <div className="item-timeline">
            <div className="t-dot">
              <div className="t-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
            </div>
            <div className="t-content">
              <div className="t-uppercontent">
                <h5>Logs</h5>
                <span className="">27 Feb, 2020</span>
              </div>
              <p>
                <span>Updated</span> Server Logs
              </p>
              <div className="tags">
                <div className="badge badge-primary">Logs</div>
                <div className="badge badge-success">CPanel</div>
                <div className="badge badge-warning">Update</div>
              </div>
            </div>
          </div>

          <div className="item-timeline">
            <div className="t-dot">
              <div className="t-success">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-mail"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
              </div>
            </div>
            <div className="t-content">
              <div className="t-uppercontent">
                <h5>Mail</h5>
                <span className="">28 Feb, 2020</span>
              </div>
              <p>
                Send Mail to <a href="javascript:void(0);">HR</a> and{" "}
                <a href="javascript:void(0);">Admin</a>
              </p>
              <div className="tags">
                <div className="badge badge-primary">Admin</div>
                <div className="badge badge-success">HR</div>
                <div className="badge badge-warning">Mail</div>
              </div>
            </div>
          </div>

          <div className="item-timeline">
            <div className="t-dot">
              <div className="t-danger">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
            </div>
            <div className="t-content">
              <div className="t-uppercontent">
                <h5>Task Completed</h5>
                <span className="">01 Mar, 2020</span>
              </div>
              <p>
                Backup <span>Files EOD</span>
              </p>
              <div className="tags">
                <div className="badge badge-primary">Backup</div>
                <div className="badge badge-success">EOD</div>
              </div>
            </div>
          </div>

          <div className="item-timeline">
            <div className="t-dot">
              <div className="t-warning">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-file"
                >
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>
              </div>
            </div>
            <div className="t-content">
              <div className="t-uppercontent">
                <h5>Collect Docs</h5>
                <span className="">10 Mar, 2020</span>
              </div>
              <p>
                Collected documents from <a href="javascript:void(0);">Sara</a>
              </p>
              <div className="tags">
                <div className="badge badge-success">Collect</div>
                <div className="badge badge-warning">Docs</div>
              </div>
            </div>
          </div>

          <div className="item-timeline">
            <div className="t-dot">
              <div className="t-dark">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-server"
                >
                  <rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect>
                  <rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect>
                  <line x1="6" y1="6" x2="6" y2="6"></line>
                  <line x1="6" y1="18" x2="6" y2="18"></line>
                </svg>
              </div>
            </div>
            <div className="t-content">
              <div className="t-uppercontent">
                <h5>Reboot</h5>
                <span className="">06 Apr, 2020</span>
              </div>
              <p>Server rebooted successfully</p>
              <div className="tags">
                <div className="badge badge-warning">Reboot</div>
                <div className="badge badge-primary">Server</div>
              </div>
            </div>
          </div> */}
        </div>
      ) : (
        <div className={`d-flex pt-4 justify-content-center`}>
          <VMBC.Loader />
        </div>
      )}
    </ScrollBar>
  );
}

const Actions = {
  "Acquire WMKS Ticket": {
    IconClassName: "t-primary",
    IconContent: <VMBC.SVGs.AirPlay />,
  },
  PowerOn: {
    IconClassName: "t-success",
    IconContent: <VMBC.SVGs.Power />,
    Name: "Power On",
  },
  PowerOff: {
    IconClassName: "t-dark",
    IconContent: <VMBC.SVGs.Power />,
    Name: "Power Off",
  },
  NotFinding: {
    IconClassName: "t-danger",
    IconContent: <VMBC.SVGs.Frown />,
    Name: "VM Not Found",
  },
  Deploy: {
    IconClassName: "t-primary",
    IconContent: <VMBC.SVGs.Template />,
  },
  Delete: {
    IconClassName: "t-danger",
    IconContent: <VMBC.SVGs.RecycleBin />,
  },
  SetupOS: {
    IconClassName: "t-success",
    IconContent: <VMBC.SVGs.Zap />,
    Name: "Configuring Guest OS",
  },
};

export default RecentActions;
