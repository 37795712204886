import React from "react";
import VMBC from "../../../../../VMBC";
import VirtualMachinesTable from "./VirtualMachinesTable";

function VirtualMachines(props) {
  const { plan } = props;

  const [virtualMachines, setVirtualMachines] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setVirtualMachines();
    VMBC.Plans.GetPlanVirtualMachines(plan.PlanId, (virtualMachines, err) => {
      if (!err) {
        setVirtualMachines(virtualMachines);
      } else {
        VMBC.Alert.Show(err, "error");
      }
    });
  }, [refreshT]);

  const Refresh = () => refresh(!refreshT);

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div className="widget widget-table-two">
        <div
          className="widget-heading"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <h5>Virtual Machines</h5>
        </div>

        <div className="widget-content">
          {virtualMachines ? (
            <VirtualMachinesTable VMs={virtualMachines} Refresh={Refresh} />
          ) : (
            <div
              className={`my-2`}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className="spinner-border align-self-center loader-lg"
                style={{ width: "3rem", height: "3rem" }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VirtualMachines;
