import Auth from "./Auth";
import Config from "./Config";
import Enums from "./Enums";
import Utils from "./Utils/index";
import Dashboard from "./Dashboard";
import Inventory from "./Inventory";
import Plans from "./Plans";
import Install from "./Install";
import Customers from "./Customers";
import { IpAddress, ManagedObject } from "./DataTypes";
import SVGs from "./SVGs";
window.AddToLoadingVmboxProccess(1);

// VMBC
export default {
  Auth,
  Config,
  Enums,
  Dashboard,
  Inventory,
  Plans,
  Install,
  Customers,
  ...Utils,
  SId: "false",
  SVGs,
  DataTypes: { IpAddress, ManagedObject },
};
