import React from "react";
import ReactDOM from "react-dom";

function Dialog(props) {
  const { id, open, Title, Ignorable, Text, Fields, Actions, children } = props;

  const DialogId = id ? id : "" + Math.random();

  const container = React.useRef(document.createElement("div"));

  React.useEffect(() => {
    document.getElementById("vmbc-modal-root").appendChild(container.current);
    return () => {
      document.getElementById("vmbc-modal-root").removeChild(container.current);
    };
  }, []);
  React.useEffect(() => {
    if (open) {
      window.$(`#${DialogId}`).modal("show");
    } else {
      window.$(`#${DialogId}`).modal("hide");
    }
  }, [open]);

  const ShowDialog = (DialogId) => {
    window.$(`#${DialogId}`).modal("show");
  };
  const HideDialog = (DialogId) => {
    window.$(`#${DialogId}`).modal("hide");
  };

  return ReactDOM.createPortal(
    <div
      className="modal fade"
      id={DialogId}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {children ? (
            children
          ) : (
            <>
              <div className="modal-header">
                {typeof Title === "string" ? (
                  <h5 className="modal-title">{Title}</h5>
                ) : (
                  Title
                )}
                {Ignorable ? (
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-x"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </button>
                ) : undefined}
              </div>
              <div className="modal-body">
                <p className="modal-text">{Text}</p>
                {Fields}
              </div>
              <div className="modal-footer">
                {Actions
                  ? Actions.map((act) => (
                      <button
                        {...act}
                        className={`btn ${act.className ? act.className : ""}`}
                      >
                        {act.text}
                      </button>
                    ))
                  : undefined}
              </div>
            </>
          )}
        </div>
      </div>
    </div>,
    container.current
  );
}

export default Dialog;
