import React from "react";
import "./CPU.css";
import VMBC from "../../../../../../VMBC";

function CPU(props) {
  var oldData =
    props.Data &&
    props.Data.current.Customization &&
    props.Data.current.Customization.CPU;

  const [expanded, setExpanded] = React.useState(
    oldData ? oldData.expanded : "cpuPanel"
  );
  const [rerender, Rerender] = React.useState(false);

  const AddNewProfile = () => {
    let found = false;
    props.Data.current.Customization.CPU.forEach(
      (prof) => (found = prof.Alias === "New Additional CPU" ? true : found)
    );
    if (found) {
      VMBC.Alert.Show(
        "Additional CPU named 'New Additional CPU' already exists.",
        "error",
        3000
      );
      return;
    }
    if (expanded !== "cpuPanel") {
      setExpanded("cpuPanel");
    }
    props.Data.current.Customization.CPU.push({
      Alias: "New Additional CPU",
      Sockets: 1,
      CoresPerSocket: 1,
      Reserve: false,
      Reservation: 0,
      ReservationUnit: 0,
      Limit: false,
      LimitBy: 0,
      LimitByUnit: 0,
    });
    Rerender(!rerender);
  };

  const DeleteProfile = (index) => {
    props.Data.current.Customization.CPU.splice(index, 1);
    if (props.Data.current.Customization.CPU.length === 1) {
      props.Data.current.Customization.CPU[0].isDefault = true;
    }
    Rerender(!rerender);
  };

  const onSetDefault = (index) => () => {
    for (var i = 0; i < props.Data.current.Customization.CPU.length; i++) {
      props.Data.current.Customization.CPU[i].isDefault = i === index;
    }
    Rerender(!rerender);
  };

  if (props.Data.current.Customization.CPU.length === 1) {
    if (!props.Data.current.Customization.CPU[0].isDefault) {
      props.Data.current.Customization.CPU[0].isDefault = true;
      Rerender(!rerender);
    }
  }

  React.useEffect(() => {
    // rerender select picker on component did mount
    window.$(".CpuSelectpicker").selectpicker("refresh");
  });

  return (
    <div className={`container-fluid`}>
      <div className={`row justify-content-center`}>
        {props.Data.current.Customization.CPU.map((profile, index) => (
          <CpuProfile
            key={index}
            new={!profile.saved}
            isDefault={profile.isDefault}
            Index={index}
            Profile={profile}
            Data={props.Data}
            onDelete={DeleteProfile}
            onSetDefault={onSetDefault(index)}
          />
        ))}
        {props.Data.current.Customization.CPU.length === 0 ? (
          <>
            <h5 style={{ margin: "20px", color: "gray" }}>No Additional CPU</h5>
            <h6 style={{ margin: "20px", color: "gray" }}>
              Virtual Machine's CPU configuration will be as same as the
              template's
            </h6>
          </>
        ) : undefined}
        <div
          className={`container`}
          style={{ cursor: "pointer" }}
          onClick={() => AddNewProfile()}
        >
          <div className={`row justify-content-center align-items-center`}>
            <VMBC.SVGs.CircleAdd size={80} />
            <span className={`ml-4`}>Add New Profile</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function CpuProfile(props) {
  const [editable, setEditable] = React.useState(props.new);

  const [alias, setAlias] = React.useState(props.Profile.Alias);
  const [sockets, setSockets] = React.useState(
    props.Profile.Sockets ? Number(props.Profile.Sockets) : 0
  );
  const [cores, setCores] = React.useState(
    props.Profile.CoresPerSocket ? Number(props.Profile.CoresPerSocket) : 0
  );

  const [reserve, setReserve] = React.useState(props.Profile.Reserve);
  const [reservation, setReservation] = React.useState(
    props.Profile.Reservation ? Number(props.Profile.Reservation) : 0
  );
  const [reservationUnit, setReservationUnit] = React.useState(
    props.Profile.ReservationUnit ? Number(props.Profile.ReservationUnit) : 0
  );

  const [limit, setLimit] = React.useState(props.Profile.Limit);
  const [limitBy, setLimitBy] = React.useState(
    props.Profile.LimitBy ? Number(props.Profile.LimitBy) : 0
  );
  const [limitByUnit, setLimitByUnit] = React.useState(
    props.Profile.LimitByUnit ? Number(props.Profile.LimitByUnit) : 0
  );

  const saveProfile = () => {
    if (alias === "") {
      VMBC.Alert.Show(`Alias cannot be empty`, "error", 3000);
      return;
    }
    if (alias.length > 100) {
      VMBC.Alert.Show(
        `Alias cannot be more than 100 characters`,
        "error",
        3000
      );
      return;
    }
    if (alias.includes(",")) {
      VMBC.Alert.Show(`Alias cannot contain unknown characters`, "error", 3000);
      return;
    }
    let found = false;
    props.Data.current.Customization.CPU.forEach(
      (prof, i) =>
        (found = prof.Alias === alias && i !== props.Index ? true : found)
    );
    if (found) {
      VMBC.Alert.Show(
        `Additional CPU named '${alias}' already exists.`,
        "error",
        3000
      );
      return;
    }
    if (
      isNaN(sockets) ||
      isNaN(cores) ||
      isNaN(reservation) ||
      isNaN(limitBy)
    ) {
      VMBC.Alert.Show(`Please Check your inputs`, "error", 3000);
      return;
    }

    props.Data.current.Customization.CPU[props.Index] = {
      Alias: alias,
      Sockets: Number(sockets),
      CoresPerSocket: Number(cores),
      saved: true,
      isDefault: props.Data.current.Customization.CPU[props.Index].isDefault,
      Reserve: reserve,
      Reservation: Number(reservation),
      ReservationUnit: Number(reservationUnit),
      Limit: limit,
      LimitBy: Number(limitBy),
      LimitByUnit: Number(limitByUnit),
    };
    setEditable(false);
  };

  React.useEffect(() => {
    // rerender select picker on component did mount
    window.$(".CpuSelectpicker").selectpicker("refresh");
  }, [editable]);

  return (
    <div className={`col-12`}>
      <div
        className={`cpuAdditionalCard`}
        style={
          props.isDefault
            ? editable
              ? { boxShadow: "0px 0px 15px 0px #8DEE9B", scale: "1.01" }
              : { boxShadow: "0px 0px 15px 0px #8DEE9B" }
            : editable
            ? {
                boxShadow: "0px 0px 15px 0px rgba(255,255,255,0.35)",
                scale: "1.01",
              }
            : {}
        }
      >
        <div className={`container`}>
          <div className={`row justify-content-around align-items-center`}>
            <div className={`container`}>
              <div className={`row cpuAdditionalCardPropertyContainer`}>
                <div className={`input-group mb-1`}>
                  <div className={`input-group-prepend`}>
                    <div class="input-group-text">Alias</div>
                  </div>
                  <input
                    class="form-control"
                    value={alias}
                    onChange={(e) => editable && setAlias(e.target.value)}
                  />
                </div>
              </div>

              <div className={`row cpuAdditionalCardPropertyContainer`}>
                <div className={`input-group mb-1`}>
                  <div className={`input-group-prepend`}>
                    <div class="input-group-text">Sockets</div>
                  </div>
                  <input
                    class="form-control"
                    type="number"
                    value={sockets}
                    onChange={(e) =>
                      editable &&
                      setSockets(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                </div>
              </div>

              <div className={`row cpuAdditionalCardPropertyContainer`}>
                <div className={`input-group mb-1`}>
                  <div className={`input-group-prepend`}>
                    <div class="input-group-text">Cores Per Socket</div>
                  </div>
                  <input
                    class="form-control"
                    type="number"
                    value={cores}
                    onChange={(e) =>
                      editable &&
                      setCores(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                </div>
              </div>

              <div className={`row cpuAdditionalCardPropertyContainer`}>
                <div class="input-group mb-1">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      Reservation
                      <label class="switch s-primary mb-0 ml-2">
                        <input
                          type="checkbox"
                          checked={reserve}
                          onChange={(e) =>
                            editable && setReserve(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <input
                    class="form-control"
                    aria-label="switch"
                    value={reservation}
                    onChange={(e) =>
                      editable &&
                      setReservation(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!editable}
                      className="CpuSelectpicker"
                      data-width="100%"
                      value={reservationUnit}
                      onChange={(e) =>
                        editable && setReservationUnit(e.target.value)
                      }
                    >
                      <option value={0}>MHz</option>
                      <option value={1}>GHz</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`row cpuAdditionalCardPropertyContainer`}>
                <div class="input-group mb-1">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      Limit
                      <label class="switch s-primary mb-0 ml-2">
                        <input
                          type="checkbox"
                          checked={limit}
                          onChange={(e) =>
                            editable && setLimit(e.target.checked)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <input
                    class="form-control"
                    aria-label="switch"
                    value={limitBy}
                    onChange={(e) =>
                      editable &&
                      setLimitBy(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                  <div class="input-group-append">
                    <select
                      disabled={!editable}
                      className="CpuSelectpicker"
                      data-width="100%"
                      value={limitByUnit}
                      onChange={(e) =>
                        editable && setLimitByUnit(e.target.value)
                      }
                    >
                      <option value={0}>MHz</option>
                      <option value={1}>GHz</option>
                    </select>
                  </div>
                </div>
              </div>

              <div
                className={`row cpuAdditionalCardPropertyContainer justify-content-end`}
              >
                {!props.isDefault && !editable ? (
                  // <VMBC.ToolTip title={"Mark as Default"}>
                  <button
                    className="btn btn-primary mb-2 mr-2 rounded-circle"
                    onClick={() => props.onSetDefault(true)}
                  >
                    <VMBC.SVGs.Star />
                  </button>
                ) : // </VMBC.ToolTip>
                undefined}
                {editable ? (
                  <>
                    <button
                      className="btn btn-danger mb-2 mr-2 rounded-circle"
                      onClick={() => {
                        setEditable(false);
                        let Old =
                          props.Data.current.Customization.CPU[props.Index];
                        setAlias(Old.Alias);
                        setSockets(Old.Sockets);
                        setCores(Old.CoresPerSocket);
                        setReserve(Old.Reserve);
                        setReservation(Old.Reservation);
                        setReservationUnit(Old.ReservationUnit);
                        setLimit(Old.Limit);
                        setLimitBy(Old.LimitBy);
                        setLimitByUnit(Old.LimitByUnit);
                      }}
                    >
                      <VMBC.SVGs.CloseXIcon />
                    </button>
                    <button
                      className="btn btn-primary mb-2 mr-2 rounded-circle"
                      onClick={saveProfile}
                    >
                      <VMBC.SVGs.CheckIcon />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-danger mb-2 mr-2 rounded-circle"
                      onClick={() => props.onDelete(props.Index)}
                    >
                      <VMBC.SVGs.RecycleBin />
                    </button>
                    <button
                      className="btn btn-primary mb-2 mr-2 rounded-circle"
                      onClick={() => setEditable(true)}
                    >
                      <VMBC.SVGs.EditByPen />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CPU;
