import React from "react";
import { withRouter } from "react-router";
import IpAddress from "../../VMBC/DataTypes/IpAddress";

function IpAddressField(props) {
  const octetTexts = [
    React.useState(""),
    React.useState(""),
    React.useState(""),
    React.useState(""),
  ];
  const [netPrefixText, setNetPrefixText] = React.useState(
    props.Subnet != undefined &&
      props.Subnet &&
      props.defaultNetPrefix != undefined
      ? props.defaultNetPrefix
      : ""
  );
  const [step, setStep] = React.useState(-1);
  const octets = [
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef(),
  ];
  const netPrefixInput = React.useRef();

  const isSubnet = props.Subnet ? true : false;

  React.useEffect(() => {
    if (step >= 0 && step < 4) {
      octets[step].current && octets[step].current.focus();
    } else if (step === 4) {
      netPrefixInput.current && netPrefixInput.current.focus();
    }
  }, [step]);

  const LoadValue = (defaultValue) => {
    let Ip;
    if (
      typeof defaultValue == "object" &&
      defaultValue.NetPrefix &&
      defaultValue.NetworkId
    ) {
      Ip = defaultValue.NetworkId;
      if (typeof defaultValue.NetPrefix == "number") {
        if (defaultValue.NetPrefix < 0) {
          return;
        }
        setNetPrefixText("" + defaultValue.NetPrefix);
      } else if (typeof defaultValue.NetPrefix == "string") {
        setNetPrefixText(defaultValue.NetPrefix);
      }
    } else {
      Ip = defaultValue;
    }
    if (typeof Ip == "number") {
      var octets = new IpAddress(Ip).ToOctets();
      octetTexts[3][1]("" + octets[3]);
      octetTexts[2][1]("" + octets[2]);
      octetTexts[1][1]("" + octets[1]);
      octetTexts[0][1]("" + octets[0]);
    } else if (typeof Ip == "string") {
      let octetsArray = Ip.split(".");
      if (octetsArray.length === 4) {
        octetsArray.forEach((octet, i) => {
          octetTexts[i][1](octet);
        });
      }
    }
  };

  if (props.reload) {
    props.reload.current = (value) => LoadValue(value);
  }

  React.useEffect(() => {
    if (props.defaultValue) {
      LoadValue(props.defaultValue);
    }
  }, []);

  let fontSize = props.fontSize ? props.fontSize : 16;
  if (typeof fontSize !== "number") {
    fontSize = 16;
  }

  const rootStyle = {
    borderRadius: "0px 4px 4px 0px",
    display: "inline-block",
    backgroundColor: "#1b2e4b",
    fontSize: "" + fontSize + "px",
    padding: "5px 7px",
    minWidth: "158px",
    flexGrow: "1",
  };

  const octetStyle = {
    display: "inline",
    width: "" + 2 * fontSize + "px",
    backgroundColor: "#0000",
    color: "#009688",
    margin: "0 2px",
    textAlign: "center",
    fontSize: "inherit",
    fontFamily: "inherit",
    border: "none",
  };

  const handleOctetOnChange = (stepNum) => (e) => {
    let value = e.target.value;
    value = Number(value);
    if (value < 0 || value > 255) {
      return;
    }
    if (value > 25) {
      setStep(step + 1);
    } else {
      setStep(stepNum);
    }

    let REGEX = new RegExp("^[0-9]*$", "g");
    if (
      !REGEX.test(e.target.value) ||
      (value === 0 && e.target.value.length > 1)
    ) {
      return;
    }

    octetTexts[stepNum][1](e.target.value);
  };

  const handleNetPrefixOnChange = (e) => {
    let value = e.target.value;
    value = Number(value);
    if (value < 0 || value > 32) {
      return;
    }
    setStep(4);
    let REGEX = new RegExp("^[0-9]*$", "g");
    if (
      !REGEX.test(e.target.value) ||
      (value === 0 && e.target.value.length > 1)
    ) {
      return;
    }
    setNetPrefixText(e.target.value);
  };

  React.useEffect(() => {
    if (isSubnet) {
      var ipAddr = new IpAddress(
        "" +
          octetTexts[0][0] +
          "." +
          octetTexts[1][0] +
          "." +
          octetTexts[2][0] +
          "." +
          octetTexts[3][0],
        Number(netPrefixText)
      );
      ipAddr.NetworkId = ipAddr._ip;
      ipAddr.NetPrefix = ipAddr._netPrefix;
      props.onChange && props.onChange(ipAddr);
    } else {
      var ipAddr = new IpAddress(
        "" +
          octetTexts[0][0] +
          "." +
          octetTexts[1][0] +
          "." +
          octetTexts[2][0] +
          "." +
          octetTexts[3][0]
      );
      props.onChange && props.onChange(ipAddr._ip);
    }
  }, [
    octetTexts[0][0],
    octetTexts[1][0],
    octetTexts[2][0],
    octetTexts[3][0],
    netPrefixText,
  ]);

  const handleOctetOnKeyDown = (e) => {
    if (e.keyCode === 8) {
      if (e.target.value === "") {
        setStep(step - 1);
      }
    } else if (e.keyCode === 110 || e.keyCode === 190) {
      setStep(step + 1);
    }
  };

  const setFoucs = (stepNum) => () => {
    setStep(stepNum);
  };

  return (
    <>
      <span
        className={`input-group ${props.className}`}
        style={{ width: "auto", display: "flex", ...props.style }}
      >
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon5">
            {props.label}
          </span>
        </div>

        <div style={rootStyle}>
          <div
            style={{
              display: "inline-block",
              margin: "auto",
              position: "relative",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                ref={octets[0]}
                value={octetTexts[0][0]}
                onFocus={setFoucs(0)}
                readOnly={props.readOnly}
                onChange={handleOctetOnChange(0)}
                onKeyDown={handleOctetOnKeyDown}
                style={octetStyle}
              />
              <span>.</span>
              <input
                ref={octets[1]}
                value={octetTexts[1][0]}
                onFocus={setFoucs(1)}
                readOnly={props.readOnly}
                onChange={handleOctetOnChange(1)}
                onKeyDown={handleOctetOnKeyDown}
                style={octetStyle}
              />
              <span>.</span>
              <input
                ref={octets[2]}
                value={octetTexts[2][0]}
                onFocus={setFoucs(2)}
                readOnly={props.readOnly}
                onChange={handleOctetOnChange(2)}
                onKeyDown={handleOctetOnKeyDown}
                style={octetStyle}
              />
              <span>.</span>
              <input
                ref={octets[3]}
                value={octetTexts[3][0]}
                onFocus={setFoucs(3)}
                readOnly={props.readOnly}
                onChange={handleOctetOnChange(3)}
                onKeyDown={handleOctetOnKeyDown}
                style={octetStyle}
              />
              {isSubnet ? (
                <>
                  <span>/</span>
                  <input
                    ref={netPrefixInput}
                    value={netPrefixText}
                    onFocus={setFoucs(4)}
                    readOnly={props.readOnly}
                    onChange={handleNetPrefixOnChange}
                    onKeyDown={handleOctetOnKeyDown}
                    style={octetStyle}
                  />
                </>
              ) : undefined}
              {props.children}
            </div>
          </div>
        </div>
      </span>
    </>
  );
}

export default withRouter(IpAddressField);
