import React from "react";
import VMBC from "../../../../../VMBC";
import Change from "./Change";
import Demo from "../../../../../Demo";

function ComputeResourceAndDS(props) {
  const { vm, Refresh } = props;
  const { Id, ComputeResourceMoId, ResourcePoolMoId, DatastoreMoId } = vm;

  const [names, setNames] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const changeFunc = React.useRef();

  const loadNames = () => {
    setLoading(true);
    Demo.DelayRandom(() =>
      Demo.DelayRandom(() => {
        var res = {
          ComputeResourceMoId: "domain-s89",
          ComputeResourceName: "46.4.100.5",
          DatastoreMoId: "datastore-94",
          DatastoreName: "NVME 3",
        };
        setNames({
          ComputeResource:
            ComputeResourceMoId === "" ? "Unknown" : res.ComputeResourceName,
          Datastore: DatastoreMoId === "" ? "Unknown" : res.DatastoreName,
        });
        setLoading(false);
      })
    );
  };

  const onChangeClicked = () => {
    VMBC.Alert.Show("This feature not available in Demo version yet", "info");
    return;
    if (changeFunc.current != undefined) {
      setLoading(true);
      VMBC.Customers.VirtualMachines.CompsAndDs.GetSelectableComputesAndDss(
        Id,
        (res, err) => {
          setLoading(false);
          if (err) {
            VMBC.Alert.Show(err, "error");
          } else {
            var Groups = res.Groups ? res.Groups : [];
            window.G1 = Groups;
            window.G2 = GetComps(vm).Comps;
            changeFunc.current(Groups);
          }
        }
      );
    }
  };

  return (
    <>
      <Change vm={vm} trigerFuncRef={changeFunc} Refresh={Refresh} />
      <div
        className={`d-flex flex-column justify-content-around align-items-center h-100 pt-2`}
        style={{ position: "relative" }}
      >
        {loading ? (
          <div
            className={`d-flex justify-content-center align-items-center w-100 h-100`}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              backgroundColor: "#0007",
              zIndex: "3",
            }}
          >
            <VMBC.Loader />
          </div>
        ) : undefined}
        <div className={`w-100 px-2 py-3`}>
          <h5>Compute Resource:</h5>
          <h6
            className={`text-right p-2`}
            style={{ color: "#d3d3d3", fontSize: "15px", fontWeight: "700" }}
          >
            {names ? (
              <VMBC.ToolTip
                tooltip={{ place: "left", delayShow: "500" }}
                title={
                  ComputeResourceMoId === "" ? "Unknown" : ComputeResourceMoId
                }
              >
                <span style={{ cursor: "pointer" }}>
                  {names.ComputeResource === ""
                    ? "Unknown"
                    : names.ComputeResource}
                </span>
              </VMBC.ToolTip>
            ) : ComputeResourceMoId === "" ? (
              "Unknown"
            ) : (
              ComputeResourceMoId
            )}
          </h6>
        </div>

        <div className={`w-100 px-2 py-2`}>
          <h5>Datastore:</h5>
          <h6
            className={`text-right p-2`}
            style={{ color: "#d3d3d3", fontSize: "15px", fontWeight: "700" }}
          >
            {names ? (
              <VMBC.ToolTip
                tooltip={{ place: "left", delayShow: "500" }}
                title={DatastoreMoId === "" ? "Unknown" : DatastoreMoId}
              >
                <span style={{ cursor: "pointer" }}>{names.Datastore}</span>
              </VMBC.ToolTip>
            ) : DatastoreMoId === "" ? (
              "Unknown"
            ) : (
              DatastoreMoId
            )}
          </h6>
        </div>

        {!names ? (
          <div className={`w-100 d-flex justify-content-center`}>
            <span style={{ cursor: "pointer" }} onClick={loadNames}>
              Click to load names
            </span>
          </div>
        ) : undefined}

        <div className={`w-100 px-2 py-2 d-flex justify-content-center mb-2`}>
          <button className={`btn btn-primary`} onClick={onChangeClicked}>
            Change
          </button>
        </div>
      </div>
    </>
  );
}

function GetComps(vm) {
  return {
    Comps: [
      {
        GroupName: vm.PlanName,
        GroupCRs: [
          {
            Name: "46.4.20.40",
            CompMoId: "domain-s46",
            Datastores: [
              {
                Name: "NVMe 1",
                DatastoreMoId: "datastore-12",
              },
              {
                Name: "NVMe 2",
                Disabled: true,
                DatastoreMoId: "datastore-14",
              },
              {
                Name: "NVMe 3",
                DatastoreMoId: "datastore-15",
              },
            ],
          },
          {
            Name: "148.251.111.67",
            Selected: true,
            CompMoId: "domain-s49",
            Datastores: [
              {
                Name: "Datastore 1",
                Disabled: true,
                DatastoreMoId: "datastore-20",
              },
              {
                Name: "Datastore 2",
                DatastoreMoId: "datastore-21",
              },
            ],
          },
          {
            Name: "213.108.242.67",
            Disabled: true,
            CompMoId: "domain-s34",
            Datastores: [
              {
                Name: "SSD 1",
                DatastoreMoId: "datastore-38",
              },
            ],
          },
        ],
      },
      {
        GroupName: "Other",
        GroupCRs: [
          {
            Name: "Any Other Compute Resource",
            CompMoId: "*",
            Datastores: [
              {
                Name: "Any Datastore",
                Selected: true,
                DatastoreMoId: "*",
              },
            ],
          },
        ],
      },
    ],
  };
}
export default ComputeResourceAndDS;
