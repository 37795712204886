import axios from "axios";

function GetSolutionDatastores(id, cb) {
  if (!id || typeof id !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/getSolutionDatastores",
        { SId: window.VMBC.SId, SolutionId: id },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function AddSolutionDatastore(solutionId, { MO_Reference }, cb) {
  if (
    !solutionId ||
    typeof solutionId !== "string" ||
    !MO_Reference ||
    typeof MO_Reference !== "string"
  )
    return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/addSolutionDatastore",
        {
          SId: window.VMBC.SId,
          SolutionId: solutionId,
          DatastoreId: MO_Reference,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

function RemoveSolutionDatastore(solutionDatastoreId, cb) {
  if (!solutionDatastoreId || typeof solutionDatastoreId !== "string") return;
  cb = cb ? cb : () => {};
  window.VMBC.CheckSId(() => {
    axios
      .post(
        window.VMBC.Config.Origin + "/api/plans/removeSolutionDatastore",
        {
          SId: window.VMBC.SId,
          SolutionDatastoreId: solutionDatastoreId,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        if (!res.data) {
          cb(null, "data undefined");
          return;
        }
        if (res.data.res === "success") {
          cb(res.data.data, null);
        } else if (res.data.err && res.data.err === "Session Expired") {
          window.VMBC.Alert.SessionExpired();
        } else if (res.data.err) {
          cb(null, res.data.err);
        } else if (res.data.res === "error") {
          cb(null, res.data.data);
        } else {
          cb(null, "Unknown Error");
        }
      })
      .catch((reason) => {
        cb(false);
      });
  });
}

export default {
  AddSolutionDatastore,
  GetSolutionDatastores,
  RemoveSolutionDatastore,
};
