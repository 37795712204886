import React from "react";
import Table from "../../../../Components/Table";
import VMBC from "../../../../VMBC";
import { withRouter } from "react-router";
import Demo from "../../../../Demo";
window.AddToLoadingVmboxProccess(1);

function VmList(props) {
  const [vms, setVms] = React.useState();
  const [refreshT, refresh] = React.useState(false);
  let CustomerId = GetQuery(props.location.search, "client");

  React.useEffect(() => {
    setVms();
    Demo.DelayRandom(() => {
      var res = [];
      if (CustomerId) {
        CustomerId = Number(CustomerId);
        res = Demo(Demo.VMachines).filter(
          (val) => val.LINK_ownerId === CustomerId
        );
      } else {
        res = Demo(Demo.VMachines);
      }
      res = res ? res : [];
      res.reverse();
      res = res.map((vm) => {
        var customer = Demo.Customers.Data.filter(
          (val) => val.id === vm.LINK_ownerId
        );
        var customerName = "No Name";
        if (customer[0]) {
          customerName = `${customer[0].firstname} ${customer[0].lastname}`;
        }
        return {
          ...vm,
          Id: Number(vm.id),
          VmName: vm.vmName,
          CustomerName: customerName,
          IpAddress: vm.Ip,
          IsSuspended: Demo.RandInt(1, 100) < 10,
        };
      });
      setVms(res);
    });
  }, [refreshT]);

  const Refresh = () => refresh(!refreshT);

  return (
    <div className="row layout-top-spacing">
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div className="widget widget-table-two">
          <Table
            columns={[
              {
                title: "ID",
                fieldName: "Id",
              },
              {
                title: "Name",
                fieldName: "VmName",
              },
              {
                title: "Customer",
                fieldName: "CustomerName",
                render: (rowData) => (
                  <VMBC.A
                    href={`/panel/customers/machines?client=${rowData.LINK_ownerId}`}
                  >
                    {rowData.CustomerName}
                  </VMBC.A>
                ),
              },
              {
                title: "IP Address",
                fieldName: "IpAddress",
              },
              {
                title: "State",
                fieldName: "State",
                render: (rowData) => {
                  if (rowData.IsSuspended && rowData.State !== "Terminated") {
                    return (
                      <>
                        <span className="badge outline-badge-warning">
                          Suspended
                        </span>
                        <span className="badge outline-badge-danger ml-1">
                          <VMBC.SVGs.Lock size={14} />
                        </span>
                      </>
                    );
                  }
                  switch (rowData.State) {
                    case "PoweredOn":
                      return (
                        <span className="badge outline-badge-success">
                          Powered On
                        </span>
                      );
                    case "PoweredOff":
                      return (
                        <span className="badge outline-badge-dark">
                          Powered Off
                        </span>
                      );
                    case "VmSuspended":
                      return (
                        <span className="badge outline-badge-secondary">
                          VM is Suspended
                        </span>
                      );
                    case "Registered":
                      return (
                        <span className="badge outline-badge-primary">
                          Registered
                        </span>
                      );
                    case "Deployed":
                      return (
                        <span className="badge outline-badge-info">
                          Deployed
                        </span>
                      );
                    case "NotFound":
                      return (
                        <span className="badge outline-badge-danger">
                          VM Not Found
                        </span>
                      );
                    case "Terminated":
                      return (
                        <>
                          <span className="badge outline-badge-dark">
                            Terminated
                          </span>
                          {rowData.IsSuspended ? (
                            <span className="badge outline-badge-danger ml-1">
                              <VMBC.ToolTip title={`Suspended`}>
                                <VMBC.SVGs.Lock size={14} />
                              </VMBC.ToolTip>
                            </span>
                          ) : undefined}
                        </>
                      );
                    default:
                      return (
                        <span className="badge outline-badge-default">
                          {rowData.State}
                        </span>
                      );
                  }
                },
              },
            ]}
            data={vms}
            actions={{
              FreeActions: [
                {
                  Icon: (
                    <button
                      className={`btn btn-sm btn-primary`}
                      onClick={Refresh}
                    >
                      Refresh
                    </button>
                  ),
                },
              ],
              Others: [
                {
                  Icon: (rowData) => (
                    <VMBC.A href={`/panel/customers/machines/${rowData.Id}`}>
                      <span className={`badge badge-primary ml-1`}>
                        Details
                      </span>
                    </VMBC.A>
                  ),
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
}

function GetQuery(search, query) {
  if (search.includes("?")) {
    let queries = search
      .split("?")[1]
      .split("&")
      .filter((q) => (typeof q == "string" ? q.startsWith(query) : false));
    if (queries.length > 0) {
      let qq = queries[0].split("=");
      if (qq.length == 2) {
        return qq[1];
      }
    }
  }
  return "";
}

export default withRouter(VmList);
