import React from "react";
import "./index.css";
import AddTemplate from "./AddTemplate";
import VMBC from "../../../../VMBC";
import TemplateCard from "./TemplateCard";

function Template(props) {
  const [templates, setTemplates] = React.useState(
    props.Data &&
      props.Data.current.Templates &&
      props.Data.current.Templates.selectedTemplates
      ? props.Data.current.Templates.selectedTemplates
      : []
  );

  var validator = () => {
    if (!props.Data || !props.Data.current.Templates) {
      return false;
    }
    const { selectedTemplates } = props.Data.current.Templates;
    if (
      !selectedTemplates ||
      !selectedTemplates.length ||
      selectedTemplates.length === 0
    ) {
      VMBC.Alert.Show("Select at least one template for plan", "error", 3000);
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    props.SubmitNextValidator && props.SubmitNextValidator(validator);
  }, []);

  if (!props.Data || !props.Data.current.Templates) {
    return null;
  }

  // update parent Data Object
  props.Data.current.Templates.selectedTemplates = templates;

  const onEdit = (editObj) => {
    let newArr = [];
    let found = false;
    templates.forEach((temp) => {
      if (temp.templateId !== editObj.TemplateId) {
        newArr.push({ ...temp });
        return;
      }
      found = true;
      newArr.push({
        ...temp,
        alias: editObj.Alias,
      });
    });
    setTemplates(newArr);
    return found;
  };
  const onDelete = (deleteObj) => {
    let newArr = [];
    templates.forEach((temp) => {
      if (temp.templateId !== deleteObj) {
        newArr.push({ ...temp });
      }
    });
    setTemplates(newArr);
  };

  return (
    <>
      <div className={`container`}>
        <div className={`row`}>
          <h6>Select Compute Resources:</h6>
        </div>

        <div className={`row justify-content-center TemplatesContainer`}>
          {templates.map((temp, i) => (
            <TemplateCard
              key={i}
              Alias={temp.alias}
              OsType={temp.osType}
              TemplateName={temp.templateName}
              TemplateId={temp.templateId}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}

          <AddTemplate
            Data={props.Data}
            onSelect={(temp) => {
              for (var i = 0; i < templates.length; i++) {
                if (templates[i].templateId === temp.templateId) {
                  VMBC.Alert.Show(
                    `You have already selected '${temp.templateName}'`,
                    "error",
                    3000
                  );
                  return;
                }
              }
              setTemplates([...templates, temp]);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Template;
