import React from "react";
import VMBC from "../../../../VMBC";
import "./TemplateCard.css";

function TemplateCard(props) {
  const [alias, setAlias] = React.useState(props.Alias);
  const [osType, setOsType] = React.useState(
    VMBC.Enums.OsTypes.indexOf(props.OsType)
  );
  const [refreshT, refresh] = React.useState(false);

  const DeleteTemplate = () => {
    if (!props.onDelete) {
      return;
    }
    window
      .swal({
        title: "Are you sure you want to remove this template?",
        text: "It'll only remove from this plan",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        padding: "2em",
      })
      .then(function (result) {
        if (result.value) {
          // swal("Deleted!", "Your file has been deleted.", "success");
          props.onDelete(props.TemplateId);
        }
      });
  };

  return (
    <>
      <div
        className={`col-xs-12 col-sm-6 col-md-2 text-center`}
        style={{
          margin: "10px",
          border: "2px solid grey",
          borderRadius: "5px",
          minHeight: "240px",
          position: "relative",
          padding: "5px",
        }}
      >
        <span style={{ position: "absolute", right: "0" }}>
          <span
            onClick={() => {
              setAlias(props.Alias);
              setOsType(VMBC.Enums.OsTypes.indexOf(props.OsType));
              window.$(`#editTemplate${props.key}`).modal("show");
            }}
            className={`cardActionButton`}
          >
            <VMBC.SVGs.EditByPen />
          </span>
          <span onClick={DeleteTemplate} className={`cardActionButton`}>
            <VMBC.SVGs.RecycleBin />
          </span>
        </span>
        <div
          className={`row justify-content-center`}
          style={{ padding: "10px 40px 0px" }}
        >
          <img
            src={
              VMBC.Enums.Logos[props.OsType]
                ? VMBC.Enums.Logos[props.OsType]
                : ""
            }
            alt={props.OsType}
            style={{ width: "100%" }}
          />
        </div>

        <div className={`templateDetails`}>Name: {props.Alias}</div>
        <div className={`templateDetails`}>OS Type: {props.OsType}</div>
        <div className={`templateDetails`}>
          Template Name: {props.TemplateName}
        </div>
      </div>

      <div
        className="modal fade"
        id={`editTemplate${props.key}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Edit Template"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Template:
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <VMBC.SVGs.CloseXIcon />
              </button>
            </div>
            <div className="modal-body">
              <div className={`container-fluid`}>
                <div
                  className={`row justify-content-center`}
                  style={{ padding: "0 40px 40px" }}
                >
                  <img
                    alt={alias}
                    src={
                      VMBC.Enums.Logos[props.OsType]
                        ? VMBC.Enums.Logos[props.OsType]
                        : ""
                    }
                    style={{ width: "100%" }}
                  />
                </div>
                <div className={`row`} style={{ marginBottom: "10px" }}>
                  <input
                    id="editTemplateAlias"
                    type="text"
                    name="alias"
                    placeholder="Template name: (shown to customer)"
                    className={`form-control`}
                    style={{ height: "48.5px" }}
                    value={alias}
                    onChange={(e) => setAlias(e.target.value)}
                  />
                </div>

                <div className={`row`}>
                  <select
                    disabled
                    className="selectpicker"
                    title={VMBC.Enums.OsTypes[osType]}
                    data-width="100%"
                    value={osType}
                  >
                    {VMBC.Enums.OsTypes.map((os, i) => (
                      <option value={i}>{os}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className={`btn btn-outline-primary`}
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                className={`btn btn-primary`}
                onClick={() => {
                  if (
                    props.onEdit &&
                    props.onEdit({
                      Alias: alias,
                      TemplateId: props.TemplateId,
                    })
                  ) {
                    window.$(`#editTemplate${props.key}`).modal("hide");
                    refresh(!refreshT);
                  }
                }}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TemplateCard;
