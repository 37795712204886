import React from "react";
import VMBC from "../../VMBC";
import Demo from "../../Demo";

function SysNet(props) {
  const { SystemNetwork } = props;

  const [editable, setEditable] = React.useState(false);
  const [ipAddresses, setIpAddresses] = React.useState(
    SystemNetwork.IpAddresses
  );
  const [gateway, setGateway] = React.useState(SystemNetwork.Gateway);
  const [dns, setDns] = React.useState(SystemNetwork.Dns);

  const submitData = () => {
    VMBC.Dialogs.Show({
      Title: "Are you sure you want to reconfigure system network?",
      Ignorable: true,
      Actions: [
        {
          className: "btn-outline-primary btn-sm",
          onClick: () => {
            VMBC.Dialogs.Close();
          },
          text: "No",
        },
        {
          className: "btn-primary btn-sm",
          onClick: () => {
            Demo.DelayRandom(() =>
              Demo.DelayRandom(() => {
                VMBC.Alert.Show("Done", "success");
                VMBC.Dialogs.Close();
              })
            );
          },
          text: "Yes",
        },
      ],
      Fields: (
        <>
          <p>
            <span style={{ color: "#fff", fontSize: "15px" }}>
              IP Addresses:
            </span>{" "}
            {ipAddresses}
          </p>
          <p>
            <span style={{ color: "#fff", fontSize: "15px" }}>Gateway:</span>{" "}
            {gateway}
          </p>
          <p>
            <span style={{ color: "#fff", fontSize: "15px" }}>DNS:</span> {dns}
          </p>
        </>
      ),
    });
    setEditable(false);
  };

  return (
    <div className="col-lg-6 col-md-12">
      <div className={`widget`}>
        <div class="widget-heading">
          <h5>System Network</h5>
        </div>
        <div class="widget-content px-4">
          <div class="input-group mt-2">
            <div class="input-group-prepend">
              <div class="input-group-text">IP Addresses:</div>
            </div>
            <input
              class="form-control"
              value={
                editable
                  ? ipAddresses
                  : SystemNetwork
                  ? SystemNetwork.IpAddresses
                  : "Loading..."
              }
              onChange={(e) => editable && setIpAddresses(e.target.value)}
            />
          </div>

          <div class="input-group mt-2">
            <div class="input-group-prepend">
              <div class="input-group-text">Gateway:</div>
            </div>
            <input
              class="form-control"
              value={
                editable
                  ? gateway
                  : SystemNetwork
                  ? SystemNetwork.Gateway
                  : "Loading..."
              }
              onChange={(e) => editable && setGateway(e.target.value)}
            />
          </div>

          <div class="input-group mt-2">
            <div class="input-group-prepend">
              <div class="input-group-text">DNS:</div>
            </div>
            <input
              class="form-control"
              value={
                editable
                  ? dns
                  : SystemNetwork
                  ? SystemNetwork.Dns
                  : "Loading..."
              }
              onChange={(e) => editable && setDns(e.target.value)}
            />
          </div>

          <div className={`row justify-content-end mt-2`}>
            {editable ? (
              <>
                <button
                  key={1}
                  className={`btn btn-outline-primary ml-1`}
                  onClick={() => {
                    setIpAddresses(SystemNetwork.IpAddresses);
                    setGateway(SystemNetwork.Gateway);
                    setDns(SystemNetwork.Dns);
                    setEditable(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  key={2}
                  className={`btn btn-primary ml-1`}
                  onClick={submitData}
                >
                  Submit
                </button>
              </>
            ) : (
              <button
                key={4}
                className={`btn btn-primary`}
                onClick={() => {
                  setIpAddresses(SystemNetwork.IpAddresses);
                  setGateway(SystemNetwork.Gateway);
                  setDns(SystemNetwork.Dns);
                  setEditable(true);
                }}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SysNet;
