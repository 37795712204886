import React from "react";
import IpField from "../../../../../Components/IpAddressField";
import IpAddressDataType from "../../../../../VMBC/DataTypes/IpAddress";
import VMBC from "../../../../../VMBC";
import SelectPortGroup from "./SelectPortGroup";

function AddSubnet(props) {
  const { ComputeResource, OnNewIp, Close } = props;

  const IPA = React.useRef();
  const [Gateway, setGateway] = React.useState();
  const [DNS1, setDNS1] = React.useState();
  const [DNS2, setDNS2] = React.useState();
  const [portGroup, setPortGroup] = React.useState();
  const [refreshT, refresh] = React.useState(false);
  const [messageShown, setMessageShown] = React.useState(false);

  const ipAddrArray = React.useRef([]);

  if (!OnNewIp) {
    return null;
  }

  const addBtnClicked = () => {
    ipAddrArray.current = [];
    if (!IPA || !Gateway) {
      VMBC.Alert.Show("Check your inputs", "warning", 3000);
      return;
    }
    if (props.ComputeResource !== false && !portGroup) {
      VMBC.Alert.Show("Please select a Port Group", "warning", 3000);
      return;
    }
    var GateWayIpObj = new IpAddressDataType(
      Gateway,
      Number(IPA.current.NetPrefix)
    );
    let AllIpAddresses = new IpAddressDataType(
      IPA.current.NetworkId,
      IPA.current.NetPrefix
    ).GetAllIpAddressesInSubnet();
    if (AllIpAddresses.length === 0) {
      VMBC.Alert.Show("No IP Addresses found in this range", "error", 3000);
      return;
    }
    for (var ipAddIndex = 0; ipAddIndex < AllIpAddresses.length; ipAddIndex++) {
      var ipAdd = AllIpAddresses[ipAddIndex];
      if (ipAdd._ip === GateWayIpObj._ip) {
        continue; // if this ip is the gateway. do not add
      }
      var ipObj = {
        Ip: ipAdd._ip,
        SubnetMask: ipAdd.NetPrefixToSubnetMask()._ip,
        Gateway: GateWayIpObj._ip,
        Dns1: 0,
        Dns2: 0,
        RDNS: "",
        PortGroupId: portGroup ? portGroup.MO_Reference : "",
      };
      if (DNS1) {
        ipObj.Dns1 = DNS1;
        if (DNS2) {
          ipObj.Dns2 = DNS2;
        }
      } else if (DNS2) {
        ipObj.Dns1 = DNS2;
      }
      ipAddrArray.current = [...ipAddrArray.current, ipObj];
    }
    setMessageShown(true);
  };

  return (
    <>
      <div className={`row justify-content-center mb-2`}>
        <IpField
          label="Subnet"
          onChange={(ipA) => {
            IPA.current = ipA;
          }}
          Subnet
          // defaultValue={{ NetworkId: "192.168.1.1", NetPrefix: "28" }}
        />
      </div>
      <div className={`row justify-content-center mb-2`}>
        <IpField
          label="Gateway"
          onChange={setGateway}
          defaultValue={Number(localStorage.getItem("addIpSubnetGateway"))}
        />

        <VMBC.ToolTip title={"Remember"}>
          <label class="switch s-outline s-outline-info mt-2 ml-2">
            <input
              type="checkbox"
              defaultChecked={
                localStorage.getItem("addIpSubnetGateway") != undefined
              }
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem("addIpSubnetGateway", Gateway);
                } else {
                  localStorage.removeItem("addIpSubnetGateway");
                }
              }}
            />
            <span class="slider round"></span>
          </label>
        </VMBC.ToolTip>
      </div>
      <div className={`row justify-content-center mb-2`}>
        <IpField
          label="DNS1"
          onChange={setDNS1}
          defaultValue={Number(localStorage.getItem("addIpSubnetDns1"))}
        />
        <VMBC.ToolTip title={"Remember"}>
          <label class="switch s-outline s-outline-info mt-2 ml-2">
            <input
              type="checkbox"
              defaultChecked={
                localStorage.getItem("addIpSubnetDns1") != undefined
              }
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem("addIpSubnetDns1", DNS1);
                } else {
                  localStorage.removeItem("addIpSubnetDns1");
                }
              }}
            />
            <span class="slider round"></span>
          </label>
        </VMBC.ToolTip>
      </div>
      <div className={`row justify-content-center mb-2`}>
        <IpField
          label="DNS2"
          onChange={setDNS2}
          defaultValue={Number(localStorage.getItem("addIpSubnetDns2"))}
        />
        <VMBC.ToolTip title={"Remember"}>
          <label class="switch s-outline s-outline-info mt-2 ml-2">
            <input
              type="checkbox"
              defaultChecked={
                localStorage.getItem("addIpSubnetDns2") != undefined
              }
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem("addIpSubnetDns2", DNS2);
                } else {
                  localStorage.removeItem("addIpSubnetDns2");
                }
              }}
            />
            <span class="slider round"></span>
          </label>
        </VMBC.ToolTip>
      </div>
      {ComputeResource != undefined && ComputeResource !== false ? (
        <div className={`row justify-content-center mb-2`}>
          <SelectPortGroup
            key={refreshT ? 1 : 0}
            ComputeResource={ComputeResource}
            onChange={(ref) => setPortGroup(ref.pg)}
          />
          <VMBC.SVGs.RefreshCCW
            onClick={() => refresh(!refreshT)}
            size={20}
            style={{ cursor: "pointer", margin: "12px 0 0 8px" }}
          />
        </div>
      ) : undefined}
      {messageShown ? (
        <>
          <div className={`row justify-content-center`}>
            {ipAddrArray.current.length} Ip Addresses are going to be added to
            IP Addresses List.
            <br />
            Are you sure you want to continue?
          </div>
          <div className={`row justify-content-center mt-2`}>
            <button
              className={`btn btn-primary btn-sm`}
              onClick={() => {
                OnNewIp([...ipAddrArray.current]);
                setMessageShown(false);
                if (Close != undefined && typeof Close === "function") {
                  Close();
                }
              }}
            >
              Yes
            </button>
            <button
              className={`btn btn-primary btn-sm`}
              onClick={() => setMessageShown(false)}
            >
              No
            </button>
          </div>
        </>
      ) : (
        <div className={`row justify-content-center`}>
          <button className={`btn btn-primary btn-sm`} onClick={addBtnClicked}>
            Add
          </button>
        </div>
      )}
    </>
  );
}

export default AddSubnet;
