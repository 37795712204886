import React from "react";
import VMBC from "../../../../VMBC";
import Demo from "../../../../Demo";

function Finishing(props) {
  const Plan = React.useRef();
  const [DataStr, setDataStr] = React.useState("");

  const [planDetails, setPlanDetails] = React.useState();
  const [tabValue, setTabValue] = React.useState(0);

  React.useEffect(() => {
    var details = {};
    var Data = props.Data.current;
    var newPlan = new VMBC.Plans.Plan();

    details.PlanName = Data.PlanAndTarget.PlanName;
    details.TargetFolderName = Data.PlanAndTarget.TargetFolder.Name;
    details.DatacenterName = Data.PlanAndTarget.TargetDatacenter.Name;
    details.Templates = [];
    Data.Templates.selectedTemplates.forEach((temp) => {
      details.Templates.push(temp.alias);
    });
    details.ComputeResources = [];
    Data.ComputeResources.SelectedComputeResources.forEach((comp) => {
      var newCR = { Datastores: [], IpPools: [], Name: comp.Name };
      comp.SelectedDatastores.forEach((DS) => {
        newCR.Datastores.push(DS.Name);
      });
      comp.SelectedIpPools.forEach((ipPool) => {
        newCR.IpPools.push(ipPool.Name);
      });
      details.ComputeResources.push(newCR);
    });
    setPlanDetails(details);

    newPlan.SetDetails(
      Data.PlanAndTarget.PlanName,
      Data.PlanAndTarget.TargetFolder.FolderId,
      Data.PlanAndTarget.TargetDatacenter.DatacenterId,
      Data.ComputeResources.PriorityByDatastore,
      Data.Datastores.PriorityByDatastore,
      {
        vmOptions: {
          name: Data.Customization.NamingOptions.name,
          networkQuota: Data.Customization.NetworkQuota.enabled
            ? {
                Tx: Data.Customization.NetworkQuota.LimitTx,
                Rx: Data.Customization.NetworkQuota.LimitRx,
                Usage: Data.Customization.NetworkQuota.LimitUsage,
                Reset: Data.Customization.NetworkQuota.ResetAfter,
                RatioLimitEnabled: !!Data.Customization.NetworkQuota
                  .limitByRatio,
                RatioLimitType: Data.Customization.NetworkQuota.limitRatioType,
                RatioAllowed: Data.Customization.NetworkQuota.maxAllowedRatio,
                RatioCheckpoint:
                  Data.Customization.NetworkQuota.limitByRatioCheckpoint,
                RatioCheckpointType:
                  Data.Customization.NetworkQuota.limitByRatioCheckpointType,
              }
            : false,
        },
        CPU: Data.Customization.CPU,
        Memory: Data.Customization.Memory,
        HardDisk: Data.Customization.HardDisk,
      }
    );
    Data.ComputeResources.SelectedComputeResources.forEach((comp) => {
      var soluObj = newPlan.AddSolution(comp.ComputeResourceMO);
      comp.SelectedDatastores.forEach((DS) => {
        soluObj.AddDatastore(DS.DatastoreMO);
      });
      comp.SelectedIpPools.forEach((ipPool) => {
        soluObj.AddIpPoolId(ipPool.Id);
      });
      if (
        Data.Customization.CdDrive &&
        Data.Customization.CdDrive.enabled &&
        comp.SelectedCdIsoFiles
      ) {
        comp.SelectedCdIsoFiles.forEach((cdIsoFileId) => {
          soluObj.AddCdIsoFileId(cdIsoFileId);
        });
      }
    });
    Data.Templates.selectedTemplates.forEach((temp) => {
      newPlan.AddTemplate(temp.alias, temp.templateId);
    });
    newPlan.Log();
    Plan.current = newPlan;
    setDataStr(JSON.stringify(newPlan));
  }, []);

  const onFinish = () => {
    window.$.blockUI({
      message:
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader spin"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>',
      fadeIn: 300,
      // timeout: 2000, //unblock after 2 seconds
      overlayCSS: {
        backgroundColor: "#191e3a",
        opacity: 0.8,
        zIndex: 1200,
        cursor: "wait",
      },
      css: {
        border: 0,
        color: "#25d5e4",
        zIndex: 1201,
        padding: 0,
        backgroundColor: "transparent",
      },
    });

    Demo.DelayRandom(() => {
      Demo.DelayRandom(() => {
        Demo.DelayRandom(() => {
          window.$.unblockUI();
          window
            .swal({
              title: "Done!",
              text: "Plan Created Successfully!",
              type: "success",
              padding: "2em",
            })
            .then((res) => {
              if (props.onGotoBegining) {
                props.onGotoBegining();
              }
            });
        });
      });
    });
  };

  return (
    <div className={`container mt-4`}>
      <div className={`row justify-content-center`}>
        <div className={`col-xs-12 col-md-7`}>
          <div className={`container-fluid`}>
            <div className={`row justify-content-center`}>
              <div className={`col-10`}>
                <h2 className={`text-success`}>
                  {planDetails ? planDetails.PlanName : ""}
                </h2>
              </div>
              <div className={`col-9`}>
                <h5 className={`text-info`}>
                  Datacenter: {planDetails ? planDetails.DatacenterName : ""}
                </h5>
              </div>
              <div className={`col-9`}>
                <h5 className={`text-info`}>
                  Target Folder:{" "}
                  {planDetails ? planDetails.TargetFolderName : ""}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className={`col-xs-12 col-md-5 mt-3`}>
          <div className={`container-fluid`}>
            <div className={`row justify-content-center`}>
              <div className={`col-10`}>
                <h5 className={`text-warning`}>Templates:</h5>
              </div>
              {planDetails
                ? planDetails.Templates.map((name) => (
                    <div className={`col-9`}>
                      <h6>{name}</h6>
                    </div>
                  ))
                : undefined}
            </div>
          </div>
        </div>
        {planDetails ? (
          <>
            <div className={`col-xs-12 col-sm-10 mt-4`}>
              <h5 className={`text-info`}>Compute Resources:</h5>
            </div>
            <div className={`col-xs-12 col-sm-10`}>
              <ul class="nav nav-tabs mb-3 mt-3 nav-fill" role="tablist">
                {planDetails.ComputeResources.map((comp, i) => (
                  <li class="nav-item" key={i}>
                    <a
                      class={`nav-link ${i === 0 ? "active" : ""}`}
                      id={`comps-list-${i}-tab`}
                      data-toggle="pill"
                      href={`#comps-list-${i}`}
                      role="tab"
                      aria-controls={`comps-list-${i}`}
                      aria-selected={i === 0 ? "true" : "false"}
                    >
                      {comp.Name}
                    </a>
                  </li>
                ))}
              </ul>

              <div class="tab-content">
                {planDetails.ComputeResources.map((comp, i) => (
                  <div
                    key={i}
                    class={`tab-pane fade ${i === 0 ? "show active" : ""}`}
                    id={`comps-list-${i}`}
                    role="tabpanel"
                    aria-labelledby={`comps-list-${i}-tab`}
                  >
                    <div className={`container`}>
                      <div className={`row justify-content-around`}>
                        <div className={`col`}>
                          <div className={`container-fluid`}>
                            <div className={`row justify-content-center`}>
                              <div className={`col-xs-12 col-sm-10`}>
                                <h5>Datastores:</h5>
                              </div>
                              {comp.Datastores.map((dsName) => (
                                <div className={`col-xs-12 col-sm-9`}>
                                  <h6>{dsName}</h6>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className={`col`}>
                          <div className={`container-fluid`}>
                            <div className={`row justify-content-center`}>
                              <div className={`col-xs-12 col-sm-10`}>
                                <h5>IP Pools:</h5>
                              </div>
                              {comp.IpPools.map((ipPoolName) => (
                                <div className={`col-xs-12 col-sm-9`}>
                                  <h6>{ipPoolName}</h6>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : undefined}
        <div className={`col-12 mt-5`}>
          <div className={`container`}>
            <div className={`row justify-content-center`}>
              <button className={`btn btn-primary btn-lg`} onClick={onFinish}>
                Finish
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Finishing;
