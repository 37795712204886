import React from "react";
import { withRouter } from "react-router";
import VMBC from "../VMBC";

function Logout(props) {
  VMBC.Auth.logout(() => {
    VMBC.Navigate(props.history, "/");
  });
  return <>Redirecting...</>;
}

export default withRouter(Logout);
