import React from "react";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import Settings from "./Settings";
window.AddToLoadingVmboxProccess(1);

function SettingsRoot(props) {
  return (
    <Switch>
      <Route path={`${props.match.path}/`} exact children={<Settings />} />
    </Switch>
  );
}

export default withRouter(SettingsRoot);
