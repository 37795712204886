import React from "react";
import VMBC from "../../VMBC";

import VCenter from "./VCenter";
import SysNet from "./SysNet";
import WebServerConfig from "./WebServerConfig";
import Demo from "../../Demo";
window.AddToLoadingVmboxProccess(1);

function Settings(props) {
  const [data, setData] = React.useState();
  const [refreshT, refresh] = React.useState(false);

  React.useEffect(() => {
    setData();
    Demo.DelayRandom(() =>
      Demo.DelayRandom(() => {
        setData({
          VcenterAddr: "vc.fireserver.org",
          VcenterUser: "administrator@vsphere.local",
          IsVcenterConnected: true,
          SystemNetwork: {
            EtherName: "ens160",
            IpAddresses: "148.251.10.12/27",
            Gateway: "148.251.10.1",
            Dns: "8.8.8.8, 4.2.2.4",
          },
          Config: {
            ConfigIFAddr: "0.0.0.0:8001",
            ConfigIFAddrSSL: "0.0.0.0:4443",
            ConfigIFHostname: "vmbox.yourhosting.com",
            ClientIFAddr: "0.0.0.0:8000",
            ClientIFSSL: true,
            ClientIFHostname: "vmbox.yourhosting.com",
            CustomerIFAddr: "0.0.0.0:443",
            CustomerIFSSL: false,
            CustomerIFHostname: "panel.yourhosting.com",
            CustomerIFACME: true,
            CustomerIFACMEBuiltIn: false,
          },
        });
      })
    );
  }, [refreshT]);

  return (
    <div className={`row layout-top-spacing layout-spacing`}>
      <VCenter
        VCenter={
          data
            ? {
                VcenterAddr: data.VcenterAddr,
                VcenterUser: data.VcenterUser,
              }
            : false
        }
      />
      <SysNet
        key={data ? 3 : 2}
        SystemNetwork={data ? data.SystemNetwork : false}
      />
      <WebServerConfig
        key={data ? 1 : 0}
        Config={data ? data.Config : false}
        refresh={() => refresh(!refreshT)}
      />
    </div>
  );
}

export default Settings;
