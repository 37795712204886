import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

function CustomeTooltip(props) {
  const id = "" + Math.random();
  return (
    <>
      <span data-tip data-for={id}>
        {props.children}
      </span>
      <ReactTooltip
        id={id}
        delayHide={250}
        place="top"
        type="dark"
        effect="solid"
        {...props.tooltip}
      >
        {props.title}
      </ReactTooltip>
    </>
  );
}

CustomeTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.shape({
    afterHide: PropTypes.func,
    afterShow: PropTypes.func,
    arrowColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    bodyMode: PropTypes.bool,
    border: PropTypes.bool,
    borderColor: PropTypes.string,
    clickable: PropTypes.bool,
    delayHide: PropTypes.number,
    delayShow: PropTypes.number,
    delayUpdate: PropTypes.number,
    disable: PropTypes.bool,
    effect: PropTypes.string,
    event: PropTypes.string,
    eventOff: PropTypes.string,
    globalEventOff: PropTypes.string,
    html: PropTypes.bool,
    id: PropTypes.string,
    insecure: PropTypes.bool,
    inCapture: PropTypes.bool,
    multiline: PropTypes.bool,
    place: PropTypes.string,
    resizeHide: PropTypes.bool,
    scrollHide: PropTypes.bool,
    textColor: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default CustomeTooltip;
