import React from "react";
import AddSubnet from "./AddSubnet";
import AddSingle from "./AddSingle";
import AddRange from "./AddRange";

function AddIpAddress(props) {
  const { ComputeResource, OnNewIp, Close } = props;

  var tabIndex = Number(localStorage.getItem("addIpAddressTabIndex"));
  tabIndex = tabIndex != undefined ? tabIndex : 0;

  return (
    <>
      <ul
        className="nav nav-tabs  mb-3 mt-3 nav-fill"
        id="justifyTab"
        role="tablist"
      >
        <li className="nav-item">
          <a
            className={`nav-link ${tabIndex === 0 ? "active" : ""}`}
            id="justify-subnet-tab"
            data-toggle="tab"
            href="#justify-subnet"
            role="tab"
            aria-controls="justify-subnet"
            aria-selected={tabIndex === 0 ? "true" : "false"}
            onClick={() => localStorage.setItem("addIpAddressTabIndex", 0)}
          >
            Subnet
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${tabIndex === 1 ? "active" : ""}`}
            id="justify-single-tab"
            data-toggle="tab"
            href="#justify-single"
            role="tab"
            aria-controls="justify-single"
            aria-selected={tabIndex === 1 ? "true" : "false"}
            onClick={() => localStorage.setItem("addIpAddressTabIndex", 1)}
          >
            Single
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${tabIndex === 2 ? "active" : ""}`}
            id="justify-range-tab"
            data-toggle="tab"
            href="#justify-range"
            role="tab"
            aria-controls="justify-range"
            aria-selected={tabIndex === 2 ? "true" : "false"}
            onClick={() => localStorage.setItem("addIpAddressTabIndex", 2)}
          >
            Range
          </a>
        </li>
      </ul>

      <div className="tab-content" id="justifyTabContent">
        <div
          className={`tab-pane fade ${tabIndex === 0 ? "show active" : ""}`}
          id="justify-subnet"
          role="tabpanel"
          aria-labelledby="justify-subnet-tab"
        >
          <div className={`container-fluid`}>
            <AddSubnet
              ComputeResource={ComputeResource}
              OnNewIp={OnNewIp}
              Close={Close}
            />
          </div>
        </div>
        <div
          className={`tab-pane fade ${tabIndex === 1 ? "show active" : ""}`}
          id="justify-single"
          role="tabpanel"
          aria-labelledby="justify-single-tab"
        >
          <div className={`container-fluid`}>
            <AddSingle ComputeResource={ComputeResource} OnNewIp={OnNewIp} />
          </div>
        </div>
        <div
          className={`tab-pane fade ${tabIndex === 2 ? "show active" : ""}`}
          id="justify-range"
          role="tabpanel"
          aria-labelledby="justify-range-tab"
        >
          <div className={`container-fluid`}>
            <AddRange
              ComputeResource={ComputeResource}
              OnNewIp={OnNewIp}
              Close={Close}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddIpAddress;
