import React from "react";
import "./ComputeResource.css";
import styles from "./index.module.css";
import VMBC from "../../../../VMBC";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

function ComputeResource(props) {
  const [addDisabled, setAddDisabled] = React.useState(true);
  const [removeDisabled, setRemoveDisabled] = React.useState(true);

  const [priority, setPriority] = React.useState(
    props.Data &&
      props.Data.current.ComputeResources &&
      !props.Data.current.ComputeResources.PriorityByDatastore
      ? "1"
      : "0"
  );
  const [collection, setCollection] = React.useState(
    props.Data
      ? props.Data.current.ComputeResources.oldCollection
        ? props.Data.current.ComputeResources.oldCollection
        : [
            {
              name: props.Data.current.PlanAndTarget.TargetDatacenter.Name,
              fromItems: props.Data.current.PlanAndTarget.TargetDatacenter.DatacenterMO.ComputeResources.map(
                (comp) => ({ name: comp.Name, ComputeResourceMO: comp })
              ),
              toItems: [],
            },
          ]
      : []
  );
  const [rearrangeT, rearrangeSelectedItems] = React.useState(false);

  var validator = () => {
    if (!props.Data || !props.Data.current.ComputeResources) {
      return false;
    }
    const { SelectedComputeResources } = props.Data.current.ComputeResources;
    if (
      !SelectedComputeResources ||
      !SelectedComputeResources.length ||
      SelectedComputeResources.length === 0
    ) {
      VMBC.Alert.Show("Select at least one compute resource", "error", 3000);
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    props.SubmitNextValidator && props.SubmitNextValidator(validator);
    if (props.Data && props.Data.current.ComputeResources) {
      setCollection([
        ...props.Data.current.ComputeResources.oldCollection.map((g) => ({
          name: g.name,
          fromItems: [...g.fromItems],
          toItems: [...g.toItems],
        })),
      ]);
    }

    collection.forEach((items, i) => {
      window
        .dragula([document.getElementById("to-" + items.name)], {
          accepts: function (el, target, source, sibling) {
            // console.log(el.id, target.id, source.id);
            return target.id === source.id; // elements can be dropped in any of the `containers` by default
          },
        })
        .on("drag", function (el) {
          el.className += " el-drag-ex-1";
        })
        .on("drop", (el) => {
          el.className = el.className.replace("el-drag-ex-1", "");
          rearrangeSelectedItems(!rearrangeT); // trigger a few lines bellow React useEffect to rearrange the list of selected Objects
        })
        .on("cancel", function (el) {
          el.className = el.className.replace("el-drag-ex-1", "");
        });
    });
  }, []);

  React.useEffect(() => {
    // submit realocations by dragula
    // and save it in collection
    const selectedComps = [];
    document
      .getElementById(`to-${collection[0].name}`)
      .childNodes.forEach((childNode) => {
        var nodeId = Number(childNode.id);
        selectedComps.push({
          Name: collection[0].toItems[nodeId].name,
          ComputeResourceMO: collection[0].toItems[nodeId].ComputeResourceMO,
        });
      });
    props.Data.current.ComputeResources.SelectedComputeResources = selectedComps;

    const newCol = [...collection];
    for (var colIndex = 0; colIndex < newCol.length; colIndex++) {
      const newToItems = [];
      document
        .getElementById(`to-${newCol[colIndex].name}`)
        .childNodes.forEach((childNode) => {
          var nodeId = Number(childNode.id);
          newToItems.push(newCol[colIndex].toItems[nodeId]);
        });
      newCol[colIndex].toItems = newToItems;
    }
    setCollection(newCol);
  }, [rearrangeT]);

  React.useEffect(() => {
    if (!props.Data) return;
    props.Data.current.ComputeResources.SelectedComputeResources = collection[0].toItems.map(
      (item) => ({
        Name: item.name,
        ComputeResourceMO: item.ComputeResourceMO,
      })
    );
    props.Data.current.Datastores.oldCollection = null;
  }, [collection]);

  if (!props.Data || !props.Data.current.ComputeResources) {
    return null;
  }

  props.Data.current.ComputeResources.PriorityByDatastore = priority === "0";
  props.Data.current.ComputeResources.oldCollection = [
    ...collection.map((g) => ({
      name: g.name,
      fromItems: [...g.fromItems],
      toItems: [...g.toItems],
    })),
  ];

  const selectItem = () => {
    collection.forEach((g) => {
      var selectedItems = [];
      var selectedItemsIndex = [];
      g.fromItems.forEach((item, index) => {
        if (item.selected) {
          item.selected = false;
          selectedItemsIndex.push(index);
          selectedItems.push(item);
          window.AA = item.ComputeResourceMO;
          item.ComputeResourceMO.ConstructProperties([
            "hostsystems",
            "networks",
          ]);
          item.ComputeResourceMO.GetIpPools();
          item.ComputeResourceMO.GetCdIsoFiles();
          item.ComputeResourceMO.Datastores.forEach((DS) => DS.GetSummary());
        }
      });
      g.fromItems = g.fromItems.filter(
        (v, i) => !selectedItemsIndex.includes(i)
      );
      g.toItems.push(...selectedItems);
    });
    props.Data.current.Datastores.oldCollection = false;
    props.Data.current.IpPools.oldCollection = false;

    setCollection([
      ...collection.map((g) => ({
        ...g,
        fromItems: [...g.fromItems],
        toItems: [...g.toItems],
      })),
    ]);
    checkAddRemoveDisabled();
  };

  const unSelectItem = () => {
    collection.forEach((g) => {
      var selectedItems = [];
      var selectedItemsIndex = [];
      g.toItems.forEach((item, index) => {
        if (item.selected) {
          item.selected = false;
          selectedItemsIndex.push(index);
          selectedItems.push(item);
        }
      });
      g.toItems = g.toItems.filter((v, i) => !selectedItemsIndex.includes(i));
      g.fromItems.push(...selectedItems);
    });
    props.Data.current.Datastores.oldCollection = false;
    props.Data.current.IpPools.oldCollection = false;
    setCollection([
      ...collection.map((g) => ({
        ...g,
        fromItems: [...g.fromItems],
        toItems: [...g.toItems],
      })),
    ]);
    checkAddRemoveDisabled();
  };

  const checkAddRemoveDisabled = () => {
    let add = false;
    let rem = false;
    collection.forEach((g) => {
      for (var i = 0; i < g.fromItems.length && !add; i++) {
        if (g.fromItems[i].selected) {
          add = true;
          break;
        }
      }
      for (var i = 0; i < g.toItems.length && !rem; i++) {
        if (g.toItems[i].selected) {
          rem = true;
          break;
        }
      }
    });
    setAddDisabled(!add);
    setRemoveDisabled(!rem);
  };

  return (
    <>
      <div className={`container`}>
        <div className={`row`}>
          <h6>Select Compute Resources:</h6>
        </div>

        <div className={`row`}>
          <ScrollBar className={`${styles.SelectPanel} col`}>
            <ul className={`${styles.TreeViewRoot} file-tree`}>
              {collection.map((items, index) => (
                <React.Fragment key={index}>
                  <li
                    className={`file-tree-folder`}
                    onClick={function (e) {
                      window
                        .$(e.target)
                        .children("ul")
                        .slideToggle(400, function () {
                          window.$(this).parent("li").toggleClass("open");
                        });
                    }}
                  >
                    {items.name}
                    <ul>
                      {items.fromItems && items.fromItems.length > 0 ? (
                        items.fromItems.map((item, i) => (
                          <SortableTreeItemComponent
                            key={i}
                            value={item.name}
                            onChange={(e) => {
                              item.selected = e;
                              checkAddRemoveDisabled();
                            }}
                          />
                        ))
                      ) : (
                        <li> {"No Compute Resources"} </li>
                      )}
                    </ul>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </ScrollBar>

          <div>
            <div
              className={`${styles.MoveButtonsContainer} container align-items-center`}
            >
              <div className={`row`}>
                <span
                  className={styles.MoveButtons}
                  disabled={addDisabled}
                  onClick={selectItem}
                >
                  <VMBC.SVGs.ChevronsRight
                    size="35"
                    style={{ color: "#eee" }}
                  />
                </span>
              </div>
              <div className={`row`}>
                <span
                  className={styles.MoveButtons}
                  onClick={unSelectItem}
                  disabled={removeDisabled}
                >
                  <VMBC.SVGs.ChevronsLeft size="35" style={{ color: "#eee" }} />
                </span>
              </div>
            </div>
          </div>

          <ScrollBar className={`${styles.SelectPanel} col`}>
            <ul className={`${styles.TreeViewRoot} file-tree`}>
              {collection.map((items, index) => (
                <React.Fragment key={index}>
                  <li
                    className={`file-tree-folder`}
                    onClick={(e) => {
                      window
                        .$(e.target)
                        .children("ul")
                        .slideToggle(400, function () {
                          window.$(this).parent("li").toggleClass("open");
                        });
                    }}
                  >
                    {items.name}
                    <ul
                      id={`to-${items.name}`}
                      className={`selectableContainer`}
                    >
                      {items.toItems && items.toItems.length > 0
                        ? items.toItems.map((item, i) => (
                            <SortableTreeItemComponent
                              id={i}
                              key={item.name}
                              value={item.name}
                              index={i}
                              collection={index}
                              onChange={(e) => {
                                item.selected = e;
                                checkAddRemoveDisabled();
                              }}
                              icon={
                                <>{i === 0 ? "Drag Drop to rearrange" : ""}</>
                              }
                            />
                          ))
                        : undefined}
                    </ul>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </ScrollBar>
        </div>

        {/* radio options */}

        {/* <Grid item container justify="flex-end" xs={11}>
        <RadioGroup
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
          row
        >
          <FormControlLabel value="" control={<></>} label="Priority:" />
          <FormControlLabel
            value="0"
            control={<Radio color="primary" />}
            label="Best Datastore"
          />
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="Respectively"
          />
        </RadioGroup>
      </Grid> */}
      </div>
    </>
  );
}

function arrayMove(Arr, from, to) {
  var Temp = Arr[from];
  var Step = 1;
  if (from > to) {
    Step = -1;
  }
  for (var i = from; i !== to; i += Step) {
    Arr[i] = Arr[i + Step];
  }
  Arr[to] = Temp;
  return Arr;
}

function SortableTreeItemComponent(props) {
  const { value, isMsg, onChange, icon } = props;
  const [checked, setChecked] = React.useState(false);
  React.useEffect(() => setChecked(false), [value]);

  return (
    <li
      id={props.id}
      onClick={(e) => {
        onChange(!checked);
        setChecked(!checked);
      }}
      nodeId={"" + value}
      onFocus={(e) => {
        if (e.currentTarget === e.target) {
          window.focus(e.target);
        }
      }}
    >
      <div className={`container`}>
        <div className={`row justify-content-between align-items-center`}>
          <span>
            {!isMsg ? (
              <label
                class="new-control new-checkbox checkbox-primary"
                style={{ color: "#fff" }}
              >
                <input
                  type="checkbox"
                  class="new-control-input"
                  onChange={(e) => {
                    setChecked(!e.target.checked);
                    onChange(!e.target.checked);
                  }}
                  checked={checked}
                />
                <span class="new-control-indicator"></span>
                {value}
              </label>
            ) : undefined}
          </span>
          {icon}
        </div>
      </div>
    </li>
  );
}

export default ComputeResource;
